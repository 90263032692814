import React from 'react';
import { Link } from "react-router-dom";
import { Button, Icon } from '@material-ui/core';

class headerButton extends React.Component {
  render() {
    const { label, onClick, path, icone, RIcone, disabled } = this.props;
    return (
      <div>
        {path ?
          <Link to={path}>
            <Button variant="contained" className={icone === 'arrow_back' ? "kt-button-voltar" : "kt-button-adicionar"} disabled={disabled}>
              {RIcone ? <span className="kt-icons">{RIcone()}</span> : <Icon className={icone === 'arrow_back' ? "mr-2 kt-icons-voltar" : "mr-2 kt-icons"}>{icone ? icone : 'add'}</Icon>} {label ? label : "Adicionar"}
            </Button>
          </Link>
          :
          <div style={{ marginRight: '5px' }}>
            <Button variant="contained" className="kt-button-adicionar" disabled={disabled} onClick={onClick}>
              {RIcone ? <span className="kt-icons">{RIcone()}</span> : <Icon className="kt-icons">{icone ? icone : 'add'}</Icon>} {label ? label : "Adicionar"}
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default headerButton;
