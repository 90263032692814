import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .line {
    display: flex;
    height: 1px;
    width: 85%;
    background: #807e80;
  }

  p {
    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #807e80;

    margin: 16px 0;
  }
`;
