import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";

export const RitualTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    max-height: 40px !important;
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const RitualTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #807e80;
    line-height: 150%;
    height: 32px;
    padding: 0 16px;
    border: none;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Light", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #4d4c4d;
    height: 32px;
    padding: 0 16px 0px 16px;
    border: none;

    white-space: nowrap;

    .ritual-link {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 400;
      color: #4672C5;
      text-transform: capitalize;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  .status-select {
    padding: 4px 0;
    & > div {
      min-height: 30px;
      & > div {
        min-height: 30px;
      }
    }

    select {
      padding: 2px 12px;
      min-height: 38px;
    }

    .chevron {
      height: 40px;
    }

    .select__control {
      min-height: 30px;
      min-width: 90px;

      border: 1px solid #4d4c4d77;
    }
  }
`;
