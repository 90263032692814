import React, { useEffect } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { Button, StyledModal } from "./styles";
import formatCurrency from "../../../../../../utils/FormatCurrency";
import moment from "moment";

export const ActivityModal = ({ open, edit, professionalEdit, close, options, handleConfirm, idRFQ, rfqDefault, wageHourDefault, projectType }) => {
  const [atividade, setAtividade] = React.useState(rfqDefault);
  const [dataAtivacao, setDataAtivacao] = React.useState(new Date());
  const [valorHora, setValorHora] = React.useState(0);
  const [qtdHorasProjeto, setQtdHorasProjeto] = React.useState(0);
  const [valorHoraProjeto, setValorHoraProjeto] = React.useState(0);
  const [qtdHoras, setQtdHoras] = React.useState(0);

  React.useEffect(() => {
    if(idRFQ) {
      return setAtividade(idRFQ ?? "");
    }
  }, [idRFQ]);

  useEffect(() => {
    if(rfqDefault) {
      return setAtividade(rfqDefault);
    }
  }, [rfqDefault]);

  useEffect(() => {
    if(wageHourDefault) {
      return setValorHora(wageHourDefault);
    }
  }, [wageHourDefault]);

  useEffect(() => {
    if(edit) {
      if (professionalEdit.idBusinessProposalValue) {
        const atividade = options.find((o) => o.value === professionalEdit.idBusinessProposalValue)
        atividade && setAtividade(atividade)
      }
      setDataAtivacao(moment(professionalEdit.activateDate).toDate())
      professionalEdit.wageHour && setValorHora(professionalEdit.wageHour)
      if (projectType === 'FÁBRICA') {
        professionalEdit.quantityHour && setQtdHoras(professionalEdit.quantityHour)
        professionalEdit.wageHourProject && setValorHoraProjeto(professionalEdit.wageHourProject)
        professionalEdit.quantityHourProject && setQtdHorasProjeto(professionalEdit.quantityHourProject)
      }
    }
  }, [professionalEdit]);

  return (
    <>
      <StyledModal show={open} onHide={close} size="lg" centered>
        <Modal.Body>
          <h1 className="modal-title">
            Selecione abaixo a atividade desse profissional no projeto:
          </h1>
          <Form>
            {
              projectType !== 'FÁBRICA' ?
              <>
                <Form.Row>
                  {/* Atividade do Profissional */}
                  <Form.Group as={Col} lg="18" xs="12">
                    <InputField
                      as="select2"
                      name="atividade"
                      label="Atividade do Profissional"
                      value={atividade}
                      options={options}
                      onChange={e => setAtividade(e)}
                      closeMenuOnSelect
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {/* Data de Ativação */}
                  <Form.Group as={Col} lg="18" xs="6">
                    <InputField
                      type="date"
                      name="dataAtivacao"
                      label="Data de Ativação"
                      placeholder="Digite a Data de Ativação"
                      value={dataAtivacao}
                      onChange={e => setDataAtivacao(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {/* Valor Hora  */}
                  <Form.Group as={Col} lg="18" xs="6">
                    <InputField
                      type="text"
                      name="valorHora"
                      label="Valor Hora"
                      value={valorHora}
                      placeholder="Digite o valor hora"
                      onChange={e => {
                        setValorHora(formatCurrency(e.target.value))
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              </> :
              <>
                <Form.Row>
                  {/* Atividade do Profissional */}
                  <Form.Group as={Col} lg="18" xs="9">
                    <InputField
                      as="select2"
                      name="atividade"
                      label="Atividade do Profissional"
                      value={atividade}
                      options={options}
                      onChange={e => setAtividade(e)}
                      closeMenuOnSelect
                    />
                  </Form.Group>
                  {/* Data de Ativação */}
                  <Form.Group as={Col} lg="18" xs="3">
                    <InputField
                      type="date"
                      name="dataAtivacao"
                      label="Data de Ativação"
                      placeholder="Digite a Data de Ativação"
                      value={dataAtivacao}
                      onChange={e => setDataAtivacao(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {/* Data de Ativação */}
                  <Form.Group as={Col} lg="18" xs="6">
                    <InputField
                      type="text"
                      name="qtdHorasProjeto"
                      label="Qtde de Horas no Projeto"
                      value={qtdHorasProjeto}
                      placeholder="Digite o valor da quantidade de horas no Projeto"
                      onChange={e => {
                        setQtdHorasProjeto(e.target.value)
                      }}
                    />
                  </Form.Group>
                  {/* Valor Hora  */}
                  <Form.Group as={Col} lg="18" xs="6">
                    <InputField
                      type="text"
                      name="valorHoraProjeto"
                      label="Valor Hora no Projeto"
                      value={valorHoraProjeto}
                      placeholder="Digite o valor hora no Projeto"
                      onChange={e => {
                        setValorHoraProjeto(formatCurrency(e.target.value))
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {/* Data de Ativação */}
                  <Form.Group as={Col} lg="18" xs="6">
                    <InputField
                      type="text"
                      name="qtdHoras"
                      label="Qtde de Horas do Profissional"
                      value={qtdHoras}
                      placeholder="Digite a quantidade de horas do Profissional"
                      onChange={e => {
                        setQtdHoras(e.target.value)
                      }}
                    />
                  </Form.Group>
                  {/* Valor Hora  */}
                  <Form.Group as={Col} lg="18" xs="6">
                    <InputField
                      type="text"
                      name="valorHora"
                      label="Valor Hora do Profissional"
                      value={valorHora}
                      placeholder="Digite o valor hora do Profissional"
                      onChange={e => {
                        setValorHora(formatCurrency(e.target.value))
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              </>
            }

            <div className="btn-container">
              <Button
                type="button"
                onClick={e => {
                  e.preventDefault();
                  !edit && handleConfirm(null, true, null);
                  setDataAtivacao(new Date());
                  setAtividade("");
                  setValorHora(0);
                  setQtdHorasProjeto(0);
                  setValorHoraProjeto(0);
                  setQtdHoras(0);
                  close();
                }}
              >
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={!atividade}
                onClick={e => {
                  e.preventDefault();

                  if (!atividade) return;

                  if (projectType !== 'FÁBRICA') {
                    handleConfirm(atividade, false, dataAtivacao, valorHora);
                  } else {
                    handleConfirm(atividade, false, dataAtivacao, valorHora , qtdHoras, valorHoraProjeto, qtdHorasProjeto);
                  }
                  setDataAtivacao(new Date());
                  setAtividade("");
                  setValorHora(0);
                  setQtdHorasProjeto(0);
                  setValorHoraProjeto(0);
                  setQtdHoras(0);
                  close();
                }}
              >
                CONFIRMAR
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
