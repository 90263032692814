import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService";
import IsValidDescricao from "../../../../../../utils/validators/IsValidDescricao";
import IsValidName from "../../../../../../utils/validators/IsValidName";
import { InputField } from "../../../../components/InputField";
import { FiPaperclip, FiX } from "react-icons/fi";
import { TimerAttachment, WarningMsg } from "../../styles";
import { Button } from "../Button";
import { TimerPicker } from "../TimePicker";
import { StyledModal } from "./style";
import { validateFileType } from "../../../../../../utils/validators/ValidateFileType";

const initialValues = {
  cliente: "",
  projeto: "",
  atividade: "",
  startFirstTimer: "",
  solicitante: "",
  demanda: "",
  horaExtra: false,
  timerDoc: null,
  endFirstTimer: "",
  disapprovedReason: ""
};

const ACCEPTED_FORMATS =
  "image/jpg," +
  "image/jpeg," +
  "image/png," +
  "application/msword," +
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
  "application/pdf";

export const EditModal = ({
  user,
  open,
  setOpen,
  clientOptions,
  handleEditSubmit
}) => {
  const api = new InexApiService();

  const fileInput = useRef(null);
  const timerDoc = useRef(null);

  const [allocatedToProj, setAllocatedToProj] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [newTimerInfo, setNewTimerInfo] = useState(initialValues);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setNewTimerInfo(initialValues);

    if (open) {
      setNewTimerInfo({
        cliente: open.idClient,
        projeto: open.idProject,
        atividade: open.activity,
        solicitante: open.clientDemandRequester,
        demanda: open.demand,
        startFirstTimer: open.startFirstTimer,
        endFirstTimer: open.endFirstTimer,
        horaExtra: open.isOvertime,
        timerDoc: open.appFile,
        disapprovedReason: open.disapprovedReason
      });
    }
  }, [open]);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const projects = await api.makeHttpRequest({
          url: `/timer/project/client/${newTimerInfo.cliente}`
        });

        setProjectOptions(projects ?? []);
      } catch (e) {
        Swal.fire(
          "Erro!",
          "Erro ao carregar lista de projetos para modal de edição.",
          "error"
        );
      }
    };

    if (newTimerInfo.cliente) {
      loadProjects();
    } else {
      newTimerInfo.projeto = "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTimerInfo.cliente]);

  useEffect(() => {
    let inProject = true;

    if (newTimerInfo.projeto) {
      const index = user.projects.findIndex(
        x => x.idProject === Number(newTimerInfo.projeto)
      );

      inProject = index !== -1;
    }

    setAllocatedToProj(inProject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTimerInfo.projeto]);

  const handleTimerDoc = () => {
    if (fileInput.current && fileInput.current.files[0]) {
      const allowedExtentions = ["jpg", "jpeg", "png", "doc", "docx", "pdf"];

      const isValid = validateFileType(
        fileInput.current.value,
        allowedExtentions
      );

      if (!isValid) {
        Swal.fire({
          icon: "error",
          title: "Tipo de arquivo inválido",
          text: "Apenas arquivos " + allowedExtentions.join(", ").toUpperCase(),
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });

        return;
      }

      const file = new FileReader();
      file.readAsDataURL(fileInput.current.files[0]);
      timerDoc.current.innerText = fileInput.current.files[0].name;
      setNewTimerInfo({
        ...newTimerInfo,
        timerDoc: fileInput.current.files[0]
      });
    }
  };

  const handleRemoveAnexo = async () => {
    const isForRemove = await Swal.fire({
      showCancelButton: true,
      icon: "warning",
      title: "Remover anexo",
      text: "Você tem certeza que quer remover seu anexo?",
      confirmButtonColor: "#4672C5",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });

    if (isForRemove.dismiss) {
      return;
    }

    if (isForRemove.value) {
      timerDoc.current.src = "";
      fileInput.current.value = "";
      setNewTimerInfo(prevState => {
        const newState = { ...prevState, timerDoc: null, horaExtra: false };
        return newState;
      });
    }

  };

  const formIsValid = () => {
    return (
      !!newTimerInfo.cliente &&
      !!newTimerInfo.projeto &&
      !!newTimerInfo.atividade &&
      !!IsValidDescricao(newTimerInfo.atividade) &&
      !!newTimerInfo.solicitante &&
      !!IsValidName(newTimerInfo.solicitante) &&
      (newTimerInfo.horaExtra ? !!newTimerInfo.timerDoc : true) &&
      !!newTimerInfo.startFirstTimer &&
      !!newTimerInfo.endFirstTimer
    );
  };

  const handleChange = e => {
    if (e.target.type === "checkbox") {
      setNewTimerInfo({ ...newTimerInfo, [e.target.name]: e.target.checked });
      return;
    }

    setNewTimerInfo({ ...newTimerInfo, [e.target.name]: e.target.value });
  };

  const handleSetNewDates = (startFirstTimer, endFirstTimer) => {
    setNewTimerInfo({ ...newTimerInfo, startFirstTimer, endFirstTimer });
  };

  return (
    <StyledModal
      show={open !== null}
      onHide={() => setOpen(null)}
      size="lg"
      centered
      scrollable
    >
      <Modal.Body>
        <Form>
          {/* row 1 */}
          <Form.Row>
            {/* Cliente */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                as="select"
                name="cliente"
                label="Cliente"
                value={newTimerInfo.cliente}
                onChange={handleChange}
                required
              >
                <option value="">Selecione o cliente</option>
                {clientOptions?.length > 0 &&
                  clientOptions.map(c => (
                    <option key={c.idClient} value={c.idClient}>
                      {c.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>

            {/* Projeto */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                as="select"
                name="projeto"
                label="Projeto"
                value={newTimerInfo.projeto}
                onChange={handleChange}
                disabled={!newTimerInfo.cliente}
                required
              >
                <option value="">Selecione o projeto</option>
                {projectOptions?.length > 0 &&
                  projectOptions.map(p => (
                    <option key={p.idProject} value={p.idProject}>
                      {p.projectArea?.name + " - " + p.name}
                    </option>
                  ))}
              </InputField>
              {!allocatedToProj && (
                <WarningMsg>
                  * Profissional não alocado a este projeto
                </WarningMsg>
              )}
            </Form.Group>
          </Form.Row>

          {/* row 2 */}
          <Form.Row>
            {/* Atividade */}
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="textarea"
                name="atividade"
                label="Atividade"
                placeholder="Descreva a atividade (mín. 10)"
                value={newTimerInfo.atividade}
                onChange={handleChange}
                isInvalid={
                  newTimerInfo.atividade &&
                  !IsValidDescricao(newTimerInfo.atividade)
                }
                required
              />
            </Form.Group>
          </Form.Row>

          {/* row 3 */}
          <Form.Row>
            {/* Solicitante da Demanda */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="solicitante"
                label="Solicitante da Demanda"
                placeholder="Digite o nome do solicitante"
                value={newTimerInfo.solicitante}
                onChange={handleChange}
                isInvalid={
                  newTimerInfo.solicitante &&
                  !IsValidName(newTimerInfo.solicitante)
                }
                required
              />
            </Form.Group>

            {/* Demanda */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="demanda"
                label="Demanda"
                placeholder="Digite a demanda"
                value={newTimerInfo.demanda}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.Row>

          {/* row 4 */}
          <Form.Row>
            <div className="time-input">
              <TimerPicker
                idClient={newTimerInfo.cliente}
                inputWidth="75px"
                popAuto
                initialDates={open}
                handleDates={handleSetNewDates}
              />
            </div>
            <TimerAttachment>
              <div className="checkbox-input">
                <InputField
                  type="checkbox"
                  name="horaExtra"
                  value={newTimerInfo.horaExtra}
                  label="Hora Excedente"
                  onChange={handleChange}
                  labelRed
                />
              </div>

              <div className="file-input">
                <input
                  type="file"
                  accept={ACCEPTED_FORMATS}
                  name="timerDoc"
                  ref={fileInput}
                  onChange={handleTimerDoc}
                />

                <div
                  className="label"
                  onClick={() => fileInput.current.click()}
                >
                  <span className="icon">
                    <FiPaperclip size={23} color="#4672C5" />
                  </span>
                  <span className="text" ref={timerDoc}>
                    {newTimerInfo.timerDoc
                      ? newTimerInfo.timerDoc.name
                      : `Adicionar anexo ${newTimerInfo.horaExtra ? "*" : ""}`}
                  </span>
                </div>
                {newTimerInfo.timerDoc && (
                  <div className="remove-button" onClick={handleRemoveAnexo}>
                    <FiX color="#333" size="20px" />
                  </div>
                )}
              </div>
            </TimerAttachment>
          </Form.Row>
          
          {/* row hidden */}
          {newTimerInfo.disapprovedReason && <Form.Row>
            {/* Motivo de Recusa */}
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="textarea"
                name="disapprovedReason"
                label="Motivo de Recusa"
                value={newTimerInfo.disapprovedReason}
                disabled
              />
            </Form.Group>
          </Form.Row>}
          <div className="last-row">
            <div className="save-btn">
              <Button type="button" onClick={e => setOpen(null)}>
                cancelar
              </Button>
            </div>
            <div className="save-btn">
              <Button
                type="submit"
                onClick={e => handleEditSubmit(e, newTimerInfo)}
                disabled={!formIsValid()}
              >
                salvar
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
};
