import Moment from 'moment';

export const CyclePeriodList = () => {
  const cyclePeriodList = [];

  // Obter a data atual
  const today = Moment();

  if (today.date() >= 22) {
    today.add(1, 'month');
  }

  // Adicionar os períodos de volta 5 meses (ou 6 períodos)
  for (let i = 2; i >= 0; i--) {
    // Subtrair i meses da data atual
    const periodDate = today.clone().subtract(i, 'months');
    
    // Obter o ano e o mês no formato YYYYMM
    const yearMonth = periodDate.format('YYYYMM');
    
    // Adicionar o ano e mês como um número inteiro na lista
    cyclePeriodList.push(parseInt(yearMonth));
  }

  return cyclePeriodList;
}
