import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CMSService from "../../../../services/CMSService";
import { PageTitle } from "../PageTitle";
import { BenefitCard } from "./components/BenefitCard";
import { Container } from "./styles";

const Benefits = ({ user }) => {
  const cosmicService = new CMSService();

  const [benefitList, setBenefitList] = React.useState([]);

  const [searchString, setSearchString] = React.useState("");

  const loadBenefits = async slug => {
    try {
      const response = await cosmicService.getObject(slug, false);
      setBenefitList(response?.object?.metadata?.cesta);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Não foi possível carregar os benefícios, procure o suporte ou tente novamente mais tarde!",
        "error"
      );
    }
  };

  React.useEffect(() => {
    if (user !== undefined) {
      switch (user.contractTypeName) {
        case "COOPERADO":
          loadBenefits("cesta-cooperado");
          break;
        case "PJ":
          loadBenefits("cesta-pj");
          break;
        default:
          loadBenefits("cesta-clt-flex");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container>
      <PageTitle
        text="CESTA DE BENEFÍCIOS"
        search={searchString}
        setSearch={setSearchString}
      />
      {user === undefined ? (
        <div className="not-logged">
          Para visualizar sua cesta de benefício, primeiro faça o login na sua
          conta.
        </div>
      ) : (
        <div className="card-list">
          {benefitList?.length > 0
            ? benefitList.map(card => (
                <BenefitCard
                  key={card.slug}
                  image={card.thumbnail}
                  title={card.title}
                  description={card.metadata.description}
                />
              ))
            : null}
        </div>
      )}
    </Container>
  );
};

const mapStoreToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStoreToProps)(Benefits);
