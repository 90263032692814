import React, { useState } from "react";
import moment from "moment";
import { Checkbox, Tooltip, Typography } from "@material-ui/core";
import Datatable from "../../../../../../partials/datatable/Datatable";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import { ApprovalTableCell, ApprovalTableRow, ContainerActions } from "./style";
import { MdMoreTime } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import CrudActions from "../../../../../../partials/datatable/CrudActions";
import iconChecked from "../../../../../../assets/icons/icon-check-fill.svg";
import iconNotChecked from "../../../../../../assets/icons/icon-check-stroke.svg";
import { downloadFromUrl } from "../../../../../../utils/DownloadFromUrl";
import store from "../../../../../../store/store";
import { ERoles } from "../../../../../../enums/Roles";
import {transformMinutesToHours} from "../../../../../../utils/CalculateSumTime"
import Swal from "sweetalert2";
import TruncatedText from "../../../../../../components/TruncatedText";

export const ApprovalDatatable = ({
  key,
  timerList,
  approvedList,
  setApprovedList,
  isFactory
}) => {

  const width = useGetWindowWidth();
  const { auth } = store.getState();
  const [checkAll, setCheckAll] = useState(false);

  const handleCheck = timer => {
    //This is array about approvedList
    const arr = [...approvedList];
    const index = arr.indexOf(arr.find(x => x.idTimer === timer.idTimer));

    //This is array about timerList. We need this to change the selectable property in checkbox, one by one
    const arr2 = [...timerList];
    const index2 = arr2.indexOf(arr2.find(x => x.idTimer === timer.idTimer));

    if (index !== -1) {
      arr.splice(index, 1);
      arr2[index2].selected = false;
    } else {
      arr.push(timer);
      arr2[index2].selected = true;
    }

    setApprovedList(arr);
  };

  const handleCheckAll = check =>{
    let vCheckAll = checkAll;
    
    checkAll ? vCheckAll = false : vCheckAll = true; 
    setCheckAll(vCheckAll);

    //This is array about timerList. We need this to change the selectable property in checkbox, all at once.
    const arr = [...timerList];
    for (let i = 0 ; i < arr.length; i++){
      arr[i].selected = vCheckAll;
    } 

    //If vCheckAll is true, all items on the screen will go to the ApprovedList
    vCheckAll? setApprovedList(timerList) : setApprovedList([]);

  }

  const statusColor = () => {
    return "#FAD247";
  }

  const handleDisapprovedReason = (reason) => {
    Swal.fire(
      "Motivo da Recusa do Timer!",
      reason,
      "info"
    );
  }

  const formatRow = (r, index) => {
    let crudActions = [];
    const isAdmin = (auth && auth.user && auth.user.role.type) === ERoles.ADMIN;

    if (isAdmin) {
      crudActions = [...crudActions, "individual-logs"];
    }
    
    return (
      !isFactory ? (
        <ApprovalTableRow hover tabIndex={-1} key={index}>
          <ApprovalTableCell scope="row" align="center">
            {r.timerStatus.idTimerStatus !== 2 && r.disapprovedReason && <span className="status-color" style={{ background: statusColor }} />}
            {r.idBusinessProposalValue ? null : <span className="status-color" />}
            <ContainerActions>
              <Checkbox
                icon={<img src={iconNotChecked} alt="Não selecionado" />}
                checkedIcon={<img src={iconChecked} alt="Selecionado" />}
                onChange={() => handleCheck(r)}
                checked={r.selected}
              />
              <CrudActions
                actions={crudActions}
                module="aprovacoes"
                path={`/admin/aprovacoes/logs/${r.idClient}`}
                id={r.idTimer}
              />
            </ContainerActions>
          </ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason && r.timerStatus.idTimerStatus !== 2 ? 'pointer' : ''}`}} onClick={() => r.timerStatus.idTimerStatus !== 2  && handleDisapprovedReason(r.disapprovedReason)} scope="row">
            {moment(r.startFirstTimer).format("DD/MM/YYYY")}
          </ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason && r.timerStatus.idTimerStatus !== 2 ? 'pointer' : ''}`}} onClick={() => r.timerStatus.idTimerStatus !== 2  && handleDisapprovedReason(r.disapprovedReason)} scope="row"> 
            <TruncatedText text={r.activity} />
          </ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason && r.timerStatus.idTimerStatus !== 2 ? 'pointer' : ''}`}} onClick={() => r.timerStatus.idTimerStatus !== 2  && handleDisapprovedReason(r.disapprovedReason)} scope="row">{r.demand ?? ""}</ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason && r.timerStatus.idTimerStatus !== 2 ? 'pointer' : ''}`}} onClick={() => r.timerStatus.idTimerStatus !== 2  && handleDisapprovedReason(r.disapprovedReason)} scope="row">{r.client.name}</ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason && r.timerStatus.idTimerStatus !== 2 ? 'pointer' : ''}`}} onClick={() => r.timerStatus.idTimerStatus !== 2  && handleDisapprovedReason(r.disapprovedReason)} scope="row">{r.project.name}</ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason && r.timerStatus.idTimerStatus !== 2 ? 'pointer' : ''}`}} onClick={() => r.timerStatus.idTimerStatus !== 2  && handleDisapprovedReason(r.disapprovedReason)} scope="row">{r.project.projectType.name}</ApprovalTableCell>
          <ApprovalTableCell scope="row" align="center">
            {transformMinutesToHours(r.durationMinutes)}
          </ApprovalTableCell>
          <ApprovalTableCell style={{cursor: `${r.disapprovedReason ? 'pointer' : ''}`}} scope="row" align="center">
            {r.isOvertime ? <MdMoreTime size={20} color="#4672C5" /> : "--"}
          </ApprovalTableCell>
          <ApprovalTableCell scope="row" align="center">
            {r.appFile && r.appFile.tempUri ? (
              <span
                className="cursor-pointer"
                onClick={() => downloadFromUrl(r.appFile.tempUri, r.appFile.name)}
                title={"Baixar: " + r.appFile.name}
              >
                <FiDownload size={20} color="#4672C5" />
              </span>
            ) : (
              "--"
            )}
          </ApprovalTableCell>
          <ApprovalTableCell scope="row">{r.timerStatus.idTimerStatus === 2 ? ( r.approver ? r.approver.name : '--') : '--'}</ApprovalTableCell>
        </ApprovalTableRow>
      ) : (
        <ApprovalTableRow hover tabIndex={-1} key={r.idTimer}>
          <ApprovalTableCell scope="row">{r.cycleDateFormatted}</ApprovalTableCell>
          <ApprovalTableCell scope="row">{r.professional.name}</ApprovalTableCell>
          <ApprovalTableCell scope="row">           
            <TruncatedText text={r.activity} />
          </ApprovalTableCell>
          <ApprovalTableCell scope="row">{r.demand ?? ""}</ApprovalTableCell>
          <ApprovalTableCell scope="row">{r.client.name}</ApprovalTableCell>
          <ApprovalTableCell scope="row">{r.project.name}</ApprovalTableCell>
          <ApprovalTableCell scope="row" align="center">{r.totalHours}</ApprovalTableCell>
          <ApprovalTableCell scope="row" align="center">
            {r.appFile && r.appFile.tempUri ? (
              <span
                className="cursor-pointer"
                onClick={() => downloadFromUrl(r.appFile.tempUri, r.appFile.name)}
                title={"Baixar: " + r.appFile.name}
              >
                <FiDownload size={20} color="#4672C5" />
              </span>
            ) : (
              "--"
            )}
          </ApprovalTableCell>
          <ApprovalTableCell scope="row">{r.timerStatus.idTimerStatus === 2 ? ( r.approver ? r.approver.name : '--') : '--'}</ApprovalTableCell>
        </ApprovalTableRow>
      )
    );
  };

  const headRows = [
    {
      label:
        <div className="row ml-1 mt-1">
          <Checkbox
            color="secondary"
            name="selectAll"
            onChange={() => handleCheckAll()}
            icon={<img src={iconNotChecked} alt="Não selecionado" />}
            checkedIcon={<img src={iconChecked} alt="Selecionado" />}
            checked={checkAll}
          />
        </div>,
      align: 'center'
    },
    { label: "Data", width: "5%" },
    { label: "Atividade", width: "20%" },
    { label: "Demanda" },
    { label: "Cliente" },
    { label: "Projeto" },
    { label: "Tipo do Proj.", width: "10%" },
    { label: "Duração", width: "8%", align: "center" },
    { label: "H.E.", width: "8%", align: "center" },
    { label: "Arq.", width: "8%", align: "center" },
    { label: "Aprovador" }
  ];

  const headRowsFactory = [
    { label: 'Ciclo' },
    { label: 'Profissional' },
    { label: 'Atividade' },
    { label: 'Demanda' },
    { label: 'Cliente' },
    { label: 'Projeto', align: "center" },
    { label: 'Total de Horas', align: "center" },
    { label: 'Arquivo', width: "8%", align: "center" }
  ];

  return (
      <>
      {
        timerList && 
        <Datatable
          key={key}
          className="h-100 m-0 p-0"
          headRows={isFactory ? headRowsFactory : headRows}
          styledTableCell={ApprovalTableCell}
          styledTableRow={ApprovalTableRow}
          formatRow={formatRow}
          rows={timerList}
          rowsPerPage={auth.user.rowsPerPage}
          width={width}
          cancelReload
          hiddenReload
          removeShadow
          hiddenFooterPagination
        />
      }
      </>
  );
};
