import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { StyledModal } from "./style";
import formatCurrency from "../../../../../../utils/FormatCurrency";

export const ReceiveModal = ({
  open,
  setOpen,
  receiveProjects
}) => {
  return (
    <StyledModal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      centered
      scrollable
    >
        <Modal.Body>
            <Table>
                <thead>
                    <tr>
                        <th>Projeto</th>
                        <th style={{textAlign: 'right'}}>Valor por Hora</th>
                        <th style={{textAlign: 'right'}}>Total de Horas</th>
                        <th style={{textAlign: 'right'}}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        receiveProjects.map((row, i) =>
                            <tr key={'projeto_'+row.idProject+''+i}>
                                <td>{row.name}</td>
                                <td style={{textAlign: 'right'}}>{row.wageHour ? formatCurrency(row.wageHour.toFixed(2)) : <span style={{color: 'red'}}>Não consta*</span>}</td>
                                <td style={{textAlign: 'right'}}>{row.totalHours.toString().padStart(2, '0')+':'+row.totalMinutes.toString().padStart(2, '0')}</td>
                                <td style={{textAlign: 'right'}}>{formatCurrency(row.receiveTotal.toFixed(2))}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            {receiveProjects.some(obj => obj.wageHour === 0) && <span style={{color: 'red'}}>* Existem projetos sem valor hora cadastrado, por favor avisar seu gestor Inex!</span>}
        </Modal.Body>
        <Modal.Footer>
            <div className="last-row">
                <div className="save-btn">
                    <Button type="button" onClick={e => setOpen(null)} style={{color:'#fff'}}>
                        Ok
                    </Button>
                </div>
            </div>
        </Modal.Footer>
    </StyledModal>
  );
};
