import moment from "moment";

export const calculateDurationDiff = (startTime, endTime, showDays = false) => {
  const timeToDiffFrom = endTime ? moment(endTime) : moment();
  const workedTime = moment.duration(timeToDiffFrom.diff(startTime));
  const days = String(workedTime.get("days"));
  const hours = String(workedTime.get("hours"));
  const minutes = String(workedTime.get("minutes"));
  const seconds = String(workedTime.get("seconds"));

  if (showDays) {
    return `${days.length < 2 ? "0" + days : days}:${
      hours.length < 2 ? "0" + hours : hours
    }:${minutes.length < 2 ? "0" + minutes : minutes}`;
  }

  return `${hours.length < 2 ? "0" + hours : hours}:${
    minutes.length < 2 ? "0" + minutes : minutes
  }:${seconds.length < 2 ? "0" + seconds : seconds}`;
};

export const calculateDurationDiffMultiTimer = (startFirstTime, endFirstTime, startSecondTimer, endSecondTimer) => {
  const timeToDiffFromFirst = endFirstTime ? moment(endFirstTime) : moment();
  const workedTimeFirst = moment.duration(timeToDiffFromFirst.diff(startFirstTime));

  const timeToDiffFromSecond = endSecondTimer ? moment(endSecondTimer) : moment();
  const workedTimeSecond = moment.duration(timeToDiffFromSecond.diff(startSecondTimer));
  const totalWorkedTime = moment.duration(workedTimeFirst.valueOf() + workedTimeSecond.valueOf());

  const hours = String(totalWorkedTime.hours());
  const minutes = String(totalWorkedTime.minutes());
  const seconds = String(totalWorkedTime.seconds());

  return `${hours.length < 2 ? "0" + hours : hours}:${
    minutes.length < 2 ? "0" + minutes : minutes
  }:${seconds.length < 2 ? "0" + seconds : seconds}`;
}
