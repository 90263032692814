import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import formatCurrency from "../../../../utils/FormatCurrency";
import moment from "moment";

const initialState = {
  id: 0,
  idBusinessProposal: 0,
  profileDescription: "",
  supplier: "",
  name: "",
  validityStartDate: "",
  validityEndDate: "",
  model: "",
  profile: "",
  level: "",
  tableLevel: "",
  ipt: "",
  type: "",
  rangeOne: "",
  rangeTwo: "",
  rangeThree: "",
  rangeFour: "",
  persistedModel: ""
};

export default class ProposalDetailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            isEditing: false
        };

        this.api = new InexApiService();
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
        this.loadProposal = this.loadProposal.bind(this);
        this.loadProposalValue = this.loadProposalValue.bind(this);
    }

    async componentDidMount() {
        const isEditing = this.props.match.path.includes('editar');
        console.log(this.props.match)
        this.setState({ isLoading: true, isEditing });
        try {
            if (isEditing) {
                await this.loadProposalValue(this.props.match.params.id);
            } else {
                await this.loadProposal(this.props.match.params.id);
            }
        } catch (error) {
            console.log(error)
            Swal.fire(
                "Erro!",
                error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
                "error"
            );
        }

        this.setState({ isLoading: false });
    }

    loadProposal = async id => {
        const proposal = await this.api.makeHttpRequest({
            url: `/businessproposal/${id}`
        });

        const persistedModel = {
            name: proposal.name,
            idBusinessProposal: proposal.idBusinessProposal
        };

        const persistedModelJson = JSON.stringify(persistedModel);
        this.setState({
            ...persistedModel,
            isLoading: false,
            persistedModel: persistedModelJson
        });
    };

    loadProposalValue = async id => {
        const proposalValue = await this.api.makeHttpRequest({
            url: `/businessproposal/value/${id}`
        });
        const persistedModel = {
            id: proposalValue.idBusinessProposalValue,
            name: proposalValue.name,
            idBusinessProposal: proposalValue.idBusinessProposal,
            profileDescription: proposalValue.profileDescription,
            supplier: proposalValue.supplier,
            validityStartDate: moment(proposalValue.validityStartDate).toDate(),
            validityEndDate: moment(proposalValue.validityEndDate).toDate(),
            model: proposalValue.model,
            profile: proposalValue.profile,
            level: proposalValue.level,
            tableLevel: proposalValue.tableLevel,
            ipt: proposalValue.ipt,
            type: proposalValue.type,
            rangeOne: formatCurrency(proposalValue.rangeOne.toFixed(2)),
            rangeTwo: formatCurrency(proposalValue.rangeTwo.toFixed(2)),
            rangeThree: formatCurrency(proposalValue.rangeThree.toFixed(2)),
            rangeFour: formatCurrency(proposalValue.rangeFour.toFixed(2))
        };

        const persistedModelJson = JSON.stringify(persistedModel);
        this.setState({
            ...persistedModel,
            isLoading: false,
            persistedModel: persistedModelJson
        });
    };

    submit = async e => {
        e.preventDefault();
        const id = this.props.match.params.id;
        this.setState({ isLoading: true });
        try {
        const reqBase = this.state.isEditing 
            ? { method: "PUT", url: `/businessproposal/value/update/${id}` }
            : { method: "POST", url: "/businessproposal/value/save" };

        await this.api.makeHttpRequest({
            ...reqBase,
            data: {
                name: this.state.name,
                idBusinessProposal: this.state.idBusinessProposal,
                profileDescription: this.state.profileDescription,
                supplier: this.state.supplier,
                validityStartDate: this.state.validityStartDate,
                validityEndDate: this.state.validityEndDate,
                model: this.state.model,
                profile: this.state.profile,
                level: this.state.level,
                tableLevel: this.state.tableLevel,
                ipt: this.state.ipt,
                type: this.state.type,
                rangeOne: this.state.rangeOne.replaceAll('.', '').replaceAll(',', '.'),
                rangeTwo: this.state.rangeTwo.replaceAll('.', '').replaceAll(',', '.'),
                rangeThree: this.state.rangeThree.replaceAll('.', '').replaceAll(',', '.'),
                rangeFour: this.state.rangeFour.replaceAll('.', '').replaceAll(',', '.')
            }
        });

        this.afterSubmit();
        } catch (e) {
        Swal.fire(
            "Erro!",
            e?.response?.data
            ? e.response.data
            : "Erro ao cadastrar proposta, tente novamente mais tarde.",
            "error"
        );
        }

        this.setState({ isLoading: false });
    };

  afterSubmit = async () => {
    const {idBusinessProposal, name} = this.state
    this.setState({
      ...initialState,
      idBusinessProposal: idBusinessProposal,
      name: name,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push(`/admin/propostas/${idBusinessProposal}/detalhes`);
    }
  };

  formIsValid = () => {
    return (
      this.state.name &&
      this.state.idBusinessProposal &&
      this.state.profileDescription &&
      this.state.supplier &&
      this.state.validityStartDate &&
      this.state.validityEndDate &&
      this.state.model &&
      this.state.profile &&
      this.state.level &&
      this.state.tableLevel &&
      this.state.ipt &&
      this.state.type &&
      this.state.rangeOne &&
      this.state.rangeTwo &&
      this.state.rangeThree &&
      this.state.rangeFour
    );
  };

  handleChange = (e) => {  
    const { name, value } = e.target;
    let valueFormated = value
    if (["rangeOne", "rangeTwo", "rangeThree", "rangeFour"].includes(name)) {
        valueFormated = formatCurrency(value)
    }
    this.setState({
        [name]: valueFormated
    });
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Cadastro de Proposta - Admin");
    } else {
      window.setPageTitle("Cadastro de Proposta - Admin");
    }

    const {
        name,
        model,
        profile,
        profileDescription,
        supplier,
        level,
        tableLevel,
        validityStartDate,
        validityEndDate,
        ipt,
        type,
        rangeOne,
        rangeTwo,
        rangeThree,
        rangeFour,
        isLoading,
        isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Proposta" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
                {/* row 1 */}
                <Form.Row>
                    {/* Nome da Proposta */}
                    <Form.Group as={Col} lg="2" xs="12">
                    <InputField
                        type="text"
                        name="name"
                        label="Nome da Proposta"
                        value={this.state.name}
                        disabled
                        required
                    />
                    </Form.Group>
                    {/* Modelo */}
                    <Form.Group as={Col} lg="5" xs="12">
                    <InputField
                        type="text"
                        name="model"
                        label="Modelo"
                        placeholder="Digite o Modelo"
                        value={model}
                        onChange={this.handleChange}
                        required
                    />
                    </Form.Group>
                    {/* Perfil */}
                    <Form.Group as={Col} lg="5" xs="12">
                    <InputField
                        type="text"
                        name="profile"
                        label="Perfil"
                        placeholder="Digite o Perfil"
                        value={profile}
                        onChange={this.handleChange}
                        required
                    />
                    </Form.Group>
                </Form.Row>
                {/* row 2 */}
                <Form.Row>
                    {/* Fornecedor */}
                    <Form.Group as={Col} lg="2" xs="12">
                        <InputField
                            type="text"
                            name="supplier"
                            label="Fornecedor"
                            placeholder="Digite o Fornecedor"
                            value={supplier}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Descrição do Perfil */}
                    <Form.Group as={Col} lg="5" xs="12">
                        <InputField
                            type="text"
                            name="profileDescription"
                            label="Descrição do Perfil"
                            placeholder="Digite a Descrição do Perfil"
                            value={profileDescription}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Nível Simplificado */}
                    <Form.Group as={Col} lg="2" xs="12">
                        <InputField
                            type="text"
                            name="level"
                            label="Nível Simplificado"
                            placeholder="Digite o Nível Simplificado"
                            value={level}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Nível Tabela */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="tableLevel"
                            label="Nível Tabela"
                            placeholder="Digite o Nível Tabela"
                            value={tableLevel}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                </Form.Row>
                {/* row 3 */}
                <Form.Row>
                    {/* Início da Vigência */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="date"
                            name="validityStartDate"
                            label="Início da Vigência"
                            placeholder="Digite o Início da Vigência"
                            value={validityStartDate}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Fim da Vigência */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="date"
                            name="validityEndDate"
                            label="Fim da Vigência"
                            placeholder="Digite o Fim da Vigência"
                            value={validityEndDate}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* IPT */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="ipt"
                            label="IPT"
                            placeholder="Digite o IPT"
                            value={ipt}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Tipo */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="type"
                            label="Tipo"
                            placeholder="Digite o Tipo"
                            value={type}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                </Form.Row>
                {/* row 4 */}
                <Form.Row>
                    {/* Primeira Faixa */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="rangeOne"
                            label="Primeira Faixa"
                            placeholder="Digite o valor da Primeira Faixa"
                            value={rangeOne}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Segunda Faixa */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="rangeTwo"
                            label="Segunda Faixa"
                            placeholder="Digite o valor da Segunda Faixa"
                            value={rangeTwo}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Terceira Faixa */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="rangeThree"
                            label="Terceira Faixa"
                            placeholder="Digite o valor da Terceira Faixa"
                            value={rangeThree}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Quarta Faixa */}
                    <Form.Group as={Col} lg="3" xs="12">
                        <InputField
                            type="text"
                            name="rangeFour"
                            label="Quarta Faixa"
                            placeholder="Digite o valor da Quarta Faixa"
                            value={rangeFour}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}
