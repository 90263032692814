import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { ClientIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { CoordinationModal } from "./component/Modal";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialModalValue = { idProjectArea: "", name: "", client: { idClient: "" } };

const initialState = {
  coordinations: [],
  clientOptions: [],
  open: false,
  modalValue: initialModalValue,
  reload: false,
  loading: false
};

export default class CoordinationListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState,
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.loadCoordinationClients = this.loadCoordinationClients.bind(this);
    this.setCoordinations = this.setCoordinations.bind(this);
    this.handleModalEdit = this.handleModalEdit.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      if (this.auth?.user.role.type === ERoles.ADMIN || this.auth?.user.role.type === ERoles.OPERATION) {
        const reqs = [ this.loadCoordinationClients() ];
        await Promise.all(reqs);
      }
    } catch (err) {
      Swal.fire(
        "Erro!",
        "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ loading: false });
  }

  handleConfirmChangeStatus = async function(obj) {
    try {
      this.setState({ loading: true });
        await this.api.makeHttpRequest({
          method: "PUT",
          url: `/coordination/toggle/${obj.idProjectArea}`
        });

        this.setState({ reload: !this.state.reload });

        Swal.fire({
          title: "Sucesso!",
          text: "Status alterado com sucesso.",
          icon: "success",
          cancelButtonText: "Sair"
        });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao alterar status, tente novamente mais tarde.",
        "error"
      )
    } finally {
      this.setState({ loading: false });
    }
  };

  loadCoordinationClients = async () => {
    try {
      this.setState({ loading: true });
      const req = await this.api.makeHttpRequest({
        method: "GET",
        url: `/coordination/clients`
      });

      this.setState({ clientOptions: req });
    }catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          :`Erro ao carregar clientes`,
        "error"
      );
    }

    this.setState({ loading: false });
  }

  handleCreateOrEditCordination = async obj => {
    try {
      this.setState({ loading: true });

      const req = obj.idProjectArea
        ? {
            method: "PUT",
            url: `/coordination/update/${obj.idProjectArea}`
          }
        : { method: "POST", url: "/coordination/save" };

      await this.api.makeHttpRequest({
        ...req,
        data: {
          name: obj.name,
          client: !obj.idProjectArea ? { idClient: Number(obj.client) } : null
        }
      });

      Swal.fire({
        title: "Sucesso!",
        text: `Coordenação ${obj.idProjectArea ? "alterada" : "criada"} com sucesso.`,
        icon: "success",
        cancelButtonText: "Sair"
      });

      this.setState({ reload: !this.state.reload });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : `Erro ao ${obj.idProjectArea ? "editar" : "adicionar"} coordenação.`,
        "error"
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  setCoordinations = coordinations => {
    this.setState({ coordinations });
  };

  handleModalEdit = obj => {
    this.setState({ open: true, modalValue: obj });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit-form"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION;

    if (isAdmin) {
      crudActions = [...crudActions, "individual-logs"];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idProjectArea}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
              actions={crudActions}
              module="coordenacoes"
              onConfirmChangeStatus={this.handleConfirmChangeStatus}
              handleEdit={this.handleModalEdit}
              path={`/admin/coordenacoes/logs/${r.idProjectArea}`}
              id={r.idProjectArea}
              obj={r}
            />
          </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.client && r.client.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.totalPayingArea ? r.totalPayingArea: "--"}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.totalProjects && r.totalProjects}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Coordenações - Admin");

    let headRows = [
      { column: "Name", label: "Nome", width: "35%" },
      { column: "Client.Name", label: "Cliente" },
      { label: "Total de Áreas Pagadoras" },
      { column: "TotalProjects", label: "Total de Projetos" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];


    if(this?.auth?.user.role.type === ERoles.ADMIN ||
      this?.auth?.user.role.type === ERoles.OPERATION) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }

    let showAdminButtons = [
      {
        label: "Adicionar",
        icone: "add",
        onClick: () => {
          this.setState({ open: true });
        }
      },
    ]

    if(this.auth?.user?.role?.type === ERoles.ADMIN ) {
      showAdminButtons = [ ...showAdminButtons,
        {
          label: "Logs",
          onClick: null,
          icone: "visibility",
          path: "/admin/coordenacoes/logs"
        }
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Coordenações"
          icon={ClientIcon}
          endpoint="/coordination/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.coordinations}
          setRows={this.setCoordinations}
          reload={this.state.reload}
          placeholderSearch="Busque por nome/cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
        <CoordinationModal
          value={this.state.modalValue}
          handleConfirm={this.handleCreateOrEditCordination}
          clients={this.state.clientOptions}
          open={this.state.open}
          close={() =>
            this.setState({
              modalValue: initialModalValue,
              open: !this.state.open
            })
          }
        />
      </>
    );
  }
}
