import React, { useCallback, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import queryString from "querystring";
// import ReCAPTCHA from "react-google-recaptcha";
import { isValidNewPassword } from "../../../utils/validators/isValidYup";
import { actionTypes } from "../../../store/ducks/auth.duck";
import Button from "../../../components/Button";
import { InexApiService } from "../../../services/InexApiService";
import useGetWindowWidth from "../../../utils/useGetWindowWidth";
import windowSize from "../../../constants/WindowSize";
import Input from "../Components/Input";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import logoInex from "../../../assets/logos/logo_inex_cor.svg";
import {
  Container,
  Body,
  ContainerInputs,
  // ContainerReCaptcha,
  ContainerWithIcon
} from "./styles";
import Loading from "../../home/components/Loading";
import Swal from "sweetalert2";

const RecoverData = ({ match, history }) => {
  useDocumentTitle("Inex - Cadastro de Senha");
  // const [tokenReCaptcha, setTokenReCaptcha] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleForm, setIsVisibleForm] = useState(true);
  const width = useGetWindowWidth();
  const dispath = useDispatch();
  const hasToken = queryString.parse(window.location.search.replace("?", "")).t;
  const token = window.location.search.replace("?t=", "");
  const { path } = match;
  const [messages] = useState(() => {
    switch (path) {
      case "/ativar-conta":
        return {
          title: "Seu email foi confirmado!",
          subtitle: "Sua conta está pronta para ser utilizada",
          button: "Entrar"
        };
      case "/recuperar-senha":
        return {
          title: "Definir nova senha",
          subtitle: "Crie uma nova senha para sua conta",
          button: "Definir senha"
        };
      case "/cadastrar-senha":
        return {
          title: "Definir senha",
          subtitle: "Agora, crie sua senha para acesso",
          button: "Cadastrar senha"
        };
      default:
        break;
    }
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmePassword: ""
    },
    validationSchema: isValidNewPassword,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    }
  });

  const activeAccount = useCallback(async () => {
    try {
      const api = new InexApiService();

      await api.makeHttpRequest({
        url: "/temporaryrequest",
        method: "POST",
        data: { token }
      });

      logOutSession();
    } catch (error) {
      Swal.fire(
        "Erro ao ativar conta.",
        error?.response ? error?.response?.data : "Entre em contato com o administrador",
        "error"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const logOutSession = useCallback(() => {
    dispath({
      type: actionTypes.Logout
    });

    history.push("/auth");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispath]);

  const handleSubmit = useCallback(async values => {
    /* if (tokenReCaptcha === null && path !== "/ativar-conta") {
        setMessageError("Clique em (Não sou um robô)");
        handleShowError();
        return;
      } */

    try {
      const api = new InexApiService();
      setIsLoading(true);
      await api.makeHttpRequest({
        url: "/temporaryrequest",
        method: "POST",
        data: { token, password: values.password }
      });
      setIsLoading(false);

      Swal.fire(
        "Sucesso",
        "Sua nova senha foi confirmada!",
        "success"
      );

      setIsVisibleForm(false);
      logOutSession();
    } catch (error) {
      Swal.fire(
        "Erro ao criar senha.",
        error?.response ? error?.response?.data : "Entre em contato com o administrador",
        "error"
      );
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (path === "/ativar-conta") {
      activeAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!hasToken && <Redirect to="/entrar" />}
      <Container>
        <Body width={width}>
          <ContainerWithIcon width={width}>
            {width >= windowSize.SM && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Link to="/">
                  <img
                    alt="logo Inex"
                    src={logoInex}
                    style={{ width: 280, marginBottom: 66 }}
                  />
                </Link>
              </div>
            )}

            <ContainerInputs error={messages.error}>
              {width >= windowSize.SM ? (
                <h3>{messages.title}</h3>
              ) : (
                <h1>{messages.title}</h1>
              )}
              <p>{messages.subtitle}</p>

              {path !== "/ativar-conta" && isVisibleForm && (
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    placeholder="Senha"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched?.password && formik.errors?.password
                    }
                    messageError={formik.errors?.password}
                  />
                  <Input
                    placeholder="Confirme sua senha"
                    type="password"
                    name="confirmePassword"
                    value={formik.values.confirmePassword}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched?.confirmePassword &&
                      formik.errors?.confirmePassword
                    }
                    messageError={formik.errors?.confirmePassword}
                  />
                  {/* <ContainerReCaptcha>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA}
                      onChange={value => {
                        setTokenReCaptcha(value);
                      }}
                    />
                  </ContainerReCaptcha> */}
                  <Button
                    text={messages.button}
                    disabled={
                      (!formik.isValid ||
                        !formik.dirty ||
                        !formik.values?.password ||
                        !formik.values
                          ?.confirmePassword) /* ||
                        tokenReCaptcha === null */ &&
                      true
                    }
                    opacity={
                      (!formik.isValid ||
                        !formik.dirty ||
                        !formik.values?.password ||
                        !formik.values
                          ?.confirmePassword) /* ||
                        tokenReCaptcha === null */ &&
                      0.5
                    }
                    cursor={
                      (!formik.isValid ||
                        !formik.dirty ||
                        !formik.values?.password ||
                        !formik.values
                          ?.confirmePassword) /* ||
                        tokenReCaptcha === null */ &&
                      "not-allowed"
                    }
                    type="submit"
                  />
                </form>
              )}
            </ContainerInputs>
          </ContainerWithIcon>
        </Body>
      </Container>
    </>
  );
};

export default RecoverData;
