import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 1220px;
  flex: 1;
  margin: 32px auto 40px;

  .card-list {
    flex: 1;
    margin-top: 48px;

    & > div:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .not-logged {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Cera Pro Light", sans-serif;
    font-weight: 600;
    font-size: 20px;
    height: 100%;
  }
`;
