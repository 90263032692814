import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  flex: 1;

  .newest-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .news-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;

    margin-top: 32px;
  }
`;
