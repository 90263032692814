import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: 600px;
    margin: auto;
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: 100%;

    position: relative;
    outline: none;

    padding: 37px 24px 24px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  max-width: 600px;
  margin: 0 auto;

  background: ${props => props.bg || "transparent"};
  border-radius: 15px;

  span {
    display: block;
  }

  .forgot-title {
    text-align: center;
    font-family: "Cera Pro Medium", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #4672C5;

    padding-bottom: 24px;
  }

  .forgot-subtitle {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #4d4c4d;

    width: fit-content;
  }
`;

export const defaultInnerWidth = 90;

export const StyledInput = styled(TextField)`
  width: ${defaultInnerWidth + "%"};
  margin: 20px 0;

  input {
    font-size: 18px;
    padding: 7px 5px;
  }
`;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  width: ${defaultInnerWidth + "%"};
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 145px;
  margin: 0 !important;
  border-radius: 4px;
  border: 1px solid #4672C5;
  background: ${({ color }) =>
    color === "primary" ? "#4672C5" : "transparent"};

  font-family: "Cera Pro Medium", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ color }) => (color === "primary" ? "#fff" : "#4672C5")};

  box-shadow: ${({ color }) =>
    color === "primary"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const PatternButton = `
  outline: none;
  border: none;
  margin: none;
  padding: 15px 25px;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: all 0.2s linear;
`;

export const CancelButton = styled.button`
  ${PatternButton}
  background: #555;
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;

  &:hover {
    background: ${props => (props.disabled ? "#807E80" : "#474747")};
    color: white;
  }
`;

export const SubmitButton = styled.button`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props?.theme?.disabledButton : "#1c0c3f"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;
`;

export const LinkButton = styled(Link)`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props?.theme?.disabledButton : "#1c0c3f"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;
`;
