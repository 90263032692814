import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 1220px;
  flex: 1;
  margin: 32px auto 40px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 24px 0 0;
  }

  .title {
    font-family: "Cera Pro Medium", sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: #4d4c4d;

    margin: 0;
    margin-bottom: 32px;
  }

  .content {
    display: flex;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 32px;

      .thumb {
        width: 502px;
        height: 301px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .share {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;

        padding: 0;
        margin: 0;
        margin-top: 24px;

        li {
          margin-right: 16px;
          cursor: pointer;

          span {
            font-family: "Cera Pro Light", sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            color: #4672C5;
          }

          &:first-child {
            cursor: default;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .text {
      & > * {
        font-family: "Cera Pro Light", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #4d4c4d;

        &:last-child {
          margin: 0;
        }
      }

      p {
        margin-bottom: 32px;
      }
    }
  }
`;
