import React from "react";
import { Container } from "./styles";

export const NewsCard = ({ news }) => {
  return (
    <Container className="news">
      <div className="image">
        <img src={news.thumbnail} alt={news.title} />
      </div>
      <div className="content">
        <h3 className="category">{news.metadata.category}</h3>
        <p className="title">{news.title}</p>
      </div>
    </Container>
  );
};
