import React from "react";
import { Container } from "./styles";
import searchIcon from "../../../../assets/icons/icon-zoom.svg";
import moment from "moment";

export const PageTitle = ({ text, search, setSearch, timeStamp }) => {
  moment.updateLocale("pt", {
    relativeTime: {
      future: "em %s",
      past: "há %s atrás",
      s: "a alguns segundos",
      ss: "%d segundos",
      m: "um minuto",
      mm: "%d minutos",
      h: "uma hora",
      hh: "%d horas",
      d: "um dia",
      dd: "%d dias",
      w: "uma semana",
      ww: "%d semanas",
      M: "um mês",
      MM: "%d meses",
      y: "um ano",
      yy: "%d anos"
    }
  });

  return (
    <Container>
      <p>{text}</p>
      {search !== undefined && (
        <div className="searchbar">
          <input
            type="text"
            placeholder="O que você está procurando?"
            value={search}
            onChange={e => {
              if (e.target.value.length <= 255) setSearch(e.target.value)
            }}
          />
          <div className="search-icon">
            <img src={searchIcon} alt="Icone de busca" />
          </div>
        </div>
      )}
      {timeStamp && (
        <span className="time-stamp">
          {moment(timeStamp).format("DD/MM/YYYY [ás] hh:mm") +
            " - " +
            moment(timeStamp).fromNow()}
        </span>
      )}
    </Container>
  );
};
