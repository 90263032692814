import React, { useEffect, useState, useCallback } from "react";
import { InexApiService } from "../../../../services/InexApiService";
import Moment from "moment";
import { Card } from "react-bootstrap";
import Loading from "../../../home/components/Loading";
import { ReportIcon } from "../../../../components/IconsSVG";
import { StyledCardBody } from "../../components/AdminCard/style";
import Swal from "sweetalert2";
import { AdminTitle } from "../../components/AdminTitle";
import { StyledAccordion, StyledAccordionCardBody } from "../../components/Accordion/styles";
import { CustomAccordionBody } from "../../components/Accordion";
import ReactJsonViewCompare from "react-json-view-compare";
import { JsonContainer, MessageContainer } from "./style";
import Filters from "./components/Filters";
import { useParams } from "react-router-dom";
import { SlugTranslation, TitleTranslation, ConvertSlugToSingular } from "./utils/TranslateOrConvert";
import ButtonActions from "./utils/ButtonActions";

const LogScreen = () => {
  const api = new InexApiService();
  const { slug, id } = useParams();
  const [logName, setLogName] = useState([]);
  const [logList, setLogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: ""
  });

  const hangleLoadLogList = useCallback(async () => {
    try {
      setLoading(true);

      const idData = {url: `${SlugTranslation(slug)}/${id}/audit`};
      const slugData = {
        url: `${SlugTranslation(slug)}/audit`,
        params: { filterString: filters.search ?? "" }
      }

      const data = await api.makeHttpRequest(id ? idData : slugData);

      if (data.length) {
        data.sort(function (a, b) {
          return new Date(b.creationDate) - new Date(a.creationDate);
        })
      };

      setLogName(data[data.length - 1] ?? []);
      setLogList(data ?? []);
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : `Erro ao buscar logs de ${TitleTranslation(slug)}, tente novamente mais tarde.`,
        "error"
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, slug]);

  useEffect(() => {
    window.setPageTitle(`Logs de ${TitleTranslation(slug)}`);

    hangleLoadLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, id]);

  const handleFilterChange = (e, period = null) => {
    if (period) {
      setFilters({ ...filters, ...period });
      return;
    }

    setFilters({ ...filters, [e.target.name]: e.target.value });
    setLoading(false);
  };

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={ReportIcon} title={
          !id
            ? `Logs de ${TitleTranslation(slug)}`
            : `Log de ${logName.length !== 0
              ? (JSON.parse(logName.after).Name || JSON.parse(logName.after).Activity)
              : TitleTranslation(slug)}`
          }
        />
        <Card className="mt-4">
          <StyledCardBody>
            <div className="d-flex flex-column w-100">
              {!id && <Filters
                  filters={filters}
                  handleChange={handleFilterChange}
                  loadCheck={hangleLoadLogList}
                  slug={TitleTranslation(slug)}
                  path={slug}
                />
              }

              {loading ? (
                <MessageContainer>
                  <span>Carregando...</span>
                </MessageContainer>
                ) : logList?.length > 0 ?
                logList.map(log => {
                  const before = JSON.parse(log.before);
                  const after = JSON.parse(log.after);

                  return  (
                    <StyledAccordion key={log.idAudit} shadow="true">
                      <CustomAccordionBody
                        eventKey="0"
                        log={true}
                        operationType={log.operationType || "Modificado"}
                        userName={log.userName}
                        creationDate={Moment(log.creationDate).format('DD/MM/YYYY HH:mm')}
                        slugName={ConvertSlugToSingular(slug)}
                        nameOrActivity={loading ? "" : (after.Name || after.Activity)}
                        numOfChanges={log.numOfChanges}
                      >
                        <StyledAccordionCardBody>
                          <JsonContainer>
                            <ReactJsonViewCompare
                              className="object-compression"
                              oldData={log.operationType !== "Criado" ? before : {}}
                              newData={after}
                            />
                          </JsonContainer>
                        </StyledAccordionCardBody>
                      </CustomAccordionBody>
                    </StyledAccordion>
                  )
                }) : (
                  <MessageContainer>
                  <span>Nenhum registro encontrado.</span>
                </MessageContainer>
                )
              }
              <ButtonActions path={slug} />
            </div>
          </StyledCardBody>
        </Card>
      </div>
    </>
  );
};

export default LogScreen;
