export default function validateAdmissionDate(value) {
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 30
  );

  if (value > maxDate) {
    return false 
  } else {
    return true
  }
}
