import React, { useEffect } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import { InexApiService } from "../../../../../../services/InexApiService";
import { StyledModal, Container } from "./styles";
import Loading from "../../../../../home/components/Loading";
import moment from "moment";
import Swal from "sweetalert2";

export const ConciliationModal = ({ title, show, close, conciliation }) => {
    const api = new InexApiService();
    
    const [loading, setLoading] = React.useState(false);
    const [conciliationDetail, setConciliationDetail] = React.useState(null);

    const handleClose = () => {
        setConciliationDetail(null);
        close();
    }

    const fetchConciliation = async () => {
        try {
            await api.makeHttpRequest({
                method: 'get',
                url: `/conciliation/${conciliation.idConciliation}`
              }).then((res) => {
                setConciliationDetail(res)
              });
        } catch (e) {
            const err = e.response
                ? e.response.data.split(/\n/)
                : 'Erro ao resgatar os dados da conciliação, tente novamente mais tarde';
        }
    }

    useEffect(() => {
        if (show === true) fetchConciliation()
    }, [show]);

    return (
        <>
            <Loading isLoading={loading} />
                <StyledModal 
                    show={show && !loading} onHide={() => handleClose()} 
                    size="lg" 
                    centered
                > 
                    <Modal.Body>
                        {conciliation && 
                        <>
                            <h1 className="modal-title">
                                <b>{title} - {conciliation?.client?.name} - {moment(conciliation?.referenceDate, 'DD/MM/YYYY').format('MMM/YYYY').toUpperCase()}</b>
                            </h1>

                            <Container>
                                {conciliationDetail && 
                                    <Table variant="dark" style={{lineHeight: '0', borderColor: '#343a40'}}>
                                        <thead>
                                            <tr>
                                                <th width="55%" style={{textAlign: "center", borderColor: '#343a40'}}>INEX</th>
                                                <th width="2%" style={{textAlign: "center", borderColor: '#343a40'}}></th>
                                                <th width="43%" style={{textAlign: "center", borderColor: '#343a40'}}>CLIENTE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3}>
                                                    <Table variant="light">
                                                        <thead>
                                                            <tr>
                                                                <th width='12%'>Coordenador</th>
                                                                <th width='19%'>Área Pagadora</th>
                                                                <th width='12%'>Chave</th>
                                                                <th width='12%' style={{textAlign: 'right'}}>Total de Horas</th>
                                                                <th width="2%" style={{textAlign: "center", backgroundColor: '#343a40', borderColor: '#343a40'}}></th>
                                                                <th width='19%'>Área Contratante</th>
                                                                <th width='12%'>Id Lançamento</th>
                                                                <th width='12%' style={{textAlign: 'right'}}>Horas Faturáveis</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {conciliationDetail.items.map((item, index) => 
                                                                <tr Key={'detail_'+index} style={{
                                                                    backgroundColor: (item.inexHours !== item.clientHours || item.inexPayableArea === 'NÃO EXISTE DADOS' || item.clientPayableArea === 'NÃO EXISTE DADOS') ? '#FF2400' : '', 
                                                                    color: (item.inexHours !== item.clientHours || item.inexPayableArea === 'NÃO EXISTE DADOS' || item.clientPayableArea === 'NÃO EXISTE DADOS') ? '#FFFFFF' : '' }}>
                                                                    <td>{item.inexCoordinator !== '' ? item.inexCoordinator : '(vazio)'}</td>
                                                                    <td>{item.inexPayableArea !== '' ? item.inexPayableArea : '(vazio)'}</td>
                                                                    <td>{item.inexReleaseId !== '' ? item.inexReleaseId : '(vazio)'}</td>
                                                                    <td style={{textAlign: 'right'}}>{item.inexHours}</td>
                                                                    <td style={{backgroundColor: '#343a40', borderColor: '#343a40'}}></td>
                                                                    <td>{item.clientPayableArea !== '' ? item.clientPayableArea : '(vazio)'}</td>
                                                                    <td>{item.clientReleaseId !== '' ? item.clientReleaseId : '(vazio)'}</td>
                                                                    <td style={{textAlign: 'right'}}>{item.clientHours}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            </Container>
                        </>
                        }
                    </Modal.Body>
            </StyledModal>
        </>
    );
};