import React from "react";
import moment from "moment";
import { Container } from "./styles";

const Footer = () => {
  return (
    <Container>
      <div className="line" />
      <p>
        &copy; Copyright {moment().format("YYYY")} INEX Intranet. Todos os
        direitos reservados.
      </p>
    </Container>
  );
};

export default Footer;
