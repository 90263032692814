import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  Menu,
  MenuItem
} from "@material-ui/core";

import useGetWindowWidth from "../../utils/useGetWindowWidth";
import WindowSize from "../../constants/WindowSize";
import logoInex from "../../assets/logos/logo_inex_branco.svg";
import iconMenu from "../../assets/icons/icon_menu.svg";
import iconClose from "../../assets/icons/icon_close.svg";
import store from "../../store/store";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { RiLoginBoxLine, RiLogoutBoxRLine } from "react-icons/ri";
import { Container, ContainerMobile } from "./styles";

const Header = () => {
  const { auth } = store.getState();
  const [showModal, setShowModal] = useState(false);
  const width = useGetWindowWidth();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const useStyles = makeStyles({
    root: {
      borderRadius: 3,
      minWidth: 0,
      padding: 4
    },
    modal: {
      display: "block",
      position: "absolute",
      zIndex: 1,
      marginTop: width > WindowSize.MD ? 114 : 94,
      width: width > WindowSize.MD ? "30%" : "80%"
    },
    menu: {
      marginLeft: 26,
      marginTop: 10
    },
    menuItem: {
      width: 210,
      marginRight: 8,
      marginLeft: 8,
      color: "#6d6d6d"
    }
  });

  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Link to="/">
        <img src={logoInex} className="logo-header" alt="Logo Inex" />
      </Link>

      {width >= WindowSize.MD && (
        <ul>
          <li>
            <Link to="/">News</Link>
          </li>
          {auth?.user && <li>
            <Link to="/beneficios">Benefícios</Link>
          </li>}
          <li>
            <Link to="/denuncia">Denúncia</Link>
          </li>
          {/* <li>
            <Link className="long-menu-item" to="#">
              Aniversariantes do Mês
            </Link>
          </li> */}
          {auth?.user ? (
            <li>
              <UserProfile showBadge={true} />
            </li>
          ) : (
            <li>
              <Link to="/auth">
                <div className="login-btn">
                  <span>
                    Login <RiLoginBoxLine />
                  </span>
                </div>
              </Link>
            </li>
          )}
        </ul>
      )}

      {width < WindowSize.MD && (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={event =>
            width >= WindowSize.MD
              ? handleClick(event)
              : setShowModal(!showModal)
          }
          className={classes.root}
        >
          <img src={iconMenu} alt="Ícone Menu" />
        </Button>
      )}

      <Menu
        className={classes.menu}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => history.push("/auth")}
        >
          Acesso interno
        </MenuItem>
      </Menu>

      <Modal
        closeAfterTransition
        BackdropProps={{
          style: { marginTop: width > WindowSize.MD ? 114 : 82 },
          timeout: 500
        }}
        BackdropComponent={Backdrop}
        className={classes.modal}
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <Fade in={showModal}>
          <ContainerMobile>
            <ul>
              <li>
                <Button
                  onClick={() => setShowModal(false)}
                  className={classes.root}
                >
                  <img src={iconClose} alt="Ícone fechar menu" />
                </Button>
              </li>
              <div className="dropdown-divider m-0"></div>
              <li>
                <Link to="/">News</Link>
              </li>
              <div className="dropdown-divider m-0"></div>
              {auth?.user && <li>
                <Link to="/beneficios">Benefícios</Link>
              </li>}
              <div className="dropdown-divider m-0"></div>
              {/*<li>
                <Link to="/denuncia">Denúncia</Link>
              </li>*/}
              <div className="dropdown-divider m-0"></div>
              {/* <li>
                <Link to="#">Aniversariantes do Mês</Link>
              </li> */}
              <div className="dropdown-divider m-0"></div>
              {auth?.user ? (
                <>
                  <li>
                    <Link to="/auth">Acesso interno</Link>
                  </li>
                  <div className="dropdown-divider m-0"></div>
                  <li className="header-logout">
                    <Link to="/logout">
                      <span>
                        Sair <RiLogoutBoxRLine color="E60023" />
                      </span>
                    </Link>
                  </li>
                </>
              ) : (
                <li className="login-container-mobile">
                  <Link to="/auth">
                    Login <RiLoginBoxLine />
                  </Link>
                </li>
              )}
            </ul>
          </ContainerMobile>
        </Fade>
      </Modal>
    </Container>
  );
};

export default Header;
