import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 140px;
  border-radius: 4px;
  border: 1px solid #4672C5;
  background: ${({ variant }) =>
    variant === "filled" ? "#4672C5" : "transparent"};

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ variant }) => (variant === "filled" ? "#fff" : "#4672C5")};

  box-shadow: ${({ variant }) =>
    variant === "filled"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  span:first-child {
    margin-right: 10px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;
