import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";

export const CheckTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    max-height: 40px !important;
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const CheckTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #807e80;
    line-height: 150%;
    height: 32px;
    padding: 0 16px;
    border: none;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Light", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #4d4c4d;
    height: 32px;
    padding: 0 16px 0px 16px;
    border: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 310px;
  }
`;
