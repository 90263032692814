import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { FiCamera, FiX } from "react-icons/fi";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import formatTel from "../../../../utils/FormatTel";
import { AdminTitle } from "../../components/AdminTitle";
import avatarPlaceholder from "../../../../assets/images/avatar-placeholder.png";
import { formatCPF } from "../../../../utils/validators/IsValidCpfCnpj";
import { validateFileType } from "../../../../utils/validators/ValidateFileType";
import DatePicker from "react-datepicker";
import { Container } from "./style.js";

const initialUserValues = {
  avatar: null,
  currentUploadedAvatar: null,
  removeAvatar: false,
  celular: "",
  personalEmail: "",
  contatoEmergencia: "",
  linkedIn: "",
  matriculaCliente: "",
  horarioEntrada: null,
  horarioAlmoco: null,
  horarioRetorno: null,
  horarioSaida: null,
  modeloRelatorio: { value: "", label: "" }
};

const EditProfileForm = props => {
  const api = new InexApiService();

  const fileInput = React.useRef(null);
  const userAvatar = React.useRef(null);

  const [reload, setReload] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);

  const [user, setUser] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(initialUserValues);
  const [initialObject, setInitialObject] = React.useState("");
  const [reportTemplateOptions, setReportTemplateOptions] = React.useState([]);

  const loadUser = React.useCallback(async () => {
    setisLoading(true);
    
    try {
      const res = await api.makeHttpRequest({ url: "/user/me" });
      const currentState = {
        avatar: null,
        currentUploadedAvatar: res.profilePictureURL,
        removeAvatar: false,
        celular: res.phone ? formatTel(res.phone) : "",
        contatoEmergencia: res.emergencyContact ? res.emergencyContact : "",
        linkedIn: res.linkedIn ? res.linkedIn : "",
        personalEmail: res.personalEmail ? res.personalEmail : "",
        matriculaCliente: res.clientEnrollment ? res.clientEnrollment : "",
        horarioEntrada: res.startFirstTimer
          ? moment(res.startFirstTimer).toDate()
          : null,
        horarioAlmoco: res.endFirstTimer
          ? moment(res.endFirstTimer).toDate()
          : null,
        horarioRetorno: res.startSecondTimer
          ? moment(res.startSecondTimer).toDate()
          : null,
        horarioSaida: res.endSecondTimer
          ? moment(res.endSecondTimer).toDate()
          : null,
        modeloRelatorio:  { value: res.reportTemplate? res.reportTemplate.idReportTemplate : "" , label: res.reportTemplate ? res.reportTemplate.type : "" }
      };
      
      const currentStateJson = JSON.stringify(currentState);

      setUser(res);
      setUserInfo(currentState);
      setInitialObject(currentStateJson);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao buscar seus dados, tente novamente mais tarde.",
        "error"
      );
    }

    setisLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadReportTemplate = React.useCallback(async () => {
    
    try {
      let reportTemplatesLocal = []
      const reportTemplates = await api.makeHttpRequest({ url: "/reporttemplate/getall" });

      for (let i in reportTemplates){
        reportTemplatesLocal.push({  value: reportTemplates[i].idReportTemplate, label: reportTemplates[i].type })
      }

      setReportTemplateOptions(reportTemplatesLocal);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao buscar os modelos de relatório disponíveis.",
        "error"
      );
    }

    setisLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (reload) {
      loadUser();
      loadReportTemplate();
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleChange = e => {
    if (e.target.name === "celular" || e.target.name === "contatoEmergencia") {
      setUserInfo({
        ...userInfo,
        [e.target.name]: formatTel(e.target.value)
      });
      return;
    }

    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

  const poppupOptions = [
    {
      name: "offset",
      options: {
        offset: [-10, 0]
      }
    }
  ];

  const handleUserAvatar = () => {
    if (fileInput.current && fileInput.current.files[0]) {
      const isValid = validateFileType(fileInput.current.value);
      if (!isValid) {
        Swal.fire({
          icon: "error",
          title: "Tipo de arquivo inválido",
          text: "Apenas arquivos JPEG/PNG/JPG",
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });

        return;
      }

      const file = new FileReader();
      file.onload = e => {
        userAvatar.current.src = e.target.result;
      };
      file.readAsDataURL(fileInput.current.files[0]);
      setUserInfo({
        ...userInfo,
        avatar: fileInput.current.files[0],
        currentUploadedAvatar: fileInput.current.files[0],
        removeAvatar: false
      });
    }
  };

  const handleRemoveAvatar = async () => {
    const isForRemove = await Swal.fire({
      showCancelButton: true,
      icon: "warning",
      title: "Remover avatar",
      text: "Você tem certeza que quer remover seu avatar?",
      confirmButtonColor: "#4672C5",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });

    if (isForRemove.dismiss) {
      return;
    }

    if (isForRemove.value) {
      if (fileInput.current?.files[0]) {
        userAvatar.current.src = "";
        fileInput.current.value = "";
      }

      setUserInfo({
        ...userInfo,
        avatar: null,
        currentUploadedAvatar: "",
        removeAvatar: true
      });
    }
  };

  const handleChangeReportTemplate = (e, select2Event) => {
    if (select2Event?.name === "ReportTemplate") {
      setUserInfo({
        ...userInfo,
        modeloRelatorio: e
      });
      return;
    }
  };

  const formIsValid = () => {
    let isTimerValid = false;

    if (
      !userInfo.horarioEntrada &&
      !userInfo.horarioAlmoco &&
      !userInfo.horarioRetorno &&
      !userInfo.horarioSaida
    ) {
      isTimerValid = true;
    }

    if (
      userInfo.horarioEntrada != null &&
      userInfo.horarioAlmoco != null &&
      userInfo.horarioRetorno != null &&
      userInfo.horarioSaida != null
    ) {
      isTimerValid = true;
    }

    const newStateJson = JSON.stringify(userInfo);

    const hasChange = newStateJson !== initialObject;

    return (
      hasChange &&
      (!!userInfo.celular ? IsValidPhone(userInfo.celular) : true) &&
      (!!userInfo.personalEmail
        ? IsValidEmail(userInfo.personalEmail)
        : true) &&
      isTimerValid
    );
  };

  const submit = async e => {
    e.preventDefault();

    setisLoading(true);
    try {
      const today = moment().format("YYYY-MM-DD");
      const formattedStartTime = moment(userInfo.horarioEntrada).format("HH:mm:ss");
      const formattedEndTime = moment(userInfo.horarioAlmoco).format("HH:mm:ss");
      const formattedStartSecondTime = moment(userInfo.horarioRetorno).format("HH:mm:ss");
      const formattedEndSecondTime = moment(userInfo.horarioSaida).format("HH:mm:ss");
      const rawPhone = userInfo.celular && userInfo.celular.replace(/\D/g, "");

      const bodyFormData = new FormData();
      bodyFormData.append("profilePicture.File", userInfo.avatar);
      bodyFormData.append("removeAvatar", userInfo.removeAvatar);
      bodyFormData.append("phone", rawPhone);
      bodyFormData.append("personalEmail", userInfo.personalEmail);
      bodyFormData.append("emergencyContact", userInfo.contatoEmergencia);
      bodyFormData.append("linkedIn", userInfo.linkedIn);
      bodyFormData.append("clientEnrollment", userInfo.matriculaCliente);

      // Informação não mutável
      bodyFormData.append("role[type]", "");
      bodyFormData.append("CPF", user.cpf);
      bodyFormData.append("inexEnrollment", user.inexEnrollment);
      bodyFormData.append("contractType", user.contractType);
      bodyFormData.append("technicalProposal", user.technicalProposal);
      bodyFormData.append("commercial[idUser]", user.commercial.idUser);
      bodyFormData.append("birthdate", moment(user.birthdate).format());
      bodyFormData.append("inexEmail", user.inexEmail);
      bodyFormData.append("admissionDate", moment(user.admissionDate).format());
      bodyFormData.append("History", user.history ?? "");
      bodyFormData.append("wage", user.wage ?? 0);
      if (user.contractType !== 0) {
        bodyFormData.append("CNPJ", user.cnpj);
        bodyFormData.append("fantasyName", user.fantasyName);
        bodyFormData.append("wageHour", user.wageHour ?? 0);
      }

      //console.table([...bodyFormData]);

      await api.makeHttpRequest({
        method: "PUT",
        url: `/user/update/${user.idUser}`,
        data: bodyFormData
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Perfil atualizado com sucesso.",
        icon: "success",
        confirmButtonText: "Ok"
      }).then(() => props.history.goBack());
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao editar profissional, tente novamente mais tarde.",
        "error"
      );
    }

    setisLoading(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <AdminTitle title="Dados Pessoais" disableHeader />
      <Card className="mt-3">
        <StyledCardBody>
          <div className="edit-profile-form">
            <div className="main-form">
              <div className="avatar">
                <input
                  type="file"
                  accept="image/*"
                  name="avatar"
                  ref={fileInput}
                  onChange={handleUserAvatar}
                  className="file-input"
                />
                {userInfo.currentUploadedAvatar ? (
                  <>
                    <div className="remove-button" onClick={handleRemoveAvatar}>
                      <FiX size="25px" />
                    </div>
                    <img
                      src={userInfo.currentUploadedAvatar}
                      alt="Foto de perfil"
                      ref={userAvatar}
                    />
                  </>
                ) : (
                  <img src={avatarPlaceholder} alt="Avatar" />
                )}
                <div
                  className="upload-button"
                  onClick={() => fileInput.current.click()}
                >
                  <FiCamera size="18px" />
                </div>
              </div>

              <div className="form-top">
                <h1 className="card-title">
                  Bem vindo, <strong>{user?.name ?? "Usuário"}</strong>
                </h1>

                {/* row 1 */}
                <Form.Row>
                  <Form.Group as={Col} lg="8" xs="6">
                    <InputField
                      className="dim-light"
                      type="text"
                      name="nome"
                      label="Nome"
                      value={user?.name ?? ""}
                      disabled
                    />
                  </Form.Group>

                  {/* CPF */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      className="dim-light"
                      type="text"
                      name="CPF"
                      label="CPF"
                      value={user?.cpf ? formatCPF(user.cpf) : ""}
                      maxLength={14}
                      disabled
                    />
                  </Form.Group>
                </Form.Row>

                {/* row 2 */}

                <Form.Row>
                  {/* Celular */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      type="tel"
                      name="celular"
                      label="Celular"
                      value={userInfo.celular}
                      maxLength="19"
                      placeholder="Digite o celular com DDD"
                      onChange={handleChange}
                      isInvalid={
                        userInfo.celular && !IsValidPhone(userInfo.celular)
                      }
                    />
                  </Form.Group>

                  {/* E-mail pessoal */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      type="email"
                      name="personalEmail"
                      label="E-mail Pessoal"
                      value={userInfo.personalEmail}
                      placeholder="Digite o email"
                      onChange={(e) => {
                        if (e.target.value.length <= 255) handleChange(e)
                      }}
                      isInvalid={
                        userInfo.personalEmail &&
                        !IsValidEmail(userInfo.personalEmail)
                      }
                    />
                  </Form.Group>

                  {/* LinkedIn */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      type="text"
                      name="linkedIn"
                      label="LinkedIn"
                      value={userInfo.linkedIn}
                      onChange={(e) => {
                        if (e.target.value.length <= 255) handleChange(e)
                      }}
                      placeholder="Adicione o seu LinkedIn"
                    />
                  </Form.Group>
                </Form.Row>

                {/* row 3 */}
                <Form.Row>
                  {/* Contato de Emergencia */}
                  <Form.Group as={Col} lg="12" xs="12">
                    <InputField
                      type="text"
                      name="contatoEmergencia"
                      label="Contato de Emergencia"
                      value={userInfo.contatoEmergencia}
                      onChange={(e) => {
                        if (e.target.value.length <= 19) handleChange(e)
                      }}
                      placeholder="Adicione o seu Contato de Emergencia"
                    />
                  </Form.Group>
                </Form.Row>
              </div>
            </div>

            <div className="form-bottom">
              {/* row 4 */}
              <Form.Row>
                {/* E-mail (INEX) */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    className="dim-light"
                    type="email"
                    name="inexEmail"
                    label="E-mail Inex (Login)"
                    value={user?.inexEmail ?? ""}
                    disabled
                  />
                </Form.Group>

                {/* Matricula Inex */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    className="dim-light"
                    type="text"
                    name="matriculaInex"
                    label="Matricula Inex"
                    value={user?.inexEnrollment ?? ""}
                    disabled
                  />
                </Form.Group>

                {/* Matricula Cliente */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="matriculaCliente"
                    label="Matricula Cliente"
                    value={userInfo.matriculaCliente}
                    placeholder="Digite o número de matricula"
                    onChange={(e) => {
                      if (e.target.value.length <= 255) handleChange(e)
                    }}
                  />
                </Form.Group>

                {/* Admissão */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    className="dim-light"
                    type="date"
                    name="dataAdmissao"
                    label="Admissão"
                    value={
                      user?.admissionDate
                        ? moment(user.admissionDate).toDate()
                        : new Date()
                    }
                    disabled
                  />
                </Form.Group>
              </Form.Row>

              {!!user && user.contractType !== 0 && (
                <>
                  {/* row 5 */}
                  <Form.Row>
                    {/* CNPJ */}
                    <Form.Group as={Col} lg="4" xs="12">
                      <InputField
                        className="dim-light"
                        type="text"
                        name="CNPJ"
                        label="CNPJ"
                        value={user?.cnpj ? formatCPF(user.cnpj) : ""}
                        maxLength={18}
                        disabled
                      />
                    </Form.Group>

                    {/* Razão Social */}
                    <Form.Group as={Col} lg="8" xs="12">
                      <InputField
                        className="dim-light"
                        type="text"
                        name="razaoSocial"
                        label="Razão Social"
                        value={user?.fantasyName ?? ""}
                        disabled
                      />
                    </Form.Group>
                  </Form.Row>
                </>
              )}
            </div>
          </div>
        </StyledCardBody>
      </Card>
      <FormActions
        onCancel={props.history.goBack}
        formIsValid={formIsValid()}
        onSubmit={submit}
        hideReload
      />
    </>
  );
};
export default EditProfileForm;
