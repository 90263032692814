import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 94px;
  padding: 30px 6.7%;
  justify-content: space-between;
  align-items: center;
  background: #4d4c4d;

  .logo-header {
    display: flex;
    align-items: center;
    width: 128px;
    @media (min-width: 769px) {
      width: 147px;
    }
  }

  ul {
    display: flex;
    flex: 1;
    margin: 0 1.93%;
    padding: 0;
    justify-content: flex-end;
    align-items: center;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin-left: 24px;

      &:hover:not(:last-child) {
        text-decoration: underline;

        a {
          font-family: "Cera Pro Regular", sans-serif;
          padding: 0;
        }
      }

      a {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        font-family: "Cera Pro Thin", sans-serif;
        text-decoration: none;
        padding: 0 .65px;

        &.long-menu-item {
          padding: 0 1.4px;
        }
      }

      .login-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #4672C5;
        background: transparent;
        padding: 5px 15px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          background: #4672C5;

          span {
            color: #fff;
          }
        }

        span {
          display: flex;
          align-items: center;
          color: #4672C5;
          font-family: "Cera Pro Regular", sans-serif;
          font-weight: 500;
          gap: 5px;
        }
      }
    }
  }

  .button-menu-header {
    width: 20px;
  }
`;

export const ContainerMobile = styled.div`
  height: 100%;
  background-color: #fff;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      padding: 16px 13px;
      list-style: none;
      background: #fff;

      &.login-container-mobile {
        background: #4672C5;

        a {
          color: #fff;
          font-weight: 400;
        }
      }

      &.header-logout {
        margin-top: auto;
        span {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 1.2rem;
          font-weight: 500;
          color: #e60023;
        }
      }

      a {
        font-size: 16px;
        color: #6d6d6d;
        font-weight: 400;
        font-family: "Cera Pro Regular", sans-serif;

        &:hover {
          color: #031d3d;
          transition: color 0.2s;
        }
      }
    }
  }
`;
