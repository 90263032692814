import React from "react";
import { Card } from "react-bootstrap";
import { StyledCard } from "./styles";

import { HiOutlineCake } from "react-icons/hi";
import placeholder from "../../../../../../assets/images/avatar-placeholder.png";

export const Birthdays = ({ birthdayList }) => {
  return (
    <StyledCard>
      <Card.Body>
        <div className="title">
          <div className="icon">
            <HiOutlineCake size="25px" color="#4D4C4D" />
          </div>
          <span>Aniversariantes do dia</span>
        </div>
        <div className="user-list">
          {birthdayList.map(e => (
            <div className="item" key={e.idUser}>
              <div className="image-wrapper">
                <img
                  src={e.profilePictureURL ? e.profilePictureURL : placeholder}
                  alt={`Foto de ${e.name}`}
                />
              </div>
              <div className="name">
                <span>{e.name}</span>
              </div>
            </div>
          ))}
        </div>
      </Card.Body>
    </StyledCard>
  );
};
