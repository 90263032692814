import React from "react";
import { Accordion } from "react-bootstrap";
import { HiPlusCircle, HiMinusCircle, HiExclamation, HiClock } from "react-icons/hi";
import { StyledAccordionHeader } from "./styles";
import { Button } from "../../pages/Approvals/components/Button/style";
import Swal from "sweetalert2";

export const CustomAccordionBody = ({
  eventKey,
  variant,
  icon,
  title,
  children,
  totalAmount,
  totalTime,
  isAllowed,
  approvedTab,
  hasTimerWithoutProposal,
  hasTimerWithoutDemand,
  hasTimerWithoutLoadedMinutes,
  handleSubmit,
  handleSubmitPending,
  handleSubmitDisapproved,
  user,
  noteForBilling,
  setProposalModal,
  log,
  operationType,
  userName,
  creationDate,
  slugName,
  nameOrActivity,
  numOfChanges,
  handleAccordionExpand,
  approvedList
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (!open) {
      handleAccordionExpand(user)
    }
    setOpen(!open);
  }

  return (
    <>
      <Accordion.Toggle
        onClick={handleOpen}
        eventKey={eventKey}
        as={StyledAccordionHeader}
        variant={variant}
      >
        <div className="accordion-title">
          {icon && <span>{icon()}</span>}

          {!log
            ?
              <div className="accordion-title">
                <span>{title}</span>
                <h3> {noteForBilling ? "(" + noteForBilling + ")": ''}</h3>
              </div>
              
            : <div className="accordion-title-logs">
                <span>{operationType} por: {userName}</span> |
                <span>Data: {creationDate}</span> |
                <span>{slugName}: {nameOrActivity}</span> |
                <span>{numOfChanges > 1 ? "Alterações" : "Alteração"}: {numOfChanges}</span>
              </div>
          }
        </div>
        <div className="toggle-icon">
          {totalAmount &&
            <span className="total-amount">
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(totalAmount)}
            </span>
          }

          {hasTimerWithoutDemand ? (<HiExclamation />) : null }
          {hasTimerWithoutLoadedMinutes ? (<HiClock />) : null }

          {isAllowed && (
            <div className="approval-btn">
              {!approvedTab && hasTimerWithoutProposal ? (
                <Button
                  type="button"
                  bgColor="#FB7E38"
                  onClick={() => setProposalModal(user)}
                >
                  ADD PROPOSTA
                </Button>
              ) : null}
              {
                !approvedTab &&
                <Button type="button" onClick={(e) => {
                  if (approvedList.length > 0) {
                    Swal.fire({
                      text: "Digite o motivo de recusa do timer:",
                      input: "textarea",
                      showCancelButton: true,
                      confirmButtonText: "Enviar",
                      cancelButtonText: "Cancelar",
                    }).then((result) => {
                      if (result.value) {
                        const reason = result.value;
                        handleSubmitDisapproved(e, reason)
                      }
                    });
                  }
                }}>
                  Adicionar Motivo Recusa
                </Button>
              } 
              
              <Button type="button" onClick={(e) => {
                if (e.target.textContent === 'Voltar para Pendente' && approvedList.length > 0) {
                  Swal.fire({
                    text: "Digite o motivo aqui para voltar para Pendente:",
                    input: "textarea",
                    showCancelButton: true,
                    confirmButtonText: "Enviar",
                    cancelButtonText: "Cancelar",
                  }).then((result) => {
                    if (result.value) {
                      const reason = result.value;
                      handleSubmitPending(e, reason)
                    } else if (result.dismiss !== 'cancel') {
                      handleSubmit(e)
                    }
                  });
                } else {
                  handleSubmit(e)
                }
              }}>
                {approvedTab ? "Voltar para Pendente" : "Aprovar"}
              </Button>

            </div>
          )}
          
          {totalTime &&
            <span className="total-amount">
              {`Total de Horas: ${totalTime}`}
            </span>
          }
          {open ? <HiMinusCircle /> : <HiPlusCircle />}
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <>{children}</>
      </Accordion.Collapse>
    </>
  );
};
