import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  .image-wrapper {
    width: 100px;
    height: 100px;
    margin-right: 36px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-wrapper {
    width: calc(100% - 136px);

    .title {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #4672C5;

      margin-bottom: 8px;
    }

    .description, .description > * {
      font-family: "Cera Pro Medium", sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #4d4c4d;

      margin: 0;
    }
  }
`;
