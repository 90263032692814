import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { NotificationIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { NotificationTableCell } from "./style";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";
import { Link } from "react-router-dom";
import TruncatedText from "../../../../components/TruncatedText";

const initialState = {
  notifications: [],
  reload: false,
  loading: false
};

export default class NotificationListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setNotifications = this.setNotifications.bind(this);    
  }
  
  handleConfirmChangeStatus = async function (obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/notification/toggle/${obj.idNotification}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        e?.response?.data ? "warning" : "error"
      )
    }
  };

  setNotifications = notifications => {
    this.setState({ notifications });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isCommercial = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.COMMERCIAL);
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION);

    if (isAdmin || isOperation || isCommercial) {
      crudActions = [...crudActions];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idNotification}>
        {(isAdmin || isOperation || isCommercial) && <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="notificacoes"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/notificacoes/logs/${r.idNotification}`}
            id={r.idNotification}
            obj={r}
          />
        </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left">
          <Link
            to={`/admin/notificacoes/${r.idNotification}/detalhes`}
            className="cta-link"
          >
            {r.title}
          </Link>
        </StyledTableCell>
        <NotificationTableCell scope="row" align="left">
          <TruncatedText text={r.message} />
        </NotificationTableCell>
        <StyledTableCell scope="row" align="left">
          <a href={r.link} style={{color: 'blue', textDecoration: 'underline'}}>{r.link}</a>
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.initialDate &&
            Moment(r.initialDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row" align="right">
          {r.validity}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Notificações - Admin");

    let headRows = [
      { column: "Title", label: "Título", align: "left" },
      { column: "Message", label: "Mensagem", align: "left" },
      { column: "Link", label: "Link", align: "left" },
      { column: "InitialDate", label: "Data de Inicio", align: "center" },
      { column: "Validity", label: "Vigência (em dias)", align: "right" }
    ];

    if (
      (this?.auth?.user && this?.auth?.user.role.type === ERoles.ADMIN)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.OPERATION)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.COMMERCIAL)
    ) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }
    
    let showAdminButtons = []
    if(this.auth?.user?.role?.type === ERoles.ADMIN 
        || this.auth?.user?.role?.type === ERoles.OPERATION
        || this.auth?.user?.role?.type === ERoles.COMMERCIAL) {
      showAdminButtons = [ 
        {
          label: "Adicionar",
          onClick: null,
          icone: "add",
          path: "/admin/notificacoes/adicionar"
        } 
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Notificações"
          icon={NotificationIcon}
          endpoint="/notification/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.notifications}
          setRows={this.setNotifications}
          reload={this.state.reload}
          placeholderSearch="Busque por título"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
