import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import moment from "moment";

const currentDate = moment().hour(23)
const nextYear = currentDate.year() + 1;
const referenceDate = moment().hour(5).date(0).month(0).year(nextYear).toDate();

const initialState = {
  client_id: 0,
  client: null,
  referenceDate: referenceDate,
  closingDateIds: [],
  startDates: [],
  endDates: [],
  closingDates: [],
  clientesOptions: [],
  isLoading: false
};

const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

const setDate = (day, month, year=nextYear, end=false) => {
  const newDate = moment().year(year).month(month).date(day);
  if (end) newDate.subtract(1, 'day');
  return newDate.toDate();
}

export default class ClosingDateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    this.setDatesState = this.setDatesState.bind(this);
    this.getStartDate = this.getStartDate.bind(this);
    this.getEndDate = this.getEndDate.bind(this);
    this.getClosingDate = this.getClosingDate.bind(this);
  }

  setDatesState = (year, initialCycleDay) => {
    this.setState({
      startDates: [
        setDate(initialCycleDay, 11, year-1), setDate(initialCycleDay, 0, year), setDate(initialCycleDay, 1, year),
        setDate(initialCycleDay, 2, year), setDate(initialCycleDay, 3, year), setDate(initialCycleDay, 4, year),
        setDate(initialCycleDay, 5, year), setDate(initialCycleDay, 6, year), setDate(initialCycleDay, 7, year),
        setDate(initialCycleDay, 8, year), setDate(initialCycleDay, 9, year), setDate(initialCycleDay, 10, year)
      ],
      endDates: [
        setDate(initialCycleDay, 0, year, true), setDate(initialCycleDay, 1, year, true), setDate(initialCycleDay, 2, year, true),
        setDate(initialCycleDay, 3, year, true), setDate(initialCycleDay, 4, year, true), setDate(initialCycleDay, 5, year, true),
        setDate(initialCycleDay, 6, year, true), setDate(initialCycleDay, 7, year, true), setDate(initialCycleDay, 8, year, true),
        setDate(initialCycleDay, 9, year, true), setDate(initialCycleDay, 10, year, true), setDate(initialCycleDay, 11, year, true)
      ],
      closingDates: [
        setDate(initialCycleDay, 0, year), setDate(initialCycleDay, 1, year), setDate(initialCycleDay, 2, year),
        setDate(initialCycleDay, 3, year), setDate(initialCycleDay, 4, year), setDate(initialCycleDay, 5, year),
        setDate(initialCycleDay, 6, year), setDate(initialCycleDay, 7, year), setDate(initialCycleDay, 8, year),
        setDate(initialCycleDay, 9, year), setDate(initialCycleDay, 10, year), setDate(initialCycleDay, 11, year)
      ]
    });
  }

  getStartDate = (index, year) => {
    return moment(this.state.startDates[index]).year(year).toDate();
  }

  getEndDate = (index, year) => {
    return moment(this.state.endDates[index]).year(year).toDate();
  }

  getClosingDate = (index, year) => {
    return moment(this.state.closingDates[index]).year(year).toDate();
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      if (this.props.match.params.id) {
        let idYearSplitted = this.props.match.params.id.split('-');
        await this.loadClosingDate(idYearSplitted[0], idYearSplitted[1]);
      }
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }
    this.loadClientes();
    this.setState({ isLoading: false });
  }

  loadClosingDate = async (id, year) => {
    const closing_date = await this.api.makeHttpRequest({
      url: `/closingdate/by-client/${id}?year=${year}`
    });
    //let referenceDate = ;
    this.setState({
      client_id: closing_date.client.idClient,
      client: { label: closing_date.client.name, value: closing_date.client.idClient, initialCycleDay: closing_date.client.initialCycleDay },
      referenceDate: moment(closing_date.referenceDate).toDate(),
      closingDateIds: closing_date.dates.map((date) => date.idClosingDate),
      startDates: closing_date.dates.map((date) => moment(date.startDate).toDate()),
      endDates: closing_date.dates.map((date) => moment(date.endDate).toDate()),
      closingDates: closing_date.dates.map((date) => moment(date.date).toDate()),
      isLoading: false
    });
  };

  loadClientes = async () => {
    const clientes = await this.api.makeHttpRequest({
      url: "/professional/clients"
    });

    const formattedOptions =
      clientes.length > 0
        ? clientes.map(c => ({ label: c.name, value: c.idClient, initialCycleDay: c.initialCycleDay }))
        : [];

    this.setState({
      clientesOptions: formattedOptions
    });
  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: "/closingdate/update" }
        : { method: "POST", url: "/closingdate/save" };

      const data = {
        idClient: this.state.client_id,
        dates: []
      }

      this.state.closingDates.forEach((closingDate, index) => {
        data.dates.push({
          idClosingDate: this.state.closingDateIds[index],
          startDate: this.state.startDates[index],
          endDate: this.state.endDates[index],
          date: closingDate
        })
      })

      await this.api.makeHttpRequest({
        ...reqBase,
        data
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar data de fechamento, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/data-de-fechamento");
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeYear = e => {
    const date = e.target.value;
    const year = date.getFullYear();
    this.setState({
      referenceDate: date,
    })
    this.setDatesState(year, this.state.client.initialCycleDay??1);
  };

  handleChangeClosingDate = (index, e) => {
    const date = e.target.value;

    this.setState({
      closingDates: this.state.closingDates.map((cd, i) => (i === index) ? date : cd)
    })
  };

  handleChangeClient = (client, event) => {
    if (client.initialCycleDay !== 0) {
      this.setState({ client_id: client.value });
      this.setState({ client: client });
      this.setDatesState(this.state.referenceDate.getFullYear(), client.initialCycleDay??1);
    } else {
      Swal.fire(
        "Erro!",
        "Cliente precisa cadastrar dia do início do ciclo.",
        "error"
      );
    }
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadClosingDate(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Data de Fechamento");
    } else {
      window.setPageTitle("Cadastro de Data de Fechamento");
    }

    const {
      isLoading,
      isEditing,
      client_id,
      client,
      referenceDate,
      startDates,
      endDates,
      closingDates,
      clientesOptions
    } = this.state;

    const getMinDate = (index) => this.state.endDates[index];

    const getMaxDate = (index) => moment(this.state.referenceDate).hour(5).month(index).add(1, 'month').date(0).toDate();
    
    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Data de Fechamento" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row style={{marginBottom: '20px'}}>
                {/* Cliente */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="client"
                    label="Cliente"
                    value={client}
                    onChange={this.handleChangeClient}
                    placeholder="Selecione a empresa"
                    options={clientesOptions}
                    closeMenuOnSelect
                    disabled={isEditing}
                    required
                  />
                </Form.Group>

                {/* Ano */}
                <Form.Group as={Col} lg="4" xs="12">
                <InputField
                    type="date"
                    name="referenceDate"
                    dateFormat="yyyy"
                    label="Ano"
                    showYearPicker
                    value={referenceDate}
                    placeholder="Selecione a ano"
                    onChange={this.handleChangeYear}
                    disabled={client===null}
                    required
                  />
                </Form.Group>
              </Form.Row>
              {
                month.map((month, index) =>
                  <div key={this.state.closingDateIds[index]??index}>
                    <h5 style={{marginBottom: '20px'}}>{month}</h5>
                    <Form.Row style={{marginBottom: '20px'}}>
                      {/* Inicio do Período */}
                      <Form.Group as={Col} lg="4" xs="12">
                        <InputField
                          type="date"
                          name={`${startDates[index]}`}
                          label="Início"
                          value={startDates[index]}
                          placeholder="Selecione a data"
                          onChange={this.handleChange}
                          disabled
                          required
                        />
                      </Form.Group>

                      {/* Fim do Período */}
                      <Form.Group as={Col} lg="4" xs="12">
                        <InputField
                          type="date"
                          name={`${endDates[index]}`}
                          label="Fim"
                          value={endDates[index]}
                          placeholder="Selecione a data"
                          onChange={this.handleChange}
                          disabled
                          required
                        />
                      </Form.Group>

                      {/* Data de Fechamento */}
                      <Form.Group as={Col} lg="4" xs="12">
                        <InputField
                          type="date"
                          name={`${closingDates[index]}`}
                          label="Fechamento"
                          value={closingDates[index]}
                          placeholder="Selecione a data"
                          onChange={(e) => this.handleChangeClosingDate(index, e)}
                          minDate={getMinDate(index)}
                          maxDate={getMaxDate(index)}
                          disabled={client===null}
                          required
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                )
              }
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          formIsValid={this.state.client_id !== 0}
          hideReload
        />
      </>
    );
  }
}
