import React from "react";
import moment from "moment";
import { InputField } from "../../../../components/InputField";
import { InexApiService } from "../../../../../../services/InexApiService";
import { DateFilter } from "../../../../components/DateFilter";
import { FiFilter } from "react-icons/fi";
import { Button } from "../Button/style";
import { Container } from "./style";
import { debounce } from "lodash";
import { getPortoDefaultDate } from "../../../../../../utils/GetPortoDefaultDate";

const initialFilterDates = getPortoDefaultDate();

export const Filters = ({ filters, handleChange, loadCheck }) => {
  const api = new InexApiService();

  const [projectAreaOptions, setProjectAreaOptions] = React.useState([]);
  const [clientOptions, setClientOptions] = React.useState([]);

  const [periodOpt, setPeriodOpt] = React.useState("");

  React.useEffect(() => {
    loadProjectArea();
    loadClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceOnClick = debounce(loadCheck, 400);

  const loadProjectArea = React.useCallback(async () => {
    const projectAreas = await api.makeHttpRequest({
      url: "/check/project/areas"
    });

    setProjectAreaOptions(projectAreas ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadClients = React.useCallback(async () => {
    const coordinators = await api.makeHttpRequest({
      url: "/check/clients"
    });

    setClientOptions(coordinators ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePeriodFilter = filter => {
    const option = {
      label: `${moment(filter.startPeriod)
        .add(1, "day")
        .format("DD/MM/YYYY")} - ${moment(filter.endPeriod).format(
        "DD/MM/YYYY"
      )}`,
      value: 1
    };

    const period = {
      startPeriod: filter.startPeriod,
      endPeriod: filter.endPeriod
    };

    setPeriodOpt(option);
    handleChange(null, period);
  };

  const PeriodFilter = () => {
    return (
      <InputField
        select2ClassName="disabled-normal"
        as="select2"
        name="periodOpt"
        value={periodOpt}
        placeholder="Período"
        disabled
      />
    );
  };

  return (
    <Container>
      {/* Período */}
      <DateFilter
        component={PeriodFilter}
        setFilter={handlePeriodFilter}
        initialValues={initialFilterDates}
      />

      {/* Cliente */}
      <InputField
        as="select"
        name="idClient"
        value={filters.idClient}
        onChange={handleChange}
      >
        <option value="">Cliente</option>
        {clientOptions?.length > 0 &&
          clientOptions.map(c => (
            <option key={c.idClient} value={c.idClient}>
              {c.name}
            </option>
          ))}
      </InputField>

      {/* Coordenação */}
      <InputField
        as="select"
        name="idCoordination"
        value={filters.idCoordination}
        onChange={handleChange}
      >
        <option value="">Coordenação</option>
        {projectAreaOptions?.length > 0 &&
          projectAreaOptions.map(p => (
            <option key={p.idProjectArea} value={p.idProjectArea}>
              {p.name}
            </option>
          ))}
      </InputField>

      <Button type="button" variant="filled" onClick={() => debounceOnClick()}>
        <span className="icon">
          <FiFilter size={22} />
        </span>{" "}
        <span>filtrar</span>
      </Button>
    </Container>
  );
};

export default Filters;
