import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment, { localeData } from "moment";
import Loading from "../../../home/components/Loading";
import { ProposalIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import CrudActions from "../../../../partials/datatable/CrudActions";
import { InexApiService } from "../../../../services/InexApiService";
import Swal from "sweetalert2";

const initialState = {
  proposalValues: [],
  reload: false,
  loading: false
};

export default class ProposalDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new InexApiService();
    this.formatRow = this.formatRow.bind(this);
    this.setProposalValues = this.setProposalValues.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.loadUserProjects = this.loadUserProjects.bind(this);
    this.deleteProposal = this.deleteProposal.bind(this);
  }

  setProposalValues = proposalValues => {
    this.setState({ proposalValues });
  };

  onConfirmDelete = async (id) => {
    this.setState({loading: true})
    const idProposal = id.replace('detalhes/', '')
    try {
      const userProjects = await this.loadUserProjects(idProposal);
      if (userProjects.length > 0) {
        this.setState({ loading: false });
        const text = "Os profissionais vinculados: \n" + userProjects.map(up => `${up.user.name}`).join(', ');
        const result = await Swal.fire({
          title: "Confirmar deleção:",
          text: text,
          showCancelButton: true,
          confirmButtonText: "Excluir",
          cancelButtonText: "Cancelar",
        });
        if (result.value) {
          await this.deleteProposal(idProposal).then(() => this.setState({ reload: true }));
        }
      } else {
        this.setState({ loading: false });
        await this.deleteProposal(idProposal).then(() => this.setState({ reload: true }));
      }
    } catch (error) {
      console.error("Error while loading user projects:", error);
      // Handle error state here if needed
      this.setState({ loading: false });
    }
  }

  deleteProposal = async (idProposal) => {
    await this.api.makeHttpRequest({
      url: `BusinessProposal/value/${idProposal}`,
      method: "DELETE"
    }).then((res) => {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Proposta excluída com sucesso.',
        icon: 'success'
      });
    })
  }

  loadUserProjects = async (idProposal) => {
    const userProjects = await this.api.makeHttpRequest({
      url: `UserProject/byProposal/${idProposal}`
    });
    return userProjects;
  }

  formatRow = r => {
    const crudActions = ["edit", "delete"];
    const valorFormatado = r.value ? `R$ ${r.value.toFixed(2)}` : "--";
    return (
      <StyledTableRow hover tabIndex={-1} key={r.idClient}>
        <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module={`propostas`}
            onConfirmChangeStatus={''}
            onConfirmDelete={this.onConfirmDelete}
            path={`/admin/propostas/logs/${this.props.match.params?.id}`}
            id={`detalhes/${r.idBusinessProposalValue}`}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.profileDescription && r.profileDescription}
        </StyledTableCell>
        <StyledTableCell scope="row" al ign="left">
          {r.supplier && r.supplier}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {!!r.validityStartDate &&
            Moment(r.validityStartDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {!!r.validityEndDate &&
            Moment(r.validityEndDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.model && r.model}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.profile && r.profile}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.level && r.level}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.tableLevel && r.tableLevel}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.range && r.range}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {valorFormatado}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.ipt && r.ipt}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.type && r.type}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Detalhe da Proposta - Admin");

    const headRows = [
      { label: "Ações", align: "center" },
      { column: "ProfileDescription", label: "Decrição do Perfil" },
      { column: "Supplier", label: "Fornecedor" },
      { column: "Name", label: "Nome da Proposta" },
      { column: "ValidityStartDate", label: "Início da Vigência" },
      { column: "ValidityEndDate", label: "Fim da Vigência" },
      { column: "Model", label: "Modelo" },
      { column: "Profile", label: "Perfil" },
      { column: "Level", label: "Nível Simplificado" },
      { column: "TableLevel", label: "Nível Tabela" },
      { column: "Range", label: "Faixa" },
      { column: "Value", label: "Valor" },
      { column: "IPT", label: "IPT" },
      { column: "Type", label: "Tipo" }
    ];

    const showAdminButtons = [
      {
        label: "Adicionar",
        onClick: null,
        icone: "add",
        path: `/admin/propostas/${this.props.match.params?.id}/adicionar-detalhes`
      } 
    ]

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Detalhe da Proposta"
          icon={ProposalIcon}
          endpoint={`/businessProposal/values/${this.props.match.params?.id}`}
          notPaginated={true}
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.proposalValues}
          setRows={this.setProposalValues}
          reload={this.state.reload}
          placeholderSearch="Busque por proposta ou descrição do perfil"
          width={this.props.width}
          hiddenReload
          hiddenFooterPagination
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
