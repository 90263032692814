import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { NotificationIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import CrudActions from "../../../../partials/datatable/CrudActions";
import { InexApiService } from "../../../../services/InexApiService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const initialState = {
    notificationDetails: [],
    reload: false,
    loading: false
};

export default class NotificationDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new InexApiService();
    this.formatRow = this.formatRow.bind(this);
    this.setNotificationDetails = this.setNotificationDetails.bind(this);
    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
  }

  setNotificationDetails = notificationDetails => {
    this.setState({ notificationDetails });
  };

  handleConfirmChangeStatus = async function (obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/notification/toggle-details/${obj.idNotificationDetail}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        e?.response?.data ? "warning" : "error"
      )
    }
  };

  formatRow = r => {
    const crudActions = ["toggle"];
    const valorFormatado = r.value ? `R$ ${r.value.toFixed(2)}` : "--";
    return (
      <StyledTableRow hover tabIndex={-1} key={r.idNotificationDetail}>
        <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module={`notificacoes`}
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/notificacoes/logs/${this.props.match.params?.id}`}
            id={`detalhes/${r.idNotificationDetail}`}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.user.name && r.user.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.awarenessDate &&
            Moment(r.awarenessDate).format("DD/MM/YYYY HH:mm:ss")}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.awarenessDate &&
            Moment(r.awarenessDate).add(r.notification.validity, 'days').format("DD/MM/YYYY HH:mm:ss")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Usuários da Notificação - Admin");

    const headRows = [
      { label: "Ações", align: "center" },
      { column: "Name", label: "Usuário" },
      { column: "AwarenessDate", label: "Data da Ciência", align: "center" },
      { column: "AwarenessDate", label: "Data de Expiração", align: "center" }
    ];

    const showAdminButtons = [ 
      {
        label: "Voltar",
        onClick: null,
        icone: "arrow_back",
        path: "/admin/notificacoes"
      } 
    ]

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Usuários da Notificação"
          icon={NotificationIcon}
          endpoint={`/notification/${this.props.match.params?.id}/details`}
          notPaginated={true}
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.notificationDetails}
          setRows={this.setNotificationDetails}
          reload={this.state.reload}
          placeholderSearch="Busque por usuário"
          width={this.props.width}
          hiddenReload
          hiddenFooterPagination
          onConfirmChangeStatus={this.handleConfirmChangeStatus}
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
