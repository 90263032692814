import React from "react";
import moment from "moment";
import Datatable from "../../../../../../partials/datatable/Datatable";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import { CheckTableRow, CheckTableCell } from "./style";
import store from "../../../../../../store/store";

export const CheckDatatable = ({ infoList, filters, totalAmount }) => {
  const width = useGetWindowWidth();
  const { auth } = store.getState();

  const formatRow = r => {
    return (
      <CheckTableRow hover tabIndex={-1} key={r.idUser}>
        <CheckTableCell scope="row">{r.name}</CheckTableCell>
        <CheckTableCell scope="row">{r.proposal ?? "--"}</CheckTableCell>
        <CheckTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.hourValue)}
          /hora
        </CheckTableCell>
        <CheckTableCell scope="row">
          {`${
            r.totalHours < 10 ? ("0" + r.totalHours).slice(-2) : r.totalHours
          }:${("0" + r.totalMinutes).slice(-2)} horas`}
        </CheckTableCell>
        <CheckTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.totalValue)}
        </CheckTableCell>
      </CheckTableRow>
    );
  };

  const headRows = [
    { label: "Nome" },
    { label: "Proposta" },
    { label: "Valor Hora (Faixa 4)", width: "20%" },
    {
      label: `Qtde Horas - ${moment(filters.startPeriod).format(
        "DD/MM"
      )} a ${moment(filters.endPeriod).format("DD/MM")}`,
      width: "20%"
    },
    {
      label: `Faturar - ${moment(filters.startPeriod).format(
        "DD/MM"
      )} a ${moment(filters.endPeriod).format("DD/MM")}`,
      width: "20%"
    }
  ];

  return (
    <Datatable
      className="h-100 m-0 p-0"
      headRows={headRows}
      styledTableCell={CheckTableCell}
      styledTableRow={CheckTableRow}
      formatRow={formatRow}
      rows={infoList}
      rowsPerPage={auth.user.rowsPerPage}
      width={width}
      removeShadow
      cancelReload
      hiddenReload
      hiddenFooterPagination
    />
  );
};
