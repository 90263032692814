import React from "react";
import { InputField } from "../../../../components/InputField";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiFilter } from "react-icons/fi";
import { Button } from "../Button/style";
import { Container } from "./style";
import { debounce } from "lodash";

export const Filters = ({ filters, handleChange, loadCheck, slug, path }) => {
  const debounceOnClick = debounce(loadCheck, 400);

  const handleFormSubmit = (event) => {
    if(event.key === "Enter") {
      debounceOnClick();
    };
  };

  return (
    <Container>
      {/* Search */}
      <InputField
        type="text"
        name="search"
        value={filters.search}
        onChange={handleChange}
        placeholder={`Busque por ${slug !== "Timers" ? slug : "Atividade"}`}
        onKeyPress={(e) => handleFormSubmit(e)}
      />

      <Button type="button" variant="filled" onClick={() => debounceOnClick()}>
        <span className="icon">
          <FiFilter size={22} />
        </span>{" "}
        <span>filtrar</span>
      </Button>

      <Link to={`/admin/${path}`}>
        <Button type="button" variant="filled" style={{ width: 140 }}>
          <span className="icon">
            <MdKeyboardArrowLeft size={22} />
          </span>{" "}
          <span>voltar</span>
        </Button>
      </Link>
    </Container>
  );
};

export default Filters;
