import React, { useRef, useEffect, useState } from 'react';
import { Tooltip, Typography, withStyles } from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#4672C5', // Cor de fundo do tooltip
        color: 'white', // Cor do texto do tooltip
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #4672C5', // Cor da borda do tooltip (opcional)
    },
    arrow: {
        color: '#4672C5', // Cor da seta do tooltip
    },
}))(Tooltip);

const TruncatedText = ({ text }) => {
    const textRef = useRef(null);
    const containerRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const checkTruncation = () => {
        if (textRef.current && containerRef.current) {
            const isOverflowing = textRef.current.scrollWidth > containerRef.current.clientWidth;
            setIsTruncated(isOverflowing);
        }
        };

        checkTruncation();
        window.addEventListener('resize', checkTruncation);

        return () => {
        window.removeEventListener('resize', checkTruncation);
        };
    }, []);

    return (
        <CustomTooltip title={text} arrow placement="top" disableHoverListener={!isTruncated}>
            <div ref={containerRef} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Typography ref={textRef} noWrap>{text}</Typography>
            </div>
        </CustomTooltip>
    );
};

export default TruncatedText;