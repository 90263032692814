export async function downloadFromUrl(fileUrl, filename) {
  const a = document.createElement("a");
  a.setAttribute("href", fileUrl);
  a.setAttribute("download", filename || "download");
  a.setAttribute("target", "_blank");
  a.setAttribute("rel", "noopener noreferrer");

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
