import React, { useState, useEffect } from "react";
import { RiAlertLine } from "react-icons/ri";
import { Card, Tab, Tabs } from "react-bootstrap";
import { InexApiService } from "../../../../services/InexApiService";
import { EndOfPeriodIcon } from "../../../../components/IconsSVG";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { ApprovalCard } from "./components/ApprovalCard";
import Filters from "./components/Filters";
import { TabsContainer } from "./styles";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const EndOfPeriod = () => {
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [period, setPeriod] = useState({});

  // Tabs state
  const [key, setKey] = useState("currentCycle");

  //const [filteredApprovalList, setFilteredApprovalList] = useState([]);

  const [projectListCurrentCycle, setProjectListCurrentCycle] = useState([]);
  const [projectListUnloadedPastCycles, setProjectListUnloadedPastCycles] = useState([]);

  useEffect(() => {
    window.setPageTitle("Fechamento - Admin");
    const loadSelects = async () => {
      const selects = await api.makeHttpRequest({
        url: "/clientloadedminutes/selects"
      });

      setFilterOptions(selects ?? {});
    };

    loadSelects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getUnloadedMinutes = async (filters) => {

    try {
      const params = {
        showOnlyMyProfessionals: filters.showOnlyMyProfessionals,
        idClient: filters.client,
        idCoordination: filters.coordination,
        idCoordinator: filters.coordinator,
        idProfessional: filters.employee
      };
      const resUnloadedProjects = await api.makeHttpRequest({
        url: "/clientloadedminutes/unloaded-approved-timers-grouped-pastcycles-by-user-and-project",
        params
      });
      setProjectListUnloadedPastCycles(resUnloadedProjects)
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }

  }


  const onSubmit = async (filters) => {
    setLoading(true);

    setPeriod({periodMonth: filters.periodMonth,
               periodYear: filters.periodYear
              })

    try {
      const params = {
        startPeriod: filters.startPeriod,
        endPeriod: filters.endPeriod,
        hasPeriod: true,
        idClient: filters.client,
        idCoordination: filters.coordination,
        idCoordinator: filters.coordinator,
        idProfessional: filters.employee,
        showOnlyMyProfessionals: filters.showOnlyMyProfessionals
      };

      const resProjects = await api.makeHttpRequest({
        url: "/clientloadedminutes/approved-timers-grouped-by-user-and-project",
        params
      });
      setProjectListCurrentCycle(resProjects);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false);

    getUnloadedMinutes(filters);
  }


  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={EndOfPeriodIcon} title="Fechamento do Ciclo" />
        <br></br>
        <Filters
          reload={reload}
          onSubmit={onSubmit}
          setReload={setReload}
          filterOptions={filterOptions}
          loading={loading}
          setLoading={setLoading}
        />
        <TabsContainer>
          <Tabs activeKey={key} transition={false} onSelect={k => setKey(k)}>
            <Tab eventKey="currentCycle" title="Ciclo Atual">
              <Card>
                <Card.Body>
                <div className="user-list">
                  {projectListCurrentCycle?.length > 0 &&
                    projectListCurrentCycle.map(item => {
                      {/* AQUI ESTOU SOMANDO O totalLoadedMinutes Para exibir no titulo junto como nome do Profissional*/}
                      let totalLoadedMinutes = 0;
                      let totalAllocatedMinutes = 0;
                      item.loadMinutesProjectList.forEach(project => {
                        totalAllocatedMinutes += project.totalAllocatedMinutes;
                        totalLoadedMinutes += project.totalLoadedMinutes;
                      });
                      return (
                        <ApprovalCard
                          key={item.professional.idUser+'_first'}
                          user={item.professional.idUser}
                          title={item.professional.name}
                          noteForBilling={item.professional.noteForBilling}
                          totalAllocatedMinutes={totalAllocatedMinutes}
                          totalLoadedMinutes={totalLoadedMinutes}
                          totalTime={item.totalMinutes}
                          projectList={item.loadMinutesProjectList}
                          setLoading={setLoading}
                          setReload={setReload}
                          period={period}
                        />
                      );
                    })}
                </div>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="unloadedPastCycles" title="Ciclos Passados">
              <Card>
                <Card.Body>
                <div className="user-list">
                  {projectListUnloadedPastCycles?.length > 0 &&
                    projectListUnloadedPastCycles.map(item => {
                      {/* AQUI ESTOU SOMANDO O totalLoadedMinutes Para exibir no titulo junto como nome do Profissional*/}
                      let totalLoadedMinutes = 0;
                      let totalAllocatedMinutes = 0;
                      item.loadMinutesProjectList.forEach(project => {
                        totalAllocatedMinutes += project.totalAllocatedMinutes;
                        totalLoadedMinutes += project.totalLoadedMinutes;
                      });
                      return (
                        <ApprovalCard
                          key={item.professional.idUser+'_second'}
                          user={item.professional.idUser}
                          title={item.professional.name}
                          noteForBilling={item.professional.noteForBilling}
                          totalAllocatedMinutes={totalAllocatedMinutes}
                          totalLoadedMinutes={totalLoadedMinutes}
                          totalTime={item.totalMinutes}
                          projectList={item.loadMinutesProjectList}
                          setLoading={setLoading}
                          setReload={setReload}
                          period={period}
                        />
                      );
                    })}
                </div>
                </Card.Body>
              </Card>
            </Tab>
          </Tabs>

          {/* {projectListCurrentCycle?.notLoadedPastCycle?.length > 0 ? (
            <div className="timer-alert">
              <RiAlertLine />
            </div>
          ) : (
            null
          )}    */}

        </TabsContainer>
      </div>
    </>
  );
};

export default EndOfPeriod;
