import React from "react";
import { InputField } from "../../../../components/InputField";
import { InexApiService } from "../../../../../../services/InexApiService";
import { FiFilter } from "react-icons/fi";
import { Button } from "../Button";
import { Container } from "./style";
import { debounce } from "lodash";

export const Filters = ({ filters, handleChange, onSubmit }) => {
  const api = new InexApiService();

  const [projectAreaOptions, setProjectAreaOptions] = React.useState([]);
  const [clientOptions, setClientOptions] = React.useState([]);

  React.useEffect(() => {
    loadClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filters.idClient) {
      loadProjectArea(filters.idClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.idClient]);

  const debounceOnClick = debounce(onSubmit, 400);

  const loadProjectArea = React.useCallback(async idClient => {
    const projectAreas = await api.makeHttpRequest({
      url: `/organograma/project-areas/client/${idClient}`
    });

    setProjectAreaOptions(projectAreas ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadClients = React.useCallback(async () => {
    const coordinators = await api.makeHttpRequest({
      url: "/organograma/clients"
    });

    setClientOptions(coordinators ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {/* Cliente */}
      <InputField
        as="select"
        name="idClient"
        value={filters.idClient}
        onChange={handleChange}
      >
        <option value="">Cliente</option>
        {clientOptions?.length > 0 &&
          clientOptions.map(c => (
            <option key={c.idClient} value={c.idClient}>
              {c.name}
            </option>
          ))}
      </InputField>

      {/* Coordenação */}
      <InputField
        as="select"
        name="idCoordination"
        value={filters.idCoordination}
        onChange={handleChange}
        disabled={!filters.idClient}
      >
        <option value="">Coordenação</option>
        {projectAreaOptions?.length > 0 &&
          projectAreaOptions.map(p => (
            <option key={p.idProjectArea} value={p.idProjectArea}>
              {p.name}
            </option>
          ))}
      </InputField>

      <Button
        type="button"
        variant="filled"
        disabled={!filters.idClient}
        onClick={() => debounceOnClick()}
      >
        <span className="icon">
          <FiFilter size={22} />
        </span>{" "}
        <span>filtrar</span>
      </Button>
    </Container>
  );
};

export default Filters;
