import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Container } from "./styles";

const Input = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  messageError,
  isError
}) => {
  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [errorDebounce, setErrorDebounce] = useState(false);

  const onChangeDebounc = useCallback(
    debounce(messageError => {
      setErrorDebounce(false);
      if (!!messageError) {
        return setErrorDebounce(true);
      }
      setErrorDebounce(false);
    }, 1000),
    [debounce, errorDebounce]
  );

  const handleChange = event => {
    onChange(event);
    onChangeDebounc(messageError);
  };

  return (
    <Container errorProps={isError || (errorDebounce && !!messageError)}>
      <div>
        {(isError || (errorDebounce && !!messageError)) && (
          <small>{messageError}</small>
        )}
        <input
          value={value}
          onChange={handleChange}
          name={name}
          onBlur={onBlur}
          className="col"
          placeholder={placeholder}
          type={
            type === "password"
              ? visibilityPassword
                ? "text"
                : "password"
              : type
          }
        />
      </div>
      {type === "password" && (
        <div
          style={{ flex: 0 }}
          onClick={() => setVisibilityPassword(!visibilityPassword)}
        >
          {visibilityPassword ? (
            <BsEye size="22px" color="#4d4d4d" />
          ) : (
            <BsEyeSlash size="22px" color="#4d4d4d" />
          )}
        </div>
      )}
    </Container>
  );
};

export default Input;
