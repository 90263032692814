import axios from "axios";
import { InexApiService } from "../services/InexApiService";

export const REGISTER_URL = "api/user";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
const api = new InexApiService();

export async function login(email, password) {
  return api.makeHttpRequest({
    url: '/login',
    method: 'POST',
    data: {
      Login: email,
      Password: password
    }
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return api.makeHttpRequest({
    url: '/user/me'
  });
};
