import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 155px;
  border-radius: 4px;
  border: 1px solid #4672C5;
  background: ${({ type }) => (type === "submit" ? "#4672C5" : "transparent")};

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ type }) => (type === "submit" ? "#fff" : "#4672C5")};

  box-shadow: ${({ type }) =>
    type === "submit"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  margin-left: auto;

  &:disabled {
    cursor: not-allowed;
    background: #807e80;
    border-color: #807e80;
  }

  svg {
    stroke: #fff;

    margin-right: 4px;
  }
`;
