import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { UserIcon } from "../../../../../../components/IconsSVG";
import Datatable from "../../../../../../partials/datatable/Datatable";
import { InexApiService } from "../../../../../../services/InexApiService";
import Loading from "../../../../../home/components/Loading";
import { AdminTitle } from "../../../../components/AdminTitle";
import {
  ReportTableCell,
  ReportTableRow,
  TableContainer,
  TableToExcelButton
} from "./style";
import { TranslateProfileType } from "../../../../../../utils/TranslateProfileType";
import { Link } from "react-router-dom";
import styled from "styled-components";
import formatCurrency from "../../../../../../utils/FormatCurrency";

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const ReportAllocated = props => {
  const api = new InexApiService();
  const params = props.location.search;

  const [loading, setLoading] = React.useState(false);
  const [professionals, setProfessionals] = React.useState([]);

  const [selected, setSelected] = React.useState([]);
  const [filterSelected, setFilterSelected] = React.useState(0)
  const [clientName, setClientName] = React.useState('')

  React.useEffect(() => {
    handleReport();
    let client = props.location.search.split('?')[1]
    setClientName(client.split('&')[1].split('=')[1])
    setFilterSelected(parseInt(client.split('&')[0].split('=')[1]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReport = React.useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.makeHttpRequest({
        url: "/professional/report-allocated" + params
      });

      if (response && response.length > 0) {
        const headers = Object.keys(response[0]).map(item => ({ label: item }));
        const arr = response.map(_ => false);
        setProfessionals(response);
        setSelected(arr);
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao gerar relatório.",
        "error"
      );
    }
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectRow = index => {
    const arr = [...selected];
    arr[index] = !arr[index];
    setSelected(arr);
  };

  const headRows = [
    { label: "Profissional", align: "left" },    
    { label: "Projeto", align: "center" },
    { label: "Atividade", align: "left" },
    { label: "Data de Ativação", align: "center" },
    { label: "Valor Contrato", align: "right" },
    { label: "Valor Hora", align: "right" }
  ];

  const formatRow = (r) => {
    return (
        <ReportTableRow hover tabIndex={-1} key={r.idProject}>
            <ReportTableCell scope="row" align="left">
                {r.name}
            </ReportTableCell>
            <ReportTableCell scope="row" align="center">
                {!!r.projectName && r.projectName}
            </ReportTableCell>
            <ReportTableCell scope="row" align="left">
                {r.profileDescription}
            </ReportTableCell>
            <ReportTableCell scope="row" align="center">
                {!!r.activateDate &&
                  moment(r.activateDate).format("DD/MM/YYYY")}
            </ReportTableCell>
            <ReportTableCell scope="row" align="right">
                {formatCurrency(r.businessProposalValue.toFixed(2))}
            </ReportTableCell>
            <ReportTableCell scope="row" align="right">
                {formatCurrency(r.wageHour.toFixed(2))}
            </ReportTableCell>
        </ReportTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <AdminTitle
        title={`Relatório de Profissionais alocados em Projetos (${clientName})`}
        icon={UserIcon}
        disableHeader
      />
      {professionals.length > 0 && <>
        <div style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "flex-end",
          alignItems: "center"
        }}>
          <Link to={`/admin/profissionais`}>
            <Button variant="secondary" color="#4672C5" type="button">
              <span>
                Voltar
              </span>
            </Button>
          </Link>
          <TableToExcelButton
            table="datatable-table"
            className="export-table-button"
            filename={`Lista de Profissionais Alocados (${clientName}) ${moment().format("DD-MM-YYYY")}`}
            sheet="Tabela 1"
            buttonText="Exportar"
          />
        </div>
        <TableContainer>
          <Datatable
            className="m-0 p-0"
            headRows={headRows}
            styledTableCell={ReportTableCell}
            styledTableRow={ReportTableRow}
            overflowX="initial"
            formatRow={formatRow}
            rows={professionals}
            width={props.width}
            hiddenFooterPagination
            cancelReload
            hiddenReload
          />
        </TableContainer>
      </>}
    </>
  );
};

export default ReportAllocated;
