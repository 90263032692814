import moment from 'moment';

export const HandleGetDateSelect  = (name, value, day, year) => {
  const concatMonthNumber = value < 10 ? `0${value}` : value;
  const concatDayNumber = day < 10 ? `0${day}` : day;

  if(name && value) {
    const formattedInitialDate = `${concatDayNumber}/${name}/${year}`;
    const formattedEndDate = moment(`${concatDayNumber}${concatMonthNumber}${year}`, "DDMMYYYY")
      .add(1, 'M')
      .subtract(1, 'days').format('DD/MMMM/YYYY');

    moment.updateLocale('pt', {
      months : [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago", "Set", "Out", "Nov", "Dez"]
    });

    return formattedInitialDate + ' -- ' + formattedEndDate;
  }
}
