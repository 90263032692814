import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import { TranslateProfileType } from "../../../../utils/TranslateProfileType";
import Loading from "../../../home/components/Loading";
import store from "../../../../store/store";
import { UserIcon } from "../../../../components/IconsSVG";
import { BiFileBlank } from "react-icons/bi";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { UserModal } from "./components/Modal";
import { ERoles } from "../../../../enums/Roles";
import ConfirmChangeStatusDialog from "../../../../partials/datatable/ConfirmChangeStatusDialog";

const initialState = {
  users: [],
  auth: null,
  profile: "",
  reload: false,
  loading: false,
  openedModal: false,
  quickView: false,
  selectFilter: "",
  gerentesOptions: [],
  confirmChangeStatus: false,
  obj: undefined
};

export default class UsersListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.handleSendConfirmationMail = this.handleSendConfirmationMail.bind(
      this
    );
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setUsers = this.setUsers.bind(this);
    this.loadGerentes = this.loadGerentes.bind(this);
    this.deleteProfessional = this.deleteProfessional.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  componentDidMount = async () => {
    const { auth } = store.getState();
    const profile = auth && auth.user && auth.user.role.type;
    this.loadGerentes();
    this.setState({ auth, profile });
  };

  loadGerentes = async () => {
    const gerentes = await this.api.makeHttpRequest({
      url: "/professional/commercial-managers"
    });

    this.setState({
      gerentesOptions: gerentes
    });
  };

  deleteProfessional = async function(id) {
    try {
      this.setState({ loading: true });
      await this.api.makeHttpRequest({
        method: "DELETE",
        url: `/professional/delete/${id}`
      });

      this.setState({ reload: !this.state.reload });
      this.setState({selectFilter: ""})
      Swal.fire({
        title: "Sucesso!",
        text: "Usuário deletado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e.response.data ? e.response.data : "Erro ao deletar usuário.",
        "error"
      );
    }
    this.setState({ loading: false });
  }

  handleConfirmDelete = async function(id) {
    if (this.state.gerentesOptions.filter(item => item.idUser === id).length > 0) {
      const gerentesOptionsFilted = this.state.gerentesOptions.filter(item => item.idUser !== id);
      const inputOptions = gerentesOptionsFilted.reduce((obj, item) => {
        obj[item.idUser] = item.name;
        return obj;
      }, {});
      Swal.fire({
        title: "Qual gerente comercial irá herdar os projetos e profissionais?",
        input: "select",
        inputOptions: inputOptions,
        inputPlaceholder: 'Selecione o Gerente Comercial',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Salvar",
        denyButtonText: `Cancelar`,
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value === undefined || value.trim() === '') {
              reject('Você precisa selecionar o gerente comercial que irá herdar!');
              Swal.fire(
                "Erro!",
                "Você precisa selecionar o gerente comercial que irá herdar!",
                "error"
              );
            } else {
              resolve()
            }
          })
        }
      }).then(async (result) => {
        if (!result.dismiss) {
          await this.api.makeHttpRequest({
            method: "PUT",
            url: `/user/migrate-relations-commercial/${id}/to/${result.value}`
          }).then(() => {
            this.deleteProfessional(id);
          })
        }
      });
    } else {
      this.deleteProfessional(id)
    }
  };

  changeStatus = async function(obj) {
    this.setState({confirmChangeStatus: false})
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/professional/toggle/${obj.idUser}`
      });

      this.setState({ reload: !this.state.reload });
      this.setState({selectFilter: ""})
      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        "error"
      );
    }
  }

  handleConfirmChangeStatus = async function(obj) {
    if (obj.deletionDate === null && this.state.gerentesOptions.filter(item => item.idUser === obj.idUser).length > 0) {
      const gerentesOptionsFilted = this.state.gerentesOptions.filter(item => item.idUser !== obj.idUser);
      const inputOptions = gerentesOptionsFilted.reduce((obj, item) => {
        obj[item.idUser] = item.name;
        return obj;
      }, {});
      Swal.fire({
        title: "Qual gerente comercial irá herdar os projetos e profissionais?",
        input: "select",
        inputOptions: inputOptions,
        inputPlaceholder: 'Selecione o Gerente Comercial',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Salvar",
        denyButtonText: `Cancelar`,
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value === undefined || value.trim() === '') {
              reject('Você precisa selecionar o gerente comercial que irá herdar!');
              Swal.fire(
                "Erro!",
                "Você precisa selecionar o gerente comercial que irá herdar!",
                "error"
              );
            } else {
              resolve()
            }
          })
        }
      }).then(async (result) => {
        if (!result.dismiss) {
          await this.api.makeHttpRequest({
            method: "PUT",
            url: `/user/migrate-relations-commercial/${obj.idUser}/to/${result.value}`
          }).then(() => {
            this.changeStatus(obj);
          })
        }
      });
    } else {
      if (!obj.deletionDate) {
        this.setState({obj: obj, confirmChangeStatus: true})
      } else {
        this.changeStatus(obj)
      }
    }
  };

  handleSendConfirmationMail = async function(obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: "/professional/resend-email",
        data: {
          inexEmail: obj.inexEmail
        }
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Email de confirmação foi enviado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Ocorreu um erro ao reenviar o email.",
        "error"
      );
    }
  };

  setUsers = users => {
    this.setState({ users });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit", "delete", "quick-view"];
    const { auth } = this.state;
    const isAdmin = auth && auth.user && auth.user.role.type === ERoles.ADMIN;
    const isOperation = auth && auth.user && auth.user.role.type === ERoles.OPERATION;
    const isFinancial = auth && auth.user && auth.user.role.type === ERoles.FINANCIAL;
    const isRecruitment = auth && auth.user && auth.user.role.type === ERoles.RECRUITMENT;

    if (isAdmin || isOperation || isFinancial || isRecruitment) {
      if (r.activationDate) {
        crudActions = [...crudActions];
      }

      if (!r.activationDate) {
        crudActions = isFinancial ? [...crudActions] : [...crudActions, "resend"];
      }

      if (r.idUser === auth.user.idUser) {
        crudActions = ["edit"];
      }
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idUser}>
        {(isAdmin || isOperation || isFinancial || isRecruitment) && <StyledTableCell scope="row" align="center">
          <CrudActions
              actions={crudActions}
              module="profissionais"
              onConfirmChangeStatus={this.handleConfirmChangeStatus}
              onConfirmResendEmail={this.handleSendConfirmationMail}
              onConfirmDelete={this.handleConfirmDelete}
              onConfirmInactive={false}
              onQuickView={this.state.quickView}
              id={r.idUser}
              obj={r}
            />
          </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left">
          {r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.role && TranslateProfileType(r.role.type)}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.inexEnrollment && r.inexEnrollment}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.clients?.length > 0 &&
            r.clients.map(c => <div key={c.idClient}>{c.name}</div>)}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.projects?.length > 0
            ? r.projects.map(c => <div key={c.idProject}>{c.area}</div>)
            : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.projects?.length > 0
            ? r.projects.map(p => <div key={p.idProject}>{p.projectName}</div>)
            : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.projects?.length > 0
            ? r.projects.map(p => (
                <div key={p.idProject}>{p.coordinatorName}</div>
              ))
            : "--"}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Profissionais - Admin");

    let headRows = [
      { column: "Name", label: "Nome" },
      {
        column: "Role.Type",
        label: "Perfil",
        align: "center",
        width: "5%"
      },
      {
        column: "InexEnrollment",
        label: "Matricula",
        align: "center",
        width: "5%"
      },
      { label: "Clientes", align: "center" },
      {
        label: "Coordenação",
        align: "center"
      },
      { label: "Projetos", align: "center" },
      { label: "Coordenadores", align: "center" }
    ];

    if (this.state.profile !== ERoles.COMMERCIAL) {
      headRows = [{ label: "Ações", align: "center", width: "5%" }, ...headRows];
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <UserModal
          open={this.state.openedModal}
          setOpen={bool => this.setState({ openedModal: bool })}
        />
        <ConfirmChangeStatusDialog
          open={this.state.confirmChangeStatus}
          closeDialog={() => this.setState({confirmChangeStatus: false})}
          confirm={() => this.changeStatus(this.state.obj)}
          message={`Esse usuário será desvinculado dos Clientes / Projetos atuais.`}
          message2={`Deseja inativar o usuário selecionado?`}
          title="Confirmação"
        />
        <Datatable
          title="Profissionais"
          icon={UserIcon}
          endpoint="/professional/paged"
          filterType={this.state.selectFilter?.value}
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.users}
          setRows={this.setUsers}
          reload={this.state.reload}
          placeholderSearch="Busque por nome, projeto ou cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          selects={[
            {
              placeholder: "Selecionar filtro",
              value: this.state.selectFilter,
              onChange: value => this.setState({ selectFilter: value }),
              options: [
                { value: "", label: "Todos" },
                { value: "withoutProject", label: "Sem projetos" },
                { value: "withoutProposal", label: "Sem propostas" },
                { value: "inactive", label: "Inativos" }
              ]
            }
          ]}
          buttons={
            (this.state.profile === ERoles.ADMIN
            || this.state.profile === ERoles.OPERATION
            || this.state.profile === ERoles.FINANCIAL
            || this.state.profile === ERoles.RECRUITMENT)
            ? [
                {
                  label: "Adicionar",
                  onClick: null,
                  icone: "add",
                  path: "/admin/profissionais/adicionar"
                },
                {
                  label: "Relatório",
                  onClick: () => this.setState({ openedModal: true }),
                  RIcone: () => <BiFileBlank size={22} />,
                  path: ""
                }
              ]
            : [
                {
                  label: "Relatório",
                  onClick: () => this.setState({ openedModal: true }),
                  RIcone: () => <BiFileBlank size={22} />,
                  path: ""
                }
              ]
          }
        />
      </>
    );
  }
}
