import React from "react";
import DatePicker from "react-datepicker";
import { pt } from "date-fns/locale";
import { Container } from "./style";
import moment from "moment";
import { DateFilterIcon } from "../../../../../../components/IconsSVG";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService";
import "react-datepicker/dist/react-datepicker.css";

const poppupOptions = [
  {
    name: "offset",
    options: {
      offset: [-10, 0]
    }
  }
];


const ID_INEX = 1;
const ID_PORTO = 2;
const FINAL_PORTO_TIMER_DATE = 21;

export const TimerPicker = ({
  idClient,
  inputWidth,
  popAuto,
  initialDates,
  handleDates,
  compound,
  isLoadTimers, 
  SimplifiedDate  //O Simplified Date usa apenas o Dia, Mes e Ano, ignorando a hora de inicio e hora de fim. 
}) => {
  const api = new InexApiService();
  const [startFirstTimer, setStartFirstTimer] = React.useState(null);
  const [endFirstTimer, setEndFirstTimer] = React.useState(null);
  const [startSecondTimer, setStartSecondTimer] = React.useState(null);
  const [endSecondTimer, setEndSecondTimer] = React.useState(null);

  const [date, setDate] = React.useState(new Date());

  const [minStartTime] = React.useState(
    moment()
      .startOf("day")
      .toDate()
  );
  const [maxStartTime, setMaxStartTime] = React.useState();
  const [minEndTime, setMinEndTime] = React.useState(new Date());
  const [maxEndTime, setMaxEndTime] = React.useState(new Date());

  const [maxDate, setMaxDate] = React.useState(new Date());

  React.useEffect(() => {
    if(compound) {
      setStartFirstTimer(
        isLoadTimers.startFirstTimer
        ? moment(isLoadTimers.startFirstTimer).toDate()
        : null
      );
      setEndFirstTimer(
        isLoadTimers.endFirstTimer
        ? moment(isLoadTimers.endFirstTimer).toDate()
        : null
      );
      setStartSecondTimer(
        isLoadTimers.startSecondTimer
        ? moment(isLoadTimers.startSecondTimer).toDate()
        : null
      );
      setEndSecondTimer(
        isLoadTimers.endSecondTimer
        ? moment(isLoadTimers.endSecondTimer).toDate()
        : null
      );

      return;
    }
  }, [compound])

  React.useEffect(() => {
    if (!startFirstTimer && !endFirstTimer) {
      if (initialDates?.startFirstTimer) {
        setStartFirstTimer(moment(initialDates.startFirstTimer).toDate());
        setDate(moment(initialDates.startFirstTimer).toDate());
      }

      if (initialDates?.endFirstTimer) {
        setEndFirstTimer(moment(initialDates.endFirstTimer).toDate());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDates]);

  React.useEffect(() => {

    if (!compound && startFirstTimer && endFirstTimer) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const formattedStartTime = moment(startFirstTimer).format("HH:mm:ss");
      const formattedEndTime = moment(endFirstTimer).format("HH:mm:ss");

      handleDates(
        `${formattedDate}T${formattedStartTime}`,
        `${formattedDate}T${formattedEndTime}`
      );
    }

    if (compound && startFirstTimer && endFirstTimer && startSecondTimer && endSecondTimer) {
      const formattedDateCompound = moment(date).format("YYYY-MM-DD");

      const formattedStartTime = moment(startFirstTimer).format("HH:mm:ss");
      const formattedEndTime = moment(endFirstTimer).format("HH:mm:ss");
      const formattedStartSecondTime = moment(startSecondTimer).format("HH:mm:ss");
      const formattedEndSecondTime = moment(endSecondTimer).format("HH:mm:ss");

      handleDates(
        `${formattedDateCompound}T${formattedStartTime}`,
        `${formattedDateCompound}T${formattedEndTime}`,
        `${formattedDateCompound}T${formattedStartSecondTime}`,
        `${formattedDateCompound}T${formattedEndSecondTime}`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, endFirstTimer, startFirstTimer, startSecondTimer, endSecondTimer])

  const setMinEndHour = React.useCallback(() => {
    const isPorto =
      !!idClient &&
      +idClient === ID_PORTO ;

    if (moment(date).isSame(moment(), "day") && startFirstTimer && !isPorto) {
      setMinEndTime(
        moment(startFirstTimer)
          .add("1", "minute")
          .toDate()
      );
    } else {
      if (startFirstTimer) {
        setMinEndTime(
          moment(startFirstTimer)
            .add("1", "minute")
            .toDate()
        );
      } else {
        setMinEndTime(
          moment()
            .startOf("day")
            .toDate()
        );
      }
    }
  }, [date, startFirstTimer, idClient]);

  const setMaxStartAndEndHour = React.useCallback(() => {
    
    const isPortoOrInex =
      !!idClient &&
      (+idClient === ID_PORTO || +idClient === ID_INEX) ;

    if (moment(date).isSame(moment(), "day") && !isPortoOrInex) {
      setMaxStartTime(new Date());
      setMaxEndTime(new Date());
    } else {
      if (endFirstTimer) {
        setMaxStartTime(
          moment(endFirstTimer)
            .add(-1, "minute")
            .toDate()
        );

        setMaxEndTime(
          moment()
            .endOf("day")
            .toDate()
        );
      } else {
        setMaxStartTime(
          moment()
            .endOf("day")
            .toDate()
        );

        setMaxEndTime(
          moment()
            .endOf("day")
            .toDate()
        );
      }
    }
  }, [date, endFirstTimer, idClient]);

  React.useEffect(() => {
    setMinEndHour();
    setMaxStartAndEndHour();
  }, [setMaxStartAndEndHour, setMinEndHour]);

  React.useEffect(() => {
    setMaxDate(new Date());

    if (!idClient) {
      return;
    }

    if (+idClient === ID_PORTO || +idClient === ID_INEX) {
          
        const today = moment(); // Obtém a data atual
        let endDate = moment(today).date(FINAL_PORTO_TIMER_DATE); // Define o dia do mês com o fechamento da Porto

        // Verifica se a data atual já é igual ou posterior ao dia do fechamento da Porto
        // Se for, adiciona um mês à data do próximo dia 21
        if (today.date() > FINAL_PORTO_TIMER_DATE) {
          endDate = endDate.add(1, 'months');
        }

        setMaxDate(moment(endDate).toDate());
      
    }

  }, [idClient]);

  const alertHoliday = async () => {
    var holidays = []
    
    if (idClient) {
      try {
        const holidayList = await api.makeHttpRequest({
          url: `/holiday/by-client/${idClient}`
        });
        holidays = holidayList.holidays.map((holiday) => moment(holiday.date).format('YYYY-MM-DD'))
      } catch { }
    }
    const isHoliday = holidays.includes(moment(date).format('YYYY-MM-DD'));
    if (isHoliday) {
      Swal.fire({
        title: "Aviso!",
        text: "Você selecionou um Feriado. Embora não seja um impeditivo, vale o aviso. Click Ok para prosseguir",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    }
  } 

  React.useEffect(() => {
    const dayOfWeek = date.getDay();
    if ( dayOfWeek === 6) {
      Swal.fire({
        title: "Aviso!",
        text: "Você selecionou um Sábado. Embora não seja um impeditivo, vale o aviso. Click Ok para prosseguir",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    } else if (dayOfWeek === 0){
      Swal.fire({
        title: "Aviso!",
        text: "Você selecionou um Domingo. Embora não seja um impeditivo, vale o aviso. Click Ok para prosseguir",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    }
    alertHoliday()
  }, [date]);


  return (
    <Container inputWidth={inputWidth}>
      {!SimplifiedDate ? ( //Se estivermos querendo exibir a data simplificada, então não mostramos os campos de hora inicio e hora de fim. 
        <div className="time-inputs">
          {compound ? (
            <>
              <div className="time">
                <label>Entrada</label>
                <DatePicker
                  selected={startFirstTimer}
                  onChange={hour => setStartFirstTimer(hour)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeIntervals={5} // TODO: voltar para 15 minutos
                  timeFormat="HH:mm"
                  timeCaption="Entrada"
                  placeholderText="--:--"
                  showPopperArrow={false}
                  popperClassName="datetime-popper"
                  popperModifiers={poppupOptions}
                  popperPlacement={popAuto ? "auto" : "bottom"}
                  minTime={moment()
                    .startOf("day")
                    .toDate()}
                  maxTime={
                    moment()
                      .endOf("day")
                      .toDate()
                  }
                />
              </div>

              <span className="space"></span>

              <div className="time">
                <label>Intervalo</label>
                <DatePicker
                  selected={endFirstTimer}
                  onChange={hour => setEndFirstTimer(hour)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeIntervals={5} // TODO: voltar para 15 minutos
                  timeFormat="HH:mm"
                  timeCaption="Intervalo"
                  placeholderText="--:--"
                  showPopperArrow={false}
                  popperClassName="datetime-popper"
                  popperModifiers={poppupOptions}
                  popperPlacement={popAuto ? "auto" : "bottom"}
                  minTime={
                    startFirstTimer !== null ? startFirstTimer :
                    moment()
                      .startOf("day")
                      .toDate()
                  }
                  maxTime={
                    moment()
                      .endOf("day")
                      .toDate()
                  }
                />
              </div>
              <span className="hyphen"></span>
              <div className="time">
                <label>Retorno</label>
                <DatePicker
                  selected={startSecondTimer}
                  onChange={hour => setStartSecondTimer(hour)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeIntervals={5} // TODO: voltar para 15 minutos
                  timeFormat="HH:mm"
                  timeCaption="Retorno"
                  placeholderText="--:--"
                  showPopperArrow={false}
                  popperClassName="datetime-popper"
                  popperModifiers={poppupOptions}
                  popperPlacement={popAuto ? "auto" : "bottom"}
                  minTime={
                    endFirstTimer !== null ? endFirstTimer :
                    moment()
                      .startOf("day")
                      .toDate()
                  }
                  maxTime={
                    moment()
                      .endOf("day")
                      .toDate()
                  }
                />
              </div>

              <span className="space"></span>

              <div className="time">
                <label>Saída</label>
                <DatePicker
                  selected={endSecondTimer}
                  onChange={hour => setEndSecondTimer(hour)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeIntervals={5} // TODO: voltar para 15 minutos
                  timeFormat="HH:mm"
                  timeCaption="Saída"
                  placeholderText="--:--"
                  showPopperArrow={false}
                  popperClassName="datetime-popper"
                  popperModifiers={poppupOptions}
                  popperPlacement={popAuto ? "auto" : "bottom"}
                  minTime={
                    startSecondTimer !== null ? startSecondTimer :
                    moment()
                      .startOf("day")
                      .toDate()
                  }
                  maxTime={moment()
                    .endOf("day")
                    .toDate()}
                />
              </div>
            </>
          ) : (
            <>
              <div className="time">
                <DatePicker
                  selected={startFirstTimer}
                  onChange={hour => setStartFirstTimer(hour)}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={minStartTime}
                  maxTime={maxStartTime}
                  dateFormat="HH:mm"
                  timeIntervals={5} // TODO: voltar para 15 minutos
                  timeFormat="HH:mm"
                  timeCaption="Início"
                  placeholderText="--:--"
                  showPopperArrow={false}
                  popperClassName="datetime-popper"
                  popperModifiers={poppupOptions}
                  popperPlacement={popAuto ? "auto" : "bottom"}
                />
              </div>
              <span className="hyphen"></span>
              <div className="time">
                <DatePicker
                  selected={endFirstTimer}
                  onChange={hour => setEndFirstTimer(hour)}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={minEndTime}
                  maxTime={maxEndTime}
                  dateFormat="HH:mm"
                  timeIntervals={5} // TODO: voltar para 15 minutos
                  timeFormat="HH:mm"
                  timeCaption="Término"
                  placeholderText="--:--"
                  showPopperArrow={false}
                  popperClassName="datetime-popper"
                  popperModifiers={poppupOptions}
                  popperPlacement={popAuto ? "auto" : "bottom"}
                />
              </div>
            </>
          )}
        </div>
      ):(null)}

      <div className="date-input">
        <div className="date-icon">
          <DateFilterIcon />
        </div>
        <DatePicker
          selected={date}
          onChange={date => setDate(date)}
          locale={pt}
          dateFormat="dd/MM/yyyy"
          showPopperArrow={false}
          popperClassName="datetime-popper"
          popperModifiers={poppupOptions}
          popperPlacement={popAuto ? "auto" : "bottom"}
        />
      </div>
    </Container>
  );
};
