import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";

export const ApprovalTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    max-height: 40px !important;
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const ApprovalTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #807e80;
    line-height: 150%;
    height: 32px;
    padding: 0 16px;
    border: none;
  }

  /* &.MuiTableCell-head:first-child {
    padding: ${({ isFactory }) => isFactory ? '' : 0 };
  } */

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Light", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #4d4c4d;
    height: 32px;
    padding: 0 16px 0px 16px;
    border: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 310px;

    /* &:first-child {
      padding: 0;
      justify-content: center;
    } */

    .time-total {
      font-family: "Cera Pro Medium", sans-serif;
      font-weight: 600;
      color: #807e80;
      text-transform: capitalize;
    }

    .status-color {
      position: absolute;
      display: flex;
      height: 48px;
      width: 6px;

      top: 0;
      left: 0;

      background: #fb7e38;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
