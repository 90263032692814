import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserIcon } from "../../../../../../components/IconsSVG";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import store from "../../../../../../store/store";
import { ApprovalDatatable } from "../ApprovalDatatable";
import { ProposalModal } from "../ProposalModal";
import { formatTotalTime } from "../../../../../../utils/FormatTotalTime";

import { StyledAccordion } from "../../../../components/Accordion/styles";
import { CustomAccordionBody } from "../../../../components/Accordion";


export const ApprovalCard = ({
  key,
  user,
  title,
  timerList,
  approvedTab,
  noteForBilling,
  totalTime,
  hasTimerWithoutProposal,
  hasTimerWithoutDemand,
  hasTimerWithoutLoadedMinutes,
  setLoading,
  reload,
  setReload,
  proposalOptions,
  handleAccordionExpand,
  setProfessionalsNotReload
}) => {
  const api = new InexApiService();
  const { auth } = store.getState();

  const [approvedList, setApprovedList] = useState([]);
  const [proposalModal, setProposalModal] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  const [noteForBillingLocal, setNoteForBillingLocal] = React.useState(noteForBilling);

  useEffect(() => {
    setIsAllowed(
      (auth?.user?.role?.type === ERoles.ADMIN ||
        auth?.user?.role?.type === ERoles.OPERATION) ??
        false
    );
  }, [auth]);

  const handleAddAprovedList = newList => {
    setApprovedList(newList);
  };


  const handleSetProposal = async (idUser, idProposal) => {
    setLoading(true);

    try {
      await api.makeHttpRequest({
        url: "/approval/set-rfq",
        method: "PUT",
        params: { idProfessional: idUser, idBusinessProposalValue: idProposal }
      });

      Swal.fire(
        "Sucesso!",
        "Sucesso ao salvar atividade ao timer e/ou ao profissional.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao salvar atividade ao timer e/ou ao profissional.",
        "error"
      );
    }

    setReload(true);
    setLoading(false);
  };

  const handleSubmit = async (e, reason) => {
    e.stopPropagation();

    if (approvedList.length <= 0) {
      Swal.fire(
        "Nenhum item selecionado",
        "Selecione os itens que deseja aprovar/reprovar.",
        "warning"
      );
      return;
    }
    setLoading(true);
    try {
      let notCoordinator = false;
      notCoordinator = approvedList.some(item => item.project.coordinator.idUser !== auth.user.idUser);
      let execute = true;
      if (notCoordinator) {
        const { value } = await Swal.fire({
          title: "Atenção!",
          text: "Existe(m) timer(s) onde você não é coordenador, deseja aprovar mesmo assim?",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não"
        });
        if (value === undefined) {
          execute = false;
        }
      }
      if (execute) {
        const data = {
          timers: approvedList, 
          disapprovedReason: reason??null
        }
        await api.makeHttpRequest({
          url: "/approval/change-timer-status",
          method: "POST",
          data
        });
      }     
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao aprovar/reprovar itens selecionados.",
        "error"
      );
    }
    // here we need reset approvedList, because if we use a new command,
    // that will use the last list in submit.  
    setApprovedList([]);
    setProfessionalsNotReload([]);
    setReload(true)
    setLoading(false);
  };

  const handleSubmitPending = async (e, reason) => {
    e.stopPropagation();

    if (approvedList.length <= 0) {
      Swal.fire(
        "Nenhum item selecionado",
        "Selecione os itens que deseja aprovar/reprovar.",
        "warning"
      );
      return;
    }
    setLoading(true);
    try {
      let notCoordinator = false;
      notCoordinator = approvedList.some(item => item.project.coordinator.idUser !== auth.user.idUser);
      let execute = true;
      if (notCoordinator) {
        const { value } = await Swal.fire({
          title: "Atenção!",
          text: "Existe(m) timer(s) onde você não é coordenador, deseja voltar para pendente mesmo assim?",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não"
        });
        if (value === undefined) {
          execute = false;
        }
      }
      if (execute) {
        const data = {
          timers: approvedList, 
          disapprovedReason: reason??null
        }
        await api.makeHttpRequest({
          url: "/approval/disapproved-timer",
          method: "POST",
          data
        });
      }     
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao aprovar/reprovar itens selecionados.",
        "error"
      );
    }
    setApprovedList([]);
    setProfessionalsNotReload([]);
    setReload(true)
    setLoading(false);
  }

  const handleSubmitDisapproved = async (e, reason) => {
    e.stopPropagation();

    if (approvedList.length <= 0) {
      Swal.fire(
        "Nenhum item selecionado",
        "Selecione os itens que deseja aprovar/reprovar.",
        "warning"
      );
      return;
    }
    setLoading(true);
    try {
      let notCoordinator = false;
      notCoordinator = approvedList.some(item => item.project.coordinator.idUser !== auth.user.idUser);
      let execute = true;
      if (notCoordinator) {
        const { value } = await Swal.fire({
          title: "Atenção!",
          text: "Existe(m) timer(s) onde você não é coordenador, deseja reprovar mesmo assim?",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não"
        });
        if (value === undefined) {
          execute = false;
        }
      }
      if (execute) {
        const data = {
          timers: approvedList, 
          disapprovedReason: reason??null
        }
        await api.makeHttpRequest({
          url: "/approval/disapproved-timer",
          method: "POST",
          data
        });
      }     
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao aprovar/reprovar itens selecionados.",
        "error"
      );
    }
    setApprovedList([]);
    setProfessionalsNotReload([]);
    setReload(true)
    setLoading(false);
  }

  return totalTime.hours <= 0 && totalTime.minutes <= 0 ? null : (
    <>
      <StyledAccordion key={key} shadow="true">
        <CustomAccordionBody
          eventKey="0"
          title={title}
          icon={UserIcon}
          user={user}
          noteForBilling={noteForBillingLocal}
          setProposalModal={setProposalModal}
          approvedTab={approvedTab}
          hasTimerWithoutProposal={hasTimerWithoutProposal}
          hasTimerWithoutDemand={hasTimerWithoutDemand}
          hasTimerWithoutLoadedMinutes={hasTimerWithoutLoadedMinutes}
          totalTime={formatTotalTime(totalTime)}
          handleAccordionExpand={handleAccordionExpand}
          handleSubmit={handleSubmit}
          handleSubmitPending={handleSubmitPending}
          handleSubmitDisapproved={handleSubmitDisapproved}
          isAllowed={isAllowed}
          approvedList={approvedList}
        >
          {
            timerList.length > 0 &&
            <ApprovalDatatable
              key={key}
              timerList={timerList}
              approvedList={approvedList}
              setApprovedList={handleAddAprovedList}
            />
          }
          
        </CustomAccordionBody>
      </StyledAccordion>

      <ProposalModal
        user={proposalModal}
        close={() => setProposalModal(false)}
        options={proposalOptions}
        handleConfirm={handleSetProposal}
      />

    </>
  );
};
