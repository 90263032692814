import React from "react";
import Swal from "sweetalert2";
import CMSService from "../../../../services/CMSService";
import { InexApiService } from "../../../../services/InexApiService";
import { Birthdays } from "./components/Birthdays";
import { NewsFeed } from "./components/NewsFeed";
import { NewsPagination } from "./components/NewsPagination";
import { PageTitle } from "../PageTitle";
import { Container } from "./styles";

const PER_PAGE = 12;

export const News = () => {
  const api = new InexApiService();
  const cosmicService = new CMSService();

  const [birthdayList, setBirthdayList] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [newsList, setNewsList] = React.useState([]);

  const [searchString, setSearchString] = React.useState("");

  const loadNewsList = React.useCallback(async () => {
    try {
      /*const response = await cosmicService.getAllContents(
        "news",
        "slug,title,metadata,thumbnail,created_at",
        "created_at",
        true,
        page * PER_PAGE,
        page - 1
      );
      setNewsList(response);*/
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadNewsFromSeach = React.useCallback(async () => {
    try {
      if (searchString?.length <= 3) return;

      setPage(1);

      /*const response = await cosmicService.getAllContents(
        "news",
        "slug,title,metadata,thumbnail,created_at",
        "created_at",
        false,
        page * PER_PAGE,
        page - 1,
        searchString
      );
      setNewsList(response);*/
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, page]);

  const loadBirthdayList = React.useCallback(async () => {
    try {
      const response = await api.makeHttpRequest({ url: "/user/birthdays" });
      if (response) setBirthdayList(response);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Ocorreu um erro ao carregar os aniversáriantes do dia, tente novamente mais tarde!",
        "error"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadBirthdayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (searchString?.length > 3) {
      loadNewsFromSeach();
    } else {
      loadNewsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return (
    <Container>
      <PageTitle
        text="NOVIDADES"
        search={searchString}
        setSearch={setSearchString}
      />
      {birthdayList?.length > 0 && <Birthdays birthdayList={birthdayList} />}
      {newsList?.objects?.length > 0 ? (
        <>
          <NewsFeed newsList={newsList.objects} page={page} />
          <NewsPagination
            page={page}
            perPage={PER_PAGE}
            pageCount={newsList.totalPages}
            newsCount={newsList.total}
            changePage={setPage}
          />
        </>
      ) : (
        <div className="not-found">
          Nenhuma novidade encontrada.
        </div>
      )}
    </Container>
  );
};
