export default function formatDocument(document, type) {
  if (type === "RG") {
    document = document.replace(/[^0-9x]/gi, "");

    const documentLegth = document?.length;

    if(documentLegth < 8) {
      document = document.replace(/(\d{1,2})(\d{3})(\d{3})$/i, "$1.$2.$3");
    }

    if(documentLegth === 9) {
      document = document.replace(/(\d{1,2})(\d{3})(\d{3})((\d|X){1})$/i, "$1.$2.$3-$4");
    }

    if(documentLegth > 10) {
      document = document.replaceAll(".", "");
    }

    return document;
  }

  if (document.length === 11) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return document.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
}
