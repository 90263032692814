import React, { useState } from 'react';
import { TimerTableCell, TimerTableRow } from "../style";
import { FiDownload } from "react-icons/fi";
import { EditModalFactory } from "../../EditModalFactory";
import CrudActions from "../../../../../../../partials/datatable/CrudActions";
import { downloadFromUrl } from "../../../../../../../utils/DownloadFromUrl";

export function TimerTable({ r, onConfirmDelete, handleEditSubmit, user, clientOptions, professionalsOptions, modalOptions }) {
  const [editOpen, setEditOpen] = useState(null);

  return (
    <>
      <TimerTableRow
        hover
        tabIndex={-1}
        key={'timer_'+r.idTimer}
      >
        <TimerTableCell scope="row" align="center">
          <span className="status-color"/>
          <CrudActions
            actions={["edit-form", "delete"]}
            module="timer"
            handleEdit={setEditOpen}
            onConfirmDelete={onConfirmDelete}
            id={r.idTimer}
            obj={r}
          />
        </TimerTableCell>
        <TimerTableCell scope="row">{r.cycleDateFormatted}</TimerTableCell>
        <TimerTableCell scope="row">{r.activity}</TimerTableCell>
        <TimerTableCell scope="row">{r.professional.name}</TimerTableCell>
        <TimerTableCell scope="row">{r.demand ?? "-"}</TimerTableCell>
        <TimerTableCell scope="row">{r.project?.name ?? "-"}</TimerTableCell>
        <TimerTableCell scope="row">{r.client?.name ?? "-"}</TimerTableCell>
        <TimerTableCell scope="row" align="center">
          {r.appFile && r.appFile.tempUri ? (
            <span
              className="cursor-pointer"
              onClick={() => downloadFromUrl(r.appFile.tempUri, r.appFile.name)}
              title={"Baixar: " + r.appFile.name}
            >
              <FiDownload size={20} color="#4672C5" />
            </span>
          ) : (
            "--"
          )}
        </TimerTableCell>
        <TimerTableCell scope="row" align="center">{r.totalHoursFormatted}</TimerTableCell>
      </TimerTableRow>
      <EditModalFactory
        user={user}
        open={editOpen}
        setOpen={setEditOpen}
        clientOptions={clientOptions}
        handleEditSubmit={handleEditSubmit}
        professionalsOptions={professionalsOptions}
        modalOptions={modalOptions}
      />
    </>
  )
}
