import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: max-content;

  margin-top: 24px;

  cursor: pointer;

  &:first-child,
  &:nth-child(2),
  &:nth-child(3) {
    margin-top: 0;
  }

  .image {
    width: 128px;
    height: 76.8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    overflow: hidden;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    max-width: 248px;

    .category {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #4672C5;
      margin-bottom: 8px;

      text-transform: uppercase;
    }

    .title {
      display: -webkit-box;

      font-family: "Cera Pro Medium", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: #4d4c4d;

      max-height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      margin-bottom: 0;
    }

    .date {
      display: flex;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        stroke: #807e80;
        stroke-width: 1.5px;
        margin-right: 9px;
      }

      span {
        font-family: "Cera Pro Light", sans-serif;
        font-weight: 300;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        color: #807e80;
      }
    }
  }
`;
