import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FiSettings } from "react-icons/fi";

export const AdminTitle = (props) => {
  const useStyles = makeStyles(() => ({
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #4d4d4d"
    },
    header: {
      display: "flex",
      color: "#4d4d4d"
    },
    titleIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 23.5,
      marginRight: 15
    },
    title: {
      fontFamily: "Cera Pro Bold",
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "150%",
      marginBottom: 0
    }
  }));

  const classes = useStyles();
  const Mobile = props.mobile;

  return (
    <header className={classes.headerWrapper}>
      <div className={classes.header}>
        <div className={classes.titleIcon}>
          {props.icon ? props.icon("#4d4d4d") : <FiSettings size="22px" />}
        </div>
        <h1 className={classes.title}>{props.title}</h1>
      </div>
      {props.disableHeader ? null : props.width >= 768 ||
        !props.mobile ? null : (
        <div>
          <Mobile
            actions={props.actions || null}
            width={props.width}
            buttons={props.generateMobileButtons}
            refreshAction={() => props.refreshAction}
          />
        </div>
      )}
    </header>
  );
};
