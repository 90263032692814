import React from "react";
import { Accordion } from "react-bootstrap";
import { HiPlusCircle, HiMinusCircle, HiExclamation, HiClock } from "react-icons/hi";
import { StyledAccordionHeader } from "./styles";
import { Button } from "../../pages/Approvals/components/Button/style";

import {transformMinutesToHours} from "./../../../../utils/CalculateSumTime"

export const CustomAccordionEndPeriodBody = ({
  eventKey,
  title,
  icon,
  noteForBilling,
  totalAllocatedMinutes,
  totalLoadedMinutes,
  totalTime,
  user,
  handleSubmit,
  isAllowed, 
  variant,
  children,
  setDemandModal,
 
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Accordion.Toggle
        onClick={() => setDemandModal(user)}
        eventKey={eventKey}
        as={StyledAccordionHeader}
        variant={variant}
      >
        <div className="accordion-title">
          {icon && <span>{icon()}</span>}

          <div className="accordion-title">
            <span>{title}</span>
            <h3> {noteForBilling ? "(" + noteForBilling + ")": ''}</h3>
          </div>
        
        </div>

        <div className="toggle-icon">

          {totalTime &&
            <span className="total-amount">
              {`H.A.: ${transformMinutesToHours(totalAllocatedMinutes)}`}
            </span>
          }

          {totalTime &&
            <span className="total-amount">
              {`H.C.: ${transformMinutesToHours(totalLoadedMinutes)}`}
            </span>
          }
          {totalTime &&
            <span className="total-amount">
              {`T.H.: ${transformMinutesToHours(totalTime)}`}
            </span>
          }
          {totalLoadedMinutes < totalTime &&
            <HiExclamation /> 
          } 
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <>{children}</>
      </Accordion.Collapse>
    </>
  );
};
