import React, { useState } from "react";
import Loading from "../../home/components/Loading";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import { InexApiService } from "../../../services/InexApiService";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";

import {
  Container,
  StyledInput,
  ButtonForm,
  Button,
  StyledModal
} from "./style";

const ForgotPassword = ({ open, setOpen, bg }) => {
  const [email, setEmail] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = e => {
    const { value } = e.target;
    if (value.length <= 255) setEmail(value);
  };

  const emailValidation = async () => {
    if (email === "") {
      setError("Preencha com seu email!");
      setDisabledButton(false);
      return false;
    }

    if (!IsValidEmail(email)) {
      setError("Este email é invalido!");
      setDisabledButton(false);
      return false;
    }

    setError("");
    return true;
  };

  const sendEmail = async email => {
    setIsLoading(true);
    const api = new InexApiService();
    return await api.makeHttpRequest({
      url: "/user/recover-password",
      method: "POST",
      data: {
        email
      }
    });
  };

  const handleEmailSend = async () => {
    setDisabledButton(true);
    if ((await emailValidation()) === false) return;

    const successOnEmailSend = () => {
      setIsLoading(false);
      setError("");
      setEmail("");
      setOpen(false);
      Swal.fire(
        "Sucesso",
        "Um link para recuperação da sua senha foi enviado ao seu email.",
        "success"
      );
    };

    const errorOnEmailSend = () => {
      setIsLoading(false);
      setError("Este email não está relacionado à nenhuma conta!");
      return false;
    };

    const sender = sendEmail(email);
    sender
      .then(successOnEmailSend)
      .catch(errorOnEmailSend)
      .finally(() => setDisabledButton(false));
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <StyledModal
        show={open}
        onHide={() => {
          setEmail("");
          setOpen(false);
        }}
        size="lg"
        centered
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Modal.Body>
          <Container bg={bg}>
            <span className="forgot-title">Esqueceu sua senha?</span>
            <span className="forgot-subtitle">
              Insira seu email para iniciar o processo de recuperação de senha:
            </span>

            <StyledInput
              placeholder="Insira seu email"
              value={email}
              error={error !== "" ? true : false}
              onChange={handleEmailChange}
              autoFocus={true}
              helperText={error !== "" && error}
            />

            <ButtonForm>
              <Button disabled={disabledButton} onClick={() => {
                setEmail("");
                setOpen(false);
              }}>
                Cancelar
              </Button>
              <Button
                color="primary"
                disabled={disabledButton}
                onClick={handleEmailSend}
              >
                Enviar
              </Button>
            </ButtonForm>
          </Container>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ForgotPassword;
