import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import moment from "moment";
import styled from "styled-components";

const currentDate = moment().hour(23)
const nextYear = currentDate.year();
const referenceDate = moment().hour(5).date(0).month(0).year(nextYear).toDate();

const Button = styled.button`
  position: relative;
  width: 200px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const initialState = {
  numFields: 0,
  isNew: false,
  holidayDates: [],
  holidayDescriptions: [],
  client_id: 0,
  client: null,
  referenceDate: null,
  holidayIds: [],
  holidayYears: [],
  holidays: [],
  clientesOptions: [],
  isLoading: false
};

export default class HolidayForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isEditing: false
    };

    this.api = new InexApiService();

    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.loadHolidays = this.loadHolidays.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    this.handleAddField = this.handleAddField.bind(this);
    this.handleRemoveField = this.handleRemoveField.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      if (this.props.match.params.id) {
        let idYearSplitted = this.props.match.params.id.split('-');
        await this.loadHolidays(idYearSplitted[0], idYearSplitted[1]);
      }
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }
    this.loadClientes();
    this.setState({ isLoading: false });
  }

  loadHolidays = async (id, year) => {
    if (id) {
      const holidayList = await this.api.makeHttpRequest({
        url: `/holiday/by-client/${id}?year=${year}`
      });
  
      this.setState({
        client_id: holidayList.client.idClient,
        client: { label: holidayList.client.name, value: holidayList.client.idClient },
        referenceDate: moment().year(year).toDate(),
        holidayIds: holidayList.holidays.map((holiday) => holiday.idHoliday),
        numFields: holidayList.holidays.length,
        holidayDescriptions: holidayList.holidays.sort((a, b) => a.date - b.date).map((holiday) => holiday.description),
        holidayDates: holidayList.holidays.sort((a, b) => a.date - b.date).map((holiday) => moment(holiday.date).toDate()),
        holidays: holidayList.holidays,
        isLoading: false
      });
    }
  };

  loadClientes = async () => {
    const clientes = await this.api.makeHttpRequest({
      url: "/professional/clients"
    });

    const formattedOptions =
      clientes.length > 0
        ? clientes.map(c => ({ label: c.name, value: c.idClient }))
        : [];

    this.setState({
      clientesOptions: formattedOptions
    });
  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
        const reqBase = id
        ? { method: "PUT", url: "/holiday/update" }
        : { method: "POST", url: "/holiday/save" };
        
        var holidays = this.state.holidayDates.reduce((accumulator, element, index) => {
            if (element !== '') {
                accumulator.push({
                    date: element,
                    description: this.state.holidayDescriptions[index]
                });
            }
            return accumulator;
        }, []);
        holidays = holidays.sort((a, b) => a.date - b.date)
        const data = {
            client: {idClient: this.state.client_id},
            referenceDate: this.state.referenceDate,
            holidays: holidays
        }

        await this.api.makeHttpRequest({
            ...reqBase,
            data
        });

        this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar feriados, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/feriados");
    }
  };

  handleChangeClient = async (client, event) => {
    this.setState({ client_id: client.value }); 
    this.setState({ client: client });
    const value = client.value;
    if (value) {
      try {
        const response = await this.api.makeHttpRequest({
            url: `/holiday/by-client/${value}`
        });
        const years = [...new Set(response.holidays.map(holiday => new Date(holiday.date).getFullYear()))].sort((a, b) => a - b);
        this.setState({holidayYears: years})
      } catch { }
    }
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadClosingDate(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  handleAddField = () => {
    this.setState((prevState) => ({
      numFields: prevState.numFields + 1,
      holidays: [...prevState.holidays, ''],
      holidayDates: [...prevState.holidayDates, ''],
      holidayDescriptions: [...prevState.holidayDescriptions, '']
    }));
  };

  handleRemoveField = (index) => {
    this.setState((prevState) => ({
      numFields: prevState.numFields - 1,
      holidays: prevState.holidays.filter((_, i) => i !== index),
      holidayDates: prevState.holidayDates.filter((_, i) => i !== index),
      holidayDescriptions: prevState.holidayDescriptions.filter((_, i) => i !== index)
    }));
  };

  handleChangeHoliday = (index, e) => {
    const { value } = e.target;
    if (e.target.type === "text") {
        this.setState((prevState) => {
            const newHolidayDescriptions = prevState.holidayDescriptions.map((description, i) => {
                if (i === index) {
                    return value;
                } else {
                    return description;
                }
            });
            return { holidayDescriptions: newHolidayDescriptions };
        });
    } else {
        this.setState((prevState) => {
            const newHolidayDates = prevState.holidayDates.map((date, i) => {
                if (i === index) {
                    return value;
                } else {
                    return date;
                }
            });
            return { holidayDates: newHolidayDates };
        });
    }
  };

  renderDateFields = () => {
    const { numFields, holidays } = this.state;
    const fields = [];

    for (let i = 0; i < numFields; i++) {
      fields.push(
        <Form.Row key={i} style={{ marginBottom: '20px' }}>
          <Form.Group as={Col} lg="4" xs="12">
            <InputField
              type="date"
              name={`holidays${i}date`}
              label="Data"
              value={this.state.holidayDates[i]}
              placeholder="Selecione a data do feriado"
              minDate={new Date(`${this.state.referenceDate?.getFullYear()}-01-01 00:00:00`)}
              maxDate={new Date(`${this.state.referenceDate?.getFullYear()}-12-31 23:59:59`)}
              onChange={(e) => this.handleChangeHoliday(i, e)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} lg="4" xs="12">
            <InputField
              type="text"
              name={`holidays${i}description`}
              label="Descrição"
              value={this.state.holidayDescriptions[i]}
              placeholder="Digite a descrição do feriado"
              onChange={(e) => this.handleChangeHoliday(i, e)}
              required
            />
          </Form.Group>
          {i >= 0 && (
            <Button type="button" variant="danger" onClick={() => this.handleRemoveField(i)}>
              Remover
            </Button>
          )}
        </Form.Row>
      );
    }

    return fields;
  };

  handleChangeYear = async (event) => {
    const { value } = event.target;

    if (this.state.holidayYears.includes(value.getFullYear())) {
        Swal.fire(
            "Erro!",
            "O ano selecionado já possui feriados cadastrados.",
            "error"
        );
        return;
    } else {
        var holidayDates = []
        var holidayDescriptions = []
        if (this.state.holidayDates.length > 0) {
            holidayDates = this.state.holidayDates.map((date) => {
                const newDate = new Date(date);
                newDate.setFullYear(value.getFullYear());
                return newDate;
            });
            holidayDescriptions = this.state.holidayDescriptions.map((description) => description);
        }
        if (this.state.holidayYears.includes(value.getFullYear() - 1)) {
            await Swal.fire({
                title: "Informativo!",
                text: `Deseja importar os feriados do ano (${value.getFullYear() - 1})`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Importar",
                cancelButtonText: "Não importar"
            }).then(async (result) => {
                if (result.value) {
                    const holidayList = await this.api.makeHttpRequest({
                        url: `/holiday/by-client/${this.state.client_id}?year=${value.getFullYear() - 1}`
                    });
                    holidayDates = holidayList.holidays.map((holiday) => {
                        const newDate = new Date(holiday.date);
                        newDate.setFullYear(value.getFullYear());
                        return newDate;
                    });
                    holidayDescriptions = holidayList.holidays.map((holiday) => holiday.description);
                }
            });
        }
        this.setState({
            referenceDate: value,
            holidayDates: holidayDates,
            holidayDescriptions: holidayDescriptions,
            numFields: holidayDates.length,
            isNew: true
        });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Feriados");
    } else {
      window.setPageTitle("Cadastro de Feriados");
    }

    const {
      isLoading,
      isEditing,
      client,
      referenceDate,
      clientesOptions
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados dos feriados" disableHeader />
        <Card className="mt-4">
            <StyledCardBody>
                <Form style={{ flex: "1" }}>
                    {/* row 1 */}
                    <Form.Row key={'client'} style={{marginBottom: '20px'}}>
                        {/* Cliente */}
                        <Form.Group as={Col} lg="4" xs="12">
                        <InputField
                            as="select2"
                            name="client"
                            label="Cliente"
                            value={client}
                            onChange={this.handleChangeClient}
                            placeholder="Selecione a empresa"
                            options={clientesOptions}
                            closeMenuOnSelect
                            disabled={isEditing}
                            required
                        />
                        </Form.Group>

                        {/* Ano */}
                        <Form.Group as={Col} lg="4" xs="12">
                        <InputField
                            type="date"
                            name="referenceDate"
                            dateFormat="yyyy"
                            label="Ano"
                            showYearPicker
                            value={this.state.referenceDate}
                            placeholder="Selecione a ano"
                            onChange={this.handleChangeYear}
                            disabled={this.state.client===null || (this.state.referenceDate!==null && !this.state.isNew)}
                            required
                        />
                        </Form.Group>
                    </Form.Row>
                    {this.renderDateFields()}
                    <Button type="button" variant="primary" color="#4672C5" onClick={this.handleAddField}>
                        Adicionar feriado
                    </Button>
                </Form>
            </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          formIsValid={this.state.client_id !== 0}
          hideReload
        />
      </>
    );
  }
}
