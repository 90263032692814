import moment from "moment";
import React from "react";
import { ClockIcon } from "../../../../../../components/IconsSVG";
import { Container } from "./styles";

export const NewestCard = ({ news }) => {
  return (
    <Container className="newest">
      <div className="image">
        <img src={news.thumbnail} alt={news.title} />
      </div>
      <div className="content">
        <h3 className="category">{news.metadata.category}</h3>
        <p className="title">{news.title}</p>
        <div className="date">
          <ClockIcon />
          <span>{moment(news.created_at).format("DD/MM/YYYY")}</span>
        </div>
      </div>
    </Container>
  );
};
