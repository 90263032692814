import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { HiPlusSm } from "react-icons/hi";
import { Card, Tab, Tabs } from "react-bootstrap";
import Loading from "../../../home/components/Loading";
import { RitualIcon } from "../../../../components/IconsSVG";
import { AdminTitle } from "../../components/AdminTitle";
import Filters from "./components/Filters";
import { RitualDatatable } from "./components/RitualDatatable";
import { SearchBar, TabsContainer } from "./styles";
import { CreateRitualModal } from "./components/CreateRitualModal";
import { Button } from "./components/Button";
import { ERoles } from "../../../../enums/Roles";
import store from "../../../../store/store";

const RitualListing = () => {
  const { auth } = store.getState();

  // Tabs state
  const [key, setKey] = React.useState("1:1");
  const [search, setSearch] = React.useState("");
  const [addModal, setAddModal] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  const [isAllowed, setIsAllowed] = React.useState(false);

  const [filters, setFilters] = React.useState({
    startPeriod: null,
    hasPeriod: false,
    idCoordination: "",
    idCoordinator: ""
  });

  React.useEffect(() => {
    window.setPageTitle("Listagem de Rituais - Admin");
  }, []);

  React.useEffect(() => {
    setIsAllowed(
      (auth?.user?.role?.type === ERoles.ADMIN ||
        auth?.user?.role?.type === ERoles.OPERATION) ??
        false
    );
  }, [auth]);

  const handleFilterChange = e => {
    setLoading(true);
    if (e.target.name === "startPeriod") {
      if (e.target.value) {
        setFilters({
          ...filters,
          [e.target.name]: e.target.value,
          hasPeriod: true
        });
      } else {
        setFilters({
          ...filters,
          [e.target.name]: e.target.value,
          hasPeriod: false
        });
      }
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }

    setLoading(false);
  };

  const handleFilterString = e =>{
    //Changed with value, send value to the filter string.
    //Changed but is empty, we reset filter. 
    if(e.target.value.length > 0){
      setSearch(e.target.value);
    }else{
      setSearch("");
      setFilters({
        ...filters,
        hasPeriod: false
      });
    }
  }

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={RitualIcon} title="Rituais" />
        <SearchBar>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Busque por coordenação ou coordenador"
              onChange={handleFilterString}
              value={search}
            />
            <span className="icon-search">
              <RiSearchLine size="24px" color="#4D4C4D" />
            </span>
          </div>
        </SearchBar>
        <TabsContainer>
          <Tabs activeKey={key} transition={false} onSelect={k => setKey(k)}>
            <Tab eventKey="1:1" title="1:1">
              <Card>
                <Card.Body>
                  <Filters
                    reload={reload}
                    filters={filters}
                    handleChange={handleFilterChange}
                  />
                  <div className="user-list">
                    <RitualDatatable
                      reload={reload}
                      ritualType="0"
                      filters={filters}
                      filterString={search}
                      setReload={setReload}
                    />
                    {isAllowed && (
                      <Button type="submit" onClick={() => setAddModal(true)}>
                        <HiPlusSm size={24} />
                        adicionar
                      </Button>
                    )}
                    <CreateRitualModal
                      open={addModal}
                      setOpen={setAddModal}
                      reload={() => setReload(true)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="mensal" title="Mensal">
              <Card>
                <Card.Body></Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="retro" title="Retrospectiva">
              <Card>
                <Card.Body></Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </TabsContainer>
      </div>
    </>
  );
};

export default RitualListing;
