import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";

export const TimerTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    max-height: 40px !important;

    cursor: ${({ pointer }) => pointer ? 'pointer' : ''}
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const TimerTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #807e80;
    line-height: 150%;
    height: 50px;
    padding: 0 16px;

    white-space: nowrap;
  }

  &.MuiTableCell-head:first-child {
    border-top-left-radius: 4px;
  }

  &.MuiTableCell-head:last-child {
    border-top-right-radius: 4px;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Light", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #4d4c4d;
    height: 40px;
    padding: 0 16px 0px 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 250px;

    .status-color {
      position: absolute;
      display: flex;
      height: 48px;
      width: 6px;

      top: 0;
      left: 0;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hyphen {
      display: flex;
      width: 10px;
      height: 1.5px;
      background: #4672C5;
      border-radius: 1px;

      margin-left: 15px;
      margin-right: 15px;
    }

    .time-total {
      font-family: "Cera Pro Medium", sans-serif;
      font-weight: 600;
      color: #807e80;
      text-transform: capitalize;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;
