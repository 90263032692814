import React from "react";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
// import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
// import { HeaderTimerStopper } from "../../../app/pages/admin/pages/Timer/components/HeaderTimerStopper";
import UserProfile from "../../../app/partials/layout/UserProfile";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
        {/* <UserNotifications
          skin="light"
          iconType=""
          icon="flaticon2-bell-alarm-symbol"
          type="primary"
          dot="false"
        /> */}

        {/* <LanguageSelector iconType="" /> */}

        {/* <HeaderTimerStopper /> */}
        
        <UserProfile showBadge={true} />
      </div>
    );
  }
}
