import React from "react";
import { Card } from "react-bootstrap";
import Loading from "../../../home/components/Loading";
import { ProposalIcon, ProjectIcon, UserIcon } from "../../../../components/IconsSVG";
import { AdminTitle } from "../../components/AdminTitle";
import Filters from "./components/Filters";
import { CheckDatatable } from "./components/CheckDatatable";
import { StyledCardBody } from "../../components/AdminCard/style";
import { StyledAccordion } from "../../components/Accordion/styles";
import { CustomAccordionBody } from "../../components/Accordion";
import { InexApiService } from "../../../../services/InexApiService";
import styled from "styled-components";
import Swal from "sweetalert2";

const ContainerTotalAmount = styled.div`
  display: flex;
  justify-content: right;
  padding: 8px 0;

  span {
    font-family: "Cera Pro Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
  }
`;

const CheckListing = () => {
  const api = new InexApiService();

  const [loading, setLoading] = React.useState(false);
  const [checks, setChecks] = React.useState([]);
  const [totalTableValue, setTotalTableValue] = React.useState(0);

  const [filters, setFilters] = React.useState({
    startPeriod: null,
    endPeriod: null,
    hasPeriod: true,
    idClient: "",
    idCoordination: ""
  });

  React.useEffect(() => {
    window.setPageTitle("Listagem de Check - Admin");
  }, []);

  const loadCheck = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        url: "/check/all",
        params: filters
      });

      if (response?.checks?.length <= 0) {
        Swal.fire("Info!", "Nenhum registro encontrado.", "info");
      }
      setTotalTableValue(response?.totalValue ?? 0)
      setChecks(response?.checks ?? []);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao filtrar check, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }, [api, filters]);

  const handleFilterChange = (e, period = null) => {
    if (period) {
      setFilters({ ...filters, ...period });
      return;
    }

    setFilters({ ...filters, [e.target.name]: e.target.value });
    setLoading(false);
  };

  return (
    <>
      <Loading isLoading={loading} />
      <AdminTitle icon={ProposalIcon} title="Check" />
      <Card className="mt-4">
        <StyledCardBody>
          <div className="d-flex flex-column w-100">
            <Filters
              filters={filters}
              handleChange={handleFilterChange}
              loadCheck={loadCheck}
            />
            {checks?.length > 0 &&
              checks.map(c => (
                <StyledAccordion shadow="true" key={c.idCoordination} style={{ marginBottom: '8px' }}>
                  <CustomAccordionBody
                    eventKey="0"
                    title={`${c.coordination}`}
                    totalAmount={c.totalValue}
                    icon={ProjectIcon}
                  >
                    {c?.payingAreas?.length > 0 &&
                      c.payingAreas.map((area, index) => (
                        <StyledAccordion key={index} style={{ padding: '10px' }}>
                          <CustomAccordionBody
                            eventKey="01"
                            title={area.payingArea}
                            variant="secondary"
                            totalAmount={area.totalValue}
                            icon={UserIcon}
                          >
                            <CheckDatatable infoList={area.users} filters={filters} />
                          </CustomAccordionBody>
                        </StyledAccordion>
                      )
                    )}
                  </CustomAccordionBody>
                </StyledAccordion>
              )
            )}

            {totalTableValue ? (
              <ContainerTotalAmount>
                <span>{
                  `Total:
                    ${new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(totalTableValue)}`
                  }
                </span>
              </ContainerTotalAmount>
            ) : null }
          </div>
        </StyledCardBody>
      </Card>
    </>
  );
};

export default CheckListing;
