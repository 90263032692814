import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserIcon } from "../../../../../../components/IconsSVG";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import store from "../../../../../../store/store";
import { ApprovalDatatable } from "../ApprovalDatatable";
import { StyledAccordion } from "../../../../components/Accordion/styles";
import { CustomAccordionEndPeriodBody } from "../../../../components/AccordionEndPeriod";
import { DemandModal } from "../DemandModal";

export const ApprovalCard = ({
  key,
  user,
  title,
  projectList,
  noteForBilling,
  totalAllocatedMinutes,
  totalLoadedMinutes,
  totalTime,
  setLoading,
  setReload,
  period
}) => {

  const api = new InexApiService();
  const { auth } = store.getState();

  const [changedProjectList, setChangedProjectList] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);

  const [demandModal, setDemandModal] = useState(false);

  useEffect(() => {
    
    setIsAllowed(
      (auth?.user?.role?.type === ERoles.ADMIN ||
        auth?.user?.role?.type === ERoles.OPERATION) ??
        false
    );
    
  }, [auth]);


  return projectList?.length <= 0 ? null : (
    <>
      <StyledAccordion shadow="true">
        <CustomAccordionEndPeriodBody
          eventKey="0"
          title={title}
          icon={UserIcon}
          user={user}
          noteForBilling={noteForBilling}
          totalAllocatedMinutes={totalAllocatedMinutes}
          totalLoadedMinutes={totalLoadedMinutes}
          totalTime={totalTime}
          setDemandModal={setDemandModal}
          isAllowed={isAllowed}
        >
          {/*
          <ApprovalDatatable
            projectList={projectList}
            changedProjectList={changedProjectList}
            setChangedProjectList={handleChangedProjectList}
          />
          */}
        </CustomAccordionEndPeriodBody>
      </StyledAccordion>

      <DemandModal
        user={demandModal}
        title={title}
        close={() => setDemandModal(false)}
        noteForBilling={noteForBilling}
        projectList={projectList}
        period={period}
        setReload={setReload}
      />

    </>
  );

};
