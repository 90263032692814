import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  & > div:first-child {
    width: 100%;
  }
`;