import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import IsValidName from "../../../../utils/validators/IsValidName";

const initialState = {
  id: 0,
  nome: "",
  descricao: "",
  isLoading: false,
  persistedModel: ""
};

export default class TrainingGroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      if (this.props.match.params.id) {
        await this.loadTrainingGroup(this.props.match.params.id);
      }
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadTrainingGroup = async id => {
    const trainingGroup = await this.api.makeHttpRequest({
      url: `/traininggroup/${id}`
    });

    const persistedModel = {
      id: id,
      nome: trainingGroup.name,
      descricao: trainingGroup.description
    };

    const persistedModelJson = JSON.stringify(persistedModel);
    this.setState({
      ...persistedModel,
      isLoading: false,
      persistedModel: persistedModelJson
    });
  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/traininggroup/update/${id}` }
        : { method: "POST", url: "/traininggroup/save" };

      await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          name: this.state.nome,
          description: this.state.descricao
        }
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar grupo de treinamento, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/treinamento-grupos");
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      nome: this.state.nome,
      descricao: this.state.descricao
    };

    const currentModelJson = JSON.stringify(currentModel);

    return (
      this.state.nome &&
      IsValidName(this.state.nome)
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadTrainingGroup(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Grupos de Treinamento - Admin");
    } else {
      window.setPageTitle("Cadastro de Grupos de Treinamento - Admin");
    }

    const {
      nome,
      descricao,
      isLoading,
      isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Grupo de Treinamento" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Nome */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="nome"
                    label="Nome"
                    placeholder="Digite o nome"
                    value={nome}
                    onChange={this.handleChange}
                    isInvalid={nome && !IsValidName(nome)}
                    required
                  />
                </Form.Group>
                {/* Descrição */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="descricao"
                    label="Descrição"
                    placeholder="Digite a descrição"
                    value={descricao}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}
