import React, {useEffect, useState} from "react";
import { Form, Modal } from "react-bootstrap";
import { InexApiService } from "../../../../../../services/InexApiService";
import { Button, StyledModal, Container } from "./styles";
import { InputField } from "../../../../components/InputField";
import Loading from "../../../../../home/components/Loading";
import { useDropzone } from 'react-dropzone';
import Swal from "sweetalert2";
import moment from "moment";

export const UploadConciliationModal = ({ title, show, close, setReload }) => {
    const api = new InexApiService();
    
    const [loading, setLoading] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [fileInex, setFileInex] = useState(null);
    const [fileClient, setFileClient] = useState(null);
    const [referenceDate, setReferenceDate] = useState(new Date());
    const [clientSelected, setClientSelected] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);

    const { getRootProps: getRootPropsInex, getInputProps: getInputPropsInex } = useDropzone({
        onDrop: acceptedFiles => {
            const file = acceptedFiles[0];
            if (file && (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                setFileInex(file);
            } else {
                Swal.fire(
                    "Erro!",
                    "Por favor, selecione um arquivo do Excel (.xls ou .xlsx).",
                    "error"
                );
            }
        }
    });

    const { getRootProps: getRootPropsClient, getInputProps: getInputPropsClient } = useDropzone({
        onDrop: acceptedFiles => {
            const file = acceptedFiles[0];
            if (file && (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                setFileClient(file);
            } else {
                Swal.fire(
                    "Erro!",
                    "Por favor, selecione um arquivo do Excel (.xls ou .xlsx).",
                    "error"
                );
            }
        }
    });

    const handleUpload = async () => {
        setLoading(true);
        setSubmitting(true);    
        try {
          const data = new FormData();
          data.append('clientId', clientSelected.value);
          data.append('referenceDate', moment(referenceDate).format('DD/MM/YYYY'));
          data.append('fileInex', fileInex);
          data.append('fileClient', fileClient);
    
          await api.makeHttpRequest({
            method: 'post',
            url: '/conciliation/save',
            data
          }).then((res) => {
            setSubmitting(false);  
            Swal.fire(
                "Sucesso!",
                "Upload realizado com sucesso.",
                "success"
            );
          }).finally(() => {
            setLoading(false);
            setReload(true);
            close();
          })
        } catch (e) {
            const err = e.response
                ? e.response.data.split(/\n/)
                : 'Erro ao fazer upload das planilhas, tente novamente mais tarde';
            setLoading(false);
            setSubmitting(false);
        }
    };

    const handleClose = () => {
        setFileInex(null);
        setFileClient(null);
        setReferenceDate(new Date());
        setClientSelected(null);
        close();
    }

    const loadClients = async () => {
        const clients = await api.makeHttpRequest({
            url: "/check/clients"
        });
        const formattedOptions = clients.length > 0
          ? clients.map(c => ({ label: c.name, value: c.idClient, initialCycleDay: c.initialCycleDay }))
          : [];
        setClientOptions(formattedOptions ?? []);
        setClientSelected(formattedOptions && formattedOptions.length ? formattedOptions[formattedOptions.length - 1] : null);
    }

    useEffect(() => {
        loadClients();
    }, []);

    return (
        <>
        <Loading isLoading={loading} />
            <StyledModal 
                show={show && !loading} onHide={() => handleClose()} 
                size="lg" 
                centered
            >
                <Modal.Body>
                    <h1 className="modal-title">
                        <b>{title}</b>
                    </h1> 

                    <Form>
                        <Container>
                            <div style={{display: 'flex', justifyContent: 'space-between', width: '600px', paddingTop: '10px', paddingBottom: '40px', gap: '20px'}}>
                                <InputField
                                    as="select2"
                                    name="referenceDate"
                                    label="Cliente"
                                    value={clientSelected}
                                    placeholder="Selecione o cliente"
                                    options={clientOptions}
                                    onChange={(client) => setClientSelected(client)}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    disabled={submitting}
                                />
                                <InputField
                                    type="date"
                                    name="referenceDate"
                                    dateFormat="MMM/yyyy"
                                    label="Ano"
                                    showMonthYearPicker
                                    value={referenceDate}
                                    placeholder="Selecione a data de referência"
                                    onChange={(e) => setReferenceDate(e.target.value)}
                                    disabled={submitting}
                                />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', width: '600px', paddingTop: '10px', paddingBottom: '40px'}}>
                                {
                                    fileInex === null && 
                                    <div
                                        style={{
                                            width: 'calc(50% - 10px)',
                                            height: 200,
                                            border: '2px dashed #ccc',
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            flexWrap: 'wrap',
                                            cursor: 'pointer'
                                        }}
                                        {...getRootPropsInex({ className: 'dropzone' })}>
                                        <input {...getInputPropsInex()}/>
                                        <div>
                                            <h3><b>INEX</b></h3>
                                            <p style={{marginBottom: 0}}>Arraste e solte o arquivo aqui, ou clique para selecionar o arquivo</p>
                                        </div>
                                    </div>
                                }
                                {
                                    fileInex !== null &&
                                    <div
                                        style={{
                                            width: 'calc(50% - 10px)',
                                            height: 200,
                                            border: '2px dashed #ccc',
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            flexWrap: 'wrap'
                                        }}>
                                        <div>
                                            <h3><b>INEX</b></h3>
                                            {fileInex &&
                                                <>
                                                    <span key={fileInex.name}>
                                                        {fileInex.name} - {fileInex.size} bytes
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <Button type='button' onClick={() => setFileInex(null)}>
                                            Limpar
                                        </Button>
                                    </div>
                                }
                                {
                                    fileClient === null && 
                                    <div
                                        style={{
                                            width: 'calc(50% - 10px)',
                                            height: 200,
                                            border: '2px dashed #ccc',
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            flexWrap: 'wrap',
                                            cursor: 'pointer'
                                        }}
                                        {...getRootPropsClient({ className: 'dropzone' })}>
                                        <input {...getInputPropsClient()}/>
                                        <div>
                                            <h3><b>CLIENTE</b></h3>
                                            <p style={{marginBottom: 0}}>Arraste e solte o arquivo aqui, ou clique para selecionar o arquivo</p>
                                        </div>
                                    </div>
                                }
                                {
                                    fileClient !== null &&
                                    <div
                                        style={{
                                            width: 'calc(50% - 10px)',
                                            height: 200,
                                            border: '2px dashed #ccc',
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            flexWrap: 'wrap'
                                        }}>
                                        <div>
                                            <h3><b>CLIENTE</b></h3>
                                            {fileClient !== null && 
                                                <>
                                                    <span key={fileClient.name}>
                                                        {fileClient.name} - {fileClient.size} bytes
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <Button type='button' onClick={() => setFileClient(null)}>
                                            Limpar
                                        </Button>
                                    </div>
                                }
                            </div>                         
                            <div className="btn-container">
                                {!submitting &&<Button 
                                    type="button" 
                                    onClick={handleUpload} 
                                    disabled={clientSelected === null || referenceDate === null || fileInex === null || fileClient === null}
                                    variant="primary"
                                >
                                    SALVAR
                                </Button>}
                                <Button type="button" variant="secondary" onClick={handleClose}>
                                    CANCELAR
                                </Button>
                            </div>
                        </Container>
                    </Form>
                </Modal.Body>
            </StyledModal>
        </>
    );
};
