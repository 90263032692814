import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: 662px;
    margin: auto;
  }

  .modal-body {
    padding: 37px 24px 24px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }

    .form-row:nth-child(4) {
      margin: 0 0 24px;

      .time-input > div {
        gap: 8px;
      }
    }

    textarea {
      line-height: 150%;
    }

    .last-row {
      display: flex;
      justify-content: space-between;

      .time-input {
        flex: 1;

        @media screen and (min-width: 669px) {
          display: flex;
          margin-right: auto;
        }
      }

      .save-btn {
        min-width: 48%;
      }
    }
  }
`;
