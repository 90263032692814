import React, { useState, useEffect } from "react";
import { Popover } from "@material-ui/core";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import useGetWindowWidth from "../../../../utils/useGetWindowWidth";
import moment from "moment";
import { ptBR } from "date-fns/locale";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from "date-fns";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { LensTwoTone } from "@material-ui/icons";

export const DateFilter = ({ component, reload, setFilter, initialValues, initialCycle = 22, newValues }) => {
  const width = useGetWindowWidth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [dateFilter, setDateFilter] = useState([
    {
      ...initialValues,
      color: "#4672C5",
      key: "selection"
    }
  ]);

  const changeDateFilter = (item) => {
    const selection = item.selection;
    setDateFilter([selection])
    setFilter({
      startPeriod: selection.startDate,
      endPeriod: selection.endDate
    })
  }

  useEffect(() => {
    changePeriod();
  }, [initialCycle, newValues]);

  const changePeriod = () => {
    setDateFilter([{
      ...newValues,
      color: "#4672C5",
      key: "selection"
    }])
  }

  const getStartCycle = (cycle) => {
    const today = moment();
    const dateCycleStart = moment().date(initialCycle).hour(0).minute(0).second(0);
    dateCycleStart.isAfter(today) && dateCycleStart.subtract(1, 'month');
    cycle === "last" && dateCycleStart.subtract(1, 'month')
    return dateCycleStart.toDate()
  };
  
  const getEndCycle = (ciclo) => {
    const dateCycleStart = getStartCycle(ciclo);
    const endOfCycle = moment(dateCycleStart).add(1, 'month').subtract(1, 'day').hour(23).minute(59).second(59).toDate();
    return endOfCycle;
  };
  
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastCycle: getStartCycle("last"),
    endOfLastCycle: getEndCycle("last"),
    startOfCurrentCycle: getStartCycle("current"),
    endOfCurrentCycle: getEndCycle("current"),
  };
  
  const filterRange = createStaticRanges([
    /* {
      label: "Hoje",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Ontem",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    }, */
  
    {
      label: "Esta semana",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek
      })
    },
    {
      label: "Semana passada",
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek
      })
    },
    {
      label: "Este Ciclo",
      range: () => ({
        startDate: defineds.startOfCurrentCycle,
        endDate: defineds.endOfCurrentCycle
      })
    },
    {
      label: "Ciclo passado",
      range: () => ({
        startDate: defineds.startOfLastCycle,
        endDate: defineds.endOfLastCycle
      })
    }
  ]);
  
  const filterInputRanges = [
    {
      label: "dias até hoje",
      range(value) {
        return {
          startDate: addDays(
            defineds.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1
          ),
          endDate: defineds.endOfToday
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
        if (!range.startDate) return "∞";
        return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
      }
    },
    {
      label: "dias a partir de hoje",
      range(value) {
        const today = new Date();
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1) - 1)
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.startDate, defineds.startOfToday)) return "-";
        if (!range.endDate) return "∞";
        return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
      }
    }
  ];  

  return (
    <>
      <div onClick={handleClick}>{component()}</div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        style={{
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
        }}
      >
        <DateRangePicker
          onChange={changeDateFilter}
          moveRangeOnFirstSelection={false}
          preventSnapRefocus={true}
          showSelectionPreview={true}
          months={width < 1023 ? 1 : 2}
          scroll={{ enabled: width < 1023 ? true : false }}
          ranges={dateFilter}
          direction={width < 1023 ? "vertical" : "horizontal"}
          calendarFocus="backwards"
          locale={ptBR}
          color="#4672C5"
          staticRanges={filterRange}
          inputRanges={filterInputRanges}
          showDateDisplay={false}
        />
      </Popover>
    </>
  );
};