import styled from 'styled-components';
import windowSize from '../../constants/WindowSize';

export const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  background: ${({theme}) => theme.colors.background.primary};
  padding: 0 ${({width}) => width >  windowSize.SM ? '10%' : '16px'};
  padding-bottom: 28px;

  > h1 {
    font-size: ${({width}) => width > windowSize.SM ? '32px' : '24px'};
    font-weight: 600;
    margin: 24px 0;
    font-family: "Biennale Regular";
    color: ${({theme}) => theme.colors.text};
  }

  > p {
    font-size: ${({width}) => width > windowSize.SM ? '18px' : '14px'};
    margin: 8px 0 40px;
    color: ${({theme}) => theme.title === 'dark' ? '#A191FF'
        : theme.colors.text};
    }

     strong {
      color: ${({theme}) => theme.title === 'dark' ? '#A191FF'
        : theme.colors.text};
    }
`;
