import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService";
import IsValidDescricao from "../../../../../../utils/validators/IsValidDescricao";
import IsValidName from "../../../../../../utils/validators/IsValidName";
import IsValidTotalHours from "../../../../../../utils/validators/IsValidTotalHours";
import { InputField } from "../../../../components/InputField";
import { FiPaperclip, FiX } from "react-icons/fi";
import { TimerAttachment, WarningMsg } from "../../styles";
import { Button } from "../Button";
import FormatJiraHour from '../../../../../../utils/FormatJiraHour';
import { MonthList } from '../../../../../../utils/CycleList';
import { validateFileType } from "../../../../../../utils/validators/ValidateFileType";
import { HandleGetDateSelect } from '../TimerFactory/utils/HandleGetDateSelect';
import Loading from "../../../../../home/components/Loading";
import { StyledModal } from "./style";

const initialValues = {
  idTimer: 0,
  cliente: "",
  projeto: "",
  atividade: "",
  solicitante: "",
  demanda: "",
  timerDoc: null,
  totalHours: "",
  idProfessional: 0,
  billingMonth: "",
  idRFQ: "",
};

const ACCEPTED_FORMATS =
  "image/jpg," +
  "image/jpeg," +
  "image/png," +
  "application/msword," +
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
  "application/pdf";

export const EditModalFactory = ({
  user,
  open,
  setOpen,
  clientOptions,
  handleEditSubmit,
  modalOptions
}) => {
  const api = new InexApiService();
  const fileInput = useRef(null);
  const timerDoc = useRef(null);
  const year = new Date().getFullYear();

  const [allocatedToProj, setAllocatedToProj] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [newTimerInfo, setNewTimerInfo] = useState(initialValues);
  const [professionalOptions, setProfessionalOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialCycleDay, setInitialCycleDay] = useState(0);

  const handleSetInitialCycleDay = clientOptions.length > 0 &&
    newTimerInfo.cliente > 0 &&
    clientOptions.find(x => x.idClient === Number(newTimerInfo.cliente));

  useEffect(() => {
    if(newTimerInfo.cliente) {
      setInitialCycleDay(handleSetInitialCycleDay.initialCycleDay);
    }
  }, [newTimerInfo.cliente]);

  useEffect(() => {
    setNewTimerInfo(initialValues);

    if (open) {
      setNewTimerInfo({
        idTimer: open.idTimer,
        cliente: open.client.idClient,
        projeto: open.project.idProject,
        atividade: open.activity,
        solicitante: open.clientDemandRequester,
        demanda: open.demand,
        timerDoc: open.appFile,
        totalHours: open.totalHours,
        idProfessional: open.idProfessional,
        idRFQ: open.idRFQ,
        billingMonth: open.billingMonth
      });
    }
  }, [open]);

  const loadProfessionals = async (idClient) => {
    setLoading(true);

    try {
      const req = await api.makeHttpRequest({
        url: `/timer/user/client/${idClient}`
      });

      if (req.professionals.length > 0) {
        setProfessionalOptions(req.professionals);
      } else {
        setProfessionalOptions([]);
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao carregar a lista de profissionais, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const projects = await api.makeHttpRequest({
          url: `/timer/project/client/${newTimerInfo.cliente}`
        });

        setProjectOptions(projects ?? []);
      } catch (e) {
        Swal.fire(
          "Erro!",
          "Erro ao carregar lista de projetos para modal de edição.",
          "error"
        );
      }
    };

    if (newTimerInfo.cliente) {
      loadProjects();
      loadProfessionals(newTimerInfo.cliente);
    } else {
      newTimerInfo.project = "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTimerInfo.cliente]);

  useEffect(() => {
    let inProject = true;

    if (newTimerInfo.projeto) {
      const index = user.projects.findIndex(
        x => x.idProject === Number(newTimerInfo.projeto)
      );

      inProject = index !== -1;
    }

    setAllocatedToProj(inProject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTimerInfo.projeto]);

  const handleTimerDoc = () => {
    if (fileInput.current && fileInput.current.files[0]) {
      const allowedExtentions = ["jpg", "jpeg", "png", "doc", "docx", "pdf"];

      const isValid = validateFileType(
        fileInput.current.value,
        allowedExtentions
      );

      if (!isValid) {
        Swal.fire({
          icon: "error",
          title: "Tipo de arquivo inválido",
          text: "Apenas arquivos " + allowedExtentions.join(", ").toUpperCase(),
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });

        return;
      }

      const file = new FileReader();
      file.readAsDataURL(fileInput.current.files[0]);
      timerDoc.current.innerText = fileInput.current.files[0].name;
      setNewTimerInfo({
        ...newTimerInfo,
        timerDoc: fileInput.current.files[0]
      });
    }
  };

  const handleRemoveAnexo = async () => {
    const isForRemove = await Swal.fire({
      showCancelButton: true,
      icon: "warning",
      title: "Remover anexo",
      text: "Você tem certeza que quer remover seu anexo?",
      confirmButtonColor: "#4672C5",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });

    if (isForRemove.dismiss) {
      return;
    }

    if (isForRemove.value) {
      timerDoc.current.src = "";
      fileInput.current.value = "";
      setNewTimerInfo({ ...newTimerInfo, timerDoc: null });
    }
  };

  const formIsValid = () => {
    return (
      !!newTimerInfo.cliente &&
      !!newTimerInfo.projeto &&
      !!newTimerInfo.atividade &&
      !!IsValidDescricao(newTimerInfo.atividade) &&
      !!newTimerInfo.solicitante &&
      !!IsValidName(newTimerInfo.solicitante) &&
      !!newTimerInfo.totalHours &&
      !!IsValidTotalHours(newTimerInfo.totalHours) &&
      !!newTimerInfo.idProfessional &&
      !!newTimerInfo.billingMonth
    );
  };

  const handleChange = e => {
    setNewTimerInfo({ ...newTimerInfo, [e.target.name]: e.target.name !== 'totalHours' ? e.target.value : FormatJiraHour(e.target.value) });
  };

  return (
    <>
      <Loading isLoading={loading} />
      <StyledModal
        show={open !== null}
        onHide={() => setOpen(null)}
        size="lg"
        centered
        scrollable
      >
        <Modal.Body>
          <Form>
            {/* row 1 */}
            <Form.Row>
              {/* Cliente */}
              <Form.Group as={Col} lg="6" xs="12">
                <InputField
                  as="select"
                  name="cliente"
                  label="Cliente"
                  value={newTimerInfo.cliente}
                  onChange={handleChange}
                  required
                >
                  <option value="">Selecione o cliente</option>
                  {clientOptions?.length > 0 &&
                    clientOptions.map(c => (
                      <option key={c.idClient} value={c.idClient}>
                        {c.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>

              {/* Projeto */}
              <Form.Group as={Col} lg="6" xs="12">
                <InputField
                  as="select"
                  name="projeto"
                  label="Projeto"
                  value={newTimerInfo.projeto}
                  onChange={handleChange}
                  disabled={!newTimerInfo.cliente}
                  required
                >
                  <option value="">Selecione o projeto</option>
                  {projectOptions?.length > 0 &&
                    projectOptions.map(p => (
                      <option key={p.idProject} value={p.idProject}>
                        {p.projectArea?.name + " - " + p.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>
            </Form.Row>

            {/* row 2 */}
            <Form.Row>
              {/* Atividade */}
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  as="textarea"
                  name="atividade"
                  label="Atividade"
                  placeholder="Descreva a atividade (mín. 10)"
                  value={newTimerInfo.atividade}
                  onChange={handleChange}
                  isInvalid={
                    newTimerInfo.atividade &&
                    !IsValidDescricao(newTimerInfo.atividade)
                  }
                  required
                />
              </Form.Group>
            </Form.Row>

            {/* row 3 */}
            <Form.Row>
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  as="select"
                  name="idRFQ"
                  label="RFQ"
                  value={newTimerInfo.idRFQ}
                  onChange={handleChange}
                >
                  <option value="">Selecione uma atividade</option>
                  {modalOptions?.length > 0 &&
                    modalOptions.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </InputField>
              </Form.Group>
            </Form.Row>

            {/* row 4 */}
            <Form.Row>
              {/* Solicitante da Demanda */}
              <Form.Group as={Col} lg="6" xs="12">
                <InputField
                  type="text"
                  name="solicitante"
                  label="Solicitante da Demanda"
                  placeholder="Digite o nome do solicitante"
                  value={newTimerInfo.solicitante}
                  onChange={handleChange}
                  isInvalid={
                    newTimerInfo.solicitante &&
                    !IsValidName(newTimerInfo.solicitante)
                  }
                  required
                />
              </Form.Group>

              {/* Demanda */}
              <Form.Group as={Col} lg="6" xs="12">
                <InputField
                  type="text"
                  name="demanda"
                  label="Demanda"
                  placeholder="Digite a demanda"
                  value={newTimerInfo.demanda}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>

            {/* row 5 */}
            <Form.Row>
              <Form.Group as={Col} lg="3" xs="12">
                <InputField
                  type="text"
                  name="totalHours"
                  label="Horas Totais"
                  placeholder="Ex.: 160h20m"
                  value={FormatJiraHour(newTimerInfo.totalHours)}
                  onChange={handleChange}
                  isInvalid={
                    newTimerInfo.totalHours &&
                    !IsValidTotalHours(newTimerInfo.totalHours)
                  }
                  maxLength={7}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} lg="5" xs="12">
                <InputField
                  as="select"
                  name="professionalFactory"
                  label="Profissional"
                  value={newTimerInfo.idProfessional}
                  onChange={handleChange}
                  required
                >
                  <option value="">Selecione o profissional</option>
                  {professionalOptions.length &&
                    professionalOptions.map((professional, index) => (
                      <option key={index} value={professional.idUser}>
                        {professional.inexEnrollment + ' - ' + professional.name}
                      </option>
                    )
                    )}
                </InputField>
              </Form.Group>

              <Form.Group as={Col} lg="4" xs="12">
                <InputField
                  as="select"
                  name="billingMonth"
                  label="Mês de Faturamento"
                  value={newTimerInfo.billingMonth}
                  onChange={handleChange}
                  required
                >
                  <option value="">Selecione o mês</option>
                  {MonthList?.length > 0 &&
                    MonthList.map((date, index) => (
                      <option key={index} value={date.value}>
                        {HandleGetDateSelect(date.name, date.value, initialCycleDay, year)}
                      </option>
                    )
                    )}
                </InputField>
              </Form.Group>
            </Form.Row>

            {/* row 6 */}
            <Form.Row>
              <TimerAttachment>
                <div className="file-input">
                  <input
                    type="file"
                    accept={ACCEPTED_FORMATS}
                    name="timerDoc"
                    ref={fileInput}
                    onChange={handleTimerDoc}
                  />

                  <div
                    className="label"
                    onClick={() => fileInput.current.click()}
                  >
                    <span className="icon">
                      <FiPaperclip size={23} color="#4672C5" />
                    </span>
                    <span className="text" ref={timerDoc}>
                      {newTimerInfo.timerDoc
                        ? newTimerInfo.timerDoc.name
                        : `Adicionar anexo ${newTimerInfo.horaExtra ? "*" : ""}`}
                    </span>
                  </div>
                  {newTimerInfo.timerDoc && (
                    <div className="remove-button" onClick={handleRemoveAnexo}>
                      <FiX color="#333" size="20px" />
                    </div>
                  )}
                </div>
              </TimerAttachment>
            </Form.Row>
            <div className="last-row">
              <div className="save-btn">
                <Button type="button" onClick={e => setOpen(null)}>
                  cancelar
                </Button>
              </div>
              <div className="save-btn">
                <Button
                  type="submit"
                  onClick={e => handleEditSubmit(e, newTimerInfo)}
                  disabled={!formIsValid()}
                >
                  salvar
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
