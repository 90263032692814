export const ERoles = Object.freeze({
  ADMIN: "ADMIN",
  CONSULTANT: "CONSULTANT",
  OPERATION: "OPERATION",
  COMMERCIAL: "COMMERCIAL",
  FINANCIAL: "FINANCIAL",
  RECRUITMENT: "RECRUITMENT",
  FACTORY: "FACTORY",
  CONSULTANTSIMPLIFIED: "CONSULTANTSIMPLIFIED",
});
