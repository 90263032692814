import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #4672C5;
  margin-bottom: 16px;

  p {
    display: flex;
    height: 32px;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 900;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
    background: #4672C5;
    margin: 0;
    padding: 0 8px;

    text-transform: uppercase;
  }

  .searchbar {
    display: flex;
    align-items: center;
    gap: 16px;

    input {
      width: 225px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: none;
      background: transparent;
      outline: none;

      font-family: "Cera Pro Light", sans-serif;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      color: #4d4d4d;

      &::-webkit-input-placeholder {
        font-family: "Cera Pro Light Italic", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #807e80;
        opacity: 0.8;
      }

      &:-ms-input-placeholder {
        font-family: "Cera Pro Light Italic", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #807e80;
        opacity: 0.8;
      }

      &::placeholder {
        font-family: "Cera Pro Light Italic", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #807e80;
        opacity: 0.8;
      }
    }
  }

  .time-stamp {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    color: #807e80;
  }
`;
