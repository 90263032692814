import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const WarningMsg = styled.span`
  position: absolute;
  color: #d93438;

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 400;
`;


export const StyledModal = styled(Modal)`
  .modal-content {
    max-height: 680px !important;
  }

  .modal-dialog {
    min-width: max-content;
    width: 90%;
    max-width: 400px;
    margin: auto;
  }

  .modal-body {
    padding: 24px 24px;

    &::-webkit-scrollbar {
      height: 6px;
      background-color: #fff;
      border-radius: 12px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #4672C5;
      border-radius: 12px;
    }

    .searchbar {
      position: relative;
      padding: 0;
      margin-bottom: 24px;

      input {
        display: block;
        height: 48px;
        width: 100%;
        font-family: "Cera Pro Light", sans-serif;
        padding: 0.65rem 1rem;
        padding-left: 56px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 1px solid #4d4c4d77;
        border-radius: 4px;
        outline: none;
        margin-top: 0;
      }

      .icon-search {
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        left: 17px;
      }
    }

    .user-line {
      display: flex;
      align-items: center;
    }

    span {
      display: flex;
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 400;
      color: #4d4c4d;

      &.centered {
        justify-content: center;
      }
    }
  }
`;
