import { Checkbox } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import { RiSearchLine } from "react-icons/ri";
import iconChecked from "../../../../../../assets/icons/icon-check-fill.svg";
import iconNotChecked from "../../../../../../assets/icons/icon-check-stroke.svg";
import { InputField } from "../../../../components/InputField";
import { StyledModal } from "./styles";
import { InexApiService } from "../../../../../../services/InexApiService";

const ProjectTypesModal = ({ setProjectTypesSelected, projectTypes, projectSelected }) => {
  const api = new InexApiService();
  const [modalOpen, setModalOpen] = React.useState(false);

  const [searchbar, setSearchbar] = React.useState("");

  const [projectTypeOpt, setProjectTypeOpt] = React.useState("");
  const [selectedProjectTypes, setSelectedProjectTypes] = React.useState([]);
  const [projectTypeList, setProjectTypeList] = React.useState([]);
  const [filteredProjectTypeList, setFilteredProjectTypeList] = React.useState([]);

  React.useEffect(() => {
    if (projectTypes) {
      setProjectTypeOpt(
        projectTypes?.length > 0
          ? projectTypes.length === 1
            ? {
              value: `${projectTypes.length} tipo de projeto selecionado`,
              label: `${projectTypes.length} tipo de projeto selecionado`
            }
            : {
              value: `${projectTypes.length} tipos de projeto selecionados`,
              label: `${projectTypes.length} tipos de projeto selecionados`
            }
          : ""
      );
      setProjectTypeList(projectTypes ?? []);
      setFilteredProjectTypeList(projectTypes ?? []);
      setSelectedProjectTypes(projectTypes);
      setProjectTypesSelected(projectTypes?.map(x => x.idProjectType) || []);
    }
  }, [projectTypes])

  React.useEffect(() => {
    if (projectSelected) {
      changeProjectType(projectSelected)
    }
  }, [projectSelected])

  const changeProjectType = async (idProject) => {
    const project = await api.makeHttpRequest({
      url: `/project/${idProject}`
    });
    const projectTypeIds = [project.projectType.idProjectType]
    setSelectedProjectTypes(projectTypeIds);
    setProjectTypeOpt(
      projectTypeIds?.length > 0
        ? projectTypeIds.length === 1
          ? {
            value: `${projectTypeIds.length} tipo de projeto selecionado`,
            label: `${projectTypeIds.length} tipo de projeto selecionado`
          }
          : {
            value: `${projectTypeIds.length} tipos de projeto selecionados`,
            label: `${projectTypeIds.length} tipos de projeto selecionados`
          }
        : ""
    );
    setSelectedProjectTypes([project.projectType]);
    setProjectTypesSelected(projectTypeIds);
  }

  const handleCheck = projectType => {
    const arr = [...selectedProjectTypes];
    const index = arr.indexOf(arr.find(x => x.idProjectType === projectType.idProjectType));

    if (index !== -1) {
      if (arr.length === 1) {
        // Se for o único tipo de projeto selecionado, não permita desmarcá-lo
        return;
      }
      arr.splice(index, 1);
    } else {
      arr.push(projectType);
    }

    setProjectTypeOpt(
      arr?.length > 0
        ? arr.length === 1
          ? {
            value: `${arr.length} tipo de projeto selecionado`,
            label: `${arr.length} tipo de projeto selecionado`
          }
          : {
            value: `${arr.length} tipos de projeto selecionados`,
            label: `${arr.length} tipos de projeto selecionados`
          }
        : ""
    );
    setSelectedProjectTypes(arr);
    setProjectTypesSelected(arr?.map(x => x.idProjectType) || []);
  };

  const handleSearchbar = value => {

    if (value?.length >= 3) {
        setFilteredProjectTypeList(
        projectTypeList?.filter(
          x => x.name.toUpperCase().includes(value.trim().toUpperCase())
        )
      );
    } else {
      setFilteredProjectTypeList(projectTypeList);
    }

    setSearchbar(value);
  };

  return (
    <>
      <div onClick={() => setModalOpen(true)}>
        <InputField
          select2ClassName="disabled-normal"
          as="select2"
          value={projectTypeOpt}
          placeholder="Selecione os Tipos de Projeto"
          className={(projectTypeList.length === 0) ? "fade" : null }
          disabled
        />
      </div>

      <StyledModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        centered
        scrollable
      >
        <Modal.Body>
          <div className="searchbar">
            <input
              type="text"
              value={searchbar}
              onChange={e => handleSearchbar(e.target.value)}
              placeholder="Buscar tipo de projeto"
            />
            <span className="icon-search">
              <RiSearchLine size="24px" color="#4672c5" />
            </span>
          </div>
          <div className="user-list">
            {filteredProjectTypeList?.length > 0 ? (
              filteredProjectTypeList.map(emp => (
                <div className="user-line" key={emp.idProjectType}>
                  <Checkbox
                    icon={<img src={iconNotChecked} alt="Não selecionado" />}
                    checkedIcon={<img src={iconChecked} alt="Selecionado" />}
                    onChange={() => handleCheck(emp)}
                    checked={
                      selectedProjectTypes.find(x => x.idProjectType === emp.idProjectType) !==
                      undefined
                    }
                  />
                  <span>{emp.name}</span>
                </div>
              ))
            ) : (
              <span className="centered">Nenhum tipo de projeto encontrado</span>
            )}
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ProjectTypesModal;
