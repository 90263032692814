import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Loading from "../../../home/components/Loading";
import { ClientIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { CoordinationModal } from "./component/Modal";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialModalValue = {
  idPayingArea: "",
  name: "",
  projectArea: { idProjectArea: 0 }
};

const initialState = {
  payingAreas: [],
  coordinationOptions: [],
  open: false,
  modalValue: initialModalValue,
  reload: false,
  loading: false
};

export default class PayingAreaListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = { ...initialState };

    this.loadCoordination = this.loadCoordination.bind(this);
    this.setPayingAreas = this.setPayingAreas.bind(this);
    this.handleModalEdit = this.handleModalEdit.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const reqs = [ this.loadCoordination() ];

      await Promise.all(reqs);
    } catch (err) {
      Swal.fire(
        "Erro!",
        "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ loading: false });
  }

  loadCoordination = async () => {
    try {
      this.setState({ loading: true });
      const req = await this.api.makeHttpRequest({
        method: "GET",
        url: `/payingarea/coordinations`
      });

      this.setState({ coordinationOptions: req });
    }catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : `Erro ao carregar coordenações`,
        "error"
      );
    }

    this.setState({ loading: false });
  }

  handleConfirmChangeStatus = async (obj) => {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/payingarea/toggle/${obj.idPayingArea}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: `Área pagadora ${!obj.deletionDate ? 'desativada' : 'ativada'} com sucesso.`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao desativar área pagadora.",
        "error"
      );
    }
  };

  handleCreateOrEditCordination = async (obj, coordinationId) => {
    try {
      this.setState({ loading: true });

      const req = obj.idPayingArea
        ? {
            method: "PUT",
            url: `/payingarea/update/${obj.idPayingArea}`
          }
        : { method: "POST", url: "/payingarea/save" };

      await this.api.makeHttpRequest({
        ...req,
        data: {
          name: obj.name,
          projectArea: { idProjectArea: Number(coordinationId) }
        }
      });

      Swal.fire({
        title: "Sucesso!",
        text: `Coordenação ${obj.idPayingArea ? "alterada" : "criada"} com sucesso.`,
        icon: "success",
        cancelButtonText: "Sair"
      });

      this.setState({ reload: !this.state.reload });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : `Erro ao ${obj.idPayingArea ? "editar" : "adicionar"} coordenação.`,
        "error"
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  setPayingAreas = payingAreas => {
    this.setState({ payingAreas });
  };

  handleModalEdit = obj => {
    this.setState({ open: true, modalValue: obj });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit-form"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION;


    if (isAdmin) {
      crudActions = [...crudActions, "individual-logs"];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idPayingArea}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
              actions={crudActions}
              module="payingArea"
              handleEdit={this.handleModalEdit}
              onConfirmChangeStatus={this.handleConfirmChangeStatus}
              path={`/admin/area-pagadora/logs/${r.idPayingArea}`}
              id={r.idPayingArea}
              obj={r}
            />
          </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.projectArea.name ? r.projectArea.name : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.client && r.client.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left" style={{ whiteSpace: "normal" }}>
          {r.projects ? r.projects : ''}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.modificationDate
            ? new Intl
              .DateTimeFormat('pt-BR')
              .format(new Date(r.modificationDate))
            : ''}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Coordenações - Admin");

    let headRows = [
      { column: "Name", label: "Nome", width: "16%" },
      { column: "ProjectArea.Name", label: "Coordenação"},
      { column: "ProjectArea.Client.Name", label: "Clientes"},
      { label: "Projetos", width: "40%" },
      { column: "ModificationDate", label: "Data Modificação" }
    ];

    if(this?.auth?.user.role.type === ERoles.ADMIN || this?.auth?.user.role.type === ERoles.OPERATION) {
      headRows = [{ label: "Ações", align: "center", width: "10%" }, ...headRows];
    }

    let showAdminButtons = [
      {
        label: "Adicionar",
        icone: "add",
        onClick: () => {
          this.setState({ open: true });
        }
      }
    ]

    if(this.auth?.user && this.auth?.user?.role?.type === ERoles.ADMIN) {
      showAdminButtons = [ ...showAdminButtons,
        {
          label: "Logs",
          onClick: null,
          icone: "visibility",
          path: "/admin/area-pagadora/logs"
        }
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Área Pagadora"
          icon={ClientIcon}
          endpoint="/payingarea/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.payingAreas}
          setRows={this.setPayingAreas}
          reload={this.state.reload}
          placeholderSearch="Busque por nome"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
        <CoordinationModal
          value={this.state.modalValue}
          handleConfirm={this.handleCreateOrEditCordination}
          coordinators={this.state.coordinationOptions}
          open={this.state.open}
          close={() =>
            this.setState({
              modalValue: initialModalValue,
              open: !this.state.open
            })
          }
        />
      </>
    );
  }
}
