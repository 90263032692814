import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { ClientIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { formatCPF } from "../../../../utils/validators/IsValidCpfCnpj";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialState = {
  clients: [],
  reload: false,
  loading: false
};

export default class ClientListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setClients = this.setClients.bind(this);
  }

  handleConfirmChangeStatus = async function(obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/client/toggle/${obj.idClient}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        "error"
      )
    }
  };

  setClients = clients => {
    this.setState({ clients });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type) === ERoles.ADMIN;
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type) === ERoles.OPERATION;

    if (isAdmin) {
      crudActions = [...crudActions, "individual-logs"];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idClient}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="clientes"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/clientes/logs/${r.idClient}`}
            id={r.idClient}
            obj={r}
          />
          </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.fantasyName && r.fantasyName}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.cnpj && formatCPF(r.cnpj)}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.contact && r.contact}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Clientes - Admin");

    let headRows = [
      { column: "Name", label: "Nome", width: "29%" },
      { column: "FantasyName", label: "Razão Social", align: "center" },
      { column: "CNPJ", label: "CNPJ", align: "center" },
      { column: "Contact", label: "Contato", align: "center" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];

    if((this.auth?.user && this.auth?.user?.role?.type === ERoles.ADMIN)
      || (this.auth?.user && this.auth?.user?.role?.type === ERoles.OPERATION)) {
        headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }

    let showAdminButtons = [
      {
        label: "Adicionar",
        onClick: null,
        icone: "add",
        path: "/admin/clientes/adicionar"
      }
    ]

    if(this.auth?.user && this.auth?.user?.role?.type === ERoles.ADMIN) {
      showAdminButtons = [ ...showAdminButtons,
        {
          label: "Logs",
          onClick: null,
          icone: "visibility",
          path: "/admin/clientes/logs"
        }
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Clientes"
          icon={ClientIcon}
          endpoint="/client/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.clients}
          setRows={this.setClients}
          reload={this.state.reload}
          placeholderSearch="Busque por nome, projeto ou cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
