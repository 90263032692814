import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
// import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import { BiImport } from "react-icons/bi";
import Loading from "../../../home/components/Loading";
import { ProposalIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { Link } from "react-router-dom";

const initialState = {
  proposal: [],
  reload: false,
  loading: false
};

export default class ProposalListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    // this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setProposal = this.setProposal.bind(this);
  }

  // handleConfirmChangeStatus = async function(obj) {
  //   try {
  //     await this.api.makeHttpRequest({
  //       method: "PUT",
  //       url: `/client/toggle/${obj.idProposal}`
  //     });

  //     this.setState({ reload: !this.state.reload });

  //     Swal.fire({
  //       title: "Sucesso!",
  //       text: "Status alterado com sucesso.",
  //       icon: "success",
  //       cancelButtonText: "Sair"
  //     });
  //   } catch (e) {
  //     Swal.fire("Erro!", "Erro ao alterar status.", "error");
  //   }
  // };

  setProposal = proposal => {
    this.setState({ proposal });
  };

  formatRow = r => {
    const crudActions = ["edit"];

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idBusinessProposal}>
        <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="propostas"
            onConfirmDelete={() => null}
            id={r.idBusinessProposal}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          <Link
            to={`/admin/propostas/${r.idBusinessProposal}/detalhes`}
            className="cta-link"
          >
            {r.name && r.name}
          </Link>
        </StyledTableCell>
        <StyledTableCell scope="row" al ign="left">
          {r.client && r.client?.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.validityStartDate && Moment(r.validityStartDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.validityEndDate && Moment(r.validityEndDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.creationDate && Moment(r.creationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Propostas Comerciais - Admin");

    const headRows = [
      { label: "Ações", align: "center" },
      { column: "Name", label: "Nome da Proposta" },
      { column: "Client.Name", label: "Cliente" },
      { column: "ValidityStartDate", label: "Início da Vigência", align: "center" },
      { column: "ValidityEndDate", label: "Fim da Vigência", align: "center" },
      { column: "CreationDate", label: "Data de Criação", align: "center" }
    ];
    
    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Propostas Comerciais"
          icon={ProposalIcon}
          endpoint="/businessProposal/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.proposal}
          setRows={this.setProposal}
          reload={this.state.reload}
          placeholderSearch="Busque por proposta ou cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={[
            {
              label: "Importar",
              onClick: null,
              RIcone: () => <BiImport size={22} />
            },
            {
              label: "Adicionar",
              onClick: null,
              icone: "add",
              path: `/admin/propostas/adicionar`
            } 
          ]}
        />
      </>
    );
  }
}
