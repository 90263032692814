import { Card } from "react-bootstrap";
import styled from "styled-components";

export const StyledOrgCard = styled(Card)`
  height: 90%;

  /* .card-body {
    padding: 0;
  } */
`;

export const ContainerOrgChart = styled.div`
  height: 100%;
  width: 100%;

  .filter-container,
  .chart-container {
    display: flex;
    width: 100%;
  }

  .filter-container {
    height: 13.5%;
  }

  .chart-container {
    height: 86.5%;
  }

  #react-org-chart {
    margin: 0;
    cursor: move;
    height: 100%;
    width: 100%;
    background-color: #f7f9fa;

    > svg {
      height: 100%;
      width: 100%;
    }
  }

  .org-chart-person-name {
    font-weight: 500;
  }
  .org-chart-person-link:hover g {
    fill: #409cf9 !important;
  }
  .org-chart-node:hover .org-chart-person-reports {
    fill: #409cf9 !important;
  }
  .org-chart-person-dept.engineering {
    fill: #4caf50 !important;
  }
  .org-chart-person-dept.communications {
    fill: #3f51b5 !important;
  }
  .org-chart-person-dept.product {
    fill: #d500f9 !important;
  }
  .org-chart-person-dept.hr {
    fill: #2196f3 !important;
  }
  .org-chart-person-dept.marketing {
    fill: #f44336 !important;
  }
  .org-chart-person-dept.design {
    fill: #26c6da !important;
  }

  .zoom-buttons {
    position: absolute;
    top: 104px;
    left: 32px;
  }

  .zoom-button {
    width: 40px;
    height: 40px;
    display: block !important;
    margin: 8px;
  }

  .btn {
    font-size: 0.875rem;
    text-transform: none;
    text-decoration-line: none;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    border-radius: 0.125rem;
    cursor: pointer;
    margin: 6px;
  }

  .btn-outline-primary {
    color: #374ea2;
    border-color: #374ea2;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #374ea2;
    border-color: #374ea2;
  }

  .github-link {
    font-size: 16px;
    margin-left: 8px;
    margin-right: 16px;
  }
`;
