import React, { useEffect, useState } from "react";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import {
  WhatsappIcon
} from "../../../../components/IconsSVG";
import { Card, Form, Col, Tabs, Tab } from "react-bootstrap";
import {
  StyledForm,
  MessageWhatsappCardBody,
  Button,
  DivButton
} from "./styles";
import { InputField } from "../../components/InputField";
import MessageWhatsappDatatable from "./components/MessageWhatsappDatatable";
import { InexApiService } from "../../../../services/InexApiService";
import store from "../../../../store/store";
import moment from "moment";
import feriados from "../../../../assets/files/feriados2024.json"
import Swal from 'sweetalert2';

const MessageWhatsapp = () => {
  const currentDate = moment();
  const { auth } = store.getState();
  const api = new InexApiService();
  const [loading, setLoading] = useState(false);
  const [messageSelect, setMessageSelect] = useState('before');
  const [operationUser, setOperationUser] = useState();
  const [lastWorkDayOfCycle, setLastWorkDayOfCycle] = useState();
  const [deadlineToRegisterTimers, setDeadlineToRegisterTimers] = useState();
  const [emailsProfissionais, setEmailsProfissionais] = useState();
  const [beforeMessageEndTime, setBeforeMessageEndTime] = useState('');
  const [messageEndTime, setMessageEndTime] = useState('');
  const [afterMessageEndTime, setAfterMessageEndTime] = useState('');
  const [messageWeekly, setMessageWeekly] = useState('');
  const [messageBlank, setMessageBlank] = useState('');
  const [operationUsers, setOperationUsers] = useState([]);
  const [notWorkProfessionalTotal, setNotWorkProfessionalTotal] = useState(0);
  const [workProfessionalTotal, setWorkProfessionalTotal] = useState(0);
  const [pendingProfessionalTotal, setPendingProfessionalTotal] = useState(0);
  const [filterStatus, setFilterStatus] = useState('');
  const [reload, setReload] = useState(false);

  const handleSubmitForm = async () => {
    if (
      beforeMessageEndTime.includes('%PROFISSIONAL%')
      && messageEndTime.includes('%PROFISSIONAL%')
      && afterMessageEndTime.includes('%PROFISSIONAL%')
      && messageWeekly.includes('%PROFISSIONAL%')
      && messageBlank.includes('%PROFISSIONAL%')
    ) {
      const reqBase = { method: "POST", url: "/whatsappmessage/save" };

      let message = messageSelect === 'before' ? beforeMessageEndTime : 
                    (messageSelect === 'current' ? messageEndTime : 
                      (messageSelect === 'after' ? afterMessageEndTime : 
                        (messageSelect === 'weekly' ? messageWeekly : messageBlank)))
      let messageType = messageSelect === 'before' ? 0 : 
                        (messageSelect === 'current' ? 1 : 
                          (messageSelect === 'after' ? 2 : 
                            (messageSelect === 'weekly' ? 3 : 4)))
      let isNonWorkingDays = true;
      let lastWorkDay = moment(lastWorkDayOfCycle)
      while (isNonWorkingDays) {
        let currentWeek = lastWorkDay.weekday();

        if (currentWeek === 6) {
          // É Sabado
          lastWorkDay.subtract(1, 'day');
        } else if (currentWeek === 0) {
          // É Domingo
          lastWorkDay.subtract(2, 'day');
        } else {
          let feriado = feriados.find((feriado) => feriado.date === lastWorkDay.format('YYYY-MM-DD'));

          if (feriado) {
            lastWorkDay.subtract(1, 'day');
          } else {
            isNonWorkingDays = false;
          }
        }
      }
      const data = {
        idUser: operationUser,
        messageType: messageType,
        lastWorkDayOfCycle: lastWorkDay.toDate(),
        deadlineToRegisterTimers: deadlineToRegisterTimers,
        consultantInexEmails: emailsProfissionais,
        message: message
      }

      await api.makeHttpRequest({
        ...reqBase,
        data
      });

      setEmailsProfissionais('');
      Swal.fire({
        title: 'Sucesso!',
        text: 'Mensagens de whatsapp salvas com sucesso!',
        icon: 'success'
      });
      setReload(true);
    } else {
      Swal.fire({
        title: 'Erro!',
        text: '%PROFISSIONAL% ausente nas mensagens!',
        icon: 'error'
      });
    }
  }

  const loadOperationUsers = async () => {
    const operations = await api.makeHttpRequest({
      url: '/user/operations'
    });

    const operationUsers =
      operations.length > 0
        ? operations.map(item => ({
            label: item.name,
            value: item.idUser,
            isDisabled: false
          }))
        : [];

    setOperationUsers(operationUsers);
    let user = auth.user
    operationUsers.forEach(operation => {
      if (operation.value === user.idUser) {
        setOperationUser(user.idUser);
      }
    });
  };

  const initialValues = () => {
    //Inicia o valor do último dia
    let dateLastCycle = moment().set('date', 15);
    let deadline = moment().set('date', 21);
    if (currentDate.date() > 15) {
      dateLastCycle.add(1, 'month')
      deadline.add(1, 'month')
    }
    setLastWorkDayOfCycle(new Date(deadline.format('YYYY-MM-DD hh:mm:ss')));    
    setDeadlineToRegisterTimers(new Date(dateLastCycle.format('YYYY-MM-DD hh:mm:ss')));

    let initialBeforeMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para lembrar que dia ${dateLastCycle.format('DD')}/${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)} (${dateLastCycle.format('dddd')}) é o último dia para a conclusão do lançamento de horas desse ciclo que termina 21/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.\n`
    + `Importante lembrar de lançar as horas provisionadas de ${dateLastCycle.format('DD')}/${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)} até 21/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;

    let initialMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para lembrar que hoje (${dateLastCycle.format('DD')}/${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)}) é o ultimo dia para a conclusão do lançamento de horas desse ciclo que termina no dia 21/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.\n`
    + `Verifiquei no sistema que ainda existem horas pendentes a serem carregadas. Não deixe para ultima hora.\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;

    let initialAfterMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para avisar que a data limite para o lançamento já passou, verifiquei no sistema que ainda existem horas pendentes a serem carregadas.\n`
    + `Poderia por gentileza efetuar o lançamento o mais breve possivel?\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem , qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;
    
    let dateNow = moment();
    let startWeekly = dateNow.clone().startOf('isoWeek').subtract(1, 'day');
    let endWeekly = dateNow.clone().endOf('isoWeek').subtract(1, 'day');

    let startLastWeek = dateNow.clone().subtract(1, 'weeks').startOf('isoWeek').subtract(1, 'day');
    let endLastWeek = dateNow.clone().subtract(1, 'weeks').endOf('isoWeek').subtract(1, 'day');

    let initialWeeklyMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para lembrar que hoje ${dateNow.format('DD')}/${dateNow.format('MMMM').charAt(0).toUpperCase() + dateNow.format('MMMM').slice(1)} (${dateNow.format('dddd')}) é o dia para a conclusão do lançamento de horas da semana anterior (de ${startLastWeek.format('DD')} de ${startLastWeek.format('MMMM').charAt(0).toUpperCase() + startLastWeek.format('MMMM').slice(1)} à ${endLastWeek.format('DD')} de ${endLastWeek.format('MMMM').charAt(0).toUpperCase() + endLastWeek.format('MMMM').slice(1)})\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;
    if (deadline.isBetween(startWeekly, endWeekly, null, '[]')) {
      initialWeeklyMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
      + `Passando para lembrar que hoje ${dateNow.format('DD')}/${dateNow.format('MMMM').charAt(0).toUpperCase() + dateNow.format('MMMM').slice(1)} (${dateNow.format('dddd')}) é o dia para a conclusão do lançamento de horas da semana anterior (de ${startLastWeek.format('DD')} de ${startLastWeek.format('MMMM').charAt(0).toUpperCase() + startLastWeek.format('MMMM').slice(1)} à ${endLastWeek.format('DD')} de ${endLastWeek.format('MMMM').charAt(0).toUpperCase() + endLastWeek.format('MMMM').slice(1)}).\n`
      + `*Importante lembrar que essa semana concluiremos o ciclo mensal, não esqueça de lançar as horas provisionadas de ${startLastWeek.format('DD')}/${startLastWeek.format('MMMM').charAt(0).toUpperCase() + startLastWeek.format('MMMM').slice(1)} até 21/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.*\n`
      + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
      + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;
    }

    let initialMessageBlank = `Olá %PROFISSIONAL%, `;

    setBeforeMessageEndTime(initialBeforeMessage);
    setMessageEndTime(initialMessage);
    setAfterMessageEndTime(initialAfterMessage);
    setMessageWeekly(initialWeeklyMessage);
    setMessageBlank(initialMessageBlank);
  }

  useEffect(() => {
    loadOperationUsers();
    initialValues();
  }, []);

  const changeEmails = (value) => {
    setEmailsProfissionais(value);
  }

  const onBlurEmails = (value) => {
    let arrayStr = value.replace(',', ' ').split(/\s+/);
    let newValue = arrayStr.join(',');
    setEmailsProfissionais(newValue);
  }

  const handleMessageSelectChange = (event) => {
    setMessageSelect(event.target.value);
  };

  const changeLastWorkDayOfCycle = (e) => {
    setLastWorkDayOfCycle(e.target.value);
    reloadMessages(moment(deadlineToRegisterTimers, 'YYYY-MM-DD'), moment(e.target.value));
  }

  const changeDeadlineToRegisterTimers = (e) => {
    setDeadlineToRegisterTimers(e.target.value);    
    reloadMessages(moment(e.target.value), moment(lastWorkDayOfCycle, 'YYYY-MM-DD'));
  }

  const reloadMessages = (dateLastCycle, deadline) => {
    let beforeMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para lembrar que dia ${dateLastCycle.format('DD')}/${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)} (${dateLastCycle.format('dddd')}) é o último dia para a conclusão do lançamento de horas desse ciclo que termina ${deadline.format('DD')}/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.\n`
    + `Importante lembrar de lançar as horas provisionadas de ${dateLastCycle.format('DD')}/${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)} até ${deadline.format('DD')}/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;

    let message = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para lembrar que hoje (${dateLastCycle.format('DD')}/${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)}) é o ultimo dia para a conclusão do lançamento de horas desse ciclo que termina no dia ${deadline.format('DD')}/${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)}.\n`
    + `Verifiquei no sistema que ainda existem horas pendentes a serem carregadas. Não deixe para ultima hora.\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;

    let dateNow = moment();
    let weeklyMessage = `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n`
    + `Passando para lembrar que hoje ${dateNow.format('DD')}/${dateNow.format('MMMM').charAt(0).toUpperCase() + dateNow.format('MMMM').slice(1)} (${dateNow.format('dddd')}) é o dia para a conclusão do lançamento de horas da semana (de ${dateLastCycle.format('DD')} de ${dateLastCycle.format('MMMM').charAt(0).toUpperCase() + dateLastCycle.format('MMMM').slice(1)} à ${deadline.format('DD')} de ${deadline.format('MMMM').charAt(0).toUpperCase() + deadline.format('MMMM').slice(1)})\n`
    + `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n`
    + `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`;

    setBeforeMessageEndTime(beforeMessage);
    setMessageEndTime(message);
    setMessageWeekly(weeklyMessage);
  }

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle title="WhatsApp" icon={WhatsappIcon} disableHeader />
        <Card className="mt-4">
          <MessageWhatsappCardBody>
            <StyledForm>
              <Form className="form-admin">
                <Form.Row>
                  {/* Usuário */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      as="select"
                      name="user"
                      label="Usuário"
                      value={operationUser}
                      onChange={(e) => setOperationUser(e.target.value)}
                      required
                    >
                      <option value="">Selecione o usuário</option>
                      {operationUsers.length > 0 &&
                        operationUsers.map(o => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                  {/* Prazo para lançamento */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      type="date"
                      name="lastWorkDayOfCycle"
                      label="Limite para lançamento"
                      value={deadlineToRegisterTimers}
                      maxDate={lastWorkDayOfCycle}
                      placeholder="Selecione a data"
                      onChange={(e) => changeDeadlineToRegisterTimers(e)}
                      required
                    />
                  </Form.Group>
                  {/* Data limite de lançamento */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      type="date"
                      name="deadlineToRegisterTimers"
                      label="Data de fim do ciclo"
                      value={lastWorkDayOfCycle}
                      placeholder="Selecione a data"
                      onChange={(e) => changeLastWorkDayOfCycle(e)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {/* E-mails dos profissionais para o envio */}
                  <Form.Group as={Col} lg="12" xs="12">
                    <InputField
                      as="textarea"
                      name="emailsProfissionais"
                      label='E-mails - (Separe por ",")'
                      placeholder="E-mails dos profissionais para o envio."
                      value={emailsProfissionais}
                      onBlur={(e) => onBlurEmails(e.target.value)}
                      onChange={(e) => changeEmails(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                {/* Mensagens */}
                <Form.Row>
                  <Form.Group as={Col} lg="12" xs="12">
                    <input type="radio" checked={messageSelect === 'before'} value="before" onChange={handleMessageSelectChange} /> Antes do fim do prazo de lançamento<br />
                    <input type="radio" checked={messageSelect === 'current'} value="current" onChange={handleMessageSelectChange}/> No ultimo dia do prazo de Lançamento<br />
                    <input type="radio" checked={messageSelect === 'after'} value="after" onChange={handleMessageSelectChange}/> Após o ultimo dia de prazo de lançamento<br />
                    <input type="radio" checked={messageSelect === 'weekly'} value="weekly" onChange={handleMessageSelectChange}/> Exclusivo para contratos semanais<br />
                    <input type="radio" checked={messageSelect === 'blank'} value="blank" onChange={handleMessageSelectChange}/> Em branco<br />
                  </Form.Group>
                </Form.Row>
                {
                  messageSelect === 'before' &&
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="beforeMessageEndTime"
                        label='Mensagem'
                        style={{height: '130px'}}
                        placeholder="Mensagem a ser enviada antes do fim do prazo de lançamento."
                        value={beforeMessageEndTime}
                        onChange={(e) => setBeforeMessageEndTime(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                }
                {
                  messageSelect === 'current' &&
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="messageEndTime"
                        label='Mensagem'
                        placeholder="Mensagem a ser enviada no ultimo dia do prazo de Lançamento."
                        value={messageEndTime}
                        style={{height: '130px'}}
                        onChange={(e) => setMessageEndTime(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                }
                {
                  messageSelect === 'after' &&
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="afterMessageEndTime"
                        label='Mensagem'
                        placeholder="Mensagem a ser enviada após o ultimo dia de prazo de lançamento."
                        value={afterMessageEndTime}
                        style={{height: '130px'}}
                        onChange={(e) => setAfterMessageEndTime(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                }
                {
                  messageSelect === 'weekly' &&
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="messageWeekly"
                        label='Mensagem'
                        style={{height: '130px'}}
                        placeholder="Mensagem a ser enviada para profissionais com contrato semanal."
                        value={messageWeekly}
                        onChange={(e) => setMessageWeekly(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                }
                {
                  messageSelect === 'blank' &&
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="messageBlank"
                        label='Mensagem'
                        placeholder="Mensagem em branco para mensagens customizáveis."
                        value={messageBlank}
                        style={{height: '130px'}}
                        onChange={(e) => setMessageBlank(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                }
                <DivButton style={{width: '100%'}}>
                  <div style={{float: 'left', alignItems: 'center'}}>
                    Profissionais:<br/>
                    <a style={{color: '#4672C5'}} onClick={() => setFilterStatus('Não Enviada - Usuário em dia')}>
                      Em dias com lançamento: {workProfessionalTotal}
                    </a>
                    <span> | </span>
                    <a style={{color: '#4672C5'}} onClick={() => setFilterStatus('not_worked')}>
                      Atrasado com lançamento: {notWorkProfessionalTotal}
                    </a>
                    <span> | </span>
                    <a style={{color: '#4672C5'}} onClick={() => setFilterStatus('Pendente')}>
                      Pendente de consulta: {pendingProfessionalTotal}
                    </a>
                    <span> | </span>
                    <a style={{color: '#4672C5'}} onClick={() => setFilterStatus('')}>
                      Limpar
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    type="button"
                    color="#4672C5"
                    onClick={handleSubmitForm}
                    title={"Salvar"}
                    style={{float: 'right'}}
                  >
                    <span className="text-light">Salvar</span>
                  </Button>
                </DivButton>
              </Form>
            </StyledForm>
          </MessageWhatsappCardBody>
        </Card>
        <br/>
        <div className="flex-grow-1">
          <MessageWhatsappDatatable
            reload={reload}
            setReload={setReload}
            operationUser={operationUser}
            setNotWorkProfessionalTotal={setNotWorkProfessionalTotal}
            setWorkProfessionalTotal={setWorkProfessionalTotal}
            setPendingProfessionalTotal={setPendingProfessionalTotal}
            filterStatus={filterStatus}
          />
        </div>
      </div>
    </>
  );
}


export default MessageWhatsapp;
