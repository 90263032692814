import React, { useEffect } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { Button, StyledModal } from "./styles";
import formatCurrency from "../../../../../../utils/FormatCurrency";

export const ContabilModal = ({ open, close, handleConfirm, profissionalList }) => {
  const [profissionais, setProfissionais] = React.useState(profissionalList);
  const [custoValue, setCustoValue] = React.useState(0);
  const [lucroValue, setLucroValue] = React.useState(0);
  const [projetadoValue, setProjetadoValue] = React.useState(0);

  useEffect(() => {
    setProfissionais(profissionalList)
  }, [open])

  useEffect(() => {
    const projetado = profissionais.reduce((acc, p) => acc + (p?.quantityHourProject * p?.wageHourProject), 0);
    const custo = profissionais.reduce((acc, p) => acc + (p?.quantityHour * p?.wageHour), 0);
    const lucro = projetado - custo;
    setProjetadoValue(projetado)
    setCustoValue(custo)
    setLucroValue(lucro)
  }, [profissionais])

  const handleChange = (profissional, type, newValue) => {
    let arr = [];
    
    profissionais.forEach(element => {
      if (element.value === profissional.value) {
        switch (type) {
          case "qtyHourProject":
            element.quantityHourProject = newValue 
            break;
          case "qtyHour":
            element.quantityHour = newValue 
            break;
          case "wageHourProject":
            element.wageHourProject = newValue 
            break;
          case "wageHour":
            element.wageHour = newValue 
            break;
        }
      }
      arr.push(element)
    });

    setProfissionais(arr)
  }

  return (
    <>
      <StyledModal show={open} size="xs" centered>
        <Modal.Body>
          <h1 className="modal-title">
            Pré-visualização dos Valores
          </h1>
          <Form>
            {
              profissionais.map((profissional) => 
                <>
                  <Form.Row>
                    <Form.Group as={Col} lg="18" xs="2">
                        <InputField
                            type="text"
                            name="nome"
                            label="Profissional"
                            value={profissional?.label.split('(')[0].trim()}
                            disabled />
                    </Form.Group>
                    <Form.Group as={Col} lg="18" xs="2">
                        <InputField
                            type="text"
                            name="atividade"
                            label="Atividade"
                            value={profissional?.activity}
                            disabled />
                    </Form.Group>
                    <Form.Group as={Col} lg="18" xs="2">
                        <InputField
                            type="text"
                            name="qtdHorasProjeto"
                            label="Qtde de Horas no Projeto"
                            value={profissional?.quantityHourProject}
                            placeholder="Digite o valor da quantidade de horas no Projeto"
                            onChange={e => handleChange(profissional, 'qtyHourProject', e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} lg="18" xs="2">
                        <InputField
                            type="text"
                            name="valorHoraProjeto"
                            label="Valor Hora no Projeto"
                            value={profissional?.wageHourProject}
                            placeholder="Digite o valor hora no Projeto"
                            onChange={e => handleChange(profissional, 'wageHourProject', e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} lg="18" xs="2">
                        <InputField
                            type="text"
                            name="qtdHoras"
                            label="Qtde de Horas do Profissional"
                            value={profissional?.quantityHour}
                            placeholder="Digite a quantidade de horas do Profissional"
                            onChange={e => handleChange(profissional, 'qtyHour', e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} lg="18" xs="2">
                        <InputField
                            type="text"
                            name="valorHora"
                            label="Valor Hora do Profissional"
                            value={profissional?.wageHour}
                            placeholder="Digite o valor hora do Profissional"
                            onChange={e => handleChange(profissional, 'wageHour', e.target.value)} />
                    </Form.Group>
                  </Form.Row>
                </>
              )
            }
            <div style={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexWrap: 'nowrap',
              gap: '50px'
            }}>
              <div style={{marginTop: '24px'}}>
                <span style={{marginRight: '12px', fontSize: '20px'}}><b>Projetado: R$ {formatCurrency((projetadoValue*100).toString())}</b></span>
                <span style={{marginRight: '12px', fontSize: '20px'}}><b>Custo: R$ {formatCurrency((custoValue*100).toString())}</b></span>
                <span style={{fontSize: '20px', color: `${lucroValue <= 0 ? 'red' : 'green'}`}}><b>Lucro: R$ {lucroValue < 0 ? '-' : ''}{formatCurrency(lucroValue <= 0? (lucroValue*100).toString() : (lucroValue*100).toString())}</b></span>
              </div>
              <div className="btn-container">
                <Button
                  type="button"
                  onClick={e => {
                    setProfissionais(profissionalList)
                    const projetado = profissionalList.reduce((acc, p) => acc + (p?.quantityHourProject * p?.wageHourProject), 0);
                    const custo = profissionalList.reduce((acc, p) => acc + (p?.quantityHour * p?.wageHour), 0);
                    const lucro = projetado - custo;
                    setProjetadoValue(projetado)
                    setCustoValue(custo)
                    setLucroValue(lucro)
                    e.preventDefault();
                    close();
                  }}
                >
                  CANCELAR
                </Button>
                <Button
                  type="submit"
                  onClick={e => {
                    e.preventDefault();
                    handleConfirm(false, profissionais);
                    close();
                  }}
                >
                  CONFIRMAR
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
