import React, { useState, useEffect } from 'react';
import queryString from 'querystring';
import { Modal } from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';
import Loading from '../../home/components/Loading';
import { IsValidPassword } from '../../../utils/validators/IsValidPassword';
import { InexApiService } from '../../../services/InexApiService';
import { CentralizedContainer, StyledInput, Requisitos, Button, ModalContainer, ButtonGroup } from './style';
import { CancelButton, LinkButton } from '../ForgotPassword/style'
import Actions from './enum/Actions';

const Password = ({ action, ...props }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState({ title: '', subtitle: '', color: 'black', success: false });
  const hasToken = queryString.parse(window.location.search.replace('?', '')).t;
  const token = window.location.search.replace('?t=', '');
  const notCriarConta = action !== Actions.CriarConta;
  const [isLoading, setIsLoading] = useState(false);
  const handleNewPasswordChange = e => {
    setNewPassword(e.target.value);
  }

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
  }

  //Actions.RecuperarSenha
  let messages = {
    title: 'Definir nova senha',
    subtitle: 'Crie uma nova senha para sua conta:',
    button: 'Definir senha'
  };

  if (action === Actions.CadastrarSenha) {
    messages = {
      title: 'Seu email foi confirmado!',
      subtitle: 'Agora, crie sua senha para acesso:',
      button: 'Cadastrar senha'
    };
  } else if (action === Actions.CriarConta) {
    messages = {
      title: 'Seu email foi confirmado!',
      subtitle: 'Sua conta está pronta para ser utilizada.',
      button: 'Ativar conta'
    };
  }

  const passwordValidation = () => {
    const validation = IsValidPassword(newPassword);
    if (newPassword === '') {
      setError('Preencha com uma senha!')
      return false;
    }

    if (validation !== true) {
      setError('A senha não possui os requisitos mínimos.');
      return false;
    }

    if (newPassword !== confirmPassword) {
      setError('As senhas não se conferem. Tente novamente!')
      return false;
    }
    return true;
  };

  const doAction = async () => {
    let data = {
      token
    };
    if (notCriarConta) {
      data = {
        ...data,
        password: newPassword,
      }
    }

    let configModal;
    const api = new InexApiService();
    setIsLoading(true);
    await api.makeHttpRequest({
      url: '/requisicaotemporaria',
      method: 'POST',
      data
    }).then(() => {
      setIsLoading(false);
      configModal = {
        title: 'Parabéns!',
        subtitle: 'Sua senha foi definida com sucesso!',
        color: 'green',
        success: true
      };

    }).catch(() => {
      setIsLoading(false);
      configModal = {
        title: 'Houve um erro...',
        subtitle: 'Ocorreu um problema ao alterar sua senha',
        color: 'red',
        success: false
      };

      if (action === Actions.CriarConta) {
        setError([
          true,
          '',
          'Ocorreu um problema ao ativar sua conta, tente novamente mais tarde ou contate a equipe técnica.'
        ]);
      }
    });

    setConfigurationModal(configModal);
    setSuccessModal(true);
  }

  const handleSubmit = () => {
    if (notCriarConta) {
      if (passwordValidation() === false) return;
    }

    doAction();
    setError('');
  };

  useEffect(() => {
    if (action === Actions.CriarConta) {
      doAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeSuccessModal = () => setSuccessModal(false);

  switch (action) {
    case Actions.CriarConta:
      window.setPageTitle('Ativar Conta');
      break;
    case Actions.CadastrarSenha:
      window.setPageTitle('Cadastrar Senha');
      break;
    default:
      window.setPageTitle('Recuperar Senha');
  }

  return (
    <CentralizedContainer>
      <Loading isLoading={isLoading} />
      {
        !hasToken && <Redirect to='/auth' />
      }

      <h2 className='title'>{error[0] === true ? error[1] : messages.title}</h2>
      <h2 className='subtitle'>{error[0] === true ? error[2] : messages.subtitle}</h2>

      {notCriarConta
        && (
          <>
            <StyledInput
              placeholder="Insira uma senha"
              value={newPassword}
              onChange={handleNewPasswordChange}
              autoFocus={true}
              error={error !== '' ? true : false}
              helperText={error !== '' && error}
              type="password"
            />
            <StyledInput
              placeholder="Confirme a senha"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoFocus={true}
              type="password"
            />

            <Requisitos>
              <h6>Requisitos de segurança para senha:</h6>
              <span> ✓ Conter entre 8 e 16 caracteres; </span>
              <span> ✓ Conter numerais; </span>
              <span> ✓ Conter letras minúsculas e maiúsculas; </span>
              <span> ✓ Caracteres especiais ?=.*[]#$^+=!*()+@-%{'&'} </span>
            </Requisitos>

            <Modal
              open={successModal}
              onClose={() => setSuccessModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <CentralizedContainer margin="0 15%">
                <ModalContainer color={configurationModal.color}>
                  <span className='title'>{configurationModal.title || ''}</span>
                  <span className='subtitle'>{configurationModal.subtitle || ''}</span>

                  <div className={configurationModal.success ? 'success' : ''}>
                    <CancelButton onClick={closeSuccessModal}>Cancelar</CancelButton>
                    {configurationModal.success &&
                      <LinkButton to='/auth'>Entrar</LinkButton>
                    }
                  </div>
                </ModalContainer>
              </CentralizedContainer>
            </Modal>
          </>
        )
      }

      <ButtonGroup width={notCriarConta && '300px'}>
        {notCriarConta &&
          <Button onClick={handleSubmit}>{messages.button}</Button>}

        <Link to='/auth'>
          <Button type="submit">
            Voltar
          </Button>
        </Link>
      </ButtonGroup>
    </CentralizedContainer >
  )
};

export default Password;
