import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { Card, Col, Form } from "react-bootstrap";
import { FiEdit3 } from "react-icons/fi";
import Loading from "../../../home/components/Loading";
import { InexApiService } from "../../../../services/InexApiService";
import { AdminTitle } from "../../components/AdminTitle";
import { StyledCardBody } from "../../components/AdminCard/style";
import { InputField } from "../../components/InputField";
import FormActions from "../../components/FormActions";
import { IndividualForm } from "./components/IndividualForm";
import { CustomAccordionBody } from "../../components/Accordion";
import {
  ProjectIcon,
  RitualIcon,
  UserIcon
} from "../../../../components/IconsSVG";
import { StyledAccordion, StyledAccordionCardBody } from "../../components/Accordion/styles";

const RitualForm = props => {
  const api = new InexApiService();
  const id = props?.match?.params?.id;

  moment.locale("pt-br", {
    months: "Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro".split(
      "_"
    ),
    monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_")
  });

  const [loading, setLoading] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const [allowEdit, setAllowEdit] = React.useState(false);

  const [ritual, setRitual] = React.useState(null);
  const [ritualProjects, setRitualProjects] = React.useState([]);

  React.useEffect(() => {
    window.setPageTitle("Atualizar Ritual - Admin");
  }, []);

  React.useEffect(() => {
    loadRitual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRitual = React.useCallback(async () => {
    setLoading(true);
    try {
      if (!id) {
        Swal.fire("Erro!", "Nenhum ID de Ritual informado.", "error");
        return;
      }

      const data = await api.makeHttpRequest({
        url: `ritual/data/${id}`
      });

      setRitual(data);
      setRitualProjects(data.ritualProjects ?? []);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar ritual.",
        "error"
      );
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUsersForm = (projectIndex, userIndex, value) => {
    setDirty(true);

    const arr = [...ritualProjects];
    arr[projectIndex].ritualUsers[userIndex] = value;
    setRitualProjects(arr);
  };

  const handleScrumMasterForm = (projectIndex, value) => {
    setDirty(true);

    const arr = [...ritualProjects];
    arr[projectIndex].scrumMaster = value;
    setRitualProjects(arr);
  };

  const onSubmit = React.useCallback(async () => {
    if (!dirty || !id) return;

    setLoading(true);

    try {
      const payload = {
        ritualProjects
      };

      await api.makeHttpRequest({
        url: `/ritual/update/${id}`,
        method: "PUT",
        data: payload
      });

      setLoading(false);
      const { value } = await Swal.fire({
        title: "Sucesso!",
        text: "Dados salvos com sucesso.",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Continuar ritual",
        cancelButtonText: "Sair"
      });

      if (!value) {
        props.history.push("/admin/rituais");
      }
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao atualizar ritual.",
        "error"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, id, ritualProjects]);

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={RitualIcon} title="Rituais" />
        <Card className="mt-4">
          <StyledCardBody>
            {ritual && (
              <Form className="form-admin">
                <h2 className="card-title default-margin">
                  {ritual.type} - Coordenação {ritual.coordination} -
                  Competência {moment(ritual.competencia).format("MMMM/YYYY")}{" "}
                </h2>
                {ritualProjects?.length > 0 &&
                  ritualProjects.map((proj, projIndex) => (
                    <StyledAccordion key={proj.idProject} shadow="true">
                      <CustomAccordionBody
                        eventKey="0"
                        title={`Projeto: ${proj.name}`}
                        icon={ProjectIcon}
                      >
                        <StyledAccordionCardBody>
                          <div className="inputs-wrapper">
                            <Form.Row>
                              {/* Scrum Master */}
                              <Form.Group as={Col} lg="12" xs="12">
                                <InputField
                                  type="text"
                                  name="scrumMaster"
                                  label="Scrum Master"
                                  placeholder="Digite o nome do Scrum Master"
                                  value={proj.scrumMaster ?? ""}
                                  disabled={!allowEdit}
                                  onChange={e =>
                                    handleScrumMasterForm(
                                      projIndex,
                                      e.target.value
                                    )
                                  }
                                  endAdornment={
                                    <div
                                      title="Toggle edição da Scrum Master"
                                      onClick={() => setAllowEdit(!allowEdit)}
                                    >
                                      {allowEdit ? (
                                        <FiEdit3 color="#4672C5" size="20px" />
                                      ) : (
                                        <FiEdit3 color="#807E80" size="20px" />
                                      )}
                                    </div>
                                  }
                                />
                              </Form.Group>
                            </Form.Row>
                          </div>
                          {proj.ritualUsers?.length > 0 &&
                            proj.ritualUsers.map((user, userIndex) => (
                              <StyledAccordion key={user.idUser}>
                                <CustomAccordionBody
                                  eventKey="01"
                                  title={user.name}
                                  icon={UserIcon}
                                  variant="secondary"
                                >
                                  <div className="inputs-wrapper">
                                    <IndividualForm
                                      userInfo={user}
                                      setUserForm={value =>
                                        handleUsersForm(
                                          projIndex,
                                          userIndex,
                                          value
                                        )
                                      }
                                    />
                                  </div>
                                </CustomAccordionBody>
                              </StyledAccordion>
                            ))}
                        </StyledAccordionCardBody>
                      </CustomAccordionBody>
                    </StyledAccordion>
                  ))}
              </Form>
            )}
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={props.history.goBack}
          isEdit={true}
          formIsValid={dirty && id}
          onSubmit={onSubmit}
          hideReload
        />
      </div>
    </>
  );
};

export default RitualForm;
