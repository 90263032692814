import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import moment from "moment";

const initialState = {
  id: 0,
  title: "",
  message: "",
  link: "",
  initialDate: new Date(),
  validity: "",
  isLoading: false,
  persistedModel: "",
  users: [],
  clients: [],
  projects: [],
  coordinations: [],
  roles: [],
  usersSelected: []
};

export default class NotificationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      userOptions: [],
      clientOptions: [],
      projectOptions: [],
      coordinationOptions: [],
      roleOptions: [],
      usersSelectedOptions: [],
      isEditing: false
    };

    this.api = new InexApiService();

    this.loadNotification = this.loadNotification.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.loadRoles = this.loadRoles.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.handleUsersSelectedChange = this.handleUsersSelectedChange.bind(this);
    this.loadUsersSelected = this.loadUsersSelected.bind(this);
    this.handleCoordinationsChange = this.handleCoordinationsChange.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      const reqs = [
        this.loadUsers(),
        this.loadRoles(),
        this.loadClients()
      ];
      if (this.props.match.params.id) {
          await this.loadNotification(this.props.match.params.id);
      }
      await Promise.all(reqs);
    } catch (error) {
        Swal.fire(
            "Erro!",
            error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
            "error"
        );
    }

    this.setState({ isLoading: false });
  }

  loadNotification = async id => {
      const notification = await this.api.makeHttpRequest({
          url: `/notification/${id}`
      });

      const persistedModel = {
        id: id,
        title: notification.title,
        message: notification.message,
        link: notification.link,
        initialDate: moment(notification.initialDate).toDate(),
        validity: notification.validity
      };

      const clients =
        notification.clients?.length > 0
          ? notification.clients.map(client => ({
            label: client.client.name,
            value: client.client.idClient
          }))
        : [];

      const projectAreas =
        notification.projectAreas?.length > 0
          ? notification.projectAreas.map(projectArea => ({
            label: projectArea.projectArea.name,
            value: projectArea.projectArea.idProjectArea
          }))
        : [];

      const projects =
        notification.projects?.length > 0
          ? notification.projects.map(project => ({
            label: project.project.name,
            value: project.project.idProject
          }))
        : [];

      const roles =
        notification.roles?.length > 0
          ? notification.roles.map(role => ({
            label: role.role.translatePtBr,
            value: role.role.idRole
          }))
        : [];

      const users =
        notification.details?.length > 0
          ? notification.details.map(detail => {
              if (!detail.freeChoice) {
                return {
                  label: detail.user.name,
                  value: detail.user.idUser
                }
              }
            })
          : [];

      const freeChoiceUsers =
        notification.details?.length > 0
          ? notification.details.map(detail => {
            if (detail.freeChoice) {
              return {
                label: detail.user.name,
                value: detail.user.idUser
              }
            }
          })
          : [];

      const persistedModelJson = JSON.stringify(persistedModel);
      this.setState({
          ...persistedModel,
          isLoading: false,
          persistedModel: persistedModelJson,
          usersSelected: users,
          users: freeChoiceUsers,
          clients: clients,
          coordinations: projectAreas,
          projects: projects,
          roles: roles
      });
  };

  loadRoles = async () => {
    this.setState({ isLoading: true });

    const roles = await this.api.makeHttpRequest({
      url: `/role/all`
    });

    const roleOptions =
      roles?.length > 0
        ? roles.map(item => ({
            label: item.translatePtBr,
            value: item.idRole
          }))
        : [];

    this.setState({
      roleOptions,
      isLoading: false
    });
  };

  loadUsers = async () => {
    this.setState({ isLoading: true });

    const users = await this.api.makeHttpRequest({
      url: `/professional/getAllActiveProfessionals`
    });

    const userOptions =
      users?.length > 0
        ? users.map(item => ({
            label: item.name,
            value: item.idUser,
            role: item.role,
            clients: item.clients,
            projects: item.projects
          }))
        : [];

    this.setState({
      userOptions,
      isLoading: false
    });
  };

  loadClients = async () => {
    this.setState({ isLoading: true });

    const clients = await this.api.makeHttpRequest({
      url: `/client/all`
    });

    const clientOptions =
      clients?.length > 0
        ? clients.map(item => ({
            label: item.name,
            value: item.idClient
          }))
        : [];

    this.setState({
      clientOptions,
      isLoading: false
    });
  };

  loadProjects = async (clientIds) => {
    if (clientIds) {
      this.setState({ isLoading: true });

      const params = {
        clientIds: clientIds.join(",")
      }
  
      const projects = await this.api.makeHttpRequest({
        url: `/project/by-client-ids`,
        params
      });
  
      const projectOptions =
        projects?.length > 0
          ? projects.map(item => ({
              label: item.name,
              value: item.idProject,
              idClient: item.idClient
            }))
          : [];

      this.setState({
        projectOptions,
        isLoading: false
      });
    }
  };

  loadCoordinations = async clientIds => {
    if (clientIds) {
      this.setState({ isLoading: true });

      const params = {
        clientIds: clientIds.join(",")
      }

      const coordinations = await this.api.makeHttpRequest({
        url: `/project/coordinations-by-client-ids`,
        params
      });

      const coordinationOptions =
        coordinations.length > 0
          ? coordinations.map(item => ({
              label: item.name,
              value: item.idProjectArea,
              idClient: item.client.idClient,
              isDisabled: false
            }))
          : [];

      this.setState({
        coordinationOptions,
        isLoading: false
      });
    }
  };

  submit = async e => {
    const {
      users = [],
      usersSelected = [],
      clients = [],
      coordinations = [],
      projects = [],
      roles = [],
      title,
      message,
      link,
      initialDate,
      validity,
    } = this.state;
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
          ? { method: "PUT", url: `/notification/update/${id}` }
          : { method: "POST", url: "/notification/save" };

      const getIds = (list) => 
        list
          .filter((item) => item && item.value !== undefined)
          .map((u) => u.value)
          .join(',');

      await this.api.makeHttpRequest({
          ...reqBase,
          data: {
            title: this.state.title,
            message: this.state.message,
            link: this.state.link,
            initialDate: this.state.initialDate,
            validity: this.state.validity,
            userIds: getIds(usersSelected),
            userFreeChoiceIds: getIds(users),
            clientIds: getIds(clients),
            projectAreaIds: getIds(coordinations),
            projectIds: getIds(projects),
            roleIds: getIds(roles)
          }
      });

      this.afterSubmit();
    } catch (e) {
      console.log(e)
      Swal.fire(
        "Erro!",
        e?.response?.data
        ? e.response.data
        : "Erro ao cadastrar notificação, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/notificacoes");
    }
  };

  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      title: this.state.title,
      message: this.state.message,
      link: this.state.link,
      initialDate: this.state.initialDate,
      validity: this.state.validity
    };

    const userList = this.state.users && this.state.users.length > 0 ? this.state.users : [];
    const userSelectedList = this.state.usersSelected && this.state.usersSelected.length > 0 ? this.state.usersSelected : [];

    const users = [...userList, ...userSelectedList]

    return (
      this.state.title &&
      this.state.title.length >= 3 &&
      this.state.title.length <= 50 &&
      this.state.message &&
      this.state.message.length >= 3 && 
      this.state.message.length <= 500 &&
      this.state.initialDate &&
      this.state.validity && 
      /^(\d+(\.\d+)?|\.\d+)$/.test(this.state.validity) &&
      users.length > 0
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadTraining(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  handleChange = (e) => {   
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleRolesChange = (value, event) => {
    const roleIds = value?.map(c => c.value)
    const clientIds = this.state.clients?.map(c => c.value)
    if (roleIds && roleIds.length > 0) {
      if (clientIds && clientIds.length > 0) {
        const projectIds = this.state.projects?.map(c => c.value)
        const coordinationIds = this.state.coordinations?.map(c => c.value)
        this.loadUsersSelected(clientIds, roleIds, projectIds, coordinationIds);
      } else {
        const usersByRole = this.state.userOptions.filter(user => roleIds.includes(user.role.idRole))
        this.setState({ 
          usersSelected: usersByRole,
          usersSelectedOptions: usersByRole
        });
      }
    } else {
      if (clientIds && clientIds.length > 0) {
        const projectIds = this.state.projects?.map(c => c.value);
        const coordinationIds = this.state.coordinations?.map(c => c.value);
        this.loadUsersSelected(clientIds, roleIds, projectIds, coordinationIds);
      } else {
        this.setState({ 
          usersSelected: [], 
          usersSelectedOptions: []
        });
      } 
    }
    this.setState({ roles: value });
  };

  handleUsersChange = (value, event) => {
    if (value === undefined || value === null) {
      this.setState({ users: null });
    } else {
      let users = value
      if (this.state.usersSelected && this.state.usersSelected.length > 0){
        users = value.filter(user => !this.state.usersSelected.some(u => u.value === user.value))
      }
      this.setState({ users: users });
    }
  };

  handleClientsChange = (value, event) => {
    const clientIds = value?.map(c => c.value)
    const roleIds = this.state.roles?.map(c => c.value)
    if (clientIds && clientIds.length > 0) {
      const projects = this.state.projects?.filter((p) => clientIds.includes(p.idClient));
      this.setState({ projects });
      this.loadProjects(clientIds);
      const coordinations = this.state.coordinations?.filter((c) => clientIds.includes(c.idClient));
      this.setState({ coordinations });
      this.loadCoordinations(clientIds);
      const projectIds = projects?.map(c => c.value)
      const coordinationIds = coordinations?.map(c => c.value)
      this.loadUsersSelected(clientIds, roleIds, projectIds, coordinationIds);
    } else {
      if (roleIds && roleIds.length > 0) {
        const usersByRole = this.state.userOptions.filter(user => roleIds.includes(user.role.idRole))
        this.setState({ 
          usersSelected: usersByRole,
          usersSelectedOptions: usersByRole
        });
      } else {
        this.setState({ 
          usersSelected: [], 
          usersSelectedOptions: [], 
          projects: [], 
          projectOptions: [],
          coordinations: [], 
          coordinationOptions: [] 
        });
      }
    }
    this.setState({ clients: value });
  };

  handleProjectsChange = (value, event) => {
    const projectIds = value?.map(c => c.value)
    const clientIds = this.state.clients?.map(c => c.value);
    const roleIds = this.state.roles?.map(c => c.value)
    const coordinationIds = this.state.coordinations?.map(c => c.value);
    this.loadUsersSelected(clientIds, roleIds, projectIds, coordinationIds);
    this.setState({ projects: value });
  }

  handleUsersSelectedChange = (value, event) => {
    if (value === undefined || value === null) {
      this.setState({ usersSelected: null });
    } else {
      let users = value
      if (this.state.users && this.state.users.length > 0){
        users = value.filter(user => !this.state.users.some(u => u.value === user.value))
      }
      this.setState({ usersSelected: users });
    }
  };

  loadUsersSelected = async (clientIds, roleIds, projectIds, coordinationIds) => {
    this.setState({ isLoading: true });

    if (clientIds && clientIds.length > 0) {  
      let users = this.state.userOptions.filter(user => 
        user.clients.some(uc => clientIds.includes(uc.idClient))
      );

      const usersSelectedOptions =
        users?.length > 0
          ? users.map(item => ({
              label: item.label,
              value: item.value,
              clients: item.clients,
              projects: item.projects,
              role: item.role
            }))
          : [];
      
      if (this.state.users && this.state.users.length > 0){
        users = usersSelectedOptions.filter(us => !this.state.users.some(u => u.value === us.value)) 
      }

      let userCoordinations = [];
      if (coordinationIds && coordinationIds.length > 0) {
        userCoordinations = users.filter(user => 
          user.projects?.some(up => coordinationIds.includes(up.projectArea.idProjectArea))
        );
      }

      let userProjects = [];
      if (projectIds && projectIds.length > 0) {
        userProjects = users.filter(user => 
          user.projects?.some(up => projectIds.includes(up.idProject))
        );
      }

      if (userCoordinations.length > 0) {
        if (userProjects.length > 0) {
          let combinedUsersSet = new Set([...userCoordinations, ...userProjects]);
          users = Array.from(combinedUsersSet);
        } else {
          users = userCoordinations
        }
      } else if (userProjects.length > 0) {
        users = userProjects
      }

      if (roleIds && roleIds.length > 0) {
        users = users.filter(user => roleIds.includes(user.role.idRole))
      }
      
      this.setState({
        usersSelectedOptions: usersSelectedOptions.sort((a, b) => a.label.localeCompare(b.label)),
        usersSelected: users.sort((a, b) => a.label.localeCompare(b.label)),
        isLoading: false
      });
    } else {
      this.setState({
        usersSelectedOptions: [],
        usersSelected: null,
        isLoading: false
      });
    }
  }

  handleCoordinationsChange = (value, event) => {
    const array1 = value;
    const array2 = this.state.coordinations;
    const set1 = new Set(array1);
    const set2 = new Set(array2);
    const difference = [...set1].filter(x => !set2.has(x));
    const uniqueElement = difference.length > 0 ? difference[0] : null;
    const coordinationIds = value?.map(c => c.value)
    const clientIds = this.state.clients?.map(c => c.value);
    const projectIds = this.state.projects?.map(c => c.value)
    const roleIds = this.state.roles?.map(c => c.value)
    this.loadUsersSelected(clientIds, roleIds, projectIds, coordinationIds);
    this.setState({ coordinations: value });
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Notificação - Admin");
    } else {
      window.setPageTitle("Cadastro de Notificação - Admin");
    }

    const {
      title,
      message,
      link,
      initialDate,
      validity,
      users,
      userOptions,
      clients,
      clientOptions,
      projects,
      projectOptions,
      coordinations,
      coordinationOptions,
      roles,
      roleOptions,
      usersSelected,
      usersSelectedOptions,
      isLoading,
      isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Notificação" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Título */}
                <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      type="text"
                      name="title"
                      label="Título (Máx. 100 Caracteres)"
                      placeholder="Digite o título"
                      value={title}
                      onChange={this.handleChange}
                      isInvalid={title && (title.length < 1 || title.length > 100)}
                      required
                    />
                </Form.Group>
                
                {/* Data de Início */}
                <Form.Group as={Col} lg="2" xs="12">
                  <InputField
                      type="date"
                      name="initialDate"
                      label="Data de Início"
                      placeholder="DD/MM/YYYY"
                      value={initialDate}
                      minDate={new Date()}
                      onChange={this.handleChange}
                      isInvalid={initialDate === ''}
                      disabled={isEditing}
                      required
                  />
                </Form.Group>
                
                {/* Vigência */}
                <Form.Group as={Col} lg="2" xs="12">
                    <InputField
                        type="text"
                        name="validity"
                        label="Vigência (em dias)"
                        placeholder="Digite os dias de vigência"
                        value={validity}
                        onChange={this.handleChange} 
                        isInvalid={validity && !(/^(\d+(\.\d+)?|\.\d+)$/.test(validity))}
                        style={{textAlign: 'right'}}
                        disabled={isEditing}
                        required
                    />
                </Form.Group>
                {/* Link */}
                <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                        type="text"
                        name="link"
                        label="Link"
                        placeholder="Digite o link"
                        value={link}
                        onChange={this.handleChange}
                    />
                </Form.Group>
              </Form.Row>
              {/* row 2 */}
              <Form.Row>
                  {/* Descrição */}
                  <Form.Group as={Col} lg="12" xs="12">
                    <InputField
                      as="textarea"
                      name="message"
                      label="Mensagem (Máx. 1000 Caracteres)"
                      placeholder="Digite a mensagem"
                      value={message}
                      onChange={this.handleChange}
                      isInvalid={message && (message.length < 1 || message.length > 1000)}
                      required
                      style={{ height: "115px", resize: "none" }}
                      errorMessage="A mensagem deve conter até no máximo 1000 caracteres."
                    />
                  </Form.Group>
                  <div style={{
                    flex: 'auto',
                    textAlign: 'right',
                    paddingRight: '10px',
                    color: message.length > 1000 ? 'red' : 'black'
                  }}>
                    <span>{message.length} Caractere(s)</span>
                  </div>
              </Form.Row>
              {/* row 3 */}
              <Form.Row>
                {/* Cliente */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    isMulti
                    as="select2"
                    name="clients"
                    label="Clientes"
                    value={clients}
                    onChange={this.handleClientsChange}
                    placeholder="Selecione os clientes"
                    options={clientOptions}
                    disabled={isEditing}
                  />
                </Form.Group>
                {/* Coordenação */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    isMulti
                    as="select2"
                    name="coordinators"
                    label="Coordenação"
                    value={coordinations}
                    onChange={this.handleCoordinationsChange}
                    placeholder="Selecione as coordenações"
                    options={coordinationOptions}
                    disabled={isEditing || !clients || clients === null || clients.length === 0}
                  />
                </Form.Group>
                {/* Projetos */}
                <Form.Group as={Col} lg="4" xs="12"> 
                  <InputField
                    isMulti
                    as="select2"
                    name="projects"
                    label="Projetos"
                    value={projects}
                    onChange={this.handleProjectsChange}
                    placeholder="Selecione os projetos"
                    options={projectOptions}
                    disabled={isEditing || !clients || clients === null || clients.length === 0}
                  />
                </Form.Group>
              </Form.Row>
              {/* row 4 */}
              <Form.Row>
                {/* Perfis */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    isMulti
                    as="select2"
                    name="roles"
                    label="Perfis"
                    value={roles}
                    onChange={this.handleRolesChange}
                    placeholder="Selecione os perfis"
                    options={roleOptions}
                    disabled={isEditing}
                  />
                </Form.Group>
                {/* Usuários */}
                <Form.Group as={Col} lg="8" xs="12">
                  <InputField
                    isMulti
                    as="select2"
                    name="users"
                    label="Usuários (Escolha livre)"
                    value={users}
                    onChange={this.handleUsersChange}
                    placeholder="Selecione os usuários"
                    options={userOptions}
                    disabled={isEditing}
                  />
                </Form.Group>
              </Form.Row>
              {/* row 5 */}
              <Form.Row>
                {/* Usuários Selecionados */}
                <Form.Group as={Col} lg="12" xs="12">
                  <InputField
                    isMulti
                    as="select2"
                    name="usersSelected"
                    label="Usuários Selecionados"
                    value={usersSelected}
                    onChange={this.handleUsersSelectedChange}
                    placeholder="Selecione os usuários"
                    options={usersSelectedOptions}
                    disabled={isEditing}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}
