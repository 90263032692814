import React from "react";
import { InputField } from "../../../../components/InputField";
import { Container } from "./style";
import { InexApiService } from "../../../../../../services/InexApiService";

export const Filters = ({ filters, handleChange }) => {
  const api = new InexApiService();

  const [projectAreaOptions, setProjectAreaOptions] = React.useState([]);
  const [coordinatorOptions, setCoordinatorOptions] = React.useState([]);

  React.useEffect(() => {
    loadProjectArea();
    loadCoordinator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProjectArea = React.useCallback(async () => {
    const projectAreas = await api.makeHttpRequest({
      url: "/ritual/project/areas"
    });

    setProjectAreaOptions(projectAreas ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCoordinator = React.useCallback(async () => {
    const coordinators = await api.makeHttpRequest({
      url: "/ritual/coordinators"
    });

    setCoordinatorOptions(coordinators ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {/* Período */}
      <InputField
        type="date"
        name="startPeriod"
        placeholder="Período"
        dateFormat="MMMM/yyyy"
        showMonthYearPicker
        isClearable
        value={filters.startPeriod}
        onChange={handleChange}
      />

      {/* Coordenação */}
      <InputField
        as="select"
        name="idCoordination"
        value={filters.idCoordination}
        onChange={handleChange}
      >
        <option value="">Coordenação</option>
        {projectAreaOptions?.length > 0 &&
          projectAreaOptions.map(p => (
            <option key={p.idProjectArea} value={p.idProjectArea}>
              {p.name}
            </option>
          ))}
      </InputField>

      {/* Coordenador de projeto */}
      <InputField
        as="select"
        name="idCoordinator"
        value={filters.idCoordinator}
        onChange={handleChange}
      >
        <option value="">Coordenador de Projeto</option>
        {coordinatorOptions?.length > 0 &&
          coordinatorOptions.map(c => (
            <option key={c.idUser} value={c.idUser}>
              {c.name}
            </option>
          ))}
      </InputField>
    </Container>
  );
};

export default Filters;
