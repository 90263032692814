import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: 460px;
    margin: auto;
  }

  .modal-body {
    padding: 24px;

    .modal-title {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #4D4C4D;

      margin-bottom: 37px;
    }

    form {
      > div {
        padding: 0;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button:first-child {
      margin-right: 24px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 145px;
  border-radius: 4px;
  border: 1px solid #4672C5;
  background: ${({ type }) => (type === "submit" ? "#4672C5" : "transparent")};

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ type }) => (type === "submit" ? "#fff" : "#4672C5")};

  box-shadow: ${({ type }) =>
    type === "submit"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;
