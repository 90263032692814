import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;

  height: 100%;

  cursor: pointer;

  .image {
    width: 384px;
    height: 230px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    .category {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #4672C5;
      margin: 16px 0 8px;

      text-transform: uppercase;
    }

    .title {
      display: -webkit-box;
      height: 100%;

      font-family: "Cera Pro Medium", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: #4d4c4d;

      max-height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      margin-bottom: 8px;
    }

    .date {
      display: flex;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        stroke: #807e80;
        stroke-width: 1.5px;
        margin-right: 9px;
      }

      span {
        font-family: "Cera Pro Light", sans-serif;
        font-weight: 300;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        color: #807e80;
      }
    }
  }
`;
