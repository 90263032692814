import { Card, Form } from "react-bootstrap";
import styled from "styled-components";

export const TimerCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 24px;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    width: 224px;
    height: 200px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const StyledForm = styled(Form)`
  flex: 1;

  @media screen and (max-width: 768px) {
    .row-3 {
      display: flex;
      gap: 24px;
      align-items: center;

      flex-direction: column;
    }

    .form-row:last-child {
      justify-content: center !important;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    .card-title {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      color: #4d4c4d;
      margin-bottom: 42px;

      strong {
        font-family: "Cera Pro Medium", sans-serif;
        font-weight: 700;
        color: #4672C5;
        text-transform: capitalize;
      }
    }

    @media screen and (max-width: 768px) {
      .card-title {
        margin-bottom: 0px;
      }

      flex-direction: column;
      margin-bottom: 14px;
    }
  }

  .radio-toggle {
    display: flex;
    height: 30px;

    span {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 18px;

      font-family: "Cera Pro Thin", sans-serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 150%;

      color: #807e80;
      cursor: pointer;

      margin-right: 25px;

      &:last-child {
        margin-right: 0px;
      }

      svg {
        stroke: #807e80;
      }

      &.active {
        color: #4672C5;

        svg {
          stroke: #4672C5;
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-between;
      height: 60px;

      span {
        margin-right: 8px;
        justify-content: space-between;
      }
    }
  }

  & .form-row {
    .form-group {
      margin-bottom: 37px;

      @media screen and (max-width: 480px) {
        margin-bottom: 24px;
      }
    }

    &:nth-child(3) {
      .form-group textarea {
        height: 48px;
        line-height: 150%;
      }
    }

    &:last-child {
      justify-content: space-between;
    }
  }

  .period {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 100%;
    color: #4672C5;
    margin-top:10px;
  }
`;

export const TimerControls = styled.div`
  display: flex;
  justify-content: space-between;

  .manual {
    display: flex;

    & > div:first-child {
      margin: 0;
      margin-left: auto;
    }

    @media screen and (max-width: 768px) {
      & > div:first-child {
        margin: 0;
      }
    }
  }

  .compound {
    display: flex;
  }

  .play-add-btn {
    display: flex;
    gap: 24px;
    margin-left: 1.2vw;
    align-items: center;

    span {
      font-family: "Cera Pro Medium", sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: #4672C5;
      text-transform: uppercase;
      width: fit-content;
    }

    button {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 154px;
      height: 48px;
      padding: 0;
      gap: 14px;

      border: none;
      background: #4672C5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      color: white;
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;

      svg {
        fill: #fff;
        cursor: pointer;
      }

      &.add-btn {
        svg {
          fill: none;
          stroke: #fff;
        }
      }

      &:disabled {
        background: #807e80;
        cursor: not-allowed;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .play-add-btn {
      margin-left: 23px;
    }
  }

  @media screen and (max-width: 768px) {
    .play-add-btn {
      margin: 0;
      gap: 0;
    }

    .manual,
    .compound {
      gap: 12px;
    }

    .manual,
    .compound {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .manual {
      margin: 0;

      > div {
        margin: 0;
      }
    }
  }
`;

export const TimerAttachment = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    & > div:first-child {
      margin: 0px;
    }
  }

  & > div:first-child {
    margin-right: 18px;
  }

  .checkbox-input {
    img {
      width: 19px;
      height: 19px;
    }

    .MuiFormControlLabel-label {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .file-input {
    display: flex;
    align-items: center;

    input[type="file"] {
      display: none;
    }

    .label {
      display: flex;
      align-items: center;
      color: #4672C5;
      cursor: pointer;

      .icon {
        margin-right: 8px;
      }

      .text {
        font-family: "Cera Pro Regular", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        max-width: 15ch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        :hover {
          text-decoration: underline;
        }
      }
    }

    .remove-button {
      display: flex;
      align-items: center;
      margin-bottom: -4px;
      margin-left: 5px;
    }
  }
`;

export const FilterInfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-block: 24px;

  .period {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 900;
    font-size: 14px;
    line-height: 150%;
    color: #4672C5;
  }

  .filter-info {
    display: flex;
    align-items: center;

    .divider {
      width: 32px;
      height: 0.5px;
      background: #807e80;
      transform: rotate(90deg);
    }

    .cell {
      display: flex;
      gap: 16px;

      span {
        font-family: "Cera Pro Thin", sans-serif;
        font-weight: 900;
        font-size: 14px;
        color: #807e80;

        &.time {
          font-family: "Cera Pro Medium", sans-serif;
          font-weight: 600;
        }
      }

      svg {
        color: #4672C5;
        &:hover {
          cursor: pointer
        }
      }
    }
  }
`;

export const SimplifiedRow = styled.span`
  

  .period {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 900;
    font-size: 14px;
    line-height: 150%;
    color: #4672C5;
  }


`;

export const IconContainer = styled.div`
  padding: 5px;
  cursor: pointer;
  svg {
    fill: #4672C5;
  }
`;

export const WarningMsg = styled.span`
  position: absolute;
  color: #d93438;

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 400;
`;
