import React from "react";
import DialogActions from "@material-ui/core/DialogActions/";
import { InexApiService } from "../../services/InexApiService";
import DialogTitle from "@material-ui/core/DialogTitle/";
import { Form, Modal, Col } from "react-bootstrap";
import {
  Button,
  StyledDialog,
  DialogContentText
} from "./components/Dialog/style";
import { InputField } from "../../pages/admin/components/InputField";
import formatDocument from "../../utils/FormatDocument";
import { formatCPF } from "../../utils/validators/IsValidCpfCnpj";
import formatTel from "../../utils/FormatTel";
import { MdOutlineContentCopy } from "react-icons/md";
import Swal from "sweetalert2";
import { Container } from "./components/Toast/styles";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

export default function ConfirmDeleteDialog({ open, closeDialog, title, obj }) {
  const api = new InexApiService();
  const toast = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [RFQ, setRFQ] = React.useState([]);

  const loadRFQS = async () => {
    try {
      const RFQS = await api.makeHttpRequest({
        url: "/professional/all-rfq"
      });

      const selectedRFQ =
        RFQS.length > 0
          ? RFQS.filter(item => item.idBusinessProposalValue === obj.idRFQ)
          : [];
      setRFQ(selectedRFQ.map(name => name.profileDescription));
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        "Ocorreu um erro ao carregar a lista de RFQ, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  async function Toast() {
    return toast.fire({
      html: (
        <Container>
          <span className="swal-icon">
            <MdOutlineContentCopy color="#fff" size="20px" />
          </span>
          <span className="swal-text">
            Copiado para a área de transferência
          </span>
        </Container>
      ),
      position: "top-right",
      timer: 8000,
      timerProgressBar: true,
      toast: true,
      showConfirmButton: false,
      background: "#4672C5"
    });
  }

  function ReplaceAllocation(value) {
    return value?.length > 0
      ? JSON.stringify(value.map(c => c.name))
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll('"', "")
          .replaceAll(",", ", ")
      : [];
  }

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      loadRFQS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <StyledDialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      width="1040px"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      {loading ? (
        <DialogContentText id="alert-dialog-text">
          Carregando...
        </DialogContentText>
      ) : (
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="profile"
                label="Perfil"
                value={obj.role.type ?? ""}
                placeholder=""
                endAdornment={
                  obj.role.type && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.role.type);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="regimeContratacao"
                label="Regime de Contratação"
                value={obj.contractTypeName ?? ""}
                placeholder=""
                endAdornment={
                  obj.contractTypeName && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.contractTypeName);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="matriculaInex"
                label="Matricula Inex"
                placeholder=""
                value={obj.inexEnrollment ?? ""}
                endAdornment={
                  <div
                    title="Copiar"
                    onClick={() => {
                      navigator.clipboard.writeText(obj.inexEnrollment);
                      Toast();
                    }}
                  >
                    <MdOutlineContentCopy color="#4672C5" size="20px" />
                  </div>
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="matriculaCliente"
                label="Matricula Cliente"
                placeholder=""
                value={obj.clientEnrollment ?? ""}
                endAdornment={
                  obj.clientEnrollment && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.clientEnrollment);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="gerente"
                label="Gerente Comercial"
                placeholder=""
                value={obj.commercial.name ?? ""}
                endAdornment={
                  obj.commercial.name && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.commercial.name);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="propostaTecnica"
                label="Proposta Técnica"
                placeholder=""
                value={obj.technicalProposal ?? ""}
                endAdornment={
                  obj.technicalProposal && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.technicalProposal);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="alocacoes"
                label="Alocações Clientes"
                placeholder=""
                value={obj.clients ? ReplaceAllocation(obj.clients) : ""}
                endAdornment={
                  obj.clients && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          ReplaceAllocation(obj.clients)
                        );
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="RFQ"
                label="RFQ"
                value={open ? RFQ : ""}
                endAdornment={
                  open && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(RFQ);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="nome"
                label="Nome"
                value={obj.name ?? ""}
                placeholder=""
                endAdornment={
                  obj.name && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.name);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="dataNascimento"
                label="Nascimento"
                value={
                  obj.birthdate
                    ? moment(obj.birthdate).format("DD/MM/YYYY")
                    : ""
                }
                placeholder=""
                endAdornment={
                  obj.birthdate && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          moment(obj.birthdate).format("DD/MM/YYYY")
                        );
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="CPF"
                label="CPF"
                value={obj.cpf ? formatCPF(obj.cpf) : ""}
                maxLength={14}
                placeholder=""
                endAdornment={
                  obj.cpf && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(formatCPF(obj.cpf));
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="RG"
                label="RG"
                value={obj.rg ? formatDocument(obj.rg) : ""}
                placeholder=""
                endAdornment={
                  obj.rg && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(formatDocument(obj.rg));
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="tel"
                name="celular"
                label="Celular"
                value={obj.phone ? formatTel(obj.phone) : ""}
                placeholder=""
                endAdornment={
                  obj.phone && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(formatTel(obj.phone));
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="dataAdmissao"
                label="Admissão"
                value={
                  obj.admissionDate
                    ? moment(obj.admissionDate).format("DD/MM/YYYY")
                    : ""
                }
                placeholder=""
                endAdornment={
                  obj.admissionDate && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          moment(obj.admissionDate).format("DD/MM/YYYY")
                        );
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="terminationDate"
                label="Rescisão"
                terminationDate
                value={
                  obj.terminationDate
                    ? moment(obj.terminationDate).format("DD/MM/YYYY")
                    : ""
                }
                placeholder=""
                endAdornment={
                  obj.terminationDate && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          moment(obj.terminationDate).format("DD/MM/YYYY")
                        );
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            {/* delete salary field from quick view screen
              asked by - Business Area
              <Form.Group as={Col} lg="4" xs="12">
                <InputField
                  type="text"
                  name="salarioFixo"
                  label="Salario Fixo"
                  value={obj.wage ?? 0}
                  placeholder=""
                  endAdornment={obj.wage && (
                    <div
                      title="Copiar"
                      onClick={() =>  {
                        navigator.clipboard.writeText(obj.wage);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )}
                />
              </Form.Group> */}
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="email"
                name="inexEmail"
                label="E-mail Inex (Login)"
                value={obj.inexEmail ?? ""}
                placeholder=""
                endAdornment={
                  obj.inexEmail && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.inexEmail);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>

            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="email"
                name="personalEmail"
                label="E-mail Pessoal"
                value={obj.personalEmail ?? ""}
                placeholder=""
                endAdornment={
                  obj.personalEmail && (
                    <div
                      title="Copiar"
                      onClick={() => {
                        navigator.clipboard.writeText(obj.personalEmail);
                        Toast();
                      }}
                    >
                      <MdOutlineContentCopy color="#4672C5" size="20px" />
                    </div>
                  )
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="textarea"
                name="historico"
                placeholder=""
                value={obj.history ?? ""}
                style={{ height: "110px", marginBottom: "-5px" }}
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
      )}

      <DialogActions>
        <Button onClick={closeDialog}>Fechar</Button>
      </DialogActions>
    </StyledDialog>
  );
}
