import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { DateTimeIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialState = {
  holidays: [],
  clientList: [],
  reload: false,
  loading: false
};

export default class HolidayListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.formatRow = this.formatRow.bind(this);
    this.setHolidays = this.setHolidays.bind(this);    
  }

  setHolidays = holidays => {
    this.setState({ holidays });
  };

  formatRow = r => {
    let crudActions = ["edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION);

    if (isAdmin || isOperation) {
      crudActions = [...crudActions];
    }

    return (
        <StyledTableRow hover tabIndex={-1} key={r.idClosingDate}>
            {(isAdmin) && <StyledTableCell scope="row" align="center" width="5  %">
                <CrudActions
                    actions={crudActions}
                    module="feriados"
                    onConfirmChangeStatus={this.handleConfirmChangeStatus}
                    path={`/admin/clientes/logs/${r.idClient}`}
                    id={`${r.client.idClient}-${r.year}`}
                    obj={r}
                />
                </StyledTableCell>
            }
            <StyledTableCell scope="row" align="left" width="60%">
                {r.client.name && r.client.name}
            </StyledTableCell>
            <StyledTableCell scope="row" align="center">
                {!!r.year && r.year}
            </StyledTableCell>
        </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Feriados - Admin");

    let headRows = [
      { column: "Client.Name", label: "Nome do Cliente", width: "29%" },
      { column: "Year", label: "Ano", align: "center" }
    ];

    if (
      (this?.auth?.user && this?.auth?.user.role.type === ERoles.ADMIN)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.OPERATION)
    ) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }
    
    let showAdminButtons = []
    if(this.auth?.user?.role?.type === ERoles.ADMIN || this.auth?.user?.role?.type === ERoles.OPERATION) {
      showAdminButtons = [ 
        {
          label: "Adicionar",
          onClick: null,
          icone: "add",
          path: "/admin/feriados/adicionar"
        } 
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Feriados"
          icon={DateTimeIcon}
          endpoint="/holiday/paged"
          className="h-100"
          headRows={headRows}
          clientList={this.state.clientList}
          formatRow={this.formatRow}
          rows={this.state.holidays}
          setRows={this.setHolidays}
          reload={this.state.reload}
          placeholderSearch="Busque por nome do cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
