import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { Button, StyledModal } from "./styles";

export const CoordinationModal = ({ open, close, value, handleConfirm, coordinators }) => {
  const [payingArea, setPayingArea] = React.useState(value);
  const [coordination, setCoordination] = React.useState(value?.projectArea?.idProjectArea);
  const [coordinationOptions, setCoordinationOptions] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setPayingArea(value);
      setCoordination(value?.projectArea?.idProjectArea);
    }
  }, [open, value]);

  React.useEffect(() => {
    if (open) {
      setCoordinationOptions(coordinators);
    }
  }, [open, coordinators]);

  const handleChange = e => {
    setPayingArea({ ...payingArea, [e.target.name]: e.target.value });
  };

  function formIsValid() {
    const validFirst =
      !!payingArea.name &&
      !!coordination
    return validFirst;
  };

  return (
    <>
      <StyledModal show={open} onHide={() => close()} size="lg" centered>
        <Modal.Body>
          <h1 className="modal-title">
            Preencha o campo abaixo para {!value ? "adicionar" : "editar"} uma
            {!value ? "nova" : ""} Área pagadora
          </h1>
          <Form>
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                type="text"
                name="name"
                label="Nome da Área Pagadora"
                value={payingArea.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="select"
                name="coordination"
                label="Coordenação"
                value={coordination}
                onChange={(e) => setCoordination(e.target.value)}
                required
              >
                <option value="">Selecione a coordenação</option>
                {coordinationOptions?.length > 0 &&
                  coordinationOptions.map(p => (
                    <option key={p.idProjectArea} value={p.idProjectArea}>
                      {p.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>
            <div className="btn-container">
              <Button type="button" onClick={() => close()}>
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={!formIsValid()}
                onClick={e => {
                  e.preventDefault();

                  if (payingArea.name) {
                    handleConfirm(payingArea, coordination);
                  }

                  close();
                }}
              >
                CONFIRMAR
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
