import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { Button, StyledModal } from "./styles";

export const CoordinationModal = ({ open, close, value, handleConfirm, clients }) => {
  const [coordination, setCoordination] = React.useState(value);
  const [client, setClient] = React.useState(value && "");
  const [clientOptions, setClientOptions] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setCoordination(value);
      setClient(value)
    }
  }, [open, value]);

  React.useEffect(() => {
    if (open) {
      setClientOptions(clients);
    }
  }, [open, clients]);

  const handleChange = e => {
    setCoordination({ ...coordination, [e.target.name]: e.target.value });
    setClient({...coordination, [e.target.name]: { idClient: Number(e.target.value) }});
  };

  return (
    <>
      <StyledModal show={open} onHide={() => close()} size="lg" centered>
        <Modal.Body>
          <h1 className="modal-title">
            Preencha o campo abaixo para {!value ? "adicionar" : "editar"} uma
            nova Coordenação
          </h1>
          <Form>
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                type="text"
                name="name"
                label="Nome da Coordenação"
                value={coordination.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="select"
                name="client"
                label="Cliente"
                value={client?.client?.idClient}
                onChange={handleChange}
                disabled={value?.client?.idClient > 0}
                required
              >
                <option value="">Selecione o cliente</option>
                {clientOptions?.length > 0 &&
                  clientOptions.map(p => (
                    <option key={p.idClient} value={p.idClient}>
                      {p.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>
            <div className="btn-container">
              <Button type="button" onClick={() => close()}>
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={!coordination.name && !client}
                onClick={e => {
                  e.preventDefault();

                  if (coordination.name) {
                    handleConfirm(coordination);
                  }

                  close();
                }}
              >
                CONFIRMAR
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
