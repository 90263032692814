import React, { useEffect, useState } from "react";
import { InputField } from "../../../../components/InputField";
import { makeStyles } from "@material-ui/core/styles/";
import { Container } from "./style";
import { DateFilter } from "../../../../components/DateFilter";
import moment from "moment";
import { ButtonFilter as Button } from "../Button/style";
import { FiFilter } from "react-icons/fi";
import { debounce } from "lodash";
import { getPortoDefaultDate } from "../../../../../../utils/GetPortoDefaultDate";
import { useSelector } from "react-redux";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import Swal from "sweetalert2";

const initialFilterDates = getPortoDefaultDate(22);

const useStyles = makeStyles(() => ({
  button: {
    margin: "3px -10px",
    "& svg": {
      fill: "#807e80"
    },
    "&:hover": {
      "& svg": {
        fill: "#4672C5"
      }
    }
  },
  div: {
    display: "flex",
    flexDirection: "row"
  }
}));

export const Filters = ({ reload, onSubmit, setReload, filterOptions, setFilterOptions, loading, setLoading }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const isCoordinator = user?.role?.type === ERoles.OPERATION;
  const api = new InexApiService();
  const [periodOpt, setPeriodOpt] = useState("");
  // const [arrClientFiltered, setArrClientFiltered] = useState([]);
  const [clientProps, setClientProps] = React.useState({
    initialCycleDay: 22
  });
  const [clientOptions, setClientOptions] = useState(filterOptions.clients ?? [])
  const [coordinationOptions, setCoordinationOptions] = useState(filterOptions.coordinations ?? []);
  const [professionalOptions, setProfessionalOptions] = useState(filterOptions.professionals ?? []);

  const [filters, setFilters] = useState({
    startPeriod: "",
    endPeriod: "",
    client: user.clientDefault ? user.clientDefault.idClient : "",
    coordination: "",
    coordinator: isCoordinator ? `${user.idUser}` : "",
    employee: ""
  });

  const handleChange = async (e, period = null) => {
    if (period) {
      setFilters({ ...filters, ...period });
      return;
    }
    
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (filters.client) {
      loadClientProps(filters.client);
      loadCoordinationByIdClient(filters.client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  React.useEffect(() => {
    if (filterOptions.clients?.length > 0) {
      setClientOptions(filterOptions.clients)
      if (!user.clientDefault) {
        setFilters({
          ...filters,
          client: filterOptions.clients[0].idClient
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions.clients]);

  const loadCoordinationByIdClient = React.useCallback(async (idClient = "") => {
    try {
      setLoading(true);
      const coordination = await api.makeHttpRequest({
        url: `/approvalreport/coordinations-by-user/client/${idClient}`
      });
      setCoordinationOptions(coordination.coordinations ?? []);
      setProfessionalOptions(coordination.professionals ?? []);
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao carregar as coordenações, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const loadClientProps = async (idClient) => {
    const client = await api.makeHttpRequest({
      url: `/client/${idClient}`
    });
    if (client) {
      setClientProps(client);
      const periodCycle = getPortoDefaultDate(client.initialCycleDay);
      handlePeriodFilter({
        startPeriod: periodCycle.startDate,
        endPeriod: periodCycle.endDate
      });
    }
  }

  useEffect(() => {
    if (user.clientDefault) {
      const initialDate = getPortoDefaultDate(user.clientDefault.initialCycleDay);
      filters.startPeriod = moment(initialDate.startDate)
                                .toDate();
      filters.endPeriod = moment(initialDate.endDate)
                                .toDate();
      onSubmit(filters);
    }
  }, []);

  const debounceOnClick = debounce(() => {
    filters.startPeriod = moment(filters.startPeriod)
      .toDate();
    filters.endPeriod = moment(filters.endPeriod)
      .toDate();
    onSubmit(filters);
  }, 400);

  useEffect(() => {
    if (reload) {
      onSubmit(filters);
      setReload(false);
    }
  }, [reload]);

  const changeDateToClient = async () => {
    const client = await api.makeHttpRequest({
      url: `/client/${filters.client}`
    });
    if (client) {
      const dateFilter = getPortoDefaultDate(client.initialCycleDay);
      handlePeriodFilter({startPeriod: dateFilter.startDate, endPeriod: dateFilter.endDate});
    }
  }

  useEffect(() => {
    if (filters.client) {
      changeDateToClient();
    }
  }, [filters.client]);

  const handlePeriodFilter = filter => {
    const option = {
      label: `${moment(filter.startPeriod)
        .format("DD/MM/YYYY")} - ${moment(filter.endPeriod).format(
        "DD/MM/YYYY"
      )}`,
      value: 1
    };

    const period = {
      startPeriod: filter.startPeriod,
      endPeriod: filter.endPeriod
    }

    setPeriodOpt(option);
    handleChange(null, period);
  };

  // const handleGetArrClientSelected = filter => {
  //   if(filter > 0) {
  //     const filterProjectsWhereIdSelected = filterOptions?.projects.length > 0
  //       ? filterOptions?.projects?.find(p => p.client.idClient === +filter)
  //       : null;

  //     setArrClientFiltered(filterProjectsWhereIdSelected
  //       ? [filterProjectsWhereIdSelected]
  //       : []
  //     );
  //   }
  // }

  const PeriodFilter = () => {
    return (
      <div style={{width: '230px'}}>
        <InputField
          select2ClassName="disabled-normal"
          as="select2"
          name="periodOpt"
          value={filters.client ? periodOpt : null}
          placeholder="Período"
          disabled
        />
      </div>
    );
  };

  return (
    <div>
      <Container>
        {/* Cliente */}
        <InputField
          as="select"
          name="client"
          value={filters.client}
          onChange={handleChange}
        >
          <option value="">Cliente</option>
          {clientOptions.length > 0 &&
            clientOptions.map(c => (
              <option key={c.idClient} value={c.idClient}>
                {c.name}
              </option>
            ))}
        </InputField>

        {/* Período */}
        <DateFilter
          component={PeriodFilter}
          reload={reload}
          setFilter={handlePeriodFilter}
          initialValues={initialFilterDates}
          initialCycle={clientProps.initialCycleDay}
          newValues={{
            startDate: filters.startPeriod,
            endDate: filters.endPeriod
          }}
        />

        {/* Coordenação */}
        <InputField
          as="select"
          name="coordination"
          value={filters.coordination}
          onChange={handleChange}
        >
          <option value="">Coordenação</option>
          {        
            coordinationOptions?.length > 0 &&
              coordinationOptions.map(p => (
                <option key={p.idProjectArea} value={p.idProjectArea}>
                  {p.name}
                </option>
              )
            )
          }
        </InputField>

        {/* Coordenador de projeto */}
        <InputField
          as="select"
          name="coordinator"
          value={filters.coordinator}
          onChange={handleChange}
        >
          <option value="">Coordenador de Projeto</option>
          {filterOptions?.coordinators?.length > 0 &&
            filterOptions.coordinators.map(c => (
              <option key={c.idUser} value={c.idUser}>
                {c.name}
              </option>
            ))}
        </InputField>

        {/* Profissional */}
        <InputField
          as="select"
          name="employee"
          value={filters.employee}
          onChange={handleChange}
        >
          <option value="">Profissional</option>
          {professionalOptions?.length > 0 &&
            professionalOptions.map(c => (
              <option key={c.idUser} value={c.idUser}>
                {c.name}
              </option>
            ))}
        </InputField>

        <Button type="button" variant="filled" onClick={() => debounceOnClick()}>
          <span className="icon">
            <FiFilter size={22} />
          </span>{" "}
          <span>filtrar</span>
        </Button>
      </Container>
    </div>
    
  );
};

export default Filters;
