import React from "react";
import { Container } from "./styles";

export const BenefitCard = ({ image, title, description }) => {
  return (
    <Container>
      <div className="image-wrapper">
        <img src={image} alt={title} />
      </div>
      <div className="text-wrapper">
        <h2 className="title">{title}</h2>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Container>
  );
};
