import styled from "styled-components";

export const Container = styled.div`
display: flex;
gap: 20px;

  & * {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #4d4d4d;

    border: none;

    .datetime-popper {
      z-index: 99;
      padding-bottom: 8px;

      & > div .react-datepicker {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__time-list-item--selected {
        background: #4672C5 !important;
        color: #fff;

        &:hover {
          background: #4672C5cc;
        }
      }
    }
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    height: 36px;

    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #807e80;
    cursor: pointer;

    border: 0.5px solid #4d4c4d77;
    border-radius: 4px;
    outline: none;
    text-align: center;
    background: transparent;
  }

  .time {
    display: flex;
    position: relative;
    width: max-content;

    label {
      position: absolute;
      top: -8px;
      left: 8px;
      padding: 0 4px;
      background: #fff;
      color: #4d4c4d;
      font-family: "Cera Pro Regular",sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 100%;

      z-index: 10;
    }
  }


  .reportTemplateContainer {
    display: flex;
    justify-content: flex-start; 
    position: relative;
    width:100%;

    input {
      height: 30px;
    }
  }
  .clientDefaultContainer {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width:100%;

    input {
      height: 30px;
    }
  }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          width: 58px;
          caret-color: transparent;

          @media screen and (min-width: 669px) {
            width: ${({ inputWidth }) => inputWidth + "px" ?? "58px"};
          }
        }
      }
    }
  }
`


export const ContainerSelect = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  gap: 20px;
`