import { Checkbox } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import { RiSearchLine } from "react-icons/ri";
import iconChecked from "../../../../../../assets/icons/icon-check-fill.svg";
import iconNotChecked from "../../../../../../assets/icons/icon-check-stroke.svg";
import { InputField } from "../../../../components/InputField";
import { StyledModal } from "./styles";

const UsersModal = ({ setUsersSelected, professionals }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const [searchbar, setSearchbar] = React.useState("");

  const [userOpt, setUserOpt] = React.useState("");
  const [selectedEmployees, setSelectedEmployees] = React.useState([]);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [filteredEmployeeList, setFilteredEmployeeList] = React.useState([]);

  React.useEffect(() => {
    if (professionals) {
      setUserOpt("");
      setSelectedEmployees([]);

      setEmployeeList(professionals ?? []);
      setFilteredEmployeeList(professionals ?? []);
    }
  }, [professionals])

  const handleCheck = user => {
    const arr = [...selectedEmployees];
    const index = arr.indexOf(arr.find(x => x.idUser === user.idUser));

    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(user);
    }

    setUserOpt(
      arr?.length > 0
        ? arr.length === 1
          ? {
            value: `${arr.length} profissional selecionado`,
            label: `${arr.length} profissional selecionado`
          }
          : {
            value: `${arr.length} profissionais selecionados`,
            label: `${arr.length} profissionais selecionados`
          }
        : ""
    );
    setSelectedEmployees(arr);
    setUsersSelected(arr?.map(x => x.idUser) || []);
  };

  const handleSearchbar = value => {

    if (value?.length >= 3) {
      setFilteredEmployeeList(
        employeeList?.filter(
          x =>
            x.name.toUpperCase().includes(value.trim().toUpperCase()) ||
            String(x.inexEnrollment)
              .toUpperCase()
              .includes(value.trim().toUpperCase())
        )
      );
    } else {
      setFilteredEmployeeList(employeeList);
    }

    setSearchbar(value);
  };

  return (
    <>
      <div onClick={() => setModalOpen(true)}>
        <InputField
          select2ClassName="disabled-normal"
          as="select2"
          value={userOpt}
          placeholder="Selecione os Profissionais"
          className={(employeeList.length === 0) ? "fade" : null }
          disabled
        />
      </div>

      <StyledModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        centered
        scrollable
      >
        <Modal.Body>
          <div className="searchbar">
            <input
              type="text"
              value={searchbar}
              onChange={e => handleSearchbar(e.target.value)}
              placeholder="Buscar profissional"
            />
            <span className="icon-search">
              <RiSearchLine size="24px" color="#4672c5" />
            </span>
          </div>
          <div className="user-list">
            {filteredEmployeeList?.length > 0 ? (
              filteredEmployeeList.map(emp => (
                <div className="user-line" key={emp.idUser}>
                  <Checkbox
                    icon={<img src={iconNotChecked} alt="Não selecionado" />}
                    checkedIcon={<img src={iconChecked} alt="Selecionado" />}
                    onChange={() => handleCheck(emp)}
                    checked={
                      selectedEmployees.find(x => x.idUser === emp.idUser) !==
                      undefined
                    }
                  />
                  <span>{emp.name + " - " + emp.inexEnrollment}</span>
                </div>
              ))
            ) : (
              <span className="centered">Nenhum profissional encontrado</span>
            )}
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default UsersModal;
