import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { ProjectIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialState = {
  projectTypes: [],
  reload: false,
  loading: false
};

export default class ProjectTypeListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setProjectTypes = this.setProjectTypes.bind(this);    
  }
  
  handleConfirmChangeStatus = async function (obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/projecttype/toggle/${obj.idProjectType}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        e?.response?.data ? "warning" : "error"
      )
    }
  };

  setProjectTypes = projectTypes => {
    this.setState({ projectTypes });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION);

    if (isAdmin || isOperation) {
      crudActions = [...crudActions];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idProjectType}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="tipos-de-projeto"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/tipos-de-projeto/logs/${r.idProjectType}`}
            id={r.idProjectType}
            obj={r}
          />
        </StyledTableCell>
        }
        <StyledTableCell scope="row" align="center">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.description && r.description}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.filename && r.filename}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Tipos de Projeto - Admin");

    let headRows = [
      { column: "Name", label: "Nome", align: "center" },
      { column: "Description", label: "Descrição", align: "left" },
      { column: "Filename", label: "Nome de Arquivo do Relatório", align: "left" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];

    if (
      (this?.auth?.user && this?.auth?.user.role.type === ERoles.ADMIN)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.OPERATION)
    ) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }

    let showAdminButtons = []

    if (this.auth?.user?.role?.type === ERoles.ADMIN || this.auth?.user?.role?.type === ERoles.OPERATION) {
        showAdminButtons = [ 
            {
            label: "Adicionar",
            onClick: null,
            icone: "add",
            path: "/admin/tipos-de-projeto/adicionar"
            } 
        ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Tipos de Projeto"
          icon={ProjectIcon}
          endpoint="/projecttype/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.projectTypes}
          setRows={this.setProjectTypes}
          reload={this.state.reload}
          placeholderSearch="Busque por nome"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
