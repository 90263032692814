import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { ProjectIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";
import { BiFileBlank } from "react-icons/bi";
import { ReportSelectModal } from "./component/ReportSelectModal";

const initialState = {
  projects: [],
  reload: false,
  loading: false,
  professionalList: [],
  reportSelectModal: false
};

export default class ProjectListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setProjects = this.setProjects.bind(this);
  }

  componentDidMount() {
    this.loadProfessional();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  handleConfirmChangeStatus = async function (obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/project/toggle/${obj.idProject}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        e?.response?.data ? "warning" : "error"
      )
    }
  };

  loadProfessional = async () => {
    let professionalListLocal = [{value : "" , label : "TODOS" }];
    const professionalListfull = await this.api.makeHttpRequest({
      url: "/professional/getAllActiveProfessionals"
    });

    for (let i in professionalListfull){
      professionalListLocal.push({  value: professionalListfull[i].idUser, label: professionalListfull[i].name })
    }

    this.setState({professionalList: professionalListLocal});
  };

  setProjects = projects => {
    this.setState({ projects });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION);

    if (isAdmin) {
      crudActions = [...crudActions, "individual-logs"];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idProject}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="projetos"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/projetos/logs/${r.idProject}`}
            id={r.idProject}
            obj={r}
          />
        </StyledTableCell>
        }
        <StyledTableCell scope="row" align="center">
          {!!r.projectArea && r.projectArea.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.projectType.name && r.projectType.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.client && r.client.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.coordinator && r.coordinator.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.clientProjectManager && r.clientProjectManager}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.professionals?.length > 0 &&
              r.professionals.map(p => <div key={p.idUser}>{p.name}</div>)}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Projetos - Admin");

    let headRows = [
      {
        column: "ProjectArea.Name",
        label: "Coordenação",
        align: "center"
      },
      { column: "Name", label: "Área / Squad", align: "center" },
      { column: "ProjectType.Name", label: "Tipo de Projeto", align: "center" },
      { column: "Client.Name", label: "Cliente", align: "center" },
      {
        column: "Coordinator.Name",
        label: "Coordenador de Operação",
        align: "center"
      },
      {
        column: "ClientProjectManager",
        label: "Gerente do Projeto (SM)",
        align: "center"
      },
      { label: "Profissionais", align: "center" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];

    if (
      (this?.auth?.user && this?.auth?.user.role.type === ERoles.ADMIN)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.OPERATION)
    ) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }

    let showAdminButtons = [
      {
        label: "Adicionar",
        onClick: null,
        icone: "add",
        path: "/admin/projetos/adicionar"
      },
      {
        label: "Relatório",
        onClick: () => this.setState({ reportSelectModal: true }),
        RIcone: () => <BiFileBlank size={22} />,
        path: ""
      }
    ]

    if(this.auth?.user?.role?.type === ERoles.ADMIN) {
      showAdminButtons = [ ...showAdminButtons,
        {
          label: "Logs",
          onClick: null,
          icone: "visibility",
          path: "/admin/projetos/logs"
        }
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <ReportSelectModal
          open={this.state.reportSelectModal}
          setOpen={bool => this.setState({ reportSelectModal: bool })}
        />
        <Datatable
          title="Projetos / Squads"
          icon={ProjectIcon}
          endpoint="/project/paged"
          className="h-100"
          headRows={headRows}
          professionalList = {this.state.professionalList}
          formatRow={this.formatRow}
          rows={this.state.projects}
          setRows={this.setProjects}
          reload={this.state.reload}
          placeholderSearch="Busque por nome, área/squad ou cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
