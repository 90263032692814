/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import AdminPage from "../pages/admin/AdminPage";
import HomePage from "../pages/home/Home";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import RecoverOrRegisterPassword from "../pages/auth/RecoverOrRegisterPassword";
import PrivacyPolicyPage from "../pages/policy/PrivacyPolicyPage";
import { News } from "../pages/home/components/News";
import Benefits from "../pages/home/components/Benefits";
import ComplaintsHub from "../pages/home/components/ComplaintsHub";
import InvoiceCombinationSearch from "../pages/home/components/InvoiceCombinationSearch";
import { NewsPage } from "../pages/home/components/NewsPage";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/auth" component={AuthPage} />
        <Route path="/auth/login" component={AuthPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />

        <Route path="/ativar-conta" component={RecoverOrRegisterPassword} />
        <Route path="/recuperar-senha" component={RecoverOrRegisterPassword} />
        <Route path="/cadastrar-senha" component={RecoverOrRegisterPassword} />

        <Route exact path="/" component={() => (
            <HomePage>
              <News />
            </HomePage>
          )}
        />

        <Route exact path="/news" component={() => (
            <HomePage>
              <News />
            </HomePage>
          )}
        />
        
        <Route path="/news/:slug" component={e => (
            <HomePage>
              <NewsPage route={e} />
            </HomePage>
          )}
        />

        <Route exact path="/beneficios" component={() => (
            <HomePage>
              <Benefits />
            </HomePage>
          )}
        />

        <Route exact path="/denuncia" component={() => (
            <HomePage>
              <ComplaintsHub />
            </HomePage>
          )}
        />

        <Route exact path="/busca-por-combinacoes-de-notas" component={() => (
            <HomePage>
              <InvoiceCombinationSearch />
            </HomePage>
          )}
        />

        {isAuthorized ? (
          <Route path="/admin">
            <Layout>
              <AdminPage userLastLocation={userLastLocation} />
            </Layout>
          </Route>
        ) : (
          <Redirect from="/admin" to="/auth/login" />
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
