import React from "react";
import OrgChart from "@unicef/react-org-chart";
import avatarPersonnel from "../../../../assets/images/avatar-personnel.svg";
import { StyledCardBody } from "../../components/AdminCard/style";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { ContainerOrgChart, StyledOrgCard } from "./styles";
import Filters from "./components/Filters";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import { OrgChartIcon } from "../../../../components/IconsSVG";

const initialValues = {
  filters: {
    idClient: "",
    idCoordination: ""
  }
};

export default class OrgChatPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialValues,
      tree: null,
      config: {},
      isLoading: false
    };

    this.api = new InexApiService();

    this.handleOnChangeConfig = this.handleOnChangeConfig.bind(this);
    this.handleLoadConfig = this.handleLoadConfig.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
  }

  handleOnChangeConfig = config => {
    this.setState({ config: config });
  };

  handleLoadConfig = () => {
    const { config } = this.state;
    if (config) return config;
  };

  handleFilterChange = e => {
    const { filters } = this.state;
    this.setState({ filters: { ...filters, [e.target.name]: e.target.value } });
  };

  handleFilterSubmit = async () => {
    this.setState({ isLoading: true });
    const { filters } = this.state;

    try {
      const response = await this.api.makeHttpRequest({
        url: "/organograma",
        params: {
          idClient: filters.idClient,
          idProjectArea: filters.idCoordination
        }
      });

      this.setState({ tree: response });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar organograma.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { filters, tree, isLoading } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Organograma" icon={OrgChartIcon} disableHeader />
        <StyledOrgCard className="mt-3">
          <StyledCardBody>
            <ContainerOrgChart>
              <div className="filter-container">
                <Filters
                  filters={filters}
                  handleChange={this.handleFilterChange}
                  onSubmit={this.handleFilterSubmit}
                />
              </div>
              <div className="chart-container">
                {!isLoading && tree !== null && (
                  <>
                    <div className="zoom-buttons">
                      <button
                        type="button"
                        id="zoom-in"
                        className="btn btn-outline-primary zoom-button"
                      >
                        +
                      </button>
                      <button
                        type="button"
                        id="zoom-out"
                        className="btn btn-outline-primary zoom-button"
                      >
                        -
                      </button>
                    </div>
                    <OrgChart
                      tree={tree}
                      onConfigChange={cfg => this.handleOnChangeConfig(cfg)}
                      loadConfig={() => this.handleLoadConfig()}
                      loadImage={() => Promise.resolve(avatarPersonnel)}
                    />
                  </>
                )}
              </div>
            </ContainerOrgChart>
          </StyledCardBody>
        </StyledOrgCard>
      </>
    );
  }
}
