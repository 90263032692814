import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

class ButtonActions extends React.Component {
  render() {
    return (
      <div className="text-right" style={{ marginTop: "24px" }}>
        {this.props.path ? (
          <Link to={`/admin/${this.props.path}`}>
            <Button variant="secondary" color="#4672C5" type="button">
              <span>
                Voltar
              </span>
            </Button>
          </Link>
        ) : null}
      </div>
    );
  }
}

export default ButtonActions;
