import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { DateTimeIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialState = {
  closingDates: [],
  reload: false,
  loading: false
};

export default class ClosingDateListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setClosingDates = this.setClosingDates.bind(this);
  }

  setClosingDates = closingDates => {
    this.setState({ closingDates });
  };

  handleConfirmChangeStatus = async function(obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/client/toggle/${obj.idClient}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        "error"
      )
    }
  };

  formatRow = r => {
    let crudActions = ["edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type) === ERoles.ADMIN
    || (this.auth?.user && this.auth?.user?.role?.type === ERoles.OPERATION);

    if (isAdmin) {
      crudActions = [...crudActions];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idClosingDate}>
        {(isAdmin) && <StyledTableCell scope="row" align="center" width="5  %">
          <CrudActions
            actions={crudActions}
            module="data-de-fechamento"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/clientes/logs/${r.idClient}`}
            id={`${r.client.idClient}-${r.year}`}
            obj={r}
          />
          </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left" width="60%">
          {r.client.name && r.client.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.year && r.year}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Data de Fechamento - Admin");

    let headRows = [
      { column: "Client.Name", label: "Nome do Cliente", width: "29%" },
      { column: "Year", label: "Ano", align: "center" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];

    if((this.auth?.user && this.auth?.user?.role?.type === ERoles.ADMIN)
    || (this.auth?.user && this.auth?.user?.role?.type === ERoles.OPERATION)) {
        headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }

    let showAdminButtons = [
      {
        label: "Adicionar",
        onClick: null,
        icone: "add",
        path: "/admin/data-de-fechamento/adicionar"
      }
    ]

    if(this.auth?.user && this.auth?.user?.role?.type === ERoles.ADMIN
      || (this.auth?.user && this.auth?.user?.role?.type === ERoles.OPERATION)) {
      showAdminButtons = [ ...showAdminButtons]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Datas de Fechamento"
          icon={DateTimeIcon}
          endpoint="/closingdate/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.closingDates}
          setRows={this.setClosingDates}
          reload={this.state.reload}
          placeholderSearch="Busque por nome do cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
