import moment from "moment";

const periods = [
  {
    StartDatePeriod: moment('2022-12-22'),
    EndDatePeriod: moment('2023-01-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-01-23 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-01-22'),
    EndDatePeriod: moment('2023-02-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-02-23 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-02-22'),
    EndDatePeriod: moment('2023-03-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-03-22 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-03-22'),
    EndDatePeriod: moment('2023-04-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-04-24 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-04-22'),
    EndDatePeriod: moment('2023-05-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-05-22 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-05-22'),
    EndDatePeriod: moment('2023-06-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-06-22 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-06-22'),
    EndDatePeriod: moment('2023-07-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-07-24 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-07-22'),
    EndDatePeriod: moment('2023-08-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-08-22 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-08-22'),
    EndDatePeriod: moment('2023-09-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-09-22 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-09-22'),
    EndDatePeriod: moment('2023-10-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-10-23 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-10-22'),
    EndDatePeriod: moment('2023-11-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-11-22 23:59:59')
  },
  {
    StartDatePeriod: moment('2023-11-22'),
    EndDatePeriod: moment('2023-12-21 23:59:59'),
    EndDateLoadedMinutes: moment('2023-12-22 23:59:59')
  }
];

export const GetPeriodBasedTodayDate = () => {
  const now = moment(); // pega a data de agora
  for (const period of periods) {
    if (now.isBefore(period.EndDatePeriod)) { // verifica se a data atual é anterior à EndDateLoadedMinutes do período
      const year = period.EndDatePeriod.year(); // obtém o ano da EndDateLoadedMinutes
      const month = period.EndDatePeriod.month() + 1; // obtém o mês da EndDateLoadedMinutes
      return { year, month }; // retorna um objeto com o ano e o mês
    }
  }
  return null; // caso a data atual seja posterior à EndDateLoadedMinutes do último período, retorna null
}

export const GetYearList = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 }, (_, i) => currentYear - i);
  return years ;
};

export const GetMonthList = () => {
  const monthsList = [
    { cod: 1, name: 'Jan' },
    { cod: 2, name: 'Fev' },
    { cod: 3, name: 'Mar' },
    { cod: 4, name: 'Abr' },
    { cod: 5, name: 'Mai' },
    { cod: 6, name: 'Jun' },
    { cod: 7, name: 'Jul' },
    { cod: 8, name: 'Ago' },
    { cod: 9, name: 'Set' },
    { cod: 10, name: 'Out' },
    { cod: 11, name: 'Nov' },
    { cod: 12, name: 'Dez' }
  ];

  return monthsList; // Retorna um objeto com o mês atual e a lista de meses
};

export const GetMonthName = (cod) => {
  const monthsList = GetMonthList(); // Obtenha a lista de meses usando a função GetMonths()
  const monthObj = monthsList.find(month => month.cod === cod); // Encontra o objeto do mês correspondente
  
  return monthObj.name; // Retorna o nome do mês
};

export const GetPeriodName = (period) => {
  const year = period.toString().substring(0, 4);
  let monthName = GetMonthName(parseInt(period.toString().slice(-2), 10));

  return `${monthName}/${year}`;
};

//Recebe um periodo ANO e MES e retorna o start e EndDate para ser usado nos filtros de busca
export const getDefaultDate = (year, month) => {
  // Converte o mês para um número e subtrai 1 (pois o índice dos meses em Moment.js começa em 0)
  const monthIndex = parseInt(month, 10) - 1;

  // Verifica se o mês é Janeiro (índice 0) e ajusta o ano e o mês de acordo - Importante considerar que retiramos 3 horas por conta da má implementação de GMT. 
  const startDateMoment = moment({ year, month: monthIndex }).subtract(1, "month").date(22).hour(0).minute(0).second(0).subtract(3, "hours");
  const endDateMoment = moment({ year, month: monthIndex }).date(21).hour(23).minute(59).second(59).subtract(3, "hours");


  const period = {
    startDate: startDateMoment.format("YYYY-MM-DD HH:mm:ss"),
    endDate: endDateMoment.format("YYYY-MM-DD HH:mm:ss")
  };

  return period;
};



