import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Col, Card, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { InexApiService } from "../../../../services/InexApiService";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { AdminTitle } from "../../components/AdminTitle";
import { DateFilter } from "../../components/DateFilter";
import { TimerPicker } from "./components/TimePicker";
import { TimerFactory } from "./components/TimerFactory";
import { TimerSimplified } from "./components/TimerSimplified";
import { TimerDatable } from "./components/TimerDatable";
import { calculateDurationDiff, calculateDurationDiffMultiTimer } from "../../../../utils/CalculateDurationDiff";
import IsValidDescricao from "../../../../utils/validators/IsValidDescricao";
import avatarPlaceholder from "../../../../assets/images/avatar-placeholder.png";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidTotalHours from "../../../../utils/validators/IsValidTotalHours";
import { validateFileType } from "../../../../utils/validators/ValidateFileType";
import store from "../../../../store/store";
import { getPortoDefaultDate } from "../../../../utils/GetPortoDefaultDate";
import { FiEdit3, FiPaperclip, FiX } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton/";
import { ERoles } from "../../../../enums/Roles";
import {
  ClockIcon,
  ListIcon,
  PlayIcon,
  PlusIcon,
  StopIcon,
  TimerIcon,
  DateFilterIcon
} from "../../../../components/IconsSVG";
import {
  FilterInfoLine,
  SimplifiedRow,
  IconContainer,
  StyledForm,
  TimerCardBody,
  TimerAttachment,
  TimerControls,
  WarningMsg
} from "./styles";
import { TabsContainer } from "../Approvals/styles";
import formatCurrency from "../../../../utils/FormatCurrency";
import { ReceiveModal } from "./components/ReceiveModal";
import { Button } from "./components/Button";

const initialTimerValues = {
  cliente: "",
  profissional: undefined,
  projeto: "",
  projetoValue: null,
  atividade: "",
  solicitante: "",
  demanda: "",

  startFirstTimer: null,
  endFirstTimer: null,
  startSecondTimer: null,
  endSecondTimer: null,

  horaExtra: false,
  timerDoc: null,

  totalHours: "",
  professionalFactory: 0,
  billingMonth: 0,
  idRFQ: 0,
  modalOptions: []
};

const initialUserValues = {
  idUser: "",
  name: "",
  email: "",
  profile: "",
  profilePictureURL: "",
  idCurrentTimer: "",
  startFirstTimer: null,
  endFirstTimer: null,
  startSecondTimer: null,
  endSecondTimer: null,
  factoryTimer: null,
  clientDefault: null
}

const ACCEPTED_FORMATS =
  "image/jpg," +
  "image/jpeg," +
  "image/png," +
  "application/msword," +
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
  "application/pdf";

const Timer = () => {
  const { auth } = store.getState();
  const api = new InexApiService();

  const fileInput = useRef(null);
  const timerDoc = useRef(null);

  //Nova data selecionada no dateTime (calendario)
  // Ajuste o estado inicial de initialCycleDay
  const [initialCycleDay, setInitialCycleDay] = useState(22);
  const [closedDay, setClosedDay] = useState(31);

  const [newDateValue, setNewDateValue] = useState();

  // Global states
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [needFirstLoadTotalHours, setNeedFirstLoadTotalHours] = useState(true);

  // Professional Select
  const [userProfessional, setUserProfessional] = useState();

  // Current user info
  const [user, setUser] = useState(initialUserValues);
  const UserTypeRole = auth?.user?.role?.type;

  // Options states
  const [clientOptions, setClientOptions] = useState([]);
  const [projOptions, setProjOptions] = useState([]);
  const [professionalsOptions, setProfessionalOptions] = useState([]);

  // Auto timer states
  const [auto, setAuto] = useState(false);
  const [manual, setManual] = useState(false);
  const [compound, setCompound] = useState(false);
  const [simplified, setSimplified] = useState(false);

  const [started, setStarted] = useState(false);
  const [runningTime, setRunningTime] = useState("00:00:00");
  const [informedTime, setInformedTime] = useState("00:00:00");
  const [tabSelected, setTabSelected] = useState("cliente");
  const [generalDate, setGeneralDate] = useState(moment().date(1).toDate());

  // Pegar o primeiro client
  const [timerValues, setTimerValues] = useState({
    ...initialTimerValues,
    cliente: clientOptions.length > 0 ? clientOptions[0].idClient : ""
  });

  const [allocatedToProj, setAllocatedToProj] = useState(false);
  const newTimerValue = timerValues;
  const [clientProps, setClientProps] = React.useState({
    initialCycleDay: 22
  });
  const [openReceiveModal, setOpenReceiveModal] = useState(false);
  const [receiveProjects, setReceiveProjects] = useState([]);
  const [viewReceiveTotal, setViewReceiveTotal] = useState(true);
  const [countLoadTimer, setCountLoadTimer] = useState(0);

  // 001 - handle vai chamar api, para trazer os valores do dia inicial do clico
  // e com isso podemos fazer a escolha do primeiro.
  const handleSetInitialCycleDay = async () => {
    if (clientOptions.length > 0 && timerValues.cliente > 0) {
      try {
        const response = await api.makeHttpRequest({
          url: `/timer/project/client/${timerValues.cliente}`
        });
        const cycleDay = response[0].client.initialCycleDay;
        setInitialCycleDay(cycleDay); // Atualize o estado com o valor obtido
        return response ?? [];
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
        throw error;
      }
    } else {
      return [];
    }
  };

  const loadClientProps = async (idClient) => {
    const client = await api.makeHttpRequest({
      url: `/client/${idClient}`
    });
    if (client) {
      handleSetInitialCycleDay(client)
      setClientProps(client);
      const periodCycle = getPortoDefaultDate(client.initialCycleDay);
      if(auth.user.showReceiveTotal) {
        loadReceiveTotal(idClient, periodCycle)
      }
      if (UserTypeRole === ERoles.ADMIN || UserTypeRole === ERoles.OPERATION) {
        loadProfessionals(idClient)
      }
      setFilter({
        ...filter,
        idClient: idClient,
        startPeriod: periodCycle.startDate,
        endPeriod: periodCycle.endDate,
        initialCycleDay: client.initialCycleDay,
        periodSeted: true
      });
    }
  }

  // 001 - Atualize a chamada de useState para usar initialCycleDay
  // Vai passar o valor do initalCycleDay, para o getPortoDefaultDate, para pegar
  // a data desejada a partir do valor inicial do ciclo
  const [initialFilterDates, setInitialFilterDates] = useState(getPortoDefaultDate(initialCycleDay));

  const [
    allowEditClientDemandRequester,
    setAllowEditClientDemandRequester
  ] = useState(false);

  // Reload Running Timer Values
  const reloadRunningTimer = useCallback(() => {
    setStarted(false);
    setRunningTime("00:00");
    setTimerValues(initialTimerValues);
  }, []);

  // Date-range filter
  const [filter, setFilter] = useState({
    startPeriod: initialFilterDates.startDate,
    endPeriod: initialFilterDates.endDate,
    idClient: timerValues?.cliente,
    periodSeted: false,
    initialCycleDay: 1
  });

  const handlePeriodFilter = newFilter => {
    setFilter({
      ...filter,
      startPeriod: moment(newFilter.startPeriod).startOf('day').toDate(),
      endPeriod: moment(newFilter.endPeriod).endOf('day').toDate()
    });
    if(auth.user.showReceiveTotal) {
      loadReceiveTotal(filter.idClient, {
        startDate: newFilter.startPeriod,
        endDate: newFilter.endPeriod
      })
    }
  };

  // Função para atualizar initialFilterDates quando initialCycleDay mudar
  useEffect(() => {
    // Atualize initialFilterDates com os novos valores de getPortoDefaultDate
    const newFilterDates = getPortoDefaultDate(initialCycleDay);
    setInitialFilterDates(newFilterDates);
  }, [initialCycleDay]);

  // Total timer by range
  const [timerSum, setTimerSum] = useState("00:00");
  const [workingHours, setWorkingHours] = useState(0);
  const [totalCycleWage, setTotalCycleWage] = useState(0);
  const [userProjects, setUserProjects] = useState("");
  const [isMountedUser, setIsMountedUser] = useState(false);

  const loadTimer = useCallback(async () => {
    if (user?.idCurrentTimer) {
      const timer = await api.makeHttpRequest({
        url: `/timer/${user.idCurrentTimer}`
      });

      setTimerValues({
        ...timerValues,
        cliente: timer.idClient,
        projeto: timer.idProject,
        atividade: timer.activity,
        solicitante: timer.clientDemandRequester,
        demanda: timer.demand,
        horaExtra: timer.isOvertime,
        timerDoc: timer.appFile,
        startFirstTimer: "",
        endFirstTimer: "",
        startSecondTimer: "",
        endSecondTimer: ""
      });

      setAuto(true);
      setStarted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleExportTimer = async () => {
    const endpoints = UserTypeRole === ERoles.FACTORY
      ? `/timer/export/factory`
      : `/timer/export/user/${auth?.user?.idUser}`

    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        url: endpoints,
        params: {
          idClient: timerValues.cliente,
          startPeriod: moment(filter.startPeriod).format(`YYYY-MM-DD[T]HH:mm:ss`),
          endPeriod: moment(filter.endPeriod).format(`YYYY-MM-DD[T]HH:mm:ss`),
          hasPeriod: true,
          ProfessionalListIds: auth?.user?.idUser === null || auth?.user?.idUser === undefined ? '' : auth?.user?.idUser.toString()
        },
        responseType: "blob",
        method: "get"
      });

      //Bom, o nome do arquivo vem dentro do response.headers, mas ele vem diferente de um objeto, então
      //para conseguir recuperar o nome do arquivo, temos que usar essa expressão regular abaixo.
      const contentDispositionHeader = response.headers['content-disposition'];
      const fileNameMatch = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      const fileName = fileNameMatch[1].replace(/['"]/g, '');

      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      //link.download = "report.zip";
      link.download = fileName;
      link.click();

      Swal.fire(
        "Sucesso!",
        "Relatório gerado com sucesso.",
        "success"
      );
    } catch (e) {
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result;
        Swal.fire(
          "Erro!",
          "Sem timer disponível para o modelo de relatório selecionado.",
          "error"
        );
      });

      reader.readAsText(e.response.data);
    } finally {
      setLoading(false);
    }
  }

  const loadFactoryClients = async () => {
    try {
      const clients = await api.makeHttpRequest({
        url: `/timer/factory/clients`
      });
      setClientOptions(clients ?? []);
    }
    catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao carregar clientes.",
        "error"
      );
    }
  }

  const loadProposals = async () => {
    const proposals = await api.makeHttpRequest({
      url: "/timer/rfq"
    });

    const formattedProposal =
      proposals.length > 0
        ? proposals.map(prop => ({
          id: prop.idBusinessProposalValue,
          name: prop.profileDescription
        }))
        : [];

    setTimerValues({
      modalOptions: formattedProposal
    });
  };

  const loadUser = async () => {
    if (!isMountedUser) {
        setLoading(true);
        try {
            const response = auth.user;
            const res = await api.makeHttpRequest({
              url: `/timer/user/${response.idUser}`
            });
            
            let projects = res.projects ?? [];
            
            setUser({
              idUser: response.idUser,
              name: response.name,
              email: response.inexEmail,
              profile: response.role,
              profilePictureURL: res.profilePictureURL,
              idCurrentTimer: response.idCurrentTimer,
              projects: projects,
              startFirstTimer: res.startFirstTimer,
              endFirstTimer: res.endFirstTimer,
              startSecondTimer: res.startSecondTimer,
              endSecondTimer: res.endSecondTimer,
              clientDefault: response.clientDefault
            });
      
            if (response.idCurrentTimer !== null)
              await loadTimer();
      
              if (response.clients.length === 1) {
                setTimerValues({ ...timerValues })
              }
              setClientOptions(response.clients ?? []);
              if (response.clients?.length > 0) {
                // Atualize o estado filter com os novos valores de initialFilterDates
                let idClientDefault = response.clientDefault ? response.clientDefault.idClient : response.clients[0].idClient;
                loadClientProps(idClientDefault)
                loadUserProjects(idClientDefault)
                if (projects.length > 0) {
                  loadProjects(idClientDefault, projects);
                }
                setTimerValues({
                    ...timerValues,
                    cliente: idClientDefault // Define o ID do primeiro cliente como o valor inicial
                });
              }
          } catch (e) {
            Swal.fire(
              "Erro!",
              e?.response?.data
                ? e.response.data
                : "Erro ao carregar informações do usuário.",
              "error"
            );
            window.location.replace("/logout"); //Em caso de problema para carregar as informaçoes do usuário, força o logout.
          } finally {
            setLoading(false);
          }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const loadTabTimerType = async () => {
    setLoading(true);
    try {
      const auth_user = auth.user;
      const res = await api.makeHttpRequest({
        url: `/timer/last-timer-type`
      });
      switch (res?.name) {
        case 'Manual':
          setManual(true)
          break;
        case 'Compound':
          setCompound(true)
          break;
        case 'Simplified':
          UserTypeRole === ERoles.ADMIN || 
          UserTypeRole === ERoles.OPERATION ||
          UserTypeRole === ERoles.CONSULTANTSIMPLIFIED  ?
          setSimplified(true) :
          setManual(true)
          break;
        default:
          setManual(true)
          break;
      }
    } catch {
    } finally {
      setLoading(false);
    }  
  };

  // // Load user initially
  useEffect(() => {
    window.setPageTitle("Timer - Admin");
    if (!isMountedUser) {       
      loadUser();
    }

    setIsMountedUser(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load user and refresh page every reload
  useEffect(() => {

    if (reload == true) {
      if (reload) {

        loadTimer();
        setReload(false);
      }
    }

    loadTabTimerType()
  }, [loadTimer, reload, UserTypeRole]);

  const loadTimerSum = async () => {
    if (filter.startPeriod && filter.endPeriod && filter.idClient && moment(filter.startPeriod).date() === filter.initialCycleDay) {
      try {
        const total = await api.makeHttpRequest({
          url: "/timer/hours",
          params: {
            startPeriod: moment(filter.startPeriod).format('YYYY-MM-DDTHH:mm:ss.000Z'),
            endPeriod: moment(filter.endPeriod).format('YYYY-MM-DDTHH:mm:ss.000Z'),
            idClient: filter.idClient,
            hasPeriod: true,
            ProjectTypeFilter: timerValues.projetoValue?.type,
            ProjectIdFilter: timerValues.projetoValue?.value
          }
        });

        if (total) {
          const totalTimerStr = `${total.hours < 10 ? ("0" + total.hours).slice(-2) : total.hours
            }:${("0" + total.minutes).slice(-2)}`;

          setTimerSum(totalTimerStr);
        }
        loadWorkingHours(filter.idClient)
      } catch (e) {
        Swal.fire(
          "Erro!",
          "Erro ao carregar informações de soma dos times.",
          "error"
        );
      }
      setNeedFirstLoadTotalHours(false);
    }
  };

  useEffect(() => {
    loadTimerSum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // Create timer motion
  useEffect(() => {
    let interval = null;
    let atualTime = 0;

    if (started) {
      if (timerValues.startFirstTimer) {
        atualTime = moment.duration(
          moment().diff(timerValues.startFirstTimer),
          "milliseconds"
        );
      }

      interval = setInterval(() => {
        atualTime = atualTime + 1000;

        const hour = Math.floor((atualTime / 3600000) % 24);
        const minutes = Math.floor((atualTime / 60000) % 60);
        const seconds = parseInt((atualTime / 1000) % 60);

        setRunningTime(
          `${hour > 9 ? hour : "0" + hour}:${minutes > 9 ? minutes : "0" + minutes
          }:${seconds > 9 ? seconds : "0" + seconds}`
        );
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerValues.startFirstTimer, started]);

  // Calculate manual timer diff
  useEffect(() => {
    if (compound && informedTime?.startFirstTimer && informedTime?.endFirstTimer &&
      informedTime?.startSecondTimer && informedTime?.endSecondTimer) {
      const formattedTime = calculateDurationDiffMultiTimer(
        informedTime?.startFirstTimer,
        informedTime?.endFirstTimer,
        informedTime?.startSecondTimer,
        informedTime?.startEndTimer,
      );
      setInformedTime(formattedTime);
    }

    if (informedTime?.startFirstTimer && informedTime?.endFirstTimer) {
      const formattedTime = calculateDurationDiff(
        informedTime?.startFirstTimer,
        informedTime?.endFirstTimer
      );
      setInformedTime(formattedTime);
    }

  }, [informedTime]);

  const loadProjects = async (idClient, userProjects) => {
    if (idClient) {
      setLoading(true);
      try {
        const projects = await api.makeHttpRequest({
          url: `/timer/project/client/${idClient}`
        });
  
        const filterAlloc = projects.filter(
          p => userProjects.findIndex(up => up.idProject === p.idProject) !== -1
        );
  
        const alloc = filterAlloc.map(p => ({
          value: p.idProject,
          label: p.projectArea?.name + " - " + p.name,
          type: p.projectType.name.toUpperCase(),
          closedDay: p.closedDay,
          solicitante: p.clientProjectManager
        }));
  
        const filterNotAlloc = projects.filter(
          p => userProjects.findIndex(up => up.idProject === p.idProject) === -1
        );
  
        const notAlloc = filterNotAlloc.map(p => ({
          value: p.idProject,
          label: p.projectArea?.name + " - " + p.name,
          type: p.projectType.name.toUpperCase(),
          closedDay: p.closedDay,
          solicitante: p.clientProjectManager
        }));
  
        const groupedOptions = [
          {
            label: "Alocações",
            options: alloc
          }
        ];
  
        setProjOptions(groupedOptions);
      } catch (e) {
        setLoading(false);
  
        Swal.fire(
          "Erro!",
          e?.response?.data
            ? e.response.data
            : "Erro ao carregar lista de projetos, tente novamente mais tarde.",
          "error"
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  
      setLoading(false);
    }
  };

  const loadProfessionals = async (idClient) => {
    try {
      const req = await api.makeHttpRequest({
        url: `/timer/user/client/${idClient}`
      });

      if (req.professionals.length > 0) {
        setProfessionalOptions(req.professionals.map(p => {
          return { value: p.idUser, label: p.name }
        }));
      } else {
        setProfessionalOptions([]);
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao carregar a lista de profissionais, tente novamente mais tarde.",
        "error"
      );
    }
  }

  const loadUserProjects = async (idClient) => {
    const userProjects = await api.makeHttpRequest({
      url: `/project/total-cycle-wage/${idClient}`
    });
    setUserProjects(userProjects);
  }

  const loadWorkingHours = async (idClient) => {
    var holidays = []
    try {
      const holidayList = await api.makeHttpRequest({
        url: `/holiday/by-client/${idClient}`
      });
      holidays = holidayList.holidays.map((holiday) => moment(holiday.date).format('YYYY-MM-DD'))
    } catch { }
    const isHoliday = date => holidays.includes(moment(date).format('YYYY-MM-DD'));
    const startPeriodDate = new Date(filter.startPeriod);
    startPeriodDate.setDate(startPeriodDate.getDate());
    startPeriodDate.setHours(0, 0, 0, 0);
    const startPeriod = moment(startPeriodDate);
    const endPeriodDate = new Date(filter.endPeriod);
    endPeriodDate.setHours(23, 59, 59);
    const endPeriod = moment(endPeriodDate);
    let businessDaysCount = 0;
    for (let day = startPeriod.clone(); day.isSameOrBefore(endPeriod); day.add(1, 'days')) {
      if (day.isoWeekday() !== 6 && day.isoWeekday() !== 7 && !isHoliday(day)) {
        businessDaysCount++;
      }
    }
    setWorkingHours(businessDaysCount * 8);
  }

  const loadReceiveTotal = async (idClient, period) => {
    try {
      const receiveByProject = await api.makeHttpRequest({
        url: `/project/receive-by-project`,
        params: {
          idClient: idClient,
          startPeriod: moment(period.startDate).format(`YYYY-MM-DD[T]HH:mm:ss`),
          endPeriod: moment(period.endDate).format(`YYYY-MM-DD[T]HH:mm:ss`),
          hasPeriod: true,        
          ProjectTypeFilter: timerValues.projetoValue?.type,
          ProjectIdFilter: timerValues.projetoValue?.value,
          ProfessionalListIds: auth?.user?.idUser === null || auth?.user?.idUser === undefined ? '' : auth?.user?.idUser.toString()
        },
      });
      
      const receiveTotal = receiveByProject.reduce((acc, obj) => acc + obj.receiveTotal, 0);
      setTotalCycleWage(formatCurrency(receiveTotal.toFixed(2)));
      setReceiveProjects(receiveByProject);
      const hasEmptyWageHour = receiveByProject.some(obj => obj.wageHour === 0);
      setViewReceiveTotal(!hasEmptyWageHour)
    } catch {

    }
  }

  useEffect(() => {
    let inProject = true;

    if (timerValues.projeto) {
      const index = user.projects.findIndex(
        x => x.idProject === Number(timerValues.projeto)
      );

      inProject = index !== -1;
    }

    setAllocatedToProj(inProject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValues.projeto]);

  const loadProfessional = async (idUser) => {
    setLoading(true)
    if (idUser) {
      const res = await api.makeHttpRequest({
        url: `/professional/${idUser}`
      });
      if (res) {
        setUserProfessional(res)
        setTimerValues({
          ...timerValues, 
          projeto: '',
          projetoValue: '',
          solicitante: '',
          atividade: ''
        })
        loadProjects(timerValues.cliente, res.projects)
      }
    } else {
      setUserProfessional(auth?.user)
      setTimerValues({
        ...timerValues, 
        projeto: '',
        projetoValue: '',
        solicitante: '',
        atividade: ''
      })
      loadProjects(timerValues.cliente, auth?.user.projects)
    }
    setLoading(false);
    setReload(true)
  }

  const handleTimerChange = e => {
    if (e.target.type === "checkbox") {
      setTimerValues({ ...timerValues, [e.target.name]: e.target.checked });
      return;
    }

    // Melhorias de telas 0 Limpar o valor do projeto/squad quando houver uma mudança no cliente
    // limpar a atividade também.
    if (e.currentTarget.name === "cliente") {
        loadClientProps(e.currentTarget.value)
        loadUserProjects(e.currentTarget.value)
        loadProjects(e.currentTarget.value, user.projects);
        setTimerValues({
            ...timerValues,
            [e.currentTarget.name]: e.currentTarget.value,
            projeto: '', // Limpa o valor do projeto/squad
            projetoValue: '', // Limpa o valor do projeto/squad
            solicitante: '', // Limpa o valor do solicitante
            atividade: '' // Limpa o valor do campo de atividade
        });
        return;
    }

    //IF que limita a escrita dentro do campo atividade em 500 caracteres, caso o campo tenha mais de 500 char, ele interrompe o metodo.
    if (e.currentTarget.name == "atividade" && e.currentTarget.value.length > 500) {
      return; // Interrompe a execução do método aqui
    }

    setTimerValues({
      ...timerValues,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const handleTimerDoc = () => {
    if (fileInput.current && fileInput.current.files[0]) {
      const allowedExtentions = ["jpg", "jpeg", "png", "doc", "docx", "pdf"];

      const isValid = validateFileType(
        fileInput.current.value,
        allowedExtentions
      );

      if (!isValid) {
        Swal.fire({
          icon: "error",
          title: "Tipo de arquivo inválido",
          text: "Apenas arquivos " + allowedExtentions.join(", ").toUpperCase(),
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });

        return;
      }

      const file = new FileReader();
      file.readAsDataURL(fileInput.current.files[0]);
      timerDoc.current.innerText = fileInput.current.files[0].name;
      setTimerValues({
        ...timerValues,
        timerDoc: fileInput.current.files[0]
      });
    }
  };

  const handleRefillTimeinfo = async obj => {
    const hasProject = await api.makeHttpRequest({
      url: `/timer/is-project-disabled/${obj.idProject}`
    });

    if (!hasProject) {
      Swal.fire(
        "Aviso!",
        "O projeto pertecente a este timer encontra-se desativado.",
        "warning"
      );
    }

    const refillTimerValues = (!auto && !compound) ? {
      startFirstTimer: "",
      endFirstTimer: "",
      startSecondTimer: "",
      endSecondTimer: ""
    } : {};

    const refillTimer = {
      ...initialTimerValues,
      cliente: hasProject ? obj.idClient : "",
      projeto: hasProject ? obj.idProject : "",
      projetoValue: hasProject
        ? {
          value: obj.project.idProject,
          label: obj.project.name
        }
        : null,
      atividade: hasProject ? obj.activity : "",
      solicitante: hasProject ? obj.clientDemandRequester : "",
      demanda: (hasProject && obj.demand !== null) ? obj.demand : "",
      ...refillTimerValues
    };

    setTimerValues(refillTimer);
  };

  const handleSetInformedDates = (startFirstTimer, endFirstTimer) => {
    const timeLog = calculateDurationDiff(startFirstTimer, endFirstTimer);
    setInformedTime(timeLog);
    setTimerValues({ ...timerValues, startFirstTimer, endFirstTimer });
  };

  const handleSetInformedDatesComposto = (startFirstTimer, endFirstTimer, startSecondTimer, endSecondTimer) => {
    const timeLog = calculateDurationDiffMultiTimer(startFirstTimer, endFirstTimer, startSecondTimer, endSecondTimer);
    setInformedTime(timeLog);
    setTimerValues({ ...timerValues, startFirstTimer, endFirstTimer, startSecondTimer, endSecondTimer });
  };

  const handleSetInformedDatesFactory = (totalHours, professionalFactory, billingMonth, idRFQ) => {
    setTimerValues({ ...timerValues, totalHours, professionalFactory, billingMonth, idRFQ });
  }

  const handleSetHoursSimplified = (totalHours) => {
    setTimerValues({ ...timerValues, totalHours });
  }

  const handleRemoveAnexo = async () => {
    const isForRemove = await Swal.fire({
      showCancelButton: true,
      icon: "warning",
      title: "Remover anexo",
      text: "Você tem certeza que quer remover seu anexo?",
      confirmButtonColor: "#4672C5",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });

    if (isForRemove.dismiss) {
      return;
    }
    if (isForRemove.value) {
      if (fileInput.current?.files[0]) {
        timerDoc.current.src = "";
        fileInput.current.value = "";
        setTimerValues({ ...timerValues, timerDoc: null });
      }
    }
  };

  const formIsValid = (isManual = false, type) => {
    const auto =
      !!timerValues.cliente &&
      !!timerValues.projeto &&
      !!timerValues.atividade &&
      !!IsValidDescricao(timerValues.atividade) &&
      !!timerValues.solicitante &&
      !!IsValidName(timerValues.solicitante) &&
      (timerValues.horaExtra ? !!timerValues.timerDoc : true);

    const validManual = auto &&
      !!timerValues.startFirstTimer &&
      !!timerValues.endFirstTimer;

    const validFactory = auto &&
      !!timerValues.totalHours &&
      !!IsValidTotalHours(timerValues.totalHours) &&
      !!timerValues.professionalFactory &&
      !!timerValues.billingMonth;

    const validSimplified = auto &&
      !!timerValues.totalHours &&
      !!IsValidTotalHours(timerValues.totalHours)

    switch (type) {
      case 'auto':
        return auto;
      case 'manual':
        return isManual ? validManual : auto;
      case 'factory':
        return validFactory;
      case 'simplified':
        return validSimplified;
      default:
        return auto;
    }
  };

  const handleStart = useCallback(async () => {
    setLoading(true);

    if (!formIsValid()) {
      Swal.fire("Erro!", "Verifique os campos e tente novamente", "error");
    }

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", timerValues.cliente);
    formData.append("IdProject", timerValues.projeto);
    formData.append("ClientDemandRequester", timerValues.solicitante);
    formData.append("Demand", timerValues.demanda ?? "");
    formData.append("Activity", timerValues.atividade);
    formData.append("IsOvertime", timerValues.horaExtra);
    formData.append("FullEdit", false);
    formData.append("IsAutoTimer", auto);

    if (timerValues?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", timerValues.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", timerValues.timerDoc);
    }

    try {
      await api.makeHttpRequest({
        url: "/timer/save",
        method: "POST",
        data: formData
      });

      setReload(true);
      setStarted(true);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao iniciar o timer.",
        "error"
      );
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.idUser,
    timerValues.cliente,
    timerValues.projeto,
    timerValues.solicitante,
    timerValues.demanda,
    timerValues.atividade,
    timerValues.horaExtra,
    timerValues.timerDoc,
    api
  ]);

  const handleStop = useCallback(async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", timerValues.cliente);
    formData.append("IdProject", timerValues.projeto);
    formData.append("Activity", timerValues.atividade);
    formData.append("FullEdit", false);

    try {
      await api.makeHttpRequest({
        url: `/timer/update/${user.idCurrentTimer}`,
        method: "PUT",
        data: formData
      });

      reloadRunningTimer();
    } catch (e) {
      if (e?.response?.status === 406) {
        Swal.fire({
          position: 'top-end',
          text: e.response.data,
          showConfirmButton: false,
          timerProgressBar: true,
          background: '#d45f6a',
          timer: 8000
        })

        reloadRunningTimer();
      }

      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao parar o timer.",
        "error"
      );
    }

    setAuto(true);
    setReload(true);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    api,
    timerValues.atividade,
    timerValues.cliente,
    timerValues.projeto,
    user.idCurrentTimer,
    user.idUser
  ]);

  const afterSubmit = async () => {
    setTimerValues({
      ...timerValues,
      endFirstTimer: "",
      startFirstTimer: "",
      endSecondTimer: "",
      startSecondTimer: ""
    });
    loadClientProps(timerValues.cliente)
  };

  // submit manual
  const handleAdd = useCallback(async () => {
    setLoading(true);

    if (!formIsValid(true)) {
      Swal.fire("Erro!", "Verifique os campos e tente novamente", "error");
    }

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", timerValues.cliente);
    formData.append("IdProject", timerValues.projeto);
    formData.append("ClientDemandRequester", timerValues.solicitante);
    formData.append("Demand", timerValues.demanda ?? "");
    formData.append("Activity", timerValues.atividade);

    formData.append("StartFirstTimer", timerValues.startFirstTimer);
    formData.append("EndFirstTimer", timerValues.endFirstTimer);


    formData.append("IsOvertime", timerValues.horaExtra);

    if (timerValues?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", timerValues.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", timerValues.timerDoc);
    }

    formData.append("FullEdit", false);

    try {
      console.log("INEX: Conteúdo do formData:");
      formData.forEach((value, key) => {
        console.log(key + ': ' + value);
      });
      console.log("INEX: Envio do formdata");
      await api.makeHttpRequest({
        url: "/timer/save",
        method: "POST",
        data: formData
      });
      console.log("INEX: Pós-Envio do formdata");
      afterSubmit();

      setReload(true);
      setInformedTime("00:00:00");
      setAuto(false)
      setManual(true)
      setCompound(false)
      setSimplified(false)
      loadTimerSum();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao adicionar o timer.",
        "error"
      );
    }

    //Aqui limpamos a referencia do arquivo de H.E.
    if (timerValues.horaExtra) {
      setTimerValues({
        ...timerValues,
        horaExtra: false,
        timerDoc: null,
      });
    }


    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.idUser,
    timerValues.cliente,
    timerValues.projeto,
    timerValues.solicitante,
    timerValues.demanda,
    timerValues.atividade,
    timerValues.startFirstTimer,
    timerValues.endFirstTimer,
    timerValues.horaExtra,
    timerValues.timerDoc,
    api
  ]);

  // submit composto
  const handleAddCompound = useCallback(async () => {
    setLoading(true);

    if (!formIsValid(true)) {
      Swal.fire("Erro!", "Verifique os campos e tente novamente", "error");
    }

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", timerValues.cliente);
    formData.append("IdProject", timerValues.projeto);
    formData.append("ClientDemandRequester", timerValues.solicitante);
    formData.append("Demand", timerValues.demanda ?? "");
    formData.append("Activity", timerValues.atividade);

    formData.append("StartFirstTimer", timerValues.startFirstTimer);
    formData.append("EndFirstTimer", timerValues.endFirstTimer);
    formData.append("StartSecondTimer", timerValues.startSecondTimer);
    formData.append("EndSecondTimer", timerValues.endSecondTimer);

    formData.append("IsOvertime", timerValues.horaExtra);

    if (timerValues?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", timerValues.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", timerValues.timerDoc);
    }

    formData.append("FullEdit", false);

    try {
      await api.makeHttpRequest({
        url: "/timer/save",
        method: "POST",
        data: formData
      });

      afterSubmit();

      //Aqui limpamos a referencia do arquivo de H.E.
      if (timerValues.horaExtra) {
        setTimerValues({
          ...timerValues,
          horaExtra: false,
          timerDoc: null,
        });
      }

      setReload(true);
      setInformedTime("00:00:00");
      setAuto(false)
      setManual(false)
      setCompound(true)
      setSimplified(false)
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao adicionar o timer.",
        "error"
      );
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.idUser,
    timerValues.cliente,
    timerValues.projeto,
    timerValues.solicitante,
    timerValues.demanda,
    timerValues.atividade,
    timerValues.startFirstTimer,
    timerValues.endFirstTimer,
    timerValues.startSecondTimer,
    timerValues.endSecondTimer,
    timerValues.horaExtra,
    timerValues.timerDoc,
    api
  ]);

  // submit factory
  const handleAddFactory = async () => {
    setLoading(true);

    if (!formIsValid(true)) {
      Swal.fire("Erro!", "Verifique os campos e tente novamente", "error");
    }

    const formData = new FormData();
    formData.append("IdClient", timerValues.cliente);
    formData.append("IdProject", timerValues.projeto);
    formData.append("ClientDemandRequester", timerValues.solicitante);
    formData.append("Demand", timerValues.demanda ?? "");
    formData.append("Activity", timerValues.atividade);

    formData.append("TotalHours", timerValues.totalHours);
    formData.append("IdProfessional", +timerValues.professionalFactory);
    formData.append("BillingMonth", +timerValues.billingMonth);
    formData.append("IdRFQ", +timerValues.idRFQ);

    if (timerValues?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", timerValues.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", timerValues.timerDoc);
    }

    formData.append("FullEdit", false);

    try {
      await api.makeHttpRequest({
        url: "/timer/save/factory",
        method: "POST",
        data: formData
      });

      afterSubmit();

      //Aqui limpamos a referencia do arquivo de H.E.
      if (timerValues.horaExtra) {
        setTimerValues({
          ...timerValues,
          horaExtra: false,
          timerDoc: null,
        });
      }

      setReload(true);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao enviar lançamento de fábrica.",
        "error"
      );
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleAddSimplified = async () => {
    setLoading(true);

    if (!formIsValid(true)) {
      Swal.fire("Erro!", "Verifique os campos e tente novamente", "error");
    }

    //No cenário de lançamento simplificado, a data utilizada na criação do timer será a data fim escolhida no componente filtro de Data, aplicando o horario 00:00:00
    const startDate = moment(filter.endPeriod).startOf('day');
    const endDate = startDate;
    const formattedStartDate = startDate.format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndDate = endDate.format('YYYY-MM-DDTHH:mm:ss');


    const formData = new FormData();
    formData.append("IdUser", userProfessional ? userProfessional.idUser : user.idUser);
    formData.append("IdClient", timerValues.cliente);
    formData.append("IdProject", timerValues.projeto);
    formData.append("ClientDemandRequester", timerValues.solicitante);
    formData.append("Demand", timerValues.demanda ?? "");
    formData.append("Activity", timerValues.atividade);

    formData.append("TotalHours", timerValues.totalHours);

    formData.append("StartFirstTimer", formattedStartDate);
    formData.append("EndFirstTimer", formattedEndDate);

    if (timerValues?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", timerValues.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", timerValues.timerDoc);
    }

    formData.append("FullEdit", false);
    //Ao tentar adicionar o timer, a gente verifica se a data de inicio corresponde a outro periodo, se sim, a gente retorna um erro,
    //pois não queremos que seja escolhido um intervalo de datas que englobe 2 periodos diferentes.
    const startPeriod = moment(initialFilterDates.startDate);
    const endPeriod = moment(initialFilterDates.endDate);

    //caso o starPeriod e o EndPeriod do filtro ultrapasse um ciclo de fechamento Porto, ele deve dar um aviso:
    const endNextMonth = moment(new Date(initialFilterDates.startDate.getFullYear(), initialFilterDates.startDate.getMonth()+1, 21, 23, 59, 59, 0));

    if (
      // Usando a lógica antiga para o cliente porto seguro, mas só vai entrar se o cliente for por seguro
      timerValues.cliente === 2 &&
      (startPeriod.date() <= 21 && endPeriod.date() > 21) ||
      (startPeriod.date() > 21 && endPeriod.milliseconds(0).isAfter(endNextMonth))
    ) {
      Swal.fire(
        "Erro!",
        "A data de inicio escolhida corresponde a outro periodo comparado com a data final.",
        "error"
      );

    } else {
      // caso o periodo esteja correto, ai salvamos o registro.
      try {
        await api.makeHttpRequest({
          url: "/timer/save/simplified",
          method: "POST",
          data: formData
        });

        //Aqui limpamos a referencia do arquivo de H.E.
        if (timerValues.horaExtra) {
          setTimerValues({
            ...timerValues,
            horaExtra: false,
            timerDoc: null,
          });
        }

        setReload(true);
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao enviar lançamento Simplificado.",
          "error"
        );
      }
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const FilterComponent = () => (
    <IconContainer>
      <DateFilterIcon />
    </IconContainer>
  );

  const handleSetTab = (tab, generalDateValue) => {
    setTabSelected(tab);
    if (tab === 'cliente') {
      setFilter({
        ...filter,
        generalDate: undefined
      })
    } else {
      setFilter({
        ...filter,
        generalDate: generalDateValue??generalDate
      })
    }
  }

  useEffect(() => {
    if (timerValues.projetoValue?.type === 'FÁBRICA') {
      const currentDate = moment();
      let lastDayOfMonth = moment(currentDate).endOf('month').date();
      let closingDay = !timerValues.projetoValue.closedDay || lastDayOfMonth <= timerValues.projetoValue.closedDay ? lastDayOfMonth : timerValues.projetoValue.closedDay;
      let closedDate = moment(currentDate).hour(23).minutes(59).seconds(59).date(closingDay);
      let initialDate = moment(closedDate).hour(0).minutes(0).seconds(0).subtract(1, 'month').add(1, 'day');      
      if (currentDate.isAfter(closedDate)) {
        const nextDate = moment(currentDate).add(1, 'month');
        lastDayOfMonth = nextDate.endOf('month').date();
        closingDay = lastDayOfMonth <= timerValues.projetoValue.closedDay ? lastDayOfMonth : timerValues.projetoValue.closedDay;
        closedDate = moment(nextDate).date(closingDay);
        initialDate = moment(closedDate).subtract(1, 'month').add(1, 'day');
      }
      setInitialFilterDates({
        ...initialFilterDates,
        startPeriod: initialDate.hour(0).minute(0).seconds(0).toDate(),
        endPeriod: closedDate.hour(23).minute(59).seconds(59).toDate()
      })
      setFilter({
        ...filter,
        initialCycleDay: initialDate.date(),
        startPeriod: initialDate.hour(0).minute(0).seconds(0).toDate(),
        endPeriod: closedDate.hour(23).minute(59).seconds(59).toDate()
      })
      if(auth.user.showReceiveTotal) {
        loadReceiveTotal(filter.idClient, {
          startDate: initialDate.hour(0).minute(0).seconds(0).toDate(),
          endDate: closedDate.hour(23).minute(59).seconds(59).toDate()
        })
      }
      setReload(true);
    }
  }, [timerValues.projetoValue])

  const handleRemoveProject = async () => {
    await Swal.fire({
      title: "Atenção!",
      text: `Deseja se desvincular do Projeto (${timerValues.projetoValue.label})?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Desvincular",
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.value) {
        try {
          const idProject = timerValues.projetoValue.value
          await api.makeHttpRequest({
            url: "/professional/remove-project/" + idProject,
            method: "PATCH"
          });
          loadClientProps(timerValues.cliente)
          loadUserProjects(timerValues.cliente)
          user.projects = user.projects.filter((up) => up.idProject !== idProject)
          loadProjects(timerValues.cliente, user.projects);
          setTimerValues({
              ...timerValues,
              projeto: '',
              projetoValue: '',
              solicitante: '',
              atividade: ''
          });
          setReload(true);
        } catch (e) {
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao remover projeto.",
            "error"
          );
        }
        setReload(true);
      }
    });
  }

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle title="Timer" icon={TimerIcon} disableHeader />
        <Card className="mt-4">
          <TimerCardBody>
            <div className="avatar">
              {user?.profilePictureURL ? (
                <>
                  <img src={user.profilePictureURL} alt="Avatar" />
                </>
              ) : (
                <img src={avatarPlaceholder} alt="Avatar" />
              )}
            </div>
            <StyledForm>
              <div className="header">
                <h2 className="card-title">
                  Olá, <strong>{user?.name ?? "Usuário"}</strong>
                </h2>
                <div className="radio-toggle">                  
                    <span
                        className={!auto && manual ? "active" : ""}
                        onClick={() => {
                        setAuto(false)
                        setManual(true)
                        setCompound(false)
                        setSimplified(false)
                        newTimerValue.startFirstTimer = null;
                        newTimerValue.endFirstTimer = null;
                        newTimerValue.startSecondTimer = null;
                        newTimerValue.endSecondTimer = null;
                        newTimerValue.profissional = undefined;
                        setInformedTime("00:00:00")
                        setTimerValues(newTimerValue);
                        setUserProfessional(undefined)
                        }}
                    >
                        Manual <ListIcon />
                    </span>

                    <span
                        className={!auto && compound ? "active" : ""}
                        onClick={() => {
                        setInformedTime("00:00:00")
                        setAuto(false)
                        setManual(false)
                        setCompound(true)
                        setSimplified(false)
                        setTimerValues({...timerValues, profissional:undefined})
                        setUserProfessional(undefined)
                        }}
                    >
                        Composto <ListIcon />
                    </span>

                    {(UserTypeRole === ERoles.CONSULTANTSIMPLIFIED || UserTypeRole === ERoles.ADMIN || UserTypeRole === ERoles.OPERATION) && (
                    <span
                        className={!auto && simplified ? "active" : ""}
                        onClick={() => {
                        setInformedTime("00:00:00")
                        setAuto(false)
                        setManual(false)
                        setCompound(false)
                        setSimplified(true)
                        }}
                    >
                        Simplificado <ListIcon />
                    </span>
                    )}
                </div>
              </div>

              {/* row 1 */}
              <Form.Row>
                {/* Cliente */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    as="select"
                    name="cliente"
                    label="Cliente"
                    // pegar o valor do primeiro cliente
                    value={
                      timerValues.cliente || (
                      clientOptions.length > 0 ? clientOptions[0].idClient : '')
                    }
                    onChange={handleTimerChange}
                    disabled={auto && started}
                    required
                  >
                    {clientOptions?.length > 0 &&
                      clientOptions.map(c => (
                        <option key={c.idClient} value={c.idClient}>
                          {c.name}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>

                {/* Projeto */}
                <Form.Group as={Col} lg={timerValues.projetoValue ? "4" : "6"} xs="12">
                  <InputField
                    as="select2"
                    name="projeto"
                    label="Projeto/Squad"
                    closeMenuOnSelect={true}
                    value={timerValues.projetoValue}
                    onChange={item =>
                      setTimerValues({
                        ...timerValues,
                        projeto: item.value,
                        projetoValue: item,
                        solicitante: item.solicitante
                      })
                    }
                    disabled={!timerValues.cliente}
                    required
                    options={projOptions}
                  />
                  {(!allocatedToProj) && (
                    <WarningMsg>
                      * Profissional não alocado a este projeto
                    </WarningMsg>
                  )}
                </Form.Group>

                {/* Botão Desvincular Projeto */}
                {timerValues.projetoValue && 
                <Form.Group as={Col} lg="2" xs="12">
                  <Button type="button" onClick={handleRemoveProject}>Desvincular Projeto</Button>
                </Form.Group>}
              </Form.Row>

              {/* row 2 */}
              <Form.Row>
                {/* Atividade */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="textarea"
                    name="atividade"
                    label="Atividade"
                    placeholder="Descreva a atividade (mín. 10)"
                    value={timerValues.atividade}
                    onChange={handleTimerChange}
                    isInvalid={
                      timerValues.atividade &&
                      !IsValidDescricao(timerValues.atividade)
                    }
                    disabled={auto && started}
                    required
                  />
                </Form.Group>

                {/* Solicitante da Demanda */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="solicitante"
                    label="Solicitante da Demanda"
                    placeholder="Digite o nome do solicitante"
                    value={timerValues.solicitante}
                    onChange={handleTimerChange}
                    isInvalid={
                      timerValues.solicitante &&
                      !IsValidName(timerValues.solicitante)
                    }
                    disabled={
                      (auto && started) || !allowEditClientDemandRequester
                    }
                    endAdornment={
                      <div
                        title="Toggle edição do solicitante da demanda"
                        onClick={() =>
                          setAllowEditClientDemandRequester(
                            !allowEditClientDemandRequester
                          )
                        }
                      >
                        {allowEditClientDemandRequester ? (
                          <FiEdit3 color="#4672C5" size="20px" />
                        ) : (
                          <FiEdit3 color="#807E80" size="20px" />
                        )}
                      </div>
                    }
                    required
                  />
                </Form.Group>

                {/* Demanda */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="demanda"
                    label="IDJira / Demanda"
                    placeholder="Digite a demanda"
                    value={timerValues.demanda}
                    onChange={handleTimerChange}
                    disabled={auto && started}
                  />
                </Form.Group>
              </Form.Row>

              {simplified && (
                <>
                  <Form.Row>
                    {
                      (UserTypeRole === ERoles.OPERATION || UserTypeRole === ERoles.ADMIN) &&
                      <Form.Group as={Col} lg="4" xs="12">
                        <InputField
                          as="select2"
                          name="professional"
                          label="Profissional"
                          placeholder="Selecione..."
                          closeMenuOnSelect={true}
                          isClearable={true}
                          value={timerValues.profissional || undefined}
                          options={professionalsOptions || ""}
                          onChange={e => {
                            setTimerValues({
                              ...timerValues,
                              profissional: e
                            })
                            if (e !== null)
                              loadProfessional(e.value)
                            else
                              loadProfessional(undefined)
                          }
                          }
                        />
                      </Form.Group>
                    }

                    <TimerSimplified
                      handleSetHours={handleSetHoursSimplified}
                      reload={reload}
                    />

                    {!loading && 
                    <DateFilter
                      component={FilterComponent}
                      reload={reload}
                      setFilter={handlePeriodFilter}
                      initialValues={initialFilterDates}
                      initialCycle={filter.initialCycleDay}
                      newValues={{
                        startDate: filter.startPeriod,
                        endDate: filter.endPeriod
                      }}
                    />}
                    <span className="period">
                      {`${moment.utc(filter.startPeriod)
                        .format("DD/MM/YYYY")} - ${moment(filter.endPeriod).format(
                          "DD/MM/YYYY"
                        )}`}
                    </span>
                  </Form.Row>
                </>

              )}

              {/* row 3 */}
              <Form.Row className="row-3">
                <TimerAttachment>
                  {UserTypeRole !== ERoles.FACTORY && (
                    <div className="checkbox-input">
                      <InputField
                        type="checkbox"
                        name="horaExtra"
                        value={timerValues.horaExtra}
                        label="H.E."
                        onChange={handleTimerChange}
                        labelRed
                        disabled={auto && started}
                      />
                    </div>
                  )}

                  <div className="file-input">
                    <input
                      type="file"
                      accept={ACCEPTED_FORMATS}
                      name="timerDoc"
                      ref={fileInput}
                      onChange={handleTimerDoc}
                      disabled={auto && started}
                    />

                    <div
                      className="label"
                      onClick={() => fileInput.current.click()}
                    >
                      <span className="icon">
                        <FiPaperclip size={23} color="#4672C5" />
                      </span>
                      <span className="text" ref={timerDoc}>
                        {timerValues.timerDoc
                          ? timerValues.timerDoc.name
                          : `Adicionar anexo ${timerValues.horaExtra ? "*" : ""
                          }`}
                      </span>
                    </div>
                    {timerValues.timerDoc && (
                      <div
                        className="remove-button"
                        onClick={handleRemoveAnexo}
                      >
                        <FiX color="#333" size="20px" />
                      </div>
                    )}
                  </div>
                </TimerAttachment>

                <TimerControls>
                    {!auto && !compound && !simplified && (
                    <div className="manual">
                      {!user?.idUser ? null : (
                        <TimerPicker
                          idClient={timerValues.cliente}
                          handleDates={handleSetInformedDates}
                          reload={reload}
                          isLoadTimers={user}
                        />
                      )}
                      <div className="play-add-btn">
                        <span>{informedTime}</span>
                        <button
                          className="add-btn"
                          type="button"
                          onClick={handleAdd}
                          disabled={started || !formIsValid(true, 'manual')}
                        >
                          <PlusIcon />
                          adicionar
                        </button>
                      </div>
                    </div>
                  )}

                  {compound && (
                    <div className="compound">
                      {!user?.idUser ? null : (
                        <TimerPicker
                          idClient={timerValues.cliente}
                          handleDates={handleSetInformedDatesComposto}
                          reload={reload}
                          inputWidth="90px"
                          isLoadTimers={user}
                          compound
                        />
                      )}
                      <div className="play-add-btn">
                        <span>{informedTime}</span>
                        <button
                          className="add-btn"
                          type="button"
                          onClick={handleAddCompound}
                          disabled={started || !formIsValid(true, 'compound')}
                        >
                          <PlusIcon />
                          adicionar
                        </button>
                      </div>
                    </div>
                  )}

                  {simplified && (
                    <div className="play-add-btn">
                      <button
                        className="add-btn"
                        type="button"
                        onClick={handleAddSimplified}
                        disabled={started || !formIsValid(true, 'simplified')}
                      >
                        <PlusIcon />
                        adicionar
                      </button>
                    </div>
                  )}
                </TimerControls>
              </Form.Row>
            </StyledForm>
          </TimerCardBody>
        </Card>
        <br/>
        <TabsContainer>
          <Tabs activeKey={tabSelected} transition={false} onSelect={tab => handleSetTab(tab)}>
            <Tab eventKey="cliente" title="Cliente">
              <Card>
                <Card.Body style={{padding: '0 16px'}}>
                <FilterInfoLine>
                  <span className="period">
                    {`${moment.utc(filter.startPeriod)
                      .format("DD/MM/YYYY")} - ${moment(filter.endPeriod).format(
                        "DD/MM/YYYY"
                      )}`}
                  </span>
                  <div className="filter-info">
                    <div className="cell">
                      {!simplified && !loading && (
                        <DateFilter
                          component={FilterComponent}
                          reload={reload}
                          setFilter={handlePeriodFilter}
                          initialValues={initialFilterDates}
                          initialCycle={filter.initialCycleDay}
                          newValues={{
                            startDate: filter.startPeriod,
                            endDate: filter.endPeriod
                          }}
                        />
                      )}
                    </div>
                    <div className="divider" />
                    <div className="cell">
                      <IconButton
                        size="small"
                        title="Extrair planilha"
                        onClick={() => handleExportTimer()}
                      >
                        <BsDownload size={20} />
                      </IconButton>
                    </div>
                    <div className="divider" />
                    <div className="cell">
                      {auth?.user.showReceiveTotal && <a onClick={() => setOpenReceiveModal(true)}>
                        <span style={{color: '#4672C5'}}>Total a receber no ciclo:</span>
                        <span className="time" style={{paddingLeft: '16px', color: '#4672C5'}}>R$ {totalCycleWage}</span>
                      </a>}
                      <span>Total de horas úteis do ciclo:</span>
                      <span className="time">{workingHours}h</span>
                      <span>Total de horas lançadas:</span>
                      <span className="time">{timerSum}</span>
                    </div>
                  </div>
                </FilterInfoLine>
                <div className="flex-grow-1">
                  {!user?.idUser ? null : (
                    <TimerDatable
                      user={userProfessional ?? user}
                      reload={reload}
                      setReload={setReload}
                      setLoading={setLoading}
                      reloadRunningTimer={reloadRunningTimer}
                      runningTime={runningTime}
                      clientOptions={clientOptions}
                      professionalsOptions={professionalsOptions}
                      filter={filter}
                      handleRefillTimeinfo={handleRefillTimeinfo}
                      modalOptions={timerValues.modalOptions}
                      screenTag={manual}
                      simplified={simplified}
                      setSimplified={setSimplified}
                      manual={manual}
                      setManual={setManual}
                      compound={compound}
                      setCompound={setCompound}
                      factory={false}
                      setFactory={() => {}}
                      projectFilter={timerValues.projetoValue}
                    />
                  )}
                </div>
                </Card.Body>
              </Card>
            </Tab>
            {clientOptions?.length > 1 && <Tab eventKey="geral" title="Geral">
              <Card>
                <Card.Body style={{padding: '24px'}}>
                  <Form.Row>
                    {/* Mês/Ano fechamento */}
                    <Form.Group as={Col} lg="3" xs="12">
                      <InputField
                        type="date"
                        name="generalDate"
                        label="Período de Consolidação"
                        dateFormat={"MMMM yyyy"}
                        value={generalDate}
                        onChange={(e) => {
                          setGeneralDate(e.target.value);
                          handleSetTab('geral', e.target.value)
                        }}
                        showMonthYearPicker={true}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <TimerDatable
                      user={userProfessional ?? user}
                      reload={reload}
                      setReload={setReload}
                      setLoading={setLoading}
                      reloadRunningTimer={reloadRunningTimer}
                      runningTime={runningTime}
                      clientOptions={clientOptions}
                      professionalsOptions={professionalsOptions}
                      filter={filter}
                      handleRefillTimeinfo={handleRefillTimeinfo}
                      modalOptions={timerValues.modalOptions}
                      screenTag={manual}
                      simplified={simplified}
                      setSimplified={setSimplified}
                      manual={manual}
                      setManual={setManual}
                      compound={compound}
                      setCompound={setCompound}
                      factory={false}
                      setFactory={() => {}}
                      userProjects={userProjects}
                      projectFilter={timerValues.projetoValue}
                    />
                  </Form.Row>
                </Card.Body>
              </Card>
            </Tab>}
          </Tabs>
        </TabsContainer>
      </div>
      {receiveProjects.length > 0 && <ReceiveModal 
        open={openReceiveModal}
        setOpen={setOpenReceiveModal}
        receiveProjects={receiveProjects}
      />}
    </>
  );
};

export default Timer;