import React, { forwardRef } from "react";
import { pt } from "date-fns/locale";
import { components } from "react-select";
import DatePicker from "react-datepicker";
import { BsChevronDown } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { DateTimeIcon } from "../../../../components/IconsSVG";
import { InputGroup } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { parse } from 'date-fns';
import {
  ContainerSelect2,
  DateContainer,
  InputContainer,
  StyledCreatableSelect2,
  StyledInput,
  StyledSelect2,
  GroupHeadingContainer,
  OptionContainer,
  ErrorMessage
} from "./style";

import iconChecked from "../../../../assets/icons/icon-check-fill.svg";
import iconNotChecked from "../../../../assets/icons/icon-check-stroke.svg";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <BsChevronDown />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = props => {
  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.selectProps.onEdit(props.data);
  };

  const handleRemoveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.selectProps.onDelete(props.data);
  };

  return (
    
    <div style={{ display: 'flex', gap: '1px' }}>
      {
        props.selectProps.isEditable && 
        <div className="select__multi-value__remove">
          <div className="border" onClick={handleEditClick} style={{cursor: 'pointer'}}>
            <MdEdit size="12px" />
          </div>
        </div>
      }
      <components.MultiValueRemove {...props}>
          <div className="border" onClick={handleRemoveClick}>
            <IoMdClose size="12px" />
          </div>
      </components.MultiValueRemove>
    </div>
  );
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <div className="border">
        <IoMdClose size="12px" />
      </div>
    </components.ClearIndicator>
  );
};

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      Sem opções
    </components.NoOptionsMessage>
  );
};

const GroupHeading = props => (
  <GroupHeadingContainer>
    <components.GroupHeading {...props} />
    <div className="divider"></div>
  </GroupHeadingContainer>
);

const Option = props => (
  <OptionContainer>
    <components.Option {...props} />
  </OptionContainer>
)

const poppupOptions = [
  {
    name: "offset",
    options: {
      offset: [-10, 0]
    }
  }
];

export const InputField = props => {
  const input = () => {
    if (props.as === "select2") {
      if (props.creatable) {
        return (
          <ContainerSelect2 className={props.className} isDisabled={props.disabled}>
            <StyledCreatableSelect2
              classNamePrefix="select"
              isMulti={props.isMulti ?? false}
              value={props.value}
              placeholder={props.placeholder ?? "Selecione..."}
              closeMenuOnSelect={props.closeMenuOnSelect ?? false}
              name={props.name}
              options={props.options}
              onChange={props.onChange}
              isDisabled={props.disabled}
              style={props.disabled ? { cursor: 'not-allowed' } : {}}
              formatCreateLabel={newItem => (
                <span className="select__create-option">{`Criar ${newItem}...`}</span>
              )}
              components={{
                DropdownIndicator,
                MultiValueRemove,
                ClearIndicator,
                NoOptionsMessage,
                GroupHeading,
                Option
              }}
            />
            <span className="label">
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </span>
          </ContainerSelect2>
        );
      } else {
        return (
          <ContainerSelect2 className={props.className} isDisabled={props.disabled}>
            <StyledSelect2
              classNamePrefix="select"
              isMulti={props.isMulti ?? false}
              value={props.value}
              placeholder={props.placeholder ?? "Selecione..."}
              closeMenuOnSelect={props.closeMenuOnSelect ?? false}
              name={props.name}
              options={props.options}
              onChange={props.onChange}
              onEdit={props.onEdit}
              onDelete={props.onDelete}
              isDisabled={props.disabled}
              isEditable={props.editable}
              style={props.disabled ? { cursor: 'not-allowed' } : {}}
              components={{
                DropdownIndicator,
                MultiValueRemove,
                ClearIndicator,
                NoOptionsMessage,
                GroupHeading,
                Option
              }}
            />
            <span className="label">
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </span>
          </ContainerSelect2>
        );
      }
    }

    if (props.as === "text2") {
      return (
        <ContainerSelect2 className={props.className}>
          <StyledSelect2
            classNamePrefix="select"
            isMulti={false}
            value={props.value}
            placeholder={props.placeholder ?? "Selecione..."}
            closeMenuOnSelect={false}
            name={props.name}
            inputValue={props.textValue}
            onInputChange={props.onChangeInput}
            options={props.options}
            onChange={props.onChange}
            isDisabled={props.disabled}
            components={{
              DropdownIndicator,
              MultiValueRemove,
              ClearIndicator,
              NoOptionsMessage,
              GroupHeading,
              Option
            }}
          />
          <span className="label">
            {props.label}{" "}
            {props.required && <span className="required">*</span>}
          </span>
        </ContainerSelect2>
      );
    }

    const DateCustomInput = forwardRef(({ value, onClick, onChange, name, disabled }, ref) => (
      <input
        onClick={onClick}
        value={value}
        placeholder="Selecione a data"
        ref={ref}
        readOnly={true}
        onPaste={(event) => {
          const pastedValue = event.clipboardData.getData('text/plain');
          const parsedDate = parse(pastedValue, 'dd/MM/yyyy', new Date(), { locale: pt });
          onChange({ target: { name: name, value: parsedDate } });
        }}
        disabled={disabled}
      />
    ));

    if (props.type === "date") {
      return (
        <DateContainer className={props.className}>
        <div className="input-container">
          <DatePicker
            adjustDateOnChange={true}
            showMonthDropdown={true}
            showYearDropdown={true}
            dropdownMode="select"
            selected={props.value}
            customInput={<DateCustomInput name={props.name} onChange={props.onChange} disabled={props.disabled} />}
            onChange={date =>
              props.onChange({ target: { name: props.name, value: date } })
            }
            locale={pt}
            placeholderText={props.placeholder ?? "Selecione a data"}
            dateFormat={props.dateFormat ?? "dd/MM/yyyy"}
            showPopperArrow={false}
            showMonthYearPicker={props.showMonthYearPicker}
            showYearPicker={props.showYearPicker}
            minDate={props.minDate}
            maxDate={props.maxDate}
            peekNextMonth={true}
            popperClassName="datetime-popper"
            popperModifiers={poppupOptions}
            popperPlacement="bottom-start"
            isClearable={props.isClearable ?? false}
            disabled={props.disabled}
          />
          <div className="date-icon">
            <DateTimeIcon />
          </div>
        </div>
        {props.label && (
          <span className="label">
            {props.label}{" "}
            {props.required && <span className="required">*</span>}
          </span>
        )}
      </DateContainer>
      );
    }

    if (props.type === "checkbox") {
      return (
        <InputContainer labelRed={props.labelRed} className={props.className}>
          <FormControlLabel
            control={
              <Checkbox
                name={props.name}
                checked={props.value}
                icon={<img src={iconNotChecked} alt="Não selecionado" />}
                checkedIcon={<img src={iconChecked} alt="Selecionado" />}
                onChange={props.onChange}
                disabled={props.disabled}
              />
            }
            label={props.label}
          />
        </InputContainer>
      );
    }

    if ((props.as === undefined || props.as === null) && props.prefix) {
      return (
        <InputContainer className={props.className}>
          <InputGroup>
            <InputGroup.Text>{props.prefix}</InputGroup.Text>
            <StyledInput
              type={props.type ? props.type : "text"}
              autoComplete="off"
              name={props.name}
              placeholder={props.placeholder ?? "Digite os dados"}
              maxLength={props.maxLength}
              style={props.style}
              value={props.value}
              isInvalid={props.isInvalid}
              onBlur={props.onBlur}
              onChange={props.onChange}
              required={props.required}
              disabled={props.disabled}
            />
            {props.label && (
              <span className="label">
                {props.label}{" "}
                {props.required && <span className="required">*</span>}
              </span>
            )}
            {props.as === "select" && (
              <div className="chevron">{<BsChevronDown />}</div>
            )}
            {props.isInvalid && props.errorMessage && (
              <InputGroup.Text className="invalid-feedback">
                {props.errorMessage}
              </InputGroup.Text>
            )}
          </InputGroup>
        </InputContainer>
      );
    }

    return (
      <InputContainer className={props.className}>
        <StyledInput
          type={props.type ?? "text"}
          as={props.as}
          autoComplete="off"
          name={props.name}
          placeholder={props.placeholder ?? "Digite os dados"}
          maxLength={props.maxLength}
          style={props.style}
          value={props.value}
          rows={props.rows}
          isInvalid={props.isInvalid}
          onChange={props.onChange}
          onBlur={props.onBlur}
          required={props.required}
          disabled={props.disabled}
          onKeyPress={props.onKeyPress}
          className={
            props.as === "select" && props.value === ""
              ? "placeholder"
              : props.as === "textarea" && props.isInvalid
              ? "is-invalid"
              : ""
          }
        >
          {props.children}
        </StyledInput>

        {props.label && (
          <span className="label">
            {props.label}{" "}
            {props.required && <span className="required">*</span>}
          </span>
        )}

        {props.as === "select" && (
          <div className="chevron">{<BsChevronDown />}</div>
        )}

        {props.endAdornment !== undefined && (
          <div className="end-adornment">{props.endAdornment}</div>
        )}
        
        {props.isInvalid && props.errorMessage && (
          <ErrorMessage>{props.errorMessage}</ErrorMessage>
        )}
      </InputContainer>
    );
  };

  return input();
};
