import { styled, TextField } from "@material-ui/core";

export const StyledTextField = styled(TextField)({
  "& label, label.Mui-focused": {
    color: "#4D4C4D"
  },
  "& .MuiInputLabel-root.Mui-focused:not(.Mui-error)": {
    color: "#4D4C4D !important"
  },
  "& .MuiOutlinedInput-root, .MuiInputBase-root": {
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "#4D4C4D"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4D4C4D",
      borderWidth: "1px"
    }
  }
});
