import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserIcon } from "../../../../../../components/IconsSVG";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import store from "../../../../../../store/store";
import { ApprovalDatatable } from "../ApprovalDatatable";
import { ProposalModal } from "../ProposalModal";
import { formatTotalTime } from "../../../../../../utils/FormatTotalTime";

import { StyledAccordion } from "../../../../components/Accordion/styles";
import { CustomAccordionBody } from "../../../../components/Accordion";

export const ApprovalCardFactory = ({
  title,
  timerList,
  totalTime
}) => {
  const { auth } = store.getState();
  const [approvedList, setApprovedList] = useState([]);

  const handleAddAprovedList = newList => {
    setApprovedList(newList);
  };

  return timerList?.length <= 0 ? null : (
    <StyledAccordion shadow="true">
      <CustomAccordionBody
        eventKey="0"
        title={title}
        icon={UserIcon}
        totalTime={formatTotalTime(totalTime)}
      >
        <ApprovalDatatable
          timerList={timerList}
          isFactory
          approvedList={approvedList}
          setApprovedList={handleAddAprovedList}
        />
      </CustomAccordionBody>
    </StyledAccordion>
  );
};
