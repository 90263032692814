export function TranslateProfileType(value) {
  switch (value) {
    case "ADMIN":
      return "Administrator";
    case "OPERATION":
      return "Operações";
    case "COMMERCIAL":
      return "Comercial";
    case "FINANCIAL":
      return "DP / Financeiro"
    case "RECRUITMENT":
      return "R&S";
    case "CONSULTANT":
      return "Consultor";
    case "FACTORY":
      return "Fábrica";
    case "CONSULTANTSIMPLIFIED":
      return "Consultor Simplificado";
    default:
      return value;
  }
}
