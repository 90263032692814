import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import IsValidName from "../../../../utils/validators/IsValidName";

const initialState = {
  id: 0,
  nome: "",
  descricao: "",
  videoLink: "",
  trainingSubgroup: {},
  isLoading: false,
  persistedModel: "",
  trainingSubgroups: []
};

export default class TrainingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            trainingGroupsOptions: [],
            isEditing: false
        };

        this.api = new InexApiService();

        this.loadTrainingSubgroups = this.loadTrainingSubgroups.bind(this);
        this.loadTraining = this.loadTraining.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onCleanForm = this.onCleanForm.bind(this);
        this.submit = this.submit.bind(this);
    }

    async componentDidMount() {
        const isEditing = !!this.props.match.params.id;
        this.setState({ isLoading: true, isEditing });
        try {
            await this.loadTrainingSubgroups();
            if (this.props.match.params.id) {
                await this.loadTraining(this.props.match.params.id);
            }
        } catch (error) {
            Swal.fire(
                "Erro!",
                error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
                "error"
            );
        }

        this.setState({ isLoading: false });
    }

    loadTrainingSubgroups = async () => {
        let trainingSubgroupListLocal = [];
        const trainingSubgroupListfull = await this.api.makeHttpRequest({
            url: "/trainingsubgroup/getAllActiveTrainingSubgroups"
        });
        
        for (let i in trainingSubgroupListfull){
            trainingSubgroupListLocal.push({  value: trainingSubgroupListfull[i].idTrainingSubgroup, label: trainingSubgroupListfull[i].name })
        }   
        

        this.setState({
            trainingSubgroupOptions: trainingSubgroupListLocal,
            trainingSubgroup: trainingSubgroupListLocal[0]
        });
    };

    loadTraining = async id => {
        const training = await this.api.makeHttpRequest({
            url: `/training/${id}`
        });
        
        await this.loadTrainingSubgroups();

        const persistedModel = {
            id: id,
            nome: training.name,
            descricao: training.description,
            videoLink: training.videoLink
        };

        const persistedModelJson = JSON.stringify(persistedModel);
        this.setState({
            ...persistedModel,
            trainingSubgroup: {value: training.idTrainingSubgroup, label: training.subgroup },
            isLoading: false,
            persistedModel: persistedModelJson
        });
    };

    submit = async e => {
        e.preventDefault();
        const id = this.props.match.params.id;
        this.setState({ isLoading: true });
        try {
        const reqBase = id
            ? { method: "PUT", url: `/training/update/${id}` }
            : { method: "POST", url: "/training/save" };

        await this.api.makeHttpRequest({
            ...reqBase,
            data: {
                name: this.state.nome,
                description: this.state.descricao,
                videoLink: this.state.videoLink,
                idTrainingSubgroup: this.state.trainingSubgroup.value
            }
        });

        this.afterSubmit();
        } catch (e) {
        Swal.fire(
            "Erro!",
            e?.response?.data
            ? e.response.data
            : "Erro ao cadastrar treinamento, tente novamente mais tarde.",
            "error"
        );
        }

        this.setState({ isLoading: false });
    };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/treinamentos");
    }
  };

  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      nome: this.state.nome,
      descricao: this.state.descricao,
      videoLink: this.state.videoLink
    };

    const currentModelJson = JSON.stringify(currentModel);

    return (
      this.state.nome &&
      IsValidName(this.state.nome)
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadTraining(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  handleChange = (e, select2Event) => {
    if (select2Event?.name === "trainingSubgroup") {
        return this.setState({ trainingSubgroup: e });
    }
    
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Treinamento - Admin");
    } else {
      window.setPageTitle("Cadastro de Treinamento - Admin");
    }

    const {
      nome,
      descricao,
      videoLink,
      trainingSubgroup,
      trainingSubgroupOptions,
      isLoading,
      isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Treinamento" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Nome */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="nome"
                    label="Nome"
                    placeholder="Digite o nome"
                    value={nome}
                    onChange={this.handleChange}
                    isInvalid={nome && !IsValidName(nome)}
                    required
                  />
                </Form.Group>
                {/* Descrição */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="descricao"
                    label="Descrição"
                    placeholder="Digite a descrição"
                    value={descricao}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                {/* Link de Vídeo */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="videoLink"
                    label="Link de Vídeo"
                    placeholder="Cole o link do vídeo"
                    value={videoLink}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
              </Form.Row>
              {/* row 2 */}
              <Form.Row>
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="trainingSubgroup"
                    label="Subgrupos"
                    value={trainingSubgroup}
                    onChange={this.handleChange}
                    placeholder="Selecione o subgrupo"
                    options={trainingSubgroupOptions}
                    closeMenuOnSelect={true}
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}
