/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import ModalAlterPassword from "./components/ModalAlterPassword/index";
import Swal from "sweetalert2";
import "./style.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import EnterIcon from "../../assets/icons/icon_enter.svg";
import ConfigIcon from "../../assets/icons/icon_settings.svg";
import UserConfigIcon from "../../assets/icons/icon_user_settings.svg";
import LockerIcon from "../../assets/icons/icon_locker.svg";
import ExitIcon from "../../assets/icons/icon_exit.svg";
import BellIcon from "../../assets/icons/icon_bell.svg";
import { bindActionCreators } from "redux";
import ModalUserSettings from "./components/ModalUserSettings";
import { InexApiService } from "../../services/InexApiService";
import { Collapse, Card, Button } from 'react-bootstrap';
import moment from "moment";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      statusModal: false,
      statusModalUserSettings: false,
      viewModal: false,
      sucess: false,
      disabledClickOut: false,
      openIndex: null,
      notifications: []
    };
    
    this.api = new InexApiService();
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(() => {
      this.fetchData();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchData = async () => {
    const response = await this.api.makeHttpRequest({
      url: `/notification/all/user`
    });
    
    this.setNotifications(response)
  }

  setNotifications = notifications => {
    this.setState({ notifications });
  };

  showMensage(sucess, error) {
    if (error) {
      Swal.fire(
        "Erro!",
        error ? error : "Erro ao alterar o status, tente novamente mais tarde.",
        "error"
      );
    } else if (sucess) {
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    }
  }

  handleItemClick = (index, event) => { 
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState) => ({
      openIndex: prevState.openIndex === index ? null : index,
    }));
  };

  render() {
    const { user, showHi, showBadge, history } = this.props;
    const { open } = this.state;

    return (
      <>
        <ModalAlterPassword
          open={this.state.statusModal}
          setOpen={e => this.setState({ statusModal: e })}
          bg="white"
          result={(sucess, error) => {
            this.showMensage(sucess, error);
            this.setState({ statusModal: false });
          }}
        />

        <ModalUserSettings
          open={this.state.statusModalUserSettings}
          setOpen={e => this.setState({ statusModalUserSettings: e })}
          bg="white"
          result={(sucess, error) => {
            this.showMensage(sucess, error);
            this.setState({ statusModalUserSettings: false });
          }}
        />

        {
          this.state.notifications && this.state.notifications.length > 0  && 
          <Dropdown
            className="kt-header__topbar-item kt-header__topbar-item--user"
            drop="down"
            alignRight
            autoclose="none"
          >
            <Dropdown.Toggle
              as={HeaderDropdownToggle}
              id="dropdown-toggle-user-profile"
            >
              <div style={{position: 'relative', display: 'inline-block', alignContent: 'center', marginRight: '10px'}}>
                <img src={BellIcon} alt="" style={{width: '15px'}} />
                <span style={{
                  position: 'absolute',
                  top: '3px',
                  right: '-7px',
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '50%',
                  padding: '3px 6px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                }}>
                  {this.state.notifications.length}
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl"
              style={{
                width: "329px",
                boxShadow:
                  "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
              }}
            >
              {
                this.state.notifications.map((notification, index) => 
                  <div 
                    className="kt-notification" 
                    style={{cursor: 'pointer'}} 
                    key={index} 
                    onClick={(e) => e.stopPropagation()}>
                    <Card>
                      <Card.Header 
                        className={`option-notification ${notification.awarenessDate ? 'aware' : ''}`}
                        aria-expanded={this.state.openIndex === index}
                        aria-controls={`collapse-text-${index}`}
                        onClick={(e) => this.handleItemClick(index, e)}
                      >
                        <div>
                          <strong>{notification.title}</strong> Criado em: {moment(notification.creationDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                      </Card.Header>
                      <Collapse in={this.state.openIndex === index}>
                        <Card.Body className={`option-notification-text ${notification.awarenessDate ? 'aware' : ''}`} id={`collapse-text-${index}`}>
                          <div>
                            {notification.message}
                          </div>
                          {notification.link && <div>
                            <a href={notification.link}>{notification.link}</a>
                          </div>}
                        </Card.Body>
                      </Collapse>
                    </Card>
                  </div>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        }
        
        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--user"
          drop="down"
          alignRight
          style={{ cursor: "pointer" }}
          autoclose="inside"
        >
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-profile"
          >
            <div className="kt-header__topbar-user">
              {showHi && (
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
              )}

              {showHi && (
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {user.name}
                </span>
              )}

              {showBadge && (
                <span className="kt-badge kt-badge--username kt-badge--unified-homeBlue kt-badge--lg kt-badge--rounded kt-badge--bold">
                  {user && user.name[0].toUpperCase()}
                </span>
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl"
            style={{
              width: "329px",
              boxShadow:
                "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
            }}
          >
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
              <div className="kt-user-card__avatar">
                <BsPerson strokeWidth=".4px" size="30px" color="#4672C5" />
              </div>
              <div className="kt-user-card__name">Olá, {user.name}</div>
            </div>
            <div className="kt-notification">

              {user && user.role.type !== "PROSPECT" && (
                <>
                  <Dropdown.Item as="div">
                    {!history.location.pathname.includes("/admin") && (
                      <>
                        <div className="dropdown-divider m-0"></div>

                        <div
                          className="col-12 p-4 option-user-profile"
                          onClick={() => history.push("/admin")}
                        >
                          <img src={EnterIcon} className="mr-2" alt="" />
                          <div className="col-10 kt-font-inverse-optionSelected">
                            <div>
                              <strong className="kt-font-boldest">
                                Acesso interno
                              </strong>
                            </div>
                            <div>Acessar a área administrativa</div>
                          </div>
                          <div className="ml-2">
                            <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                          </div>
                        </div>
                      </>
                    )}
                  </Dropdown.Item>

                  <div className="dropdown-divider m-0"></div>
                  <Dropdown.Item as="div">
                    <div
                      className="col-12 p-4 option-user-profile"
                      onClick={() => history.push("/admin/editar/perfil")}
                    >
                      <img src={ConfigIcon} className="mr-2" alt="" />
                      <div className="col-10 kt-font-inverse-optionSelected">
                        <div>
                          <strong>Editar Conta</strong>
                        </div>
                        <div>Alterar informações da conta</div>
                      </div>
                      <div className="ml-2">
                        <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                      </div>
                    </div>
                  </Dropdown.Item>
                </>
              )}

              <div className="dropdown-divider m-0"></div>
              <Dropdown.Item as="div">
                <div
                  className="col-12 p-4 option-user-profile"
                  onClick={() => this.setState({ statusModal: true })}
                >
                  <img src={LockerIcon} className="mr-2" alt="" />
                  <div className="col-10 kt-font-inverse-optionSelected">
                    <div>
                      <strong>Minha Senha</strong>
                    </div>
                    <div>Alterar a senha de acesso</div>
                  </div>
                  <div className="ml-2">
                    <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                  </div>
                </div>
              </Dropdown.Item>
              <div className="dropdown-divider m-0"></div>
              <Dropdown.Item as="div">
                <div
                  className="col-12 p-4 option-user-profile"
                  onClick={() => this.setState({ statusModalUserSettings: true })}
                >
                  <img src={UserConfigIcon} className="mr-2" alt="" />
                  <div className="col-10 kt-font-inverse-optionSelected">
                    <div>
                      <strong>Preferências do Usuário</strong>
                    </div>
                    <div>Alterar preferências do usuário</div>
                  </div>
                  <div className="ml-2">
                    <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                  </div>
                </div>
              </Dropdown.Item>
              <div className="dropdown-divider m-0"></div>
              <Dropdown.Item as="div">
                <div
                  className="col-12 p-4 option-user-profile"
                  onClick={() => {
                    this.setState({ statusModal: true });
                    this.props.logout();
                  }}
                >
                  <img src={ExitIcon} className="mr-2" alt="" />
                  <div>
                    <strong className="kt-font-homeBlue">Sair</strong>
                  </div>
                </div>
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapActionsToProps = dispatch =>
  bindActionCreators({ logout: auth.actions.logout }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(UserProfile)
);
