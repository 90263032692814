import { Accordion, Card } from "react-bootstrap";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  display: flex;
  flex-direction: column;
  width: 100%;

  background: #ffffff;
  box-shadow: ${({ shadow }) =>
    shadow
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};
  border-radius: 4px;
  border: none;

  .kt-portlet {
    margin-bottom: 0px;
  }
`;

export const StyledAccordionHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px 8px 8px;
  z-index: 2;
  cursor: pointer;

  background-color: ${({ variant }) =>
    variant === "secondary" ? "#ECECEC" : "#4672C526"};

  .accordion-title {
    display: flex;
    align-items: center;

    font-family: "Cera Pro Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #4d4c4d;

    span {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    svg {
      fill: #4d4c4d;
      max-height: 20px;
    }

    h3{
      margin-top: 9px;
      font-size: 9px;
    }
  }

  .accordion-title-logs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
      min-width: 160px;
      margin-right: 0px;
    }
  }

  .toggle-icon {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;

      fill: #4672C5;
    }

    .approval-btn {
      display: flex;
      width: fit-content;

      & > button {
        margin: 0 10px;
      }
    }
  }

  .total-amount {
    font-family: "Cera Pro Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;

    margin-right: 12px;
  }
`;

export const StyledAccordionCardBody = styled(Card.Body)`
  padding: 0;

  .inputs-wrapper {
    margin: 37px 24px 24px;
  }
`;
