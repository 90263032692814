import React from "react";
import { Container } from "./styles";
import { GoToLastIcon, NextIcon } from "../../../../../../components/IconsSVG";

export const NewsPagination = ({
  page,
  perPage,
  pageCount,
  newsCount,
  changePage
}) => {
  const handleClick = name => {
    switch (name) {
      case "first":
        changePage(1);
        break;
      case "previous":
        if (page > 1) {
          changePage(page - 1);
        }
        break;
      case "next":
        if (page < pageCount) {
          changePage(page + 1);
        }
        break;
      case "last":
        changePage(pageCount);
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <span className="info">
        {`${page === 1 ? 1 : perPage * (page - 1) + 1} - ${
          perPage * page <= newsCount ? perPage * page : newsCount
        } de ${newsCount}`}
      </span>
      <div
        className={`icon first ${page === 1 ? "disabled" : ""}`}
        onClick={() => handleClick("first")}
      >
        <GoToLastIcon />
      </div>
      <div
        className={`icon previous ${page === 1 ? "disabled" : ""}`}
        onClick={() => handleClick("previous")}
      >
        <NextIcon />
      </div>
      <div
        className={`icon next ${page === pageCount ? "disabled" : ""}`}
        onClick={() => handleClick("next")}
      >
        <NextIcon />
      </div>
      <div
        className={`icon last ${page === pageCount ? "disabled" : ""}`}
        onClick={() => handleClick("last")}
      >
        <GoToLastIcon />
      </div>
    </Container>
  );
};
