import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { Button, StyledModal } from "./styles";

export const ProposalModal = ({ user, close, options, handleConfirm }) => {
  const [atividade, setAtividade] = React.useState("");

  React.useEffect(() => {
    if (user) {
      setAtividade("");
    }
  }, [user]);

  return (
    <>
      <StyledModal show={user} size="lg" centered>
        <Modal.Body>
          <h1 className="modal-title">
            Atenção! Essa opção é aplicada a todos os itens em destaque (tarja
            laranja). Selecione abaixo a atividade desse profissional no
            projeto:
          </h1>
          <Form>
            {/* Atividade do Profissional */}
            <Form.Group as={Col} lg="18" xs="12">
              <InputField
                as="select"
                name="atividade"
                label="Atividade do Profissional"
                value={atividade}
                onChange={e => setAtividade(e.target.value)}
              >
                <option value="">Selecione</option>
                {options?.length > 0 &&
                  options.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>
            <div className="btn-container">
              <Button type="button" onClick={close}>
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={!atividade}
                onClick={e => {
                  e.preventDefault();

                  if (!atividade) return;

                  handleConfirm(user, atividade);
                  close();
                }}
              >
                CONFIRMAR
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
