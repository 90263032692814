import Cosmic from "cosmicjs";
import { setData, getData } from "../utils/CacheStorage";

const api = Cosmic();
const bucket = api.bucket({
  slug: process.env.REACT_APP_COSMICJS_BUCKET_SLUG,
  read_key: process.env.REACT_APP_COSMICJS_BUCKET_READ_KEY
});

class CMSService {
  async getObject(slug, cached = true) {
    let response = cached ? this.getCachedAllObjects(slug) : null;

    if (
      response !== null &&
      response !== undefined &&
      response.object !== undefined
    ) {
      return response;
    }

    response = await bucket.getObject({
      slug
    });

    if (cached) {
      this.setCachedAllObjects(slug, response);
    }

    return response;
  }

  async getAllContents(
    type,
    props = "slug,title,content",
    sort = "created_at",
    cached = true,
    limit = 0,
    skip = 0,
    title = ""
  ) {
    let response = cached ? this.getCachedAllObjects(type) : null;

    if (
      response !== null &&
      response !== undefined &&
      response?.objects?.length > 0
    ) {
      if (limit) {
        response = this.paginateObjects(response, limit, skip);
      }

      return response;
    }

    response = await bucket.getObjects({
      type,
      sort,
      props,
      q: title
    });

    if (cached) {
      this.setCachedAllObjects(type, response);
    }

    if (limit) {
      response = this.paginateObjects(response, limit, skip);
    }

    return response;
  }

  async getContent(type, slug, props = "slug,title,content", cached = true) {
    let response = cached ? this.getCachedObject(type, slug) : null;

    if (
      response !== null &&
      response !== undefined &&
      response !== "" &&
      response !== "undefined"
    ) {
      return response;
    }

    response = await bucket.getObject({
      slug,
      props,
      sort: "created_at"
    });

    if (cached) {
      this.setCachedObject(type, response?.object);
    }

    return response?.object;
  }

  getCachedAllObjects(type) {
    const response = getData(type);

    if (
      response !== null &&
      response !== undefined &&
      response !== "" &&
      response !== "undefined"
    ) {
      return JSON.parse(response);
    }

    return response;
  }

  setCachedAllObjects(type, newObjects) {
    if (!newObjects) return;

    setData(type, JSON.stringify(newObjects));
  }

  getCachedObject(type, slug) {
    const allCachedObjects = getData(type);
    let response = null;

    if (
      allCachedObjects !== null &&
      allCachedObjects !== undefined &&
      allCachedObjects !== "" &&
      allCachedObjects !== "undefined"
    ) {
      const objectsParsed = JSON.parse(allCachedObjects);
      response = objectsParsed?.objects?.find(obj => obj.slug === slug) ?? null;
    }

    return response;
  }

  setCachedObject(type, newObject) {
    if (!newObject) return;

    const response = this.getCachedAllObjects(type);

    if (
      response !== null &&
      response !== undefined &&
      response?.objects?.length > 0
    ) {
      response.objects.push(newObject);
      response.total = response?.length;
      setData(type, JSON.stringify(response));
    } else {
      const newData = { objects: [newObject], total: 1 };
      setData(type, JSON.stringify(newData));
    }
  }

  paginateObjects(data, limit, skip = 0) {
    const page = skip + 1;
    const spliceStart = limit * skip;
    const spliceEnd = limit * page;

    return {
      objects: data?.objects?.splice(spliceStart, spliceEnd),
      page,
      totalPages: Math.ceil(data.total / limit),
      total: data.total
    };
  }
}

export default CMSService;
