import React from "react";
import { PageTitle } from "../PageTitle";
import { Container } from "./styles";
import CMSService from "../../../../services/CMSService";

import whatsapp from "../../../../assets/icons/icon-whatsapp.svg";
import telegram from "../../../../assets/icons/icon-telegram.svg";
import facebook from "../../../../assets/icons/icon-facebook.svg";
import twitter from "../../../../assets/icons/icon-twitter.svg";
import linkedin from "../../../../assets/icons/icon-linkedin.svg";
import Swal from "sweetalert2";

export const NewsPage = ({ route }) => {
  const cosmicService = new CMSService();
  const slug = route?.match?.params?.slug;

  const [news, setNews] = React.useState(null);

  const loadNews = async () => {
    try {
      const response = await cosmicService.getContent(
        "news",
        slug,
        "slug,title,metadata,thumbnail,created_at"
      );
      setNews(response);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }
  };

  React.useEffect(() => {
    loadNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {news !== null && (
        <>
          <PageTitle
            text={news?.metadata?.category}
            timeStamp={news.created_at}
          />
          <div className="content-wrapper">
            <h1 className="title">{news.title}</h1>
            <div className="content">
              <div className="left">
                <div className="thumb">
                  <img src={news.thumbnail} alt="Imagem da notícia" />
                </div>
                <ul className="share">
                  <li>
                    <span>Compartilhe:</span>
                  </li>
                  <li>
                    <img src={whatsapp} alt="Whatsapp" />
                  </li>
                  <li>
                    <img src={telegram} alt="Telegram" />
                  </li>
                  <li>
                    <img src={facebook} alt="Facebook" />
                  </li>
                  <li>
                    <img src={twitter} alt="Twitter" />
                  </li>
                  <li>
                    <img src={linkedin} alt="Linkedin" />
                  </li>
                </ul>
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: news.metadata.content }}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  );
};
