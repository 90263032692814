import React, { useEffect, useState } from "react";
import Datatable from "../../../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../../../partials/datatable/styles.js";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import moment from "moment";

const MessageWhatsappDatatable = ({
  reload,
  setReload,
  setNotWorkProfessionalTotal,
  setWorkProfessionalTotal,
  setPendingProfessionalTotal,
  filterStatus,
  operationUser
}) => {
  const [whatsappMessages, setWhatsappMessages] = useState([]);
  const [render, setRender] = useState(false);

  const width = useGetWindowWidth();

  const headRows = [
    { label: "Profissional (Nome)" },
    { label: "Profissional (E-mail)" },
    { label: "Gerente de Operações   (Nome)" },
    { label: "Data de Solicitação" },
    { label: "Status" }
  ];

  const formatRow = r => {
    const crudActions = [];

    return (
      <>
        <StyledTableRow
          hover
          pointer={''}
          tabIndex={-1}
          key={r.idWhatsappMessage}
          onClick={() => null}
        >
          <StyledTableCell scope="row">{r.consultantInexName}</StyledTableCell>
          <StyledTableCell scope="row">{r.consultantInexEmail}</StyledTableCell>
          <StyledTableCell scope="row">{r.operationName}</StyledTableCell>
          <StyledTableCell scope="row">{moment(r.creationDate).format('DD/MM/YYYY HH:mm:ss')}</StyledTableCell>
          <StyledTableCell scope="row">{r.status}</StyledTableCell>
        </StyledTableRow>
      </>
    );
  };

  useEffect(() => {
    setNotWorkProfessionalTotal(whatsappMessages.filter((wm) => wm.status !== 'Não Enviada - Usuário em dia' && wm.status !== 'Pendente').length);
    setWorkProfessionalTotal(whatsappMessages.filter((wm) => wm.status === 'Não Enviada - Usuário em dia').length);
    setPendingProfessionalTotal(whatsappMessages.filter((wm) => wm.status === 'Pendente').length);
  }, [whatsappMessages]); 

  return (
    <>
      <Datatable
        search={''}
        dateRange={''}
        endpoint={`whatsappmessage/paged`}
        className="h-100 m-0 p-0"
        headRows={headRows}
        styledTableCell={StyledTableCell}
        styledTableRow={StyledTableRow}
        formatRow={formatRow}
        rows={whatsappMessages}
        setRows={setWhatsappMessages}
        reload={render} 
        setReload={setRender}
        hiddenReload
        width={width}
        operationUser={operationUser}
        filterStatusWhatsappMessage={filterStatus}
      />
    </>
  )
}

export default MessageWhatsappDatatable;
