import { TableCell, TableRow } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styled from "styled-components";

export const ReportTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    max-height: 32px !important;
  }

  &:nth-of-type(even) {
    background-color: #fff;
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const ReportTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    font-family: "Cera Pro Medium", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #4d4c4d;
    height: 64px;
    padding: 0 16px;
    background: #c4c4c4;

    white-space: nowrap;
  }

  &.MuiTableCell-head:not(:last-child) {
    border-right: 1px solid #888;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Light", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #4d4c4d;
    max-height: 32px;
    padding: 3.5px 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 520px;
  }
  &.MuiTableCell-root.MuiTableCell-body:not(:last-child) {
    border-right: 0.25px solid #ddd;
  }
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

export const TableToExcelButton = styled(ReactHTMLTableToExcel)`
  display: flex;
  padding: 13.5px 24px;
  margin: 24px 0px;
  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;

  border-radius: 4px;
  border: none;

  background: #4672C5;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export const TableContainer = styled.div`
  display: flex;
  max-width: 90vw;
  max-height: 75vh;
  overflow: auto;
  background: #fff;

  margin: 0 auto;
`;
