import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  .swal-icon {
    margin-right: 10px;
  }

  .swal-text {
    color: #ffffff;
    font-family: "Cera Pro", sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
`;
