import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import formatCurrency from "../../../../utils/FormatCurrency";
import moment from "moment";

const initialState = {
  id: 0,
  name: "",
  validityStartDate: "",
  validityEndDate: "",
  client: {},
  clients: [],
  clientOptions: [],
  isLoading: false,
  isEditing: false,
  persistedModel: ""
};

export default class ProposalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        };

        this.api = new InexApiService();
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.loadProposal = this.loadProposal.bind(this);
    }

    async componentDidMount() {
        const isEditing = !!this.props.match.params.id;
        this.setState({ isLoading: true, isEditing });
        try {
            await this.loadClients();
            if (this.props.match.params.id) {
                await this.loadProposal(this.props.match.params.id);
            }
        } catch (error) {
            Swal.fire(
                "Erro!",
                error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
                "error"
            );
        }

        this.setState({ isLoading: false });
    }

    loadClients = async () => {
        const clients = await this.api.makeHttpRequest({
            url: "Professional/clients"
        });
        const clientOptions = []
        clients.forEach((client) => {
            clientOptions.push({ value: client.idClient, label: client.name })
        });
        
        this.setState({
            clientOptions: clientOptions,
            client: clientOptions[0]
        });
    };

    loadProposal = async id => {
        const proposal = await this.api.makeHttpRequest({
            url: `/businessproposal/${id}`
        });
        
        const persistedModel = {
            id: id,
            name: proposal.name,
            validityStartDate: moment(proposal.validityStartDate).toDate(),
            validityEndDate: moment(proposal.validityEndDate).toDate(),
        };

        const persistedModelJson = JSON.stringify(persistedModel);
        this.setState({
            ...persistedModel,
            client: {value: proposal.client.idClient, label: proposal.client.name },
            isLoading: false,
            persistedModel: persistedModelJson
        });
    };

    submit = async e => {
        e.preventDefault();
        const id = this.props.match.params.id;
        this.setState({ isLoading: true });
        try {
        const reqBase = id
            ? { method: "PUT", url: `/businessproposal/update/${id}` }
            : { method: "POST", url: "/businessproposal/save" };
        console.log(this.state.client)
        await this.api.makeHttpRequest({
            ...reqBase,
            data: {
                name: this.state.name,
                validityStartDate: this.state.validityStartDate,
                validityEndDate: this.state.validityEndDate,
                client: {
                    idClient: this.state.client.value
                }
            }
        });

        this.afterSubmit();
        } catch (e) {
        Swal.fire(
            "Erro!",
            e?.response?.data
            ? e.response.data
            : "Erro ao cadastrar proposta, tente novamente mais tarde.",
            "error"
        );
        }

        this.setState({ isLoading: false });
    };

    afterSubmit = async () => {
        this.setState({
            ...initialState,
            resetInput: true
        });
    
        const { isEditing } = this.state;
    
        const { value } = await Swal.fire({
            title: "Sucesso!",
            text: "Dados salvos com sucesso.",
            icon: "success",
            showCancelButton: !isEditing,
            confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
            cancelButtonText: "Sair"
        });
    
        if (!value || isEditing) {
            this.props.history.push("/admin/propostas");
        }
    };

    formIsValid = () => {
        return (
            this.state.name &&
            this.state.validityStartDate &&
            this.state.validityEndDate
        );
    };

    onCleanForm = async () => {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true });

            try {
                await this.loadProposal(this.props.match.params.id);
            } catch (e) {
                Swal.fire(
                    "Erro",
                    "Problema ao reverter as alterações, tente mais tarde",
                    "error"
                );
            }

            this.setState({ isLoading: false });
        } else {
            this.setState({
                ...initialState,
                resetInput: true
            });
        }
    };

    handleChange = (e, select2Event) => {  
        if (select2Event?.name === "client") {
            return this.setState({ client: e });
        }
        
        this.setState({
          [e.target.name]: e.target.value
        });
    };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Proposta - Admin");
    } else {
      window.setPageTitle("Cadastro de Proposta - Admin");
    }

    const {
        name,
        validityStartDate,
        validityEndDate,
        client,
        clientOptions,      
        isLoading,
        isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Proposta" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
                {/* row 1 */}
                <Form.Row>
                    {/* Clientes */}
                    <Form.Group as={Col} lg="6" xs="12">
                        <InputField
                            as="select2"
                            name="client"
                            label="Clientes"
                            value={client}
                            onChange={this.handleChange}
                            placeholder="Selecione o cliente"
                            options={clientOptions}
                            closeMenuOnSelect={true}
                            required
                        />
                    </Form.Group>
                    {/* Nome */}
                    <Form.Group as={Col} lg="6" xs="12">
                        <InputField
                            type="text"
                            name="name"
                            label="Nome"
                            placeholder="Digite o Nome"
                            value={name}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                </Form.Row>
                {/* row 2 */}
                <Form.Row>
                    {/* Início da Vigência */}
                    <Form.Group as={Col} lg="6" xs="12">
                        <InputField
                            type="date"
                            name="validityStartDate"
                            label="Início da Vigência"
                            placeholder="Digite o Início da Vigência"
                            value={validityStartDate}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Fim da Vigência */}
                    <Form.Group as={Col} lg="6" xs="12">
                        <InputField
                            type="date"
                            name="validityEndDate"
                            label="Fim da Vigência"
                            placeholder="Digite o Fim da Vigência"
                            value={validityEndDate}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}