import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { GraduationIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";

const initialState = {
  trainings: [],
  trainingSubgroupList: [],
  trainingGroupList: [],
  reload: false,
  loading: false
};

export default class TrainingListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setTrainings = this.setTrainings.bind(this);    
  }

  componentDidMount() {
    this.loadTrainingSubgroups();
    this.loadTrainingGroups();
  }

  loadTrainingSubgroups = async () => {
    let trainingSubgroupListLocal = [{value : "" , label : "TODOS" }];
    const trainingSubgroupListfull = await this.api.makeHttpRequest({
      url: "/trainingsubgroup/getAllActiveTrainingSubgroups"
    });
    
    for (let i in trainingSubgroupListfull){
      trainingSubgroupListLocal.push({  value: trainingSubgroupListfull[i].idTrainingSubgroup, label: trainingSubgroupListfull[i].name })
    }

    this.setState({trainingSubgroupList: trainingSubgroupListLocal});
  };

  loadTrainingGroups = async () => {
    let trainingGroupListLocal = [{value : "" , label : "TODOS" }];
    const trainingGroupListfull = await this.api.makeHttpRequest({
      url: "/traininggroup/getAllActiveTrainingGroups"
    });
    
    for (let i in trainingGroupListfull){
      trainingGroupListLocal.push({  value: trainingGroupListfull[i].idTrainingGroup, label: trainingGroupListfull[i].name })
    }

    this.setState({trainingGroupList: trainingGroupListLocal});
  };
  
  handleConfirmChangeStatus = async function (obj) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/training/toggle/${obj.idTraining}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        e?.response?.data ? "warning" : "error"
      )
    }
  };

  setTrainings = trainings => {
    this.setState({ trainings });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION);

    if (isAdmin || isOperation) {
      crudActions = [...crudActions];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idProject}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="treinamentos"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            path={`/admin/treinamentos/logs/${r.idTraining}`}
            id={r.idTraining}
            obj={r}
          />
        </StyledTableCell>
        }
        <StyledTableCell scope="row" align="center">
          {r.name && r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          {r.description && r.description}
        </StyledTableCell>
        <StyledTableCell scope="row" align="left">
          <a href={r.videoLink} style={{color: 'blue', textDecoration: 'underline'}}>{r.videoLink && r.videoLink}</a>
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.group && r.group}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {r.subgroup && r.subgroup}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Treinamentos - Admin");

    let headRows = [
      { column: "Name", label: "Nome", align: "center" },
      { column: "Description", label: "Descrição", align: "left" },
      { column: "VideoLink", label: "Link do Vídeo", align: "left" },
      { column: "Group", label: "Grupo", align: "center" },
      { column: "Subgroup", label: "Subgrupo", align: "center" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];

    if (
      (this?.auth?.user && this?.auth?.user.role.type === ERoles.ADMIN)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.OPERATION)
    ) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }
    
    let showAdminButtons = []
    if(this.auth?.user?.role?.type === ERoles.ADMIN || this.auth?.user?.role?.type === ERoles.OPERATION) {
      showAdminButtons = [ 
        {
          label: "Adicionar",
          onClick: null,
          icone: "add",
          path: "/admin/treinamentos/adicionar"
        } 
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Treinamentos"
          icon={GraduationIcon}
          endpoint="/training/paged"
          className="h-100"
          headRows={headRows}
          trainingSubgroupList={this.state.trainingSubgroupList}
          trainingGroupList={this.state.trainingGroupList}
          formatRow={this.formatRow}
          rows={this.state.trainings}
          setRows={this.setTrainings}
          reload={this.state.reload}
          placeholderSearch="Busque por nome"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
      </>
    );
  }
}
