import styled from "styled-components";
import { Form } from "react-bootstrap";
import Select2 from "react-select";
import CreatableSelect2 from "react-select/creatable";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  height: fit-content;

  &.dim-light {
    opacity: 0.6;
  }

  &.fade {
    opacity: 0.4;
  }

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: #4d4c4d;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;

    .required {
      color: #4672c5;
    }
  }

  .chevron {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 48px;
    right: 15px;
  }

  .end-adornment {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 15px;

    cursor: pointer;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  .MuiFormControlLabel-label {
    color: ${({ labelRed }) => (labelRed ? "#4672C5" : "#4D4C4D")};
  }
`;

export const StyledInput = styled(Form.Control)`
  width: 100%;
  min-height: 48px;
  color: #807e80;
  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: ${({ as }) => (as === "textarea" ? "150%" : "24px")};
  padding: 12px 30px 12px 16px;
  border: 1px solid #4d4c4d77;
  border-radius: 4px;
  outline: none;
  background: #fff;

  &:focus,
  &:focus-visible {
    border-color: #4d4c4d77;
    color: #4d4c4d;
    text-shadow: none;
  }

  &.is-invalid {
    border-color: #ff7563;
  }

  &:disabled {
    background: #fff;
    opacity: 0.8;
    cursor: not-allowed;
  }

  &::-webkit-input-placeholder {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: 300;
    color: #807e80;
    opacity: 0.8;
  }

  &:-ms-input-placeholder {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: 300;
    color: #807e80;
    opacity: 0.8;
  }

  &::placeholder {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: 300;
    color: #807e80;
    opacity: 0.8;
  }

  ${({ as }) =>
    as === "select" &&
    `
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    height: 48px;
    opacity: 0.8;

    &.placeholder {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 300;
      color: #807e80;
      opacity: 0.8;
    }

    option {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 300;
      color: #4d4c4d;
      opacity: 0.8;
    }
  `}
`;

export const ContainerSelect2 = styled.div`
  position: relative;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    `cursor: not-allowed;`}
  &.dim-light {
    opacity: 0.6;
  }

  &.fade {
    opacity: 0.4;
  }

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: #4d4c4d;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;

    .required {
      color: #4672c5;
    }
  }
`;

export const StyledSelect2 = styled(Select2)`
  min-height: 48px;
  .select__control {
    min-height: 48px;
    border: 1px solid #4d4c4d77;

    &--is-disabled {
      background: #fff;
      cursor: not-allowed;
    }

    &--is-focused {
      border: 1px solid #4d4c4d77;
      box-shadow: none;

      &:focus,
      &:hover {
        border: 1px solid #4d4c4d77;
      }
    }

    .select__value-container {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      padding-left: 16px;
      color: #4d4c4d;

      .select__placeholder {
        font-family: "Cera Pro Light", sans-serif;
        font-weight: 300;
        color: #807e80;
        opacity: 0.8;
      }

      .select__multi-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f2f2;
        border-radius: 4px;

        .select__multi-value__label {
          color: #4d4c4d;
          font-family: "Cera Pro Regular", sans-serif;
          font-weight: 300;
          font-size: 12px;
          line-height: 17px;

          padding: 6px 3px 6px 6px;
        }

        .select__multi-value__remove {
          background: transparent;
          color: #4672c5;
          padding: 6px 6px 5px 3px;

          &:hover {
            background: transparent;
            color: #4672c5;
          }

          .border {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            border: 1px solid #4672c5 !important;
            border-radius: 2px;
          }
        }
      }
    }

    .select__indicators {
      .select__indicator-separator {
        width: 0;
      }

      .select__dropdown-indicator {
        margin-right: 8px;
        color: #4d4c4d;
      }

      .select__clear-indicator {
        color: #fff;

        .border {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background: #4672c5;
          border-radius: 3px;
          border: none !important;
        }
      }
    }
  }

  .select__menu {
    margin-top: 0;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;

    z-index: 11;
  }
`;

export const StyledCreatableSelect2 = styled(CreatableSelect2)`
  min-height: 48px;

  .select__control {
    min-height: 48px;
    border: 1px solid #4d4c4d77;

    &--is-disabled {
      background: #fff;
      cursor: not-allowed;
    }

    &--is-focused {
      border: 1px solid #4d4c4d77;
      box-shadow: none;

      &:focus,
      &:hover {
        border: 1px solid #4d4c4d77;
      }
    }

    .select__value-container {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      padding-left: 16px;

      .select__placeholder {
        font-family: "Cera Pro Light", sans-serif;
        font-weight: 300;
        color: #807e80;
        opacity: 0.8;
      }

      .select__multi-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f2f2;
        border-radius: 4px;

        .select__multi-value__label {
          color: #4d4c4d;
          font-family: "Cera Pro Regular", sans-serif;
          font-weight: 300;
          font-size: 12px;
          line-height: 17px;

          padding: 6px 3px 6px 6px;
        }

        .select__multi-value__remove {
          background: transparent;
          color: #4672c5;
          padding: 6px 6px 5px 3px;

          &:hover {
            background: transparent;
            color: #4672c5;
          }

          .border {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            border: 1px solid #4672c5 !important;
            border-radius: 2px;
          }
        }
      }
    }

    .select__indicators {
      .select__indicator-separator {
        width: 0;
      }

      .select__dropdown-indicator {
        margin-right: 8px;
        color: #4d4c4d;
      }

      .select__clear-indicator {
        color: #fff;

        .border {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background: #4672c5;
          border-radius: 3px;
          border: none !important;
        }
      }
    }
  }

  .select__menu {
    margin-top: 0;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
  .edit-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border: 1px solid #4672c5 !important;
    border-radius: 2px;
  }
`;

export const DateContainer = styled.div`
  position: relative;
  width: 100%;

  &.dim-light {
    opacity: 0.6;
  }

  &.fade {
    opacity: 0.4;
  }

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: #4d4c4d;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    z-index: 5;

    .required {
      color: #4672c5;
    }
  }

  & .input-container *:not(input) {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #4d4d4d;

    border: none;

    .datetime-popper {
      z-index: 99;
      padding-bottom: 8px;
      & > div .react-datepicker {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }

      .react-datepicker__header__dropdown {
        padding: 8px 0;

        select {
          border-radius: 4px;
          padding: 4px;
        }
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected {
        background: #4672c5 !important;
        color: #fff;

        &:hover {
          background: #4672c5cc;
        }
      }
    }
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    height: 48px;
    width: 100%;

    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #4d4c4d;
    cursor: pointer;

    border: 0.5px solid #4d4c4d77;
    border-radius: 4px;
    outline: none;
    text-align: left;
    background: transparent;

    padding-left: 16px;

    &::-webkit-input-placeholder {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 300;
      color: #807e80;
      opacity: 0.8;
    }

    &:-ms-input-placeholder {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 300;
      color: #807e80;
      opacity: 0.8;
    }

    &::placeholder {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 300;
      color: #807e80;
      opacity: 0.8;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .date-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 16px;
    z-index: 1;

    svg {
      stroke: #4d4c4d;
    }
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      z-index: 2;

      input {
        width: 100%;
        text-align: left;
        color: #4d4c4d;
      }
    }
  }

  .react-datepicker__close-icon {
    top: 0px;
    right: 9px;
  }

  .react-datepicker__close-icon::after {
    height: 19px;
    width: 19px;
    padding: 0px;
    font-size: 18px;
    line-height: 16px;

    border: 2px solid #4d4d4d;
    background-color: #fff;
    color: #4d4d4d;
  }
`;

export const GroupHeadingContainer = styled.div`
  .select__group-heading {
    color: #4672c5;
    font-size: 16px;
    font-family: "Cera Pro Light", sans-serif;
  }

  .divider {
    border-bottom: 1px solid #4d4d4d63;
  }
`;

export const OptionContainer = styled.div`
  >.select__option--is-selected {
    background: #4672c5;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  color: #fff;
  background-color: #dc3545;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 0.875rem;    
  margin-top: 150px;
`;