import moment from "moment";

export const calculateSumTimes = (firstTime, secondTime) => {
  // firstTime = hh:mm:00
  // secondTime = hh:mm:00

  let [h1, m1] = firstTime.split(":");
  let [h2, m2] = secondTime.split(":");

  let totalMinutes = parseInt(m1) + parseInt(m2);
  let totalHours = parseInt(h1) + parseInt(h2);

  if (totalMinutes >= 60) {
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;
  }

  let formattedHours = String(totalHours).padStart(2, "0");
  let formattedMinutes = String(totalMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:00`;

}

export const transformHoursToMinutes = (timeInHours) => {
  //timeInHours =  hh:mm:ss 

  let [hours, minutes, seconds] = timeInHours.split(":").map(Number);

  let timeInMinutes = hours * 60 + minutes;

  //return 483
  return timeInMinutes;
}

export const transformMinutesToHours = (timeInMinutes) => {
  //timeInMinutes =  600  (10:00:00)

  let hours = Math.floor(timeInMinutes / 60);
  let remainingMinutes = timeInMinutes % 60;
  let seconds = 0;

  let formattedHours = String(hours).padStart(2, "0");
  let formattedMinutes = String(remainingMinutes).padStart(2, "0");
  let formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const TransformMinutesToJiraHours = (timeInMinutes) => {
  //timeInMinutes =  600  (10:00:00)

  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  const formattedHours = hours.toString();
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // O retorno será 10h00m
  return `${formattedHours}h${formattedMinutes}m`;
}