import React, { useEffect, useState } from "react";
import { InputField } from "../../../../components/InputField";
import { makeStyles } from "@material-ui/core/styles/";
import { Container, Period } from "./style";
import moment from "moment";
import { ButtonFilter as Button } from "../Button/style";
import { FiFilter } from "react-icons/fi";
import { debounce } from "lodash";
import { getPortoDefaultDate } from "../../../../../../utils/GetPortoDefaultDate";
import { GetYearList, GetMonthList, getDefaultDate, GetPeriodBasedTodayDate } from "../../../../../../utils/GetYearsAndMonths";
import { useSelector } from "react-redux";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import Swal from "sweetalert2";
import { DateFilter } from "../../../../components/DateFilter";
import { IconContainer } from "../../../Timer/styles";
import { DateFilterIcon } from "../../../../../../components/IconsSVG";

const initialFilterDates = getPortoDefaultDate(22);

const initialFilterYearList = GetYearList();
const initialFilterMonthList = GetMonthList();
const period = GetPeriodBasedTodayDate();

const useStyles = makeStyles(() => ({
  button: {
    margin: "3px -10px",
    "& svg": {
      fill: "#807e80"
    },
    "&:hover": {
      "& svg": {
        fill: "#4672C5"
      }
    }
  },
  div: {
    display: "flex",
    flexDirection: "row"
  }
}));

const FilterComponent = () => (
    <IconContainer>
        <DateFilterIcon />
    </IconContainer>
);

export const Filters = ({ reload, onSubmit, setReload, filterOptions, loading, setLoading }) => {
  const classes = useStyles();
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);
  const isCoordinator = user?.role?.type === ERoles.OPERATION;

  const [periodOpt, setPeriodOpt] = useState("");

  const [periodYearList, setPeriodYearList] = useState(initialFilterYearList);
  const [periodMonthList, setPeriodMonthList] = useState(initialFilterMonthList);
  const [projectOptions, setProjectOptions] = useState([]);
  const [professionalOptions, setProfessionalOptions] = useState([]);

  const [filters, setFilters] = useState({
    periodYear: moment().year(),
    periodMonth: moment().month()+1,
    startPeriod:  user.clientDefault ? moment(getPortoDefaultDate(user.clientDefault.initialCycleDay).startDate).toDate() : initialFilterDates.startDate,
    endPeriod:  user.clientDefault ? moment(getPortoDefaultDate(user.clientDefault.initialCycleDay).endDate).toDate() : initialFilterDates.endDate,
    client: user.clientDefault ? user.clientDefault.idClient : "",
    initialCycleDay: user.clientDefault ? user.clientDefault.initialCycleDay : "",
    project: "",
    coordinator: isCoordinator ? `${user.idUser}` : "",
    employee: ""
  });

  React.useEffect(() => {
    if (filters.client) {
      loadCoordinationByIdClient(filters.client);
      if (filterOptions.clients){
        const result = filterOptions.clients.find((c) => c.idClient.toString() === filters.client)
        const period = getPortoDefaultDate(result.initialCycleDay)
        const endPeriod = moment(period.endDate)
        setFilters({
            ...filters,
            initialCycleDay: result.initialCycleDay,
            periodMonth: endPeriod.month()+1,
            periodYear: endPeriod.year(),
            startPeriod: moment(period.startDate).startOf('day').toDate(),
            endPeriod: endPeriod.endOf('day').toDate()
        })
      }
    }
  }, [filters.client]);

  const loadCoordinationByIdClient = async (idClient = "") => {
    try {
        setLoading(true);
        const response = await api.makeHttpRequest({
            url: `/flexiclosure/projects-by-client/${idClient}`
        });
        setProjectOptions(response.projects ?? []);
        setProfessionalOptions(response.professionals ?? []);
    } catch (e) {
        setLoading(false);
        Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar as coordenações, tente novamente mais tarde.",
            "error"
        );
    } finally {
        setLoading(false);
    }
  };

  const handleChange = e => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleChangeYear = e => {
    const filterDates = getDefaultDate(e.target.value, filters.periodMonth);   
    
    const cycleDay = parseInt(filters.initialCycleDay, 10);
    var startDate = moment(filterDates.endDate).date(cycleDay).startOf('day');
    var endDate = moment(startDate).date(cycleDay).add('1', 'month').subtract('1', 'day').endOf('day');

    setFilters({ 
      ...filters,
      periodYear: e.target.value,
      startPeriod: startDate.toDate(),
      endPeriod: endDate.toDate()
    });
  };

  const handleChangeMonth = e => {
    const filterDates = getDefaultDate(filters.periodYear, e.target.value);  

    const cycleDay = parseInt(filters.initialCycleDay, 10);
    var startDate = moment(filterDates.endDate).date(cycleDay).startOf('day');
    var endDate = moment(startDate).date(cycleDay).add('1', 'month').subtract('1', 'day').endOf('day');

    setFilters({ 
      ...filters, 
      periodMonth: e.target.value,
      startPeriod: startDate.toDate(),
      endPeriod: endDate.toDate(),
    });
  };


  const debounceOnClick = debounce(() => onSubmit(filters), 400);

  useEffect(() => {
    onSubmit(filters);
  }, []);

  useEffect(() => {
    if (reload) {
      onSubmit(filters);
      setReload(false);
    }
  }, [reload]);

  React.useEffect(() => {
    if (filters.coordination) {
      loadProjectByIdCoordination(filters.coordination);
    } else {
      loadProjectByIdCoordination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.coordination]);

  const loadProjectByIdCoordination = async (idCoordination = "") => {
    if (user.role.idRole != 1) {
      try {
        setLoading(true);
        if (idCoordination !== "") {
          const projectReq = await api.makeHttpRequest({
            url: `/approvalreport/projects/coordination-by-user/${idCoordination}`
          });
          setProfessionalOptions(projectReq.professionals ?? []);
        } else {
          if (filters.client) {
            const coordination = await api.makeHttpRequest({
              url: `/approvalreport/coordinations-by-user/client/${filters.client}`
            });
            setProfessionalOptions(coordination.professionals ?? []);
          }
        }
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar os projetos, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePeriodFilter = filter => {
    const option = {
      label: `${moment(filter.startPeriod)
        .format("DD/MM/YYYY")} - ${moment(filter.endPeriod).format(
        "DD/MM/YYYY"
      )}`,
      value: 1
    };

    setPeriodOpt(option);
    const period = moment(filter.endPeriod)
    setFilters({
        ...filters,
        periodMonth: period.month()+1,
        periodYear: period.year(),
        startPeriod: filter.startPeriod,
        endPeriod: filter.endPeriod
    })
  };

  return (
    <>
        <div>
            <Container>
                {/* Cliente */}
                <InputField
                as="select"
                name="client"
                value={filters.client}
                onChange={handleChange}
                >
                <option value="">Cliente</option>
                {filterOptions?.clients?.length > 0 &&
                    filterOptions.clients.map(c => (
                    <option key={c.idClient} value={c.idClient}>
                        {c.name}
                    </option>
                    ))}
                </InputField>
                <div>
                    {/* Ano Período */}
                    <InputField
                        as="select"
                        name="periodYear"
                        value={filters.periodYear}
                        onChange={handleChangeYear}
                        style={{width: '90px'}}
                    >
                        {periodYearList.length > 0 &&
                            periodYearList.map(y => (
                            <option key={y} value={y}>
                                {y}
                            </option>
                        ))}
                    </InputField>
                </div>
            
                <div>
                    {/* Mês Período */}
                    <InputField
                        as="select"
                        name="periodMonth"
                        value={filters.periodMonth}
                        onChange={handleChangeMonth}
                        style={{width: '80px'}}
                    >
                        {
                            periodMonthList.length > 0 &&
                            periodMonthList.map(m => (
                                <option key={m.cod} value={m.cod}>
                                    {m.name}
                                </option>
                            ))
                        }
                    </InputField>
                </div>        

                {/* Coordenação */}
                <InputField
                    as="select"
                    name="project"
                    value={filters.project}
                    onChange={handleChange}
                >
                <option value="">Projeto</option>
                {        
                    projectOptions.length > 0 &&
                    projectOptions.map(p => (
                        <option key={p.idProject} value={p.idProject}>
                            {p.name}
                        </option>
                    )
                    )
                }
                </InputField>

                {/* Coordenador de projeto */}
                <InputField
                as="select"
                name="coordinator"
                value={filters.coordinator}
                onChange={handleChange}
                >
                <option value="">Coordenador de Projeto</option>
                {filterOptions?.coordinators?.length > 0 &&
                    filterOptions.coordinators.map(c => (
                    <option key={c.idUser} value={c.idUser}>
                        {c.name}
                    </option>
                    ))}
                </InputField>

                {/* Profissional */}
                <InputField
                as="select"
                name="employee"
                value={filters.employee}
                onChange={handleChange}
                >
                <option value="">Profissional</option>
                {professionalOptions.length > 0 &&
                    professionalOptions.map(c => (
                    <option key={c.idUser} value={c.idUser}>
                        {c.name}
                    </option>
                    ))}
                </InputField>

                <Button type="button" variant="filled" onClick={() => debounceOnClick()}>
                <span className="icon">
                    <FiFilter size={22} />
                </span>{" "}
                <span>filtrar</span>
                </Button>
            </Container>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '12px'}}>
            <DateFilter
                component={FilterComponent}
                reload={reload}
                setFilter={handlePeriodFilter}
                initialValues={initialFilterDates}
                initialCycle={filters.initialCycleDay}
                newValues={{
                    startDate: filters.startPeriod,
                    endDate: filters.endPeriod
                }}
            />
            <Period>
                {
                    `${moment.utc(filters.startPeriod).format("DD/MM/YYYY")} - 
                    ${moment(filters.endPeriod).format("DD/MM/YYYY")}`
                }
            </Period>
        </div>
    </>
  );
};

export default Filters;
