import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import { ConciliationIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import store from "../../../../store/store";
import { ERoles } from "../../../../enums/Roles";
import { ConciliationModal } from "./components/ConciliationModal";
import { UploadConciliationModal } from "./components/UploadConciliationModal";

const initialState = {
  uploadConciliationModal: false,
  conciliationModal: false,
  conciliation: null,
  conciliations: [],
  setUploads: (val) => this.setState({uploads: val}),
  reload: false,
  loading: false
};

export default class ConciliationListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    this.auth = auth;

    this.api = new InexApiService();

    this.state = {
      ...initialState
    };

    this.formatRow = this.formatRow.bind(this);
    this.setUploadConciliationModal = this.setUploadConciliationModal.bind(this);
    this.setConciliationModal = this.setConciliationModal.bind(this);
    this.setConciliations = this.setConciliations.bind(this);
    this.setConciliation = this.setConciliation.bind(this);
    this.setReload = this.setReload.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.onViewDetails = this.onViewDetails.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  setReload = reload => {
    this.setState({ reload });
  };

  setLoading = loading => {
    this.setState({ loading });
  };

  setConciliations = conciliations => {
    this.setState({ conciliations });
  };

  setConciliation = conciliation => {
    this.setState({ conciliation });
  };

  setUploadConciliationModal = (value) => {
    this.setState({uploadConciliationModal: value})
  }

  setConciliationModal = (value) => {
    this.setState({conciliationModal: value})
  }

  formatRow = r => {
    let crudActions = ["show", "delete"];
    const isAdmin = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.ADMIN);
    const isOperation = (this.auth && this.auth.user && this.auth.user.role.type === ERoles.OPERATION);

    if (isAdmin || isOperation) {
      crudActions = [...crudActions];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={'Conciliation_'+r.idConciliation}>
        {(isAdmin || isOperation) && <StyledTableCell scope="row" align="center">
          <CrudActions
            actions={crudActions}
            module="conciliacoes"
            onViewDetails={this.onViewDetails}
            onConfirmDelete={this.onConfirmDelete}
            path={''}
            id={r.idConciliation}
            obj={r}
          />
        </StyledTableCell>
        }
        <StyledTableCell scope="row" align="left" width="60%">
          {r.client.name && r.client.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.referenceDate && Moment(r.referenceDate, 'DD/MM/YYYY').format('MMM/YYYY').toUpperCase()}
        </StyledTableCell>
        <StyledTableCell scope="row" align="center">
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  onViewDetails = (obj) => {
    this.setConciliation(obj)
    this.setConciliationModal(true);
  }

  onConfirmDelete = async (id) => {
    this.setLoading(true);
    try {
      await this.api.makeHttpRequest({
        url: `/conciliation/delete/${id}`,
        method: "DELETE"
      })
      .then(() => {
        Swal.fire(
          "Sucesso!",
          "Conciliação deletada com sucesso!",
          "success"
        );
      })
      .finally(() => {
        this.setReload(true);
        this.setLoading(false);
      })
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao deletar a conciliação.",
        "error"
      );
    }
  }

  render() {
    window.setPageTitle("Listagem de Conciliação - Admin");

    let headRows = [
      { column: "Client.Name", label: "Nome do Cliente", width: "29%" },
      { column: "ReferenceDate", label: "Mês/Ano", align: "center" },
      {
        column: "ModificationDate",
        label: "Data de Modificação",
        align: "center"
      }
    ];

    if (
      (this?.auth?.user && this?.auth?.user.role.type === ERoles.ADMIN)
      || (this?.auth?.user && this?.auth?.user.role.type === ERoles.OPERATION)
    ) {
      headRows = [{ label: "Ações", align: "center" }, ...headRows];
    }
    
    let showAdminButtons = []
    if(this.auth?.user?.role?.type === ERoles.ADMIN || this.auth?.user?.role?.type === ERoles.OPERATION) {
      showAdminButtons = [ 
        {
          label: "Realizar Conciliação",
          onClick: () => this.setUploadConciliationModal(true),
          icone: "upload"
        } 
      ]
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Conciliações"
          icon={ConciliationIcon}
          endpoint="/conciliation/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.conciliations}
          setRows={this.setConciliations}
          reload={this.state.reload}
          placeholderSearch="Busque por cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={showAdminButtons}
        />
        <UploadConciliationModal
          title={"Realizar Conciliação"}
          show={this.state.uploadConciliationModal}
          close={() => this.setUploadConciliationModal(false)}
          setReload={this.setReload}
        />
        <ConciliationModal
          title={"Conciliação"}
          conciliation={this.state.conciliation}
          show={this.state.conciliationModal}
          close={() => {
            this.setConciliationModal(false);
            this.setConciliation(null);
          }}
        />
      </>
    );
  }
}
