import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 100%;
    max-width: 458px;
    margin: auto;
  }

  .modal-body {
    padding: 37px 24px 24px;

    .modal-title {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #4d4c4d;

      margin-bottom: 37px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }

    .last-row {
      display: flex;
      justify-content: space-between;

      .save-btn {
        min-width: 48%;
      }
    }
  }
`;
