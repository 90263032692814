import styled from "styled-components";
import { TableCell, TableRow } from "@material-ui/core";

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    max-height: 32px !important;
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    font-family: "Cera Pro Medium", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #4d4c4d;
    height: 64px;
    padding: 0 16px;
    background: #c4c4c4;

    white-space: nowrap;
  }

  &.MuiTableCell-head:not(:last-child) {
    border-right: 1px solid #888;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #4d4c4d;
    max-height: 32px;
    padding: 3.5px 16px;

    white-space: nowrap;

    .cta-link {
      font-family: "Cera Pro Light", sans-serif;
      font-weight: 400;
      color: #4672C5;
      text-transform: capitalize;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  &.MuiTableCell-root.MuiTableCell-body:not(:last-child) {
    border-right: 0.25px solid #ddd;
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;
