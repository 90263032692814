import styled from "styled-components";
import { Card } from "react-bootstrap";

export const StyledCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 24px;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    width: 224px;
    height: 285px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 4px;

    .file-input {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .upload-button {
      position: absolute;
      bottom: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #4672C5;
      border-radius: 4px;
      color: #fff;
      width: 30px;
      height: 30px;

      cursor: pointer;
    }

    .remove-button {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 20px;
      height: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      background: #4c4c4c;
      color: #fff;
      border-radius: 3px;

      cursor: pointer;
    }
  }
  .edit-profile-form {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 24px;
    flex-direction: column;
    width: 100%;

    .main-form {
      display: flex;
      margin-bottom: 37px;
      width: 100%;

      .form-top {
        flex: 1;

        > .card-title {
          font-family: "Cera Pro Regular", sans-serif;
          font-weight: 300;
          font-size: 20px;
          line-height: 150%;
          color: #4d4c4d;
          margin-bottom: 42px;

          strong {
            font-family: "Cera Pro Medium", sans-serif;
            font-weight: 700;
            color: #4672C5;
            text-transform: capitalize;
          }
        }
      }
    }

    .form-bottom {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  > .accordion:not(:last-child) {
    margin-bottom: 24px;
  }

  .form-admin {
    flex: 1;

    > .card-title {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      color: #4d4c4d;
      margin-bottom: 37px;

      &.default-margin {
        margin-bottom: 24px;
      }
    }

    > .accordion:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .form-row {
    height: fit-content;
    margin-bottom: 0px;

    @media screen and (min-width: 1024px) {
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0px;

      & .form-group:last-child {
        margin-bottom: 0px;
      }
    }

    .form-group {
      margin-bottom: 0px;
    }
  }
`;
