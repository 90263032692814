import moment from "moment";

const DEFAULT_CYCLE_DAY = 1;

/**
 * Função principal para calcular as datas de início e fim do ciclo
 * @param {number} initialCycleDay - Dia inicial do ciclo (padrão é o dia 1)
 * @returns {Object} - Objeto contendo as datas de início e fim do ciclo
 */
export const getPortoDefaultDate = (initialCycleDay = DEFAULT_CYCLE_DAY) => {
  const cycleDay = parseInt(initialCycleDay, 10) || DEFAULT_CYCLE_DAY; // Converte o dia do ciclo para um número inteiro
  const dateNow = moment();
  var startDate = moment().date(cycleDay).startOf('day');
  if (dateNow.isBefore(startDate)) {
    startDate.subtract('1', 'month');
  }

  var endDate = moment(startDate).date(cycleDay).add('1', 'month').subtract('1', 'day').endOf('day');
  if (dateNow.isAfter(endDate)) {
    startDate.add('1', 'month');
    endDate = moment().add('2', 'month').subtract('1', 'day').endOf('day');
  }
  return { startDate: startDate.startOf('day').toDate(), endDate: endDate.endOf('day').toDate() };
};
