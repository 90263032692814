import React, { useEffect } from "react";
import useDocumentTitle from "../../utils/useDocumentTitle";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container } from "./style";

function Home({ children }) {
  useDocumentTitle("Inex - Home");

  useEffect(() => {
    document.getElementById("root").style.height = "auto";

    return () => {
      document.getElementById("root").style.height = "100%";
    };
  }, []);

  return (
    <Container>
      <Header />
      {children}
      <Footer />
    </Container>
  );
}

export default Home;
