import moment from "moment";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";

export const IndividualForm = ({ userInfo, setUserForm }) => {
  const [values, setValues] = React.useState(null);
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    setValues(userInfo);
  }, [userInfo]);

  React.useEffect(() => {
    if (dirty) {
      setUserForm(values);
      setDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  const handleChange = e => {
    setDirty(true);
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
      return;
    }

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    values !== null && (
      <>
        {/* row 1 */}
        <Form.Row>
          {/* Feedback SM */}
          <Form.Group as={Col} lg="12" xs="12">
            <InputField
              as="textarea"
              name="feedBackScrumMaster"
              label="Feedback do Scrum Master"
              placeholder="Digite o feedback do Scrum Master"
              rows={4}
              value={values.feedBackScrumMaster ?? ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Form.Row>

        {/* row 2 */}
        <Form.Row>
          {/* Anotações */}
          <Form.Group as={Col} lg="12" xs="12">
            <InputField
              as="textarea"
              name="coordinatorNotes"
              label="Anotações"
              placeholder="Digite as anotações"
              value={values.coordinatorNotes ?? ""}
              rows={4}
              onChange={handleChange}
            />
          </Form.Group>
        </Form.Row>

        {/* row 3 */}
        <Form.Row>
          {/* Plano de Ação */}
          <Form.Group as={Col} lg="12" xs="12">
            <InputField
              as="textarea"
              name="actionPlan"
              label="Plano de Ação"
              placeholder="Digite o plano de ação sugerido"
              value={values.actionPlan ?? ""}
              rows={3}
              onChange={handleChange}
            />
          </Form.Group>
        </Form.Row>

        {/* row 3 */}
        <Form.Row>
          {/* SLA Plano de Ação */}
          <Form.Group as={Col} lg="4" xs="6">
            <InputField
              type="date"
              name="actionPlanDate"
              value={
                values.actionPlanDate
                  ? moment(values.actionPlanDate).toDate()
                  : null
              }
              label="SLA Plano de Ação"
              placeholder="Selecione a data"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} lg="4" xs="6">
            <InputField
              type="checkbox"
              name="isActionPlanDone"
              value={values.isActionPlanDone ?? false}
              label="Plano de Ação Concluído"
              onChange={handleChange}
              labelRed
            />
          </Form.Group>
        </Form.Row>
      </>
    )
  );
};
