export let headRowsAny = [
  { label: "Ações", align: "center", width: "8%" },
  { column: "StartDate", label: "Data", width: "6%" },
  { label: "Atividade", width: "30%" },
  { label: "Demanda" },
  { label: "Projeto" },
  { label: "Cliente" },
  { label: "Início / Término", align: "center", width: "10%" },
  { label: "Duração", align: "center", width: "6%" },
  { label: "Hora Excedente", width: "5%", align: "center" },
  { label: "Arquivo", width: "5%", align: "center" },
  { label: "Aprovador" },
];


export let headRowsFactory = [
  { label: "Ações", align: "center", width: "8%" },
  { label: "Ciclo de Faturamento", width: "6%" },
  { label: "Atividade", width: "30%" },
  { label: "Profissional" },
  { label: "Demanda" },
  { label: "Projeto" },
  { label: "Cliente" },
  { label: "Arquivo", width: "5%", align: "center" },
  { label: "Horas totais", align: "center", width: "10%" }
];
