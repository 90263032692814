export const validateFileType = (filename, allowedExtentions = null) => {
  if (typeof filename !== "string") {
    return false;
  }

  let dotFileIndex = filename.lastIndexOf(".") + 1;
  let extFile = filename.substr(dotFileIndex, filename.length).toLowerCase();

  const ext = allowedExtentions ?? ["jpg", "jpeg", "png"];

  if (ext.includes(extFile)) {
    return extFile;
  } else {
    return false;
  }
};
