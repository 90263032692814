import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 12px;

  & > div:first-child {
    width: 100%;
  }
`;

export const Period = styled.span`
  margin-top: 1px;
  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 150%;
  color: #4672C5;
`;