import React from "react";
import { Link } from "react-router-dom";
import { NewestCard } from "../NewestCard";
import { NewsCard } from "../NewsCard";
import { Container } from "./styles";

export const NewsFeed = ({ newsList, page }) => {
  return (
    <Container>
      {newsList?.length > 0 &&
        (page === 1 ? (
          <>
            <div className="newest-list">
              {newsList.map(
                (news, i) =>
                  i < 3 && (
                    <Link key={news.slug} to={`/news/${news.slug}`}>
                      <NewestCard news={news} />
                    </Link>
                  )
              )}
            </div>
            <div className="news-list">
              {newsList.map(
                (news, i) =>
                  i >= 3 && (
                    <Link key={news.slug} to={`/news/${news.slug}`}>
                      <NewsCard news={news} />
                    </Link>
                  )
              )}
            </div>
          </>
        ) : (
          <div className="news-list">
            {newsList.map(news => (
              <Link key={news.slug} to={`/news/${news.slug}`}>
                <NewsCard news={news} />
              </Link>
            ))}
          </div>
        ))}
    </Container>
  );
};

// {newsList?.length > 0 && (
//   <>
//     <div className="newest-list">
//       {newsList.map(
//         (news, i) => i < 3 && <NewestCard key={i} news={news} />
//       )}
//     </div>
//     <div className="news-list">
//       {newsList.map(
//         (news, i) => i >= 3 && <NewsCard key={i} news={news} />
//       )}
//     </div>
//   </>
// )}
