import React, { useEffect, useState } from "react";
import moment from "moment";
import Datatable from "../../../../../../partials/datatable/Datatable";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import { ApprovalTableCell, ApprovalTableRow, ContainerActions } from "./style";
import store from "../../../../../../store/store";

export const ReportDatatable = ({
  key,
  projectList,
}) => {

  const width = useGetWindowWidth();
  const { auth } = store.getState();

  const formatRow = (r, index) => {
    let crudActions = [];

    return (

      <ApprovalTableRow hover tabIndex={-1} key={index}>
        <ApprovalTableCell scope="row" align="center">
          {<span className="status-color" />}
        </ApprovalTableCell>
        <ApprovalTableCell scope="row" align="center">{r.professionalName}</ApprovalTableCell>
        <ApprovalTableCell scope="row" align="center">{r.projectName}</ApprovalTableCell>
        <ApprovalTableCell scope="row" align="center">{r.qtdHours}</ApprovalTableCell>
      </ApprovalTableRow>
    );
  };

  const headRows = [
    {
      label:
        <div className="row ml-1 mt-1">
          &nbsp;
        </div>,
      align: 'center'
    },
    { label: "Profissional", width: "20%" , align: "center" },
    { label: "Projeto", width: "60%" , align: "center" },
    { label: "Total Horas", width: "20%" , align: "center" },
  ];

  return (
    <Datatable
      className="h-100 m-0 p-0"
      headRows={headRows}
      styledTableCell={ApprovalTableCell}
      styledTableRow={ApprovalTableRow}
      formatRow={formatRow}
      rows={projectList}
      // reload={reload}
      rowsPerPage={auth.user.rowsPerPage}
      width={width}
      removeShadow
      cancelReload
      hiddenReload
      hiddenFooterPagination
    />
  );
};
