import styled from "styled-components";

export const SearchBar = styled.div`
  position: relative;
  padding: 24px 0px;

  input {
    display: block;
    height: 48px;
    width: 100%;
    font-family: "Cera Pro Light", sans-serif;
    padding: 0.65rem 1rem;
    padding-left: 56px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #4d4c4d;
    border-radius: 4px;
    outline: none;
  }

  .icon-search {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 17px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: max-content;

  .nav-tabs {
    display: flex;
    margin: 0;
    border: none;
    border-radius: 4px 4px 0px 0px;
    gap: 6px;

    a.nav-item {
      padding: 14px 40px;
      background: #ffffff;

      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #4d4c4d;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px 4px 0px 0px;
      border: none;

      &.active {
        font-family: "Cera Pro Bold", sans-serif;
      }

      &:not(.active) {
        background: #f3f2f2;
      }
    }
  }

  .tab-content {
    height: 100%;
    width: 100%;
    background: #ffffff;

    .tab-pane.active {
      height: 100%;
      width: 100%;
      background: #ffffff;
      transition: none;
    }

    .card {
      display: flex;
      flex: 1;
      height: 100%;
      width: 100%;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 4px 4px;
      border: none;

      .card-body {
        padding: 24px;

        .user-list {
          display:flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }
`;
