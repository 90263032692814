import styled from "styled-components";

export const JsonContainer = styled.div`
  .c-json-outter {
    display: none;
  }

  .c-json-key {
    font-size: 13px;
    font-weight: bold;
    color: #4d4c4d;
  }

  .c-json-string {
    color: #807e80
  }

  .c-line-del {
    background: #ffb0b9;
  }

  .c-line-add {
    background: #9dffb8;
  }

`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #4d4c4d;
  }
`;
