import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import formatTel from "../../../../utils/FormatTel";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import { CycleList } from "../../../../utils/CycleList";
import verifyCpfCnpj, {
  formatCPF
} from "../../../../utils/validators/IsValidCpfCnpj";

const initialState = {
  id: 0,
  nome: "",
  razaoSocial: "",
  CNPJ: "",
  contato: "",
  telefone: "",
  email: "",
  isLoading: false,
  persistedModel: "",
  initialCycleDay: 0
};

export default class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      if (this.props.match.params.id) {
        await this.loadClient(this.props.match.params.id);
      }
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadClient = async id => {
    const client = await this.api.makeHttpRequest({
      url: `/client/${id}`
    });

    const persistedModel = {
      id: id,
      nome: client.name,
      razaoSocial: client.fantasyName,
      CNPJ: client.cnpj,
      contato: client.contact ?? "",
      telefone: client.phone,
      email: client.email,
      initialCycleDay: client.initialCycleDay
    };

    const persistedModelJson = JSON.stringify(persistedModel);
    this.setState({
      ...persistedModel,
      isLoading: false,
      persistedModel: persistedModelJson
    });
  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/client/update/${id}` }
        : { method: "POST", url: "/client/save" };

      const rawPhone = this.state.telefone
        ? this.state.telefone.replace(/\D/g, "")
        : "";

      const rawCnpj = this.state.CNPJ ? this.state.CNPJ.replace(/\D/g, "") : "";

      await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          name: this.state.nome,
          fantasyName: this.state.razaoSocial,
          CNPJ: rawCnpj,
          contact: this.state.contato ?? "",
          phone: rawPhone,
          email: this.state.email,
          initialCycleDay: this.state.initialCycleDay
        }
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar cliente, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/clientes");
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      nome: this.state.nome,
      razaoSocial: this.state.razaoSocial,
      CNPJ: this.state.CNPJ,
      contato: this.state.contato,
      telefone: this.state.telefone,
      email: this.state.email,
      initialCycleDay: this.state.initialCycleDay
    };

    const currentModelJson = JSON.stringify(currentModel);

    return (
      this.state.nome &&
      IsValidName(this.state.nome) &&
      this.state.email &&
      IsValidEmail(this.state.email) &&
      this.state.telefone &&
      IsValidPhone(this.state.telefone) &&
      this.state.CNPJ &&
      verifyCpfCnpj(this.state.CNPJ).isValid &&
      this.state.razaoSocial &&
      IsValidName(this.state.razaoSocial) &&
      (isEditing ? currentModelJson !== persistedModel : true) &&
      this.state.initialCycleDay
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadClient(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Clientes - Admin");
    } else {
      window.setPageTitle("Cadastro de Clientes - Admin");
    }

    const {
      nome,
      razaoSocial,
      CNPJ,
      contato,
      telefone,
      email,
      isLoading,
      isEditing,
      initialCycleDay
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Cliente" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Nome */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="nome"
                    label="Nome"
                    placeholder="Digite o nome"
                    value={nome}
                    onChange={this.handleChange}
                    isInvalid={nome && !IsValidName(nome)}
                    required
                  />
                </Form.Group>

                {/* Razão Social */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="razaoSocial"
                    label="Razão Social"
                    placeholder="Digite a razão social"
                    value={razaoSocial}
                    onChange={this.handleChange}
                    isInvalid={razaoSocial && !IsValidName(razaoSocial)}
                    required
                  />
                </Form.Group>

                {/* CNPJ */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="CNPJ"
                    label="CNPJ"
                    maxLength="18"
                    placeholder="Digite o CNPJ"
                    value={formatCPF(CNPJ)}
                    onChange={this.handleChange}
                    isInvalid={CNPJ && !verifyCpfCnpj(CNPJ).isValid}
                    required
                  />
                </Form.Group>
              </Form.Row>

              {/* row 2 */}
              <Form.Row>
                {/* Contato */}
                <Form.Group as={Col} lg="2" xs="12">
                  <InputField
                    type="text"
                    name="contato"
                    label="Contato"
                    placeholder="Digite o contato"
                    value={contato}
                    onChange={this.handleChange}
                  />
                </Form.Group>

                {/* Telefone */}
                <Form.Group as={Col} lg="2" xs="12">
                  <InputField
                    type="tel"
                    name="telefone"
                    label="Telefone"
                    maxLength="19"
                    placeholder="Digite o telefone com DDD"
                    value={formatTel(telefone)}
                    onChange={this.handleChange}
                    isInvalid={telefone && !IsValidPhone(telefone)}
                    required
                  />
                </Form.Group>

                {/* E-mail */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="email"
                    name="email"
                    label="E-mail"
                    placeholder="Digite o e-mail"
                    value={email}
                    onChange={this.handleChange}
                    isInvalid={email && !IsValidEmail(email)}
                    disabled={isEditing}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select"
                    name="initialCycleDay"
                    label="Data de Início do ciclo"
                    value={initialCycleDay}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione a data</option>
                    {CycleList?.length > 0 &&
                      CycleList.map((date, index) => (
                        <option key={index} value={date.value}>
                          {date.name}
                        </option>
                      )
                    )}
                  </InputField>
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}
