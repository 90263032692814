export function SlugTranslation(value) {
  switch (value) {
    case "clientes":
      return "client";
    case "coordenacoes":
      return "coordination";
    case "area-pagadora":
      return "payingArea";
    case "projetos":
      return "project"
    case "aprovacoes":
      return "approval"
    default:
      return value;
  }
};

export function TitleTranslation(value) {
  switch (value) {
    case "clientes":
      return "Clientes";
    case "coordenacoes":
      return "Coordenações";
    case "area-pagadora":
      return "Áreas Pagadoras";
    case "projetos":
      return "Projetos"
    case "aprovacoes":
      return "Timers"
    default:
      return value;
  }
};

export function ConvertSlugToSingular(value) {
  switch (value) {
    case "clientes":
      return "Cliente";
    case "coordenacoes":
      return "Coordenação";
    case "area-pagadora":
      return "Área Pagadora";
    case "projetos":
      return "Projeto"
    case "aprovacoes":
      return "Timer"
    default:
      return value;
  }
};
