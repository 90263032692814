import React, { useState, useEffect } from "react";
import { RiAlertLine } from "react-icons/ri";
import { Card, Tab, Tabs } from "react-bootstrap";
import { InexApiService } from "../../../../services/InexApiService";
import { EndOfPeriodIcon } from "../../../../components/IconsSVG";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { ApprovalCard } from "./components/ApprovalCard";
import Filters from "./components/Filters";
import { TabsContainer } from "./style";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import moment from "moment";

const EndOfPeriodFlex = () => {
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [period, setPeriod] = useState({});
  const [projectListCurrentCycle, setProjectListCurrentCycle] = useState([]);
  const [startPeriod, setStartPeriod] = useState(new Date());
  const [endPeriod, setEndPeriod] = useState(new Date());

  useEffect(() => {
    window.setPageTitle("Fechamento Flexível - Admin");
    const loadSelects = async () => {
      const selects = await api.makeHttpRequest({
        url: "/flexiclosure/selects"
      });

      setFilterOptions(selects ?? {});
    };

    loadSelects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (filters) => {
    setLoading(true);

    setPeriod({
      periodMonth: filters.periodMonth,         
      periodYear: filters.periodYear
    })

    const startDate = moment(filters.startPeriod).subtract(3, 'hour').toDate()
    const endDate = moment(filters.endPeriod).subtract(3, 'hour').toDate()

    setStartPeriod(startDate)
    setEndPeriod(endDate)

    try {
      const params = {
        startPeriod: startDate,
        endPeriod: endDate,
        hasPeriod: true,
        idClient: filters.client,
        idProject: filters.project,
        idCoordinator: filters.coordinator,
        idProfessional: filters.employee,
        showOnlyMyProfessionals: filters.showOnlyMyProfessionals
      };

      const resProjects = await api.makeHttpRequest({
        url: "/flexiclosure",
        params
      });

      setProjectListCurrentCycle(resProjects??[]);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
        "error"
      );
    }
    setLoading(false);
  }


  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={EndOfPeriodIcon} title="Fechamento Flexível" />
        <br></br>
        <Filters
          reload={reload}
          onSubmit={onSubmit}
          setReload={setReload}
          filterOptions={filterOptions}
          loading={loading}
          setLoading={setLoading}
        />
        <Card>
          <Card.Body>
          <div className="user-list">
            {projectListCurrentCycle?.length > 0 &&
              projectListCurrentCycle.map(item => {
                {/* AQUI ESTOU SOMANDO O totalLoadedMinutes Para exibir no titulo junto como nome do Profissional*/}
                let totalLoadedMinutes = 0;
                let totalAllocatedMinutes = 0;
                item.loadMinutesProjectList.forEach(project => {
                  totalAllocatedMinutes += project.totalAllocatedMinutes;
                  totalLoadedMinutes += project.totalLoadedMinutes;
                });
                return (
                  <ApprovalCard
                    key={item.professional.idUser+'_first'}
                    user={item.professional.idUser}
                    title={item.professional.name}
                    noteForBilling={item.professional.noteForBilling}
                    totalAllocatedMinutes={totalAllocatedMinutes}
                    totalLoadedMinutes={totalLoadedMinutes}
                    totalTime={item.totalMinutes}
                    projectList={item.loadMinutesProjectList}
                    setLoading={setLoading}
                    setReload={setReload}
                    period={period}
                    startPeriod={startPeriod}
                    endPeriod={endPeriod}
                  />
                );
              })}
          </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default EndOfPeriodFlex;
