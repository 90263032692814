import styled from 'styled-components';

export const Container = styled.button`
 display: flex;
 height: 56px;
 width: 100%;
 background-color: #FFFFFF;
 border: 1px solid #4672C5;
 border-radius: 4px;
 color: #4672C5;
 font-family: "Cera Pro Medium", sans-serif;
 font-weight: 300;
 font-size: 18px;
 align-items: center;
 justify-content: center;
 margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : 0};

 svg {
	transform: rotate(90deg);
 }

 :hover {
   transition: ${({isLoading}) => isLoading ? 0 :  '0.3s'};
   opacity: ${({isLoading}) => isLoading ? 1 :  '1'};
   border: 1px solid #ffff;
   border: ${({isLoading}) => isLoading && 0};
   color: #fff;
   background: #4672C5;
  }
`;
