import React from "react";
import moment from "moment";
import Datatable from "../../../../../../partials/datatable/Datatable";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import { RitualTableCell, RitualTableRow } from "./style";
import { Link } from "react-router-dom";
import { InputField } from "../../../../components/InputField";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService";
import CrudActions from "../../../../../../partials/datatable/CrudActions";
import store from "../../../../../../store/store";
import { ERoles } from "../../../../../../enums/Roles";

const tableHeads = [
  { label: "Concluído", value: 3 },
  { label: "Em Aberto", value: 0 },
  { label: "Em Andamento", value: 2 },
  { label: "Não Aplicável", value: 1 }
];

export const RitualDatatable = ({
  reload,
  ritualType,
  filters,
  filterString,
  setReload
}) => {
  const api = new InexApiService();
  const { auth } = store.getState();

  const width = useGetWindowWidth();

  moment.locale("pt-br", {
    months: "Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro".split(
      "_"
    ),
    monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_")
  });

  const [rituals, setRituals] = React.useState([]);

  const handleChangeStatus = React.useCallback(
    async (idRitual, newStatus) => {
      try {
        await api.makeHttpRequest({
          method: "PUT",
          url: `/ritual/update-status/${idRitual}`,
          data: { status: newStatus }
        });

        setReload(true);

        Swal.fire("Sucesso", "Status alterado com sucesso.", "success");

        setReload(false);
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao alterar status.",
          "error"
        );
      }
    },
    [api, setReload]
  );

  const onDelete = React.useCallback(
    async id => {
      try {
        await api.makeHttpRequest({
          url: `/ritual/soft-delete/${id}`,
          method: "DELETE"
        });

        setReload(true);

        Swal.fire("Sucesso", "Ritual deletado com sucesso.", "success");

        setReload(false);
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao deletar o ritual.",
          "error"
        );
      }
    },
    [api, setReload]
  );

  const formatRow = r => {
    const statusValue = { value: r.status, label: r.statusName };
    const crudActions = [];

    if (
      auth?.user?.role?.type === ERoles.ADMIN ||
      auth?.user?.role?.type === ERoles.OPERATION
    ) {
      crudActions.push("soft-delete");
    }

    return (
      <RitualTableRow hover tabIndex={-1} key={r.idRitual}>
        <RitualTableCell scope="row">
          {auth?.user?.role?.type === ERoles.ADMIN ||
          auth?.user?.role?.type === ERoles.OPERATION ? (
            <Link
              to={`/admin/rituais/${r.idRitual}/editar`}
              className="ritual-link"
            >
              {moment(r.competencia).format("MMMM/YYYY")}
            </Link>
          ) : (
            moment(r.competencia).format("MMMM/YYYY")
          )}
        </RitualTableCell>
        <RitualTableCell scope="row" align="center">
          {r.client}
        </RitualTableCell>
        <RitualTableCell scope="row" align="center">
          {!!r.coordination && r.coordination}
        </RitualTableCell>
        <RitualTableCell scope="row" align="center">
          {!!r.projectCoordinator && r.projectCoordinator}
        </RitualTableCell>
        <RitualTableCell scope="row" align="center">
          {r.totalProfessionals}
        </RitualTableCell>
        <RitualTableCell scope="row" align="center">
          <div className="status-select">
            <InputField
              as="select"
              name="status"
              value={statusValue.value}
              onChange={e => handleChangeStatus(r.idRitual, e.target.value)}
            >
              {tableHeads?.map((i, index) => (
                <option className="option" key={index} value={i.value}>
                  {i.label}
                </option>
              ))}
            </InputField>
          </div>
        </RitualTableCell>

        {(auth?.user && auth?.user?.role?.type === ERoles.ADMIN) ||
        (auth?.user && auth?.user?.role?.type === ERoles.OPERATION) ? (
          <RitualTableCell scope="row">
            <CrudActions
              actions={crudActions}
              module="ritual"
              onConfirmDelete={onDelete}
              id={r.idRitual}
              obj={r}
            />
          </RitualTableCell>
        ) : null}
      </RitualTableRow>
    );
  };

  const headRows = [
    { column: "Competencia", label: "Competência" },
    { column: "Client.Name", label: "Cliente", align: 'center' },
    { column: "Coordination.Name", label: "Coordenação", align: 'center' },
    { column: "ProjectCoordinator.Name", label: "Coordenador do Projeto", align: 'center' },
    { column: "TotalProfessionals", label: "Profissionais", align: 'center' },
    { column: "Status", label: "Status" }
  ];

  if (
    (auth?.user && auth?.user?.role?.type === ERoles.ADMIN) ||
    (auth?.user && auth?.user?.role?.type === ERoles.OPERATION)
  ) {
    headRows.push({ label: "Ações", align: "center" });
  }

  return (
    <Datatable
      className="h-100 m-0 p-0"
      endpoint="/ritual/paged"
      ritualType={ritualType}
      search={filterString}
      filters={filters}
      headRows={headRows}
      styledTableCell={RitualTableCell}
      styledTableRow={RitualTableRow}
      formatRow={formatRow}
      rows={rituals}
      setRows={setRituals}
      reload={reload}
      width={width}
      hiddenReload
    />
  );
};
