import React from "react";
import { InputField } from "./InputField";

export const HeaderSelect = ({ value, onChange, placeholder, options }) => {
  return (
    <InputField
      as="select2"
      value={value}
      options={options ?? []}
      placeholder={placeholder}
      onChange={onChange}
      closeMenuOnSelect
    />
  );
};
