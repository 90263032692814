import React, { useEffect, useState } from "react";
import { InputField } from "../../../../components/InputField";
import { makeStyles } from "@material-ui/core/styles/";
import { Container } from "./style";
import moment from "moment";
import { ButtonFilter as Button } from "../Button/style";
import { FiFilter } from "react-icons/fi";
import { debounce } from "lodash";
import { getPortoDefaultDate } from "../../../../../../utils/GetPortoDefaultDate";
import { GetYearList, GetMonthList, getDefaultDate, GetPeriodBasedTodayDate } from "../../../../../../utils/GetYearsAndMonths";
import { useSelector } from "react-redux";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import Swal from "sweetalert2";


const initialFilterDates = getPortoDefaultDate(22);

const initialFilterYearList = GetYearList();
const initialFilterMonthList = GetMonthList();
const period = GetPeriodBasedTodayDate();

const useStyles = makeStyles(() => ({
  button: {
    margin: "3px -10px",
    "& svg": {
      fill: "#807e80"
    },
    "&:hover": {
      "& svg": {
        fill: "#4672C5"
      }
    }
  },
  div: {
    display: "flex",
    flexDirection: "row"
  }
}));

export const Filters = ({ reload, onSubmit, setReload, filterOptions, loading, setLoading }) => {
  const classes = useStyles();
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);
  const isCoordinator = user?.role?.type === ERoles.OPERATION;

  const [periodOpt, setPeriodOpt] = useState("");

  const [periodYearList, setPeriodYearList] = useState(initialFilterYearList);
  const [periodMonthList, setPeriodMonthList] = useState(initialFilterMonthList);
  const [coordinationOptions, setCoordinationOptions] = useState([]);
  const [professionalOptions, setProfessionalOptions] = useState([]);

  const [filters, setFilters] = useState({
    periodYear: moment().year(),
    periodMonth: moment().month()+1,
    startPeriod: initialFilterDates.startDate,
    endPeriod: initialFilterDates.endDate,
    client: user.clientDefault ? user.clientDefault.idClient : "",
    coordination: "",
    coordinator: isCoordinator ? `${user.idUser}` : "",
    employee: ""
  });

  React.useEffect(() => {
    if (filters.client) {
      loadCoordinationByIdClient(filters.client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  const loadCoordinationByIdClient = async (idClient = "") => {
    if (user.role.idRole != 1) {
      try {
        setLoading(true);
        const coordination = await api.makeHttpRequest({
          url: `/approvalreport/coordinations-by-user/client/${idClient}`
        });
        setCoordinationOptions(coordination.coordinations ?? []);
        setProfessionalOptions(coordination.professionals ?? []);
      } catch (e) {
        setLoading(false);
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao carregar as coordenações, tente novamente mais tarde.",
          "error"
        );
      } finally {
        setLoading(false);
      }
    } else {
      setCoordinationOptions(filterOptions.coordinations ?? []);
      setProfessionalOptions(filterOptions.professionals ?? []);
    }
  };

  const handleChange = e => {

    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleChangeYear = e => {
    const filterDates = getDefaultDate(e.target.value, filters.periodMonth);   
    
    setFilters({ 
      ...filters,
      periodYear: e.target.value,
      startPeriod: filterDates.startDate,
      endPeriod: filterDates.endDate,
    });
  };

  const handleChangeMonth = e => {
    const filterDates = getDefaultDate(filters.periodYear, e.target.value);  

    setFilters({ 
      ...filters, 
      periodMonth: e.target.value,
      startPeriod: filterDates.startDate,
      endPeriod: filterDates.endDate,
    });
  };


  const debounceOnClick = debounce(() => onSubmit(filters), 400);

  useEffect(() => {
    //On the first time of page load , we need substract 3 hours from startPeriod, 
    //that is because we have a issue with the way the app resolves GMT setting. 
    //Added this issue to the backlog. 
    filters.startPeriod = moment( filters.startPeriod)
                              .startOf("day")
                              .add(-3, "hour")
                              .toDate();
    onSubmit(filters);
  }, []);

  useEffect(() => {
    if (reload) {
      onSubmit(filters);
      setReload(false);
    }
  }, [reload]);


  // const handleGetArrClientSelected = filter => {
  //   if(filter > 0) {
  //     const filterProjectsWhereIdSelected = filterOptions?.projects.length > 0
  //       ? filterOptions?.projects?.find(p => p.client.idClient === +filter)
  //       : null;

  //     setArrClientFiltered(filterProjectsWhereIdSelected
  //       ? [filterProjectsWhereIdSelected]
  //       : []
  //     );
  //   }
  // }

  const PeriodFilter = () => {
    return (
      <InputField
        select2ClassName="disabled-normal"
        as="select2"
        name="periodOpt"
        value={periodOpt}
        placeholder="Período"
        disabled
      />
    );
  };

  React.useEffect(() => {
    if (filters.coordination) {
      loadProjectByIdCoordination(filters.coordination);
    } else {
      loadProjectByIdCoordination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.coordination]);

  const loadProjectByIdCoordination = async (idCoordination = "") => {
    if (user.role.idRole != 1) {
      try {
        setLoading(true);
        if (idCoordination !== "") {
          const projectReq = await api.makeHttpRequest({
            url: `/approvalreport/projects/coordination-by-user/${idCoordination}`
          });
          setProfessionalOptions(projectReq.professionals ?? []);
        } else {
          if (filters.client) {
            const coordination = await api.makeHttpRequest({
              url: `/approvalreport/coordinations-by-user/client/${filters.client}`
            });
            setProfessionalOptions(coordination.professionals ?? []);
          }
        }
      } catch (e) {
          setLoading(false);
          Swal.fire(
            "Erro!",
            e?.response?.data ? e.response.data : "Erro ao carregar os projetos, tente novamente mais tarde.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Container>
        {/* Cliente */}
        <InputField
          as="select"
          name="client"
          value={filters.client}
          onChange={handleChange}
        >
          <option value="">Cliente</option>
          {filterOptions?.clients?.length > 0 &&
            filterOptions.clients.map(c => (
              <option key={c.idClient} value={c.idClient}>
                {c.name}
              </option>
            ))}
        </InputField>
        
        {/* Ano Período */}
        <InputField
          as="select"
          name="periodYear"
          value={filters.periodYear}
          onChange={handleChangeYear}
        >
          {periodYearList.length > 0 &&
            periodYearList.map(y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
        </InputField>

        {/* Mês Período */}
        <InputField
          as="select"
          name="periodMonth"
          value={filters.periodMonth}
          onChange={handleChangeMonth}
        >
          {periodMonthList.length > 0 &&
            periodMonthList.map(m => (
              <option key={m.cod} value={m.cod}>
                {m.name}
              </option>
            ))}
        </InputField>
      

        {/* Coordenação */}
        <InputField
          as="select"
          name="coordination"
          value={filters.coordination}
          onChange={handleChange}
        >
          <option value="">Coordenação</option>
          {        
            coordinationOptions.length > 0 &&
              coordinationOptions.map(p => (
                <option key={p.idProjectArea} value={p.idProjectArea}>
                  {p.name}
                </option>
              )
            )
          }
        </InputField>

        {/* Coordenador de projeto */}
        <InputField
          as="select"
          name="coordinator"
          value={filters.coordinator}
          onChange={handleChange}
        >
          <option value="">Coordenador de Projeto</option>
          {filterOptions?.coordinators?.length > 0 &&
            filterOptions.coordinators.map(c => (
              <option key={c.idUser} value={c.idUser}>
                {c.name}
              </option>
            ))}
        </InputField>

        {/* Profissional */}
        <InputField
          as="select"
          name="employee"
          value={filters.employee}
          onChange={handleChange}
        >
          <option value="">Profissional</option>
          {professionalOptions.length > 0 &&
            professionalOptions.map(c => (
              <option key={c.idUser} value={c.idUser}>
                {c.name}
              </option>
            ))}
        </InputField>

        <Button type="button" variant="filled" onClick={() => debounceOnClick()}>
          <span className="icon">
            <FiFilter size={22} />
          </span>{" "}
          <span>filtrar</span>
        </Button>
      </Container>
    </div>
    
  );
};

export default Filters;
