import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { UserIcon } from "../../../../../../components/IconsSVG";
import Datatable from "../../../../../../partials/datatable/Datatable";
import { InexApiService } from "../../../../../../services/InexApiService";
import Loading from "../../../../../home/components/Loading";
import { AdminTitle } from "../../../../components/AdminTitle";
import {
  ReportTableCell,
  ReportTableRow,
  TableContainer,
  TableToExcelButton
} from "./style";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const UserReport = props => {
  const api = new InexApiService();
  const params = props.location.search;

  const [loading, setLoading] = React.useState(false);

  const [reportHeaders, setReportHeaders] = React.useState([]);
  const [report, setReport] = React.useState([]);

  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    handleReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReport = React.useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.makeHttpRequest({
        url: "/professional/export" + params
      });

      if (response && response.length > 0) {
        const headers = Object.keys(response[0]).map(item => ({ label: item }));
        const arr = response.map(_ => false);

        setReportHeaders(headers);
        setReport(response);
        setSelected(arr);
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao gerar relatório.",
        "error"
      );
    }
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectRow = index => {
    const arr = [...selected];
    arr[index] = !arr[index];
    setSelected(arr);
  };

  const formatRow = (r, index) => {
    return (
      <ReportTableRow
        hover
        tabIndex={-1}
        key={index}
        onClick={() => handleSelectRow(index)}
        style={selected[index] ? { background: "#E4E4E4" } : null}
      >
        {Object.values(r)?.map((item, i) => (
          <ReportTableCell key={i} scope="row">
            {item ? item : "--"}
          </ReportTableCell>
        ))}
      </ReportTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      {report?.length > 0 && (
        <>
          <AdminTitle
            title="Relatório de Profissionais"
            icon={UserIcon}
            disableHeader
          />
          <div style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            alignItems: "center"
          }}>
            <Link to={`/admin/profissionais`}>
              <Button variant="secondary" color="#4672C5" type="button">
                <span>
                  Voltar
                </span>
              </Button>
            </Link>
            <TableToExcelButton
              table="datatable-table"
              className="export-table-button"
              filename={`Lista de Ativos ${moment().format("DD-MM-YYYY")}`}
              sheet="Tabela 1"
              buttonText="Exportar"
            />
          </div>
          <TableContainer>
            <Datatable
              className="m-0 p-0"
              headRows={reportHeaders}
              styledTableCell={ReportTableCell}
              styledTableRow={ReportTableRow}
              overflowX="initial"
              formatRow={formatRow}
              rows={report}
              width={props.width}
              hiddenFooterPagination
              cancelReload
              hiddenReload
            />
          </TableContainer>
        </>
      )}
      {report?.length === 0 && (
        <>
          <AdminTitle
            title="Relatório de Profissionais"
            icon={UserIcon}
            disableHeader
          />
          <div style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: '20px'
          }}>
            <Link to={`/admin/profissionais`}>
              <Button variant="secondary" color="#4672C5" type="button">
                <span>
                  Voltar
                </span>
              </Button>
            </Link>
          </div>
          <div style={{textAlign: 'center'}}>
            <h1>Nenhum Profissional Encontrado</h1>
          </div>
        </>
      )}
    </>
  );
};

export default UserReport;
