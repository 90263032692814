import React, { useEffect, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService";
import { InputField } from "../../../../components/InputField";
import { Button } from "../Button";
import { StyledModal } from "./style";

const initialValues = {
  competencia: new Date(),
  coordenacao: "",
  projeto: ""
};

export const CreateRitualModal = ({ open, setOpen, reload }) => {
  const api = new InexApiService();

  const [projectOptions, setProjectOptions] = useState([]);
  const [coordinationOptions, setCoordinationOptions] = useState([]);

  const [ritual, setRitual] = useState(initialValues);

  useEffect(() => {
    if (open) {
      setRitual(initialValues);
    }
  }, [open]);

  const loadAreas = async () => {
    const coord = await api.makeHttpRequest({
      url: "/ritual/project/areas"
    });

    setCoordinationOptions(coord ?? []);
  };

  const loadProjects = async () => {
    try {
      const projects = await api.makeHttpRequest({
        url: "/ritual/projects"
      });

      setProjectOptions(projects ?? []);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao carregar lista de projetos.",
        "error"
      );
    }
  };

  useEffect(() => {
    loadProjects();
    loadAreas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const params = {
        idProject: ritual.projeto,
        idProjectArea: ritual.coordenacao,
        date: ritual.data
      };

      await api.makeHttpRequest({
        url: "/ritual/save",
        method: "POST",
        params
      });

      Swal.fire("Sucesso!", "Rituais criados com sucesso", "success");

      reload();
      setOpen(false);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao criar rituais.",
        "error"
      );
    }
  };

  const formIsValid = () => {
    return (
      !!ritual.data &&
      ((ritual.projeto && !ritual.coordenacao) ||
        (!ritual.projeto && ritual.coordenacao))
    );
  };

  const handleChange = e => {
    setRitual({ ...ritual, [e.target.name]: e.target.value });
  };

  return (
    <StyledModal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      centered
      scrollable
    >
      <Modal.Body>
        <h1 className="modal-title">
          Preencha os campos abaixo para adicionar um novo ritual:
        </h1>
        <Form>
          {/* row 1 */}
          <Form.Row>
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                type="date"
                name="data"
                label="Período"
                placeholder="Selecione"
                dateFormat="MMMM/yyyy"
                showMonthYearPicker
                value={ritual.data}
                onChange={handleChange}
                isClearable
                required
              />
            </Form.Group>
          </Form.Row>

          {/* row 2 */}
          <Form.Row>
            {/* Coordenação */}
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="select"
                name="coordenacao"
                label="Coordenação"
                value={ritual.coordenacao}
                onChange={handleChange}
                disabled={!!ritual.projeto}
                required={!ritual.projeto}
                className={!!ritual.projeto ? "dim-light" : ""}
              >
                <option value="">Selecione a coordenação</option>
                {coordinationOptions?.length > 0 &&
                  coordinationOptions.map(c => (
                    <option key={c.idProjectArea} value={c.idProjectArea}>
                      {c.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>
          </Form.Row>

          {/* row 3 */}
          <Form.Row>
            {/* Projeto */}
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="select"
                name="projeto"
                label="Projeto"
                value={ritual.projeto}
                onChange={handleChange}
                disabled={!!ritual.coordenacao}
                required={!ritual.coordenacao}
                className={!!ritual.coordenacao ? "dim-light" : ""}
              >
                <option value="">Selecione o projeto</option>
                {projectOptions?.length > 0 &&
                  projectOptions.map(p => (
                    <option key={p.idProject} value={p.idProject}>
                      {p.projectArea?.name + " - " + p.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>
          </Form.Row>

          <div className="last-row">
            <div className="save-btn">
              <Button type="button" onClick={() => setOpen(false)}>
                cancelar
              </Button>
            </div>
            <div className="save-btn">
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={!formIsValid()}
              >
                confirmar
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
};
