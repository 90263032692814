import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { ERoles } from "../../../enums/Roles";

const AdminRoute = props => {
  const [returnedRoute, setReturnedRoute] = useState("");
  useEffect(() => {
    switch (props.permissionLevel) {
      case ERoles.ADMIN:
        return setReturnedRoute(
          props.roleType === ERoles.ADMIN ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );
      case ERoles.OPERATION:
        return setReturnedRoute(
          props.roleType === ERoles.OPERATION
            || props.roleType === ERoles.ADMIN
          ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );

      case ERoles.COMMERCIAL:
        return setReturnedRoute(
          props.roleType === ERoles.COMMERCIAL
            || props.roleType === ERoles.ADMIN
            || props.roleType === ERoles.OPERATION
          ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );

      case "RECRUITMENT_WITCHOUT_COMMERCIAL":
        return setReturnedRoute(
          props.roleType === ERoles.ADMIN
            || props.roleType === ERoles.OPERATION
            || props.roleType === ERoles.FINANCIAL
            || props.roleType === ERoles.RECRUITMENT
          ? (
            <Route {...props} />
          ): (
            <Redirect to="/admin/error" />
          )
        );

      case "COMMERCIAL_WITCHOUT_OPERATION":
        return setReturnedRoute(
          props.roleType === ERoles.COMMERCIAL
            || props.roleType === ERoles.ADMIN
            || props.roleType === ERoles.FINANCIAL
          ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );

      case ERoles.FINANCIAL:
        return setReturnedRoute(
          props.roleType === ERoles.FINANCIAL
            || props.roleType === ERoles.ADMIN
            || props.roleType === ERoles.OPERATION
            || props.roleType === ERoles.COMMERCIAL
          ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );

      case ERoles.RECRUITMENT:
        return setReturnedRoute(
          props.roleType === ERoles.RECRUITMENT
            || props.roleType === ERoles.ADMIN
            || props.roleType === ERoles.OPERATION
            || props.roleType === ERoles.COMMERCIAL
            || props.roleType === ERoles.FINANCIAL
          ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );

      case ERoles.CONSULTANT:
      default:
        return setReturnedRoute(<Route {...props} />);
    }
  }, [props, props.permissionLevel]);
  return <>{returnedRoute}</>;
};

const mapStateToProps = state => ({
  roleType: state?.auth?.user?.role?.type
});

export default connect(mapStateToProps, null)(AdminRoute);
