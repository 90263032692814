import React from "react";
import { Container } from "./styles";

const Button = ({
  text,
  opacity,
  disabled,
  cursor,
  type,
  loading,
  marginBottom,
  onClick
}) => {
  return (
    <Container
      type={type}
      isLoading={loading}
      marginBottom={marginBottom}
      style={{ opacity, cursor }}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Container>
  );
};

export default Button;
