import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InputField } from "../../../../components/InputField";
import { ButtonContainer, StyledModal } from "./styles";
import { InexApiService } from "../../../../../../services/InexApiService";

export const ReportSelectModal = ({ open, setOpen }) => {
  const api = new InexApiService();

  const [filters, setFilters] = React.useState({
    idClient: "",
    clientName: ""
  });

  const tableHeads = [
    { label: "Projeto", value: "Projeto" },
    { label: "Cliente", value: "Cliente" },
    { label: "Coordenação", value: "Coordenação" },
    { label: "Área Pagadora", value: "Área Pagadora" },
    { label: "Tipo Projeto", value: "Tipo Projeto" },
    { label: "Coordenador Operação", value: "Coordenador Operação" },
    { label: "Gerente Comercial", value: "Gerente Comercial" },
    { label: "Proposta", value: "Proposta" },
    { label: "Gerente Projeto (SM)", value: "Gerente Projeto (SM)" },
    { label: "Nome do Alocador", value: "Nome do Alocador" },
    { label: "Valor Escopo", value: "Valor Escopo" }
  ];

  const reportOptions = [
    {
        key: 0,
        value: "Projetos sem lançamentos de horas"
    },
    {
      key: 1,
      value: "Relatório Geral"
    }
  ]
  const [reportSelected, setReportSelected] = React.useState(0);

  const reportWithoutLaunchedOptions = [
    {
        key: 0,
        value: "Últimos 3 meses"
    },
    {
        key: 1,
        value: "Últimos 6 meses"
    },
    {
        key: 2,
        value: "Últimos 12 meses"
    },
    {
        key: 3,
        value: "Último 18 meses"
    },
    {
        key: 4,
        value: "Sem lançamentos"
    }
  ]
  const [reportWithoutLaunchedSelected, setReportWithoutLaunchedSelected] = React.useState(0);

  const [fields, setFields] = React.useState(tableHeads);

  const [clientOptions, setClientOptions] = React.useState([]);

  const handleChange = e => {
    if (e.target.name === 'client') {
      let client = clientOptions.filter((c) => c.idClient === parseInt(e.target.value))[0];
      setFilters({ ...filters, clientName: client.name, idClient: client.idClient });
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }
  };

  const loadClientes = React.useCallback(async () => {
    const clientes = await api.makeHttpRequest({
      url: "/professional/clients"
    });

    setClientOptions(clientes ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledModal show={open} onHide={() => setOpen(false)} size="lg" centered>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  as="select"
                  name="reportSelected"
                  label="Tipos de Relatórios"
                  value={reportSelected}
                  onChange={(e) => setReportSelected(parseInt(e.target.value))}
                >
                    {
                        reportOptions?.length > 0 &&
                        reportOptions.map(r => (
                            <option key={r.key} value={r.key}>
                                {r.value}
                            </option>
                        ))
                    }
                </InputField>
              </Form.Group>
            </Form.Row>
            {
              reportSelected === 0 && 
              <Form.Row>
                  <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                          as="select"
                          name="filter"
                          label="Filtro"
                          value={reportWithoutLaunchedSelected}
                          onChange={(e) => setReportWithoutLaunchedSelected(parseInt(e.target.value))}
                      >
                          {
                              reportWithoutLaunchedOptions?.length > 0 &&
                              reportWithoutLaunchedOptions.map(r => (
                                  <option key={r.key} value={r.key}>
                                      {r.value}
                                  </option>
                              ))
                          }
                      </InputField>
                  </Form.Group>
              </Form.Row>
            }
            {
              reportSelected === 1 && 
              <Form.Row>
                {/* Cliente */}
                <Form.Group as={Col} lg="12" xs="12">
                  <InputField
                    as="select"
                    name="client"
                    label="Cliente"
                    value={filters.idClient}
                    onChange={handleChange}
                  >
                    <option value="">Selecione</option>
                    {clientOptions?.length > 0 &&
                    clientOptions.map(c => (
                      <option key={c.idClient} value={c.idClient}>
                        {c.name}
                      </option>
                    ))}
                  </InputField>
                </Form.Group>
                {/* Campos Exibidos no Relatório */}
                <Form.Group as={Col} lg="12" xs="12">
                  <InputField
                    as="select2"
                    isMulti
                    name="columns"
                    label="Campos Exibidos no Relatório"
                    value={fields}
                    onChange={e => setFields(e)}
                    options={tableHeads}
                  />
                </Form.Group>
              </Form.Row>
            }
          </Form>
          <ButtonContainer>
            <Link
              to={location => {           
                const paransFields =
                  fields?.length > 0 ? fields.map(item => item.value) : [];
                       
                const params = reportSelected === 0 ? {
                  reportWithoutLaunchedSelected: reportWithoutLaunchedSelected
                } :
                {
                  idClient: filters.idClient,
                  clientName: filters.clientName,
                  columnNames: paransFields.toString()
                };

                const qs = new URLSearchParams(params);

                return {
                  ...location,
                  pathname: reportSelected === 0 ? "/admin/projetos/relatorio-sem-lancamento" :
                    "/admin/projetos/relatorio",
                  search: qs.toString()
                };
              }}
            >
              <button type="button">
                ABRIR RELATÓRIO
              </button>
            </Link>
          </ButtonContainer>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
