export default function FormatJiraHour(value){
  value = value.replace(/[^0-9hm]/gi, "")
  const valueLength = value.length;

  if (valueLength === 5) {
    value = value.replace(/(\d{3})(\d)/i, "$1h")
    value = value.replace(/(\d{3})(\d{3})$/i, "$1h$2m");
  }

  return value;
}