import styled from "styled-components";
import { Card } from "react-bootstrap";

export const StyledCard = styled(Card)`
  margin-top: 16px;

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 16px 16px 8px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .icon {
        margin-right: 16px;
      }

      span {
        font-family: "Cera Pro Medium", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: #4d4c4d;
      }
    }

    .user-list {
      display: flex;
      max-width: 100%;
      padding-bottom: 8px;

      overflow-x: scroll;

      ::-webkit-scrollbar {
        width: 6px;
        background: transparent;
        border-radius: 12px;
        height: 6px;
      }

      ::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 12px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-right: 24px;

      &:last-child {
        margin: 0;
      }

      .image-wrapper {
        width: 80px;
        height: 80px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        overflow: hidden;

        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        font-family: "Cera Pro Medium", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #4672C5;

        white-space: nowrap;
      }
    }
  }
`;
