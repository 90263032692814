import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PageTitle } from "../PageTitle";
import { Container } from "./styles";
import { InputField } from "../../../admin/components/InputField";
import { Form, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import Loading from "../../../home/components/Loading";



const initialState = {
  status: "Parado",
  valorTotal: "",
  listaNotas: "", 
  loading: false
}


const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const DivStatus = styled.div`
  position: relative;
  width: 400px;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
  line-height: 150%;
  text-transform: uppercase;
  margin-left: 10px;
`;



class InvoiceCombinationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  formatNumber = num => {
    if (num === null || num === undefined) {
      return '';
    }
  
    let str = num.toString();
    let formattedStr = '';
    let n = str.length;
  
    for (let i = n - 1; i >= 0; i--) {
      formattedStr = str.charAt(i) + formattedStr;
      if ((n - i) % 3 === 0 && i !== 0) {
        formattedStr = '.' + formattedStr;
      }
    }
  
    return formattedStr;
  } 

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  handleNotaFiscal = async () => {
    this.setState({ loading: true });
    const start = new Date().getTime();
    await this.sleep(50);
    
    const valorTotal = parseFloat(this.state.valorTotal.replace('R$ ', '').replace('.', '').replace(',', '.'));
    const linhas = this.state.listaNotas.split('\n');
    
    const matriz = [];
    const valores = [];
    
    for (let i = 0; i < linhas.length; i++) {
      const colunas = linhas[i].split('\t');
      const referencia = colunas[0] ? colunas[0].trim() : '';
      const valor = colunas[2] ? parseFloat(colunas[2].trim().replace(/\./g, '').replace('R$ ', '').replace(',', '.')) : 0;
      matriz.push([referencia, valor]);
      valores.push(valor);
    }
    
    const arrayNotasOriginal = matriz.filter(nota => nota[1] <= valorTotal);
    const arrayNotas = arrayNotasOriginal.map(nota => [nota[0], Number(nota[1].toFixed(2))]);
    const total_combinacoes = 2 ** arrayNotas.length;
  
    console.log("Qtd de Notas:", matriz.length, " - Qtd Analisadas: ", arrayNotas.length);
  
    const combinacoes_iguais_valor_total = [];
    let qtd_combinacoes_verificadas = 1;
    let qtd_combinacoes_iguais_valor_total = 0;
  
    for (let i = 1; i < total_combinacoes; i++) {
      const comb = [];
      let soma = 0
      for (let j = 0; j < arrayNotas.length; j++) {
        if ((i & (1 << j)) !== 0) {
          comb.push(arrayNotas[j][0]);
          soma = Number((soma + arrayNotas[j][1]).toFixed(2));
        }
      }
      qtd_combinacoes_verificadas += 1;
  
      if (Math.abs(soma - valorTotal) <= 0.05) {
        combinacoes_iguais_valor_total.push(comb);
        qtd_combinacoes_iguais_valor_total += 1;
      }
  
      if (qtd_combinacoes_verificadas > 0 && qtd_combinacoes_verificadas % 1000000 === 0) {
        console.log(`${qtd_combinacoes_verificadas}/${total_combinacoes}`);
      }
      
      if (qtd_combinacoes_verificadas > 0 && qtd_combinacoes_verificadas % 1000000 === 0) {
        const percentual = qtd_combinacoes_verificadas / total_combinacoes * 100;
        console.log(`${percentual.toFixed(2)}% (${qtd_combinacoes_verificadas}/${total_combinacoes})`);
      }
    }
  
    if (combinacoes_iguais_valor_total.length > 0) {
      console.log(combinacoes_iguais_valor_total);
    } else {
      console.log('Nenhuma combinação encontrada.');
    }

    const end = new Date().getTime();
    const tempoExecucao = (end - start) / 1000; // Tempo em segundos
    const horas = Math.floor(tempoExecucao / 3600);
    const minutos = Math.floor((tempoExecucao % 3600) / 60);
    const segundos = Math.floor(tempoExecucao % 60);

    const tempoFormatado = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

    const res = `
    Analisamos ${arrayNotas.length} notas de um total ${matriz.length}.
    ${qtd_combinacoes_verificadas} combinações.
    em ${tempoFormatado} tempo.
    encontramos ${combinacoes_iguais_valor_total.length} combinações onde a soma tenha no máximo uma diferença de 5 centavos:
    ${combinacoes_iguais_valor_total.join('\n    ')}`;


    this.setState({ listaNotas: res, loading: false});

    //this.setState({status: "Concluída as " + this.formatNumber((qtd_combinacoes_verificadas)) + " Validações."});
  
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const {
      status,
      valorTotal,
      listaNotas, 
      loading     
    } = this.state;

    return (
      <>
        <Container>
          <Loading isLoading={loading} />
          <PageTitle
            text="Busca Por Combinações de Notas Fiscais"
            search={''}
            setSearch={""}
          />
          
          <Form className="form-admin">

            <Form.Row>
              <DivStatus >
                Status: {status}
              </DivStatus>
            </Form.Row>
            <br></br>
            <Form.Row>
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  type="text"
                  name="valorTotal"
                  label="Valor Total Recebido"
                  value={valorTotal}
                  maxLength="200"
                  placeholder="Escreva o valor total recebido ex. R$ 10.012,13"
                  onChange={this.handleChange}
                  isInvalid={""}
                />
              </Form.Group>
            </Form.Row>
    
            <Form.Row >
              {/* Historico */}
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  as="textarea"
                  name="listaNotas"
                  label="Lista de Notas"
                  placeholder="Cole aqui a lista com as notas, valor bruto e valor liquido."
                  value={listaNotas}
                  maxLength="10000"
                  style={{ height: "300px"}}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
              <Button
                variant="primary"
                type="button"
                color="#4672C5"
                onClick={this.handleNotaFiscal}
              >
                Nota Fiscal
              </Button>
              &nbsp;
                
            </Form.Row>

          </Form>
          

        </Container>
      
      </>
    );
  } 
}

const mapStoreToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStoreToProps)(InvoiceCombinationSearch);
