import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }

  & * {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #4d4d4d;

    border: none;

    .datetime-popper {
      z-index: 99;
      padding-bottom: 8px;

      & > div .react-datepicker {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__time-list-item--selected {
        background: #4672C5 !important;
        color: #fff;

        &:hover {
          background: #4672C5cc;
        }
      }
    }
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    height: 48px;

    font-family: "Cera Pro Thin", sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #807e80;
    cursor: pointer;

    border: 0.5px solid #4d4c4d77;
    border-radius: 4px;
    outline: none;
    text-align: center;
    background: transparent;
  }

  .hyphen {
    display: flex;
    width: 10px;
    height: 1.5px;
    background: #4672C5;
    border-radius: 1px;

    margin: 8px;
  }

  .space {
    margin: 0 8px;
  }

  .time-inputs {
    display: flex;
    justify-content: center;
    align-items: center;

    .time {
      display: flex;
      position: relative;
      width: max-content;

      label {
        position: absolute;
        top: -8px;
        left: 8px;
        padding: 0 4px;
        background: #fff;
        color: #4d4c4d;
        font-family: "Cera Pro Regular",sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;

        z-index: 10;
      }
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          width: 58px;
          caret-color: transparent;

          @media screen and (min-width: 669px) {
            width: ${({ inputWidth }) => inputWidth ?? "58px"};
          }
        }
      }
    }
  }

  .date-input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

    .date-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      left: 10px;
      z-index: 0;

      svg {
        fill: #4672C5;
      }
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          width: 116px;
          padding-right: 8px;
          text-align: right;
        }
      }
    }
  }
`;
