import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import IsValidName from "../../../../utils/validators/IsValidName";

const initialState = {
  id: 0,
  nome: "",
  descricao: "",
  trainingGroup: {},
  isLoading: false,
  persistedModel: "",
  trainingGroups: []
};

export default class TrainingSubgroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            trainingGroupsOptions: [],
            isEditing: false
        };

        this.api = new InexApiService();

        this.loadTrainingGroups = this.loadTrainingGroups.bind(this);
        this.loadTrainingSubgroup = this.loadTrainingSubgroup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onCleanForm = this.onCleanForm.bind(this);
        this.submit = this.submit.bind(this);
    }

    async componentDidMount() {
        const isEditing = !!this.props.match.params.id;
        this.setState({ isLoading: true, isEditing });
        try {
            await this.loadTrainingGroups();
            if (this.props.match.params.id) {
                await this.loadTrainingSubgroup(this.props.match.params.id);
            }
        } catch (error) {
            Swal.fire(
                "Erro!",
                error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
                "error"
            );
        }

        this.setState({ isLoading: false });
    }

    loadTrainingGroups = async () => {
        let trainingGroupListLocal = [];
        const trainingGroupListfull = await this.api.makeHttpRequest({
            url: "/traininggroup/getAllActiveTrainingGroups"
        });
        
        for (let i in trainingGroupListfull){
            trainingGroupListLocal.push({  value: trainingGroupListfull[i].idTrainingGroup, label: trainingGroupListfull[i].name })
        }   
        

        this.setState({
            trainingGroupOptions: trainingGroupListLocal,
            trainingGroup: trainingGroupListLocal[0]
        });
    };

    loadTrainingSubgroup = async id => {
        const trainingSubgroup = await this.api.makeHttpRequest({
            url: `/trainingsubgroup/${id}`
        });
        
        await this.loadTrainingGroups();

        const persistedModel = {
            id: id,
            nome: trainingSubgroup.name,
            descricao: trainingSubgroup.description
        };

        const persistedModelJson = JSON.stringify(persistedModel);
        this.setState({
            ...persistedModel,
            trainingGroup: {value: trainingSubgroup.idTrainingGroup, label: trainingSubgroup.group },
            isLoading: false,
            persistedModel: persistedModelJson
        });
    };

    submit = async e => {
        e.preventDefault();
        const id = this.props.match.params.id;
        this.setState({ isLoading: true });
        try {
        const reqBase = id
            ? { method: "PUT", url: `/trainingsubgroup/update/${id}` }
            : { method: "POST", url: "/trainingsubgroup/save" };

        await this.api.makeHttpRequest({
            ...reqBase,
            data: {
                name: this.state.nome,
                description: this.state.descricao,
                idTrainingGroup: this.state.trainingGroup.value
            }
        });

        this.afterSubmit();
        } catch (e) {
        Swal.fire(
            "Erro!",
            e?.response?.data
            ? e.response.data
            : "Erro ao cadastrar subgrupo de treinamento, tente novamente mais tarde.",
            "error"
        );
        }

        this.setState({ isLoading: false });
    };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/treinamento-subgrupos");
    }
  };

  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      nome: this.state.nome,
      descricao: this.state.descricao
    };

    const currentModelJson = JSON.stringify(currentModel);

    return (
      this.state.nome &&
      IsValidName(this.state.nome)
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadTrainingSubgroup(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  handleChange = (e, select2Event) => {
    if (select2Event?.name === "trainingGroup") {
        return this.setState({ trainingGroup: e });
    }
    
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Subgrupos de Treinamento - Admin");
    } else {
      window.setPageTitle("Cadastro de Subgrupos de Treinamento - Admin");
    }

    const {
      nome,
      descricao,
      trainingGroup,
      trainingGroupOptions,
      isLoading,
      isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Subgrupo de Treinamento" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Nome */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="nome"
                    label="Nome"
                    placeholder="Digite o nome"
                    value={nome}
                    onChange={this.handleChange}
                    isInvalid={nome && !IsValidName(nome)}
                    required
                  />
                </Form.Group>
                {/* Descrição */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="descricao"
                    label="Descrição"
                    placeholder="Digite a descrição"
                    value={descricao}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select2"
                    name="trainingGroup"
                    label="Grupos"
                    value={trainingGroup}
                    onChange={this.handleChange}
                    placeholder="Selecione o grupo"
                    options={trainingGroupOptions}
                    closeMenuOnSelect={true}
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
      </>
    );
  }
}
