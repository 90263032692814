import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { AdminTitle } from "../../components/AdminTitle";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidDescricao from "../../../../utils/validators/IsValidDescricao";
import IsValidEmailAlocator from "../../../../utils/validators/IsValidEmailAlocator";
const initialState = {
  id: 0,
  nome: "",
  cliente: "",
  coordenador: "",
  gerenteComercial: "",
  coordenacao: "",
  projeto:"",
  clientCoordinator: "", //Thiago Excluir:
  factoryProjectName: "",
  totalHours: "",
  factoryProjectEndDate: "",
  profissionais: [],
  clientManager: "",
  alocatorName: "",
  alocatorEmail: "",
  descricao: "",
  isLoading: false,
  persistedModel: "",
  idRFQ: ""
};

export default class FactoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      projectOptions: [],
      gerentesOptions: [],
      clientesOptions: [],
      rfqsOptions: [],

      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.loadProjeto = this.loadProjeto.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    //this.loadPayingArea = this.loadPayingArea.bind(this);
    this.loadProposals = this.loadProposals.bind(this);
    //this.loadCoordination = this.loadCoordination.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });
    try {
      const reqs = [
        this.loadClientes(),
        this.loadProposals()
      ];

      if (this.props.match.params.id) {
        reqs.push(this.loadProjeto(this.props.match.params.id));
      }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response ? error?.response?.data : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadProjeto = async id => {
    const project = await this.api.makeHttpRequest({
      url: `/project/${id}`
    });

    if (project.client.idClient) {
      //this.loadEmployees(project.client.idClient);
      //this.loadCoordination(project.client.idClient);
      //this.loadPayingArea(project.projectArea.idProjectArea);
    }

    const usersFormatted =
      project.professionals?.length > 0
        ? project.professionals.map(item => ({
            label: item.name,
            value: item.idUser,
            idBusinessProposalValue: item.idBusinessProposalValue
          }))
        : [];

    const currentClient = {
      label: project.client.name,
      value: project.client.idClient,
      isDisabled: project.client.deletionDate !== null ?? false,
    }

    const currentCoordination = {
      label: project.projectArea.name,
      value: project.projectArea.idProjectArea,
      isDisabled: project.projectArea.deletionDate !== null ?? false,
    }

    const currentPayingArea = {
      label: project.payingArea.name,
      value: project.payingArea.idPayingArea,
      isDisabled: project.payingArea.deletionDate !== null ?? false,
    }

    // Client
    if(project.client.deletionDate !== null) {
      this.setState({ clientesOptions: [...this.state.clientesOptions, currentClient] })
    }

    // Thiago PayingArea Excluir depois de descobrir o que vem nesse load. Lembrando que eu já exclui o bloco de cima que era sobre a coordenação. :(
    /* if(project.payingArea.deletionDate !== null) {
      this.setState({ payingAreaOptions: [...this.state.payingAreaOptions, currentPayingArea] })
    } */

    const persistedModel = {
      id: id,
      nome: project.name,
      cliente: currentClient,
      coordenador: project.coordinator.idUser,
      gerenteComercial: project.commercial.idUser,
      coordenacao: currentCoordination,
      descricao: project.description,
      clientCoordinator: project.clientCoordinator,
      clientManager: project.clientProjectManager,
      alocatorName: project.alocatorName,
      alocatorEmail: project.alocatorEmail,
      profissionais: usersFormatted
    };

    const persistedModelJson = JSON.stringify(persistedModel);
    this.setState({
      ...persistedModel,
      isLoading: false,
      persistedModel: persistedModelJson
    });
  };

  loadClientes = async () => {
    const clientes = await this.api.makeHttpRequest({
      url: "/project/selects" //Thiago criar uma busca dos selects especialmente para Fabrica.
    });

    const clientOptions =
      clientes.clients?.length > 0
        ? clientes.clients.map(item => ({
            label: item.name,
            value: item.idClient,
            isDisabled: false
          }))
        : [];

    this.setState({
      clientesOptions: clientOptions,
      gerentesOptions: clientes.commercialManagers
    });
  };

  //Thiago Trocar essa consulta de coordenação por Projeto. 
  /* loadCoordination = async idClient => {
    this.setState({ isLoading: true });
    const coordinations = await this.api.makeHttpRequest({
      url: `/project/coordinations-by-client/${idClient}`
    });

    const coordinationOptions =
      coordinations.coordinations.length > 0
        ? coordinations.coordinations.map(item => ({
            label: item.name,
            value: item.idProjectArea,
            isDisabled: false
          }))
        : [];

    this.setState({
      coordinationOptions,
      isLoading: false
    });
  }; */

  // Thiago Excluir esse bloco quando estiver polindo. 
  // Área Pagadora
  /* loadPayingArea = async idCoordination => {
    this.setState({ isLoading: true });
    const payingAreas = await this.api.makeHttpRequest({
      url: `/project/paying-area-by-coordination/${idCoordination}`
    });

    const payingAreaOptions =
      payingAreas?.length > 0
        ? payingAreas.map(item => ({
            label: item.name,
            value: item.idPayingArea,
            isDisabled: false
          }))
        : [];

    if (payingAreaOptions?.length <= 0) {
      Swal.fire(
        "Aviso!",
        "Não existem Áreas Pagadoras para esta Coordenação.",
        "warning"
      );
    }

    this.setState({
      payingAreaOptions,
      isLoading: false
    })
  } */

  // Projetos
  loadProjects = async idClient => {
    this.setState({ isLoading: true });
    const projectList = await this.api.makeHttpRequest({
      url: `/project/projects-by-client/${idClient}`
    });

    const projectOptions =
      projectList?.length > 0
        ? projectList.map(item => ({
            label: item.name,
            value: item.idPayingArea,
            isDisabled: false
          }))
        : [];

    if (projectOptions?.length <= 0) {
      Swal.fire(
        "Aviso!",
        "Não existem Áreas Pagadoras para esta Coordenação.",
        "warning"
      );
    }

    this.setState({
      projectOptions,
      isLoading: false,
    })
  }

  loadProposals = async () => {
    const proposals = await this.api.makeHttpRequest({
      url: "/project/all-rfq"
    });

    const formattedProposal =
      proposals.length > 0
        ? proposals.map(prop => ({
            value: prop.idBusinessProposalValue,
            label: prop.profileDescription
          }))
        : [];

    this.setState({
      rfqsOptions: formattedProposal
    });

  };

  submit = async e => {
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/project/update/${id}` }
        : { method: "POST", url: "/project/save" };

      const unFormattedUsers =
        this.state.profissionais?.length > 0
          ? this.state.profissionais.map(item => ({
              IdUser: item.value,
              idBusinessProposalValue: Number(item.idBusinessProposalValue) ?? null
            }))
          : [];

      await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          name: this.state.nome,
          description: this.state.descricao,
          idClient: this.state.cliente.value,
          idCoordinator: this.state.coordenador,
          idCommercial: this.state.gerenteComercial,
          clientCoordinator: this.state.clientCoordinator,
          clientProjectManager: this.state.clientManager,
          alocatorName: this.state.alocatorName,
          alocatorEmail: this.state.alocatorEmail,
          idProjectArea: this.state.coordenacao.value,
          professionals: unFormattedUsers
        }
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar projeto, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/projetos");
    }
  };

  handleChange = (e, select2Event) => {
    if (select2Event?.name === "cliente") {
      this.setState({
        profissionais: [],
        coordenacao: "",
        projeto:""
      });

      if (e?.value) {
        //this.loadEmployees(e.value);
        //this.loadCoordination(e.value);

        this.loadProjects(e.value);


      } else {
        this.setState({
          profissionais: []
        });
      }

      return this.setState({ cliente: e });
    }


    this.setState({
      [e.target.name]: e.target.value
    });
  };


  formIsValid = () => {
    const { isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,
      nome: this.state.nome,
      cliente: this.state.cliente,
      coordenador: this.state.coordenador,
      gerenteComercial: this.state.gerenteComercial,
      coordenacao: this.state.coordenacao,
      clientCoordinator: this.state.clientCoordinator,
      clientManager: this.state.clientManager,
      alocatorName: this.state.alocatorName,
      alocatorEmail: this.state.alocatorEmail,
      profissionais: this.state.profissionais
    };

    const currentModelJson = JSON.stringify(currentModel);

    return (
      this.state.nome &&
      IsValidName(this.state.nome) &&
      this.state.cliente &&
      this.state.coordenador &&
      this.state.coordenacao &&
      this.state.projeto &&
      this.state.gerenteComercial &&
      this.state.clientCoordinator &&
      this.state.factoryProjectName &&
      this.state.totalHours &&
      this.state.factoryProjectEndDate &&
      IsValidName(this.state.clientCoordinator) &&
      this.state.clientManager &&
      IsValidName(this.state.clientManager) &&
      IsValidName(this.state.alocatorName) &&
      IsValidEmailAlocator(this.state.alocatorEmail)
    );
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadClient(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Projeto - Admin");
    } else {
      window.setPageTitle("Cadastro de Projeto - Admin");
    }

    const {
      cliente,
      coordenador,
      coordenacao,
      projeto,
      idRFQ,
      profissionais,
      descricao,
      clientCoordinator,
      factoryProjectName,
      totalHours,
      factoryProjectEndDate,
      clientManager,
      isLoading,
      clientesOptions,
      rfqsOptions,
      projectOptions,
      isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Projeto de Fabrica" disableHeader />
        <Card className="mt-4">
          <StyledCardBody>
            <Form className="form-admin" style={{ flex: "1" }}>
              {/* row 1 */}
              <h1 className="card-title">Dados do Projeto de Fábrica </h1>
              <Form.Row>
                {/* Nome do Projeto da Fábrica */}
                <Form.Group as={Col} lg="6" xs="12">
                  <InputField
                    type="text"
                    name="factoryProjectName"
                    label="Projeto de Fábrica"
                    placeholder="Digite o nome do Projeto de Fábrica"
                    value={factoryProjectName}
                    onChange={this.handleChange}
                    /* isInvalid={
                      clientCoordinator && !IsValidName(clientCoordinator)
                    } */
                    required
                  />
                </Form.Group>

                {/* Quantidade de Horas do Projeto Fábrica */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="totalHours"
                    label="Total de Horas"
                    placeholder="Digite qtd de Horas: 400h20m"
                    value={totalHours}
                    onChange={this.handleChange}
                    /* isInvalid={clientManager && !IsValidName(clientManager)} */
                    required
                  />
                </Form.Group>

                {/* Data limite para concluir o Projeto de Fábrica */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="factoryProjectEndDate"
                    label="Data Fim do Projeto"
                    placeholder="escolha a data fim"
                    value={factoryProjectEndDate}
                    onChange={this.handleChange}
                    /* isInvalid={clientManager && !IsValidName(clientManager)} */
                    required
                  />
                </Form.Group>

                

              </Form.Row>
              <Form.Row>
                 {/* RFQ */}
                <Form.Group as={Col} lg="12" xs="12">
                  <InputField
                    as="select2"
                    name="idRFQ"
                    label="RFQ"
                    value={idRFQ}
                    onChange={this.handleChange}
                    placeholder="Selecione a RFQ"
                    options={rfqsOptions}  
                    closeMenuOnSelect={true}
                    required
                  />
                </Form.Group>

              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <Card className="mt-4">
          <StyledCardBody>
            <Form style={{ flex: "1" }}>
              {/* row 1 */}
              <Form.Row>
                {/* Cliente */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    as="select2"
                    name="cliente"
                    label="Cliente"
                    value={cliente}
                    onChange={this.handleChange}
                    placeholder="Selecione o cliente"
                    options={clientesOptions}
                    closeMenuOnSelect={true}
                    required
                  />
                </Form.Group>

                {/* Projeto */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    as="select2"
                    name="projeto"
                    label="projeto"
                    value={projeto}
                    onChange={this.handleChange}
                    placeholder="Selecione o Projeto"
                    options={projectOptions}
                    closeMenuOnSelect={true}
                    disabled={cliente?.length <= 0 ? true : false}
                    required
                  />
                </Form.Group>

                {/* Coordenação Cliente*/}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="coordenacao"
                    label="Coordenação Cliente"
                    placeholder=""
                    value={coordenacao}
                    disabled={true}
                  />
                </Form.Group>

                {/* Área pagadora */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="coordenadorOperacoes"
                    label="Coordenador de Operação Inex"
                    placeholder=""
                    value={coordenador}
                    disabled={true}
                  />
                </Form.Group>
              </Form.Row>

              {/* row 2 */}
              <Form.Row style={{ height: "fit-content" }}>
                {/* Alocações */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    isMulti
                    as="select2"
                    name="profissionais"
                    label="Alocações"
                    value={profissionais}
                    placeholder="Profissionais Alocados"
                    disabled={true}
                  />
                </Form.Group>

                 {/* Nome do Gerente de Projetos */}
                 <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="clientManager"
                    label="Gerente do Projeto (SM)"
                    placeholder="Gerente do Projeto"
                    value={clientManager}
                    disabled={true}
                  />
                </Form.Group>

                {/* Descrição */}
                <Form.Group as={Col} lg="4" xs="6">
                  <InputField
                    as="textarea"
                    name="descricao"
                    label="Descrição"
                    placeholder="Descreva o projeto"
                    style={{ height: "72px", marginBottom: "-5px" }}
                    value={descricao}
                    disabled={true}
                  />
                </Form.Group>

               
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        
        
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          hideReload
        />
        
      </>
    );
  }
}
