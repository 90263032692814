import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin: 40px 0 0 auto;

  .info {
    display: flex;
    align-items: center;

    font-family: "Cera Pro Light", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #828282;

    margin-right: 16px;
  }

  .icon {
    display: flex;
    align-items: center;
    width: max-content;
    height: max-content;

    margin-right: 14px;
    padding: 8px;
    cursor: pointer;

    stroke: #807e80;

    &:last-child {
      margin-right: 0;
    }

    &:hover:not(.disabled) {
      stroke: #4672C5;
    }

    &.next,
    &.last {
      transform: rotate(180deg);
    }

    &.disabled {
      opacity: 0.6;
      cursor: default;
    }
  }
`;
